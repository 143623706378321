define(['lodash', 'coreUtils', 'core/core/data/apiSections/AnchorsDataAPI', 'core/core/data/structureInfoGetter'], function (_, coreUtils, AnchorsDataAPI, structureInfoGetter) {
    'use strict';

    function isInPagesDataPath(path) {
        return path && path.length > 0 && path[0] === 'pagesData';
    }

    function isPagesDataPath(path) {
        return _.isEqual(path, ['pagesData']);
    }

    function isPagePointer(pointer) {
        return this.pointers.getPointerType(pointer) === 'page';
    }

    function isDataPointer(pointer) {
        return pointer && _.includes(coreUtils.constants.DATA_TYPES, pointer.type);
    }

    function updateDisplayedJsonByPointer(pointer, activeModes) {
        const nodePath = this.fullCache.getPath(pointer);
        if (!nodePath) {
            throw new Error('pointer path does not exist');
        }
        if (isPagesDataPath(nodePath)) {
            const fullPagesData = _.get(this.fullJson, nodePath);
            updateDisplayedPagesData.call(this, fullPagesData, activeModes);
        } else if (isInPagesDataPath(nodePath)) {
            updateDisplayedJsonInPagesDataByPointer.call(this, pointer, activeModes);
        } else {
            const fullStructureNode = _.get(this.fullJson, nodePath);
            this.displayedJsonDAL.set(pointer, fullStructureNode);
        }
    }

    function updateDisplayedPagesData(fullPagesData, activeModes, withAnchors) {
        const pagesDataPointer = this.pointers.page.getAllPagesPointer();
        this.displayedJsonDAL.set(pagesDataPointer, {});
        if (fullPagesData.masterPage) {
            updateDisplayedPage.call(this, activeModes, 'masterPage', true);
        }
        _(fullPagesData)
            .omit('masterPage')
            .keys()
            .forEach(rootId => updateDisplayedPage.call(this, activeModes, rootId, withAnchors));
    }

    function updateDisplayedPage(activeModes, rootId, withAnchors) {
        activeModes[rootId] = activeModes[rootId] || {};
        const pagePointer = this.pointers.page.getPagePointer(rootId) || this.pointers.page.getNewPagePointer(rootId);
        this.displayedJsonDAL.set(pagePointer, {});
        updateDisplayedJsonInPagesDataByPointer.call(this, pagePointer, activeModes, withAnchors);
    }

    function updateDisplayedRoot(activeModes, fullPage, pageId, experimentContext) { // eslint-disable-line complexity
        const rootId = fullPage.structure.id || pageId;
        const getStructureInfo = structureInfoGetter(this.pointers, this.fullJsonGetters, rootId);
        const displayedDataAndStructure = coreUtils.fullToDisplayedJson.getDisplayedJson(fullPage, activeModes, rootId, getStructureInfo, this.pointersMapApi, experimentContext);
        const rootPointer = this.pointers.page.getPagePointer(rootId) || this.pointers.page.getNewPagePointer(rootId);
        const rootPath = this.fullCache.getPath(rootPointer);
        const includeMobile = _.has(displayedDataAndStructure.structure, 'mobileComponents');
        const dal = this.displayedJsonDAL;
        const pointers = this.pointers;
        const currentLang = dal.get(pointers.multilingual.currentLanguageCode()) || null;
        const originalLangPointer = pointers.multilingual.originalLanguage();
        const originalLang = dal.isExist(originalLangPointer) ? dal.get(originalLangPointer).languageCode : null;
        if (!_.isNull(currentLang) && !_.isNull(originalLang) && currentLang !== originalLang) {
            const translatedData = _.cloneDeep(_.get(fullPage, `translations.${currentLang}.data.document_data`, {}));
            _.assign(displayedDataAndStructure.data.document_data, translatedData);
        }

        const flatDataAndStructure = _.defaults({structure: flatStructure(displayedDataAndStructure.structure, {includeMobile, id: pageId})}, displayedDataAndStructure);
        setStructureAndData.call(this, rootPointer, flatDataAndStructure, rootPath);
        this.updateDisplayedLayoutFunc(this.pointers.components.getPage(rootId, coreUtils.constants.VIEW_MODES.DESKTOP));
        this.updateDisplayedLayoutFunc(this.pointers.components.getPage(rootId, coreUtils.constants.VIEW_MODES.MOBILE));
        return displayedDataAndStructure;
    }

    function updateDisplayedJsonInPagesDataByPointer(pointer, activeModes, withAnchors = true) { // eslint-disable-line complexity
        const nodePath = this.fullCache.getPath(pointer);
        if (this.pointers.page.isPointerPageType(pointer)) {
            const fullPage = _.get(this.fullJson, nodePath);
            updateDisplayedRoot.call(this, activeModes, fullPage, pointer.id, this.displayedJsonDAL.jsonData);

            if (withAnchors) {
                this.anchorsAPI.createPageAnchors(pointer.id);
            }
            return;
        }

        if (!nodePath) {
            return;
        }

        const pagePath = _.take(nodePath, 2);
        const pageId = _.last(pagePath);

        const translationNodePath = this.fullCache.getPath(_.assign({}, pointer, {multilingual: coreUtils.multilingual.PointerOperation.GET}));
        const fullJsonNode = _.get(this.fullJson, translationNodePath);

        if (isInPagesDataPath(nodePath) && !isDataPointer(pointer)) {
            const fullStructure = fullJsonNode.structure ? fullJsonNode.structure : fullJsonNode;
            activeModes[pageId] = resolveAndUpdateActiveModes.call(this, fullStructure, activeModes[pageId], pageId);
            const getStructureInfo = this.pointers.page.isPointerPageType(pointer) ? structureInfoGetter(this.pointers, this.fullJsonGetters, pageId) : structureInfoGetter(this.pointers, this.displayedJsonDAL, pageId);
            const displayedDataAndStructure = coreUtils.fullToDisplayedJson.getDisplayedJson(fullJsonNode, activeModes, pageId, getStructureInfo, this.pointersMapApi, this.displayedJsonDAL.jsonData);
            const flatDataAndStructure = _.defaults({structure: flatStructure(displayedDataAndStructure.structure, {viewMode: pointer.type})}, displayedDataAndStructure);
            const parent = this.pointers.components.getParent(pointer);
            if (parent) {
                flatDataAndStructure.structure[pointer.type][fullStructure.id].parent = parent.id;
            }
            setStructureAndData.call(this, pointer, flatDataAndStructure, pagePath);
            if (this.pointers.page.isPointerPageType(pointer) || this.pointers.components.isPage(pointer)) {
                this.updateDisplayedLayoutFunc(this.pointers.components.getPage(pageId, coreUtils.constants.VIEW_MODES.DESKTOP));
                this.updateDisplayedLayoutFunc(this.pointers.components.getPage(pageId, coreUtils.constants.VIEW_MODES.MOBILE));
                this.anchorsAPI.createPageAnchors(pageId);
            } else {
                this.updateDisplayedLayoutFunc(pointer);
            }
        } else {
            this.displayedJsonDAL.set(pointer, coreUtils.objectUtils.cloneDeep(fullJsonNode));
        }
    }

    function resolveAndUpdateActiveModes(fullStructureNode, rootActiveModes, pageId) {
        const compTreeActiveModes = coreUtils.modesUtils.resolveCompActiveModesRecursive(fullStructureNode, rootActiveModes);
        _.assign(rootActiveModes, compTreeActiveModes);
        const activeModesPointer = this.pointers.general.getActiveModes();
        const pageActiveModesPointer = this.pointers.getInnerPointer(activeModesPointer, pageId);
        this.displayedJsonDAL.set(pageActiveModesPointer, rootActiveModes);
        return rootActiveModes;
    }

    const convertNestedStructureToFlat = coreUtils.componentUtils.convertNestedStructureToFlat;
    function flatStructure(structure, {viewMode = 'ALL', includeMobile = true, id = 'masterPage'}) {
        if (_.isEmpty(structure)) {
            return {};
        }
        if (!structure.id) {
            structure = _.defaults({id}, structure);
        }
        const DESKTOP = viewMode !== 'MOBILE' && convertNestedStructureToFlat(structure, coreUtils.constants.VIEW_MODES.DESKTOP);
        const MOBILE = includeMobile && (viewMode !== 'DESKTOP' || structure.mobileComponents) && convertNestedStructureToFlat(structure, coreUtils.constants.VIEW_MODES.MOBILE);

        return _.pickBy({DESKTOP, MOBILE});
    }

    function setStructureAndData(pointer, flatDataAndStructure, pagePath) {
        if (this.pointers.full.components.isPage(pointer)) {
            const pageStructurePointer = this.pointers.getInnerPointer(this.pointers.page.getPagePointer(pointer.id), 'structure');
            this.displayedJsonDAL.merge(pageStructurePointer, flatDataAndStructure.structure);
            return;
        }

        if (!isPagePointer.call(this, pointer)) {
            const {id: pageId} = this.pointers.full.components.getPageOfComponent(pointer);
            const {getRepeaterItemId, getUniqueFlatStructureMap} = coreUtils.displayedOnlyStructureUtil;
            _.forEach(this.pointers.components.getAllDisplayedOnlyComponents(pointer), compPointer => {
                const itemId = getRepeaterItemId(compPointer.id);
                if (itemId) {
                    const layout = this.displayedJsonDAL.get(this.pointers.getInnerPointer(compPointer, 'layout'));
                    const parent = this.displayedJsonDAL.get(this.pointers.getInnerPointer(compPointer, 'parent'));
                    const componentsMap = getUniqueFlatStructureMap(flatDataAndStructure.structure[this.pointers.components.getViewMode(pointer)], itemId, this.pointers.components.registerDisplayedOnlyComponent);
                    componentsMap[compPointer.id] = _.defaults({layout, parent}, componentsMap[compPointer.id]);

                    const componentsMapPointer = this.pointers.page.getComponentsMapPointer(pageId, pointer.type);
                    this.displayedJsonDAL.merge(componentsMapPointer, componentsMap);
                } else {
                    _.forEach(flatDataAndStructure.structure, (componentsMap, viewMode) => {
                        const viewModeCompPointer = viewMode === 'DESKTOP' ?
                            this.pointers.components.getDesktopPointer(pointer) :
                            this.pointers.components.getMobilePointer(pointer);
                        const currentComps = this.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(viewModeCompPointer);
                        _(currentComps)
                            .pickBy(({id}) => !componentsMap[id])
                            .forEach(removedComp => {
                                this.displayedJsonDAL.remove(removedComp);
                            });

                        const compsMapPointer = this.pointers.page.getComponentsMapPointer(pageId, viewMode);
                        this.displayedJsonDAL.merge(compsMapPointer, componentsMap);
                    });
                }
            });
            return;
        }

        if (!_.get(this.displayedJsonDAL.jsonData, pagePath)) {
            this.displayedJsonDAL.set(pointer, flatDataAndStructure);
            return;
        }

        if (!_.isEqual(this.displayedJsonDAL.get(pointer), flatDataAndStructure)) {
            this.displayedJsonDAL.merge(pointer, flatDataAndStructure);
        }
    }

    function registerDisplayedOnlyComponent(pointers, compId, displayedCompId) {
        pointers.components.registerDisplayedOnlyComponent(compId, displayedCompId);
    }

    function clearDisplayedOnlyComponents(pointers, compId) {
        pointers.components.clearDisplayedOnlyComponents(compId);
    }

    function getPointersMapApi(pointers) {
        return {
            update: _.partial(registerDisplayedOnlyComponent, pointers),
            clear: _.partial(clearDisplayedOnlyComponents, pointers)
        };
    }

    /**
     * ViewerDisplayedJsonUpdater
     * @param fullJson
     * @param displayedJsonDAL
     * @param fullJsonCache
     * @param dataAccessPointers
     * @constructor
     */
    function ViewerDisplayedJsonUpdater(fullJson, displayedJsonDAL, fullJsonCache, dataAccessPointers, updateDisplayedLayoutFunc) {
        if (!displayedJsonDAL) {
            return; //so that empty constructor won't crush
        }
        this.fullJson = fullJson;
        this.displayedJsonDAL = displayedJsonDAL;
        this.fullCache = fullJsonCache;
        this.pointers = dataAccessPointers;
        //TODO: Alissa remove this
        this.anchorsAPI = new AnchorsDataAPI(displayedJsonDAL.jsonData, dataAccessPointers, displayedJsonDAL);
        this.pointersMapApi = getPointersMapApi(dataAccessPointers);
        this.fullJsonGetters = {
            get(pointer) {
                const path = fullJsonCache.getPath(pointer);
                return coreUtils.objectUtils.cloneDeep(_.get(fullJson, path));
            }
        };
        this.updateDisplayedLayoutFunc = updateDisplayedLayoutFunc;
    }

    ViewerDisplayedJsonUpdater.prototype = {
        updateDisplayedJsonByPointer,

        //used only in DS
        updateDisplayedJsonInPagesDataByPointer,

        //used only in DS
        updateDisplayedPagesData,

        updateDisplayedRoot
    };

    return ViewerDisplayedJsonUpdater;
});
