define([
    'lodash',
    'coreUtils',
    'componentsCore',
    'core/core/viewerCompsService'
], function (
    _,
    coreUtils,
    componentsCore,
    viewerCompsService
) {
    'use strict';

    const TYPE_MAP = {
        Image: 'wysiwyg.components.imageZoom',
        PermaLink: 'wixapps.integration.components.AppPartZoom'
    };

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function CompsLog() {
        this._comps = {};
    }
    CompsLog.prototype = {
        remember(componentType) {
            this._comps[componentType] = true;
        },
        getAndClear() {
            const result = _.keys(this._comps);
            this._comps = {};
            return result;
        }
    };

    function getCompsLog(siteData) {
        let compsLog = privates.get(siteData);
        if (!compsLog) {
            compsLog = new CompsLog();
            privates.set(siteData, compsLog);
        }
        return compsLog;
    }

    function getRequireForComp(siteData, componentType) {
        if (!siteData.selectiveCompDownload) {
            return;
        }

        const compsLog = getCompsLog(siteData);

        compsLog.remember(componentType);

        const listComponents = siteData.currentUrl.query.listComponents;
        if (listComponents === 'all') {
            coreUtils.log.info('Using component', componentType);
        }

        if (viewerCompsService.isComponentLoaded(componentType) || !viewerCompsService.exists(componentType, siteData)) {
            return;
        }

        if (listComponents === 'requested' || listComponents === 'true') {
            //coreUtils.log.info('Requesing component', componentType, packageNames);
            coreUtils.log.info('Requesing component', componentType);
        }

        return {
            customDownload() {
                viewerCompsService.loadAndRegister(componentType, siteData)
                    .then(() => {
                        this.done();
                    });
            }
        };
    }

    function callAllRequiredPackagesCallbacks(loadedPackages) {
        const packagesNamesWithCallbacks = coreUtils.getAllPackagesForAllComponents();
        const packageNames = _.keys(packagesNamesWithCallbacks);
        _.forEach(packageNames, packageName =>
            packagesNamesWithCallbacks[packageName](loadedPackages[packageName], componentsCore.compRegistrar.register, componentsCore.siteAspectsRegistry.registerHostLibsAspect, loadedPackages.skins.skinsMap.addBatch));
    }

    function callNativePackageCallback(loadedPackage, clientSpecMap) {
        const packagesNamesWithCallbacks = coreUtils.getAllPackagesForAllComponents();
        const requiredModules = coreUtils.nativeComponents(clientSpecMap);
        _.forEach(requiredModules, ({type}) =>
            packagesNamesWithCallbacks[type](loadedPackage[type], componentsCore.compRegistrar.register, componentsCore.siteAspectsRegistry.registerHostLibsAspect));
    }

    return {
        get: getRequireForComp,

        forDataItem(siteData, dataItemType) {
            const componentType = TYPE_MAP[dataItemType];
            if (componentType) {
                return getRequireForComp(siteData, componentType);
            }
        },

        allButNativeComponents: coreUtils.allButNativeComponents,

        callNativePackageCallback,

        requested(siteData) {
            const compsLog = privates.get(siteData);
            return compsLog ? compsLog.getAndClear() : [];
        },

        all: coreUtils.allPackages,

        callAllRequiredPackagesCallbacks
    };
});
