define([
    'lodash',
    'core/core/externalComponentsServices'
], function (
    _,
    externalComponentsServices
) {
    'use strict';

    const register = service => {
        externalComponentsServices.register(service);
    };

    return {register};
});


