/* eslint-disable santa/no-module-state */

define(['coreUtils', 'lodash'], function (coreUtils, _) {
    'use strict';

    let appUrl, collectionId, baseUrl, metaSiteId, verifyTokenUrl, logoutUrl, resendEmailVerification, customRegisterUrl, authorizedPagesUrl, siteRevision;
    const types = {
        POST: 'POST',
        GET: 'GET'
    };
    const path = {
        login: '/_api/wix-sm-webapp/member/login',
        register: '/_api/wix-sm-webapp/member/register',
        customRegister: '/_api/wix-sm-webapp/v1/auth/register',
        apply: '/_api/wix-sm-webapp/member/apply',
        sendForgotPasswordMail: '/_api/wix-sm-webapp/member/sendForgotPasswordMail',
        resetMemberPassword: '/_api/wix-sm-webapp/member/changePasswordWithMailToken',
        getMemberDetails: '/_api/wix-sm-webapp/member',
        handleOauthToken: '/_api/wix-sm-webapp/social/token/handle',
        verify: '/_api/wix-sm-webapp/tokens/verify',
        authorize: '/api/wix-sm/v1/authorize',
        logout: '/_api/wix-sm-webapp/tokens/logout',
        resendEmailVerification: '/_api/wix-sm-webapp/tokens/email/resend'
    };

    const SiteMembersAPI = { };

    SiteMembersAPI.initializeData = function (siteData) {
        metaSiteId = siteData.getMetaSiteId();
        siteRevision = siteData.getSiteRevision();
        const collection = siteData.getClientSpecMapEntriesByType('sitemembers')[0];
        const publicBaseUrl = siteData.getPublicBaseUrl();

        collectionId = collection && collection.smcollectionId;
        baseUrl = `//${siteData.currentUrl.host}`;
        verifyTokenUrl = `//${siteData.currentUrl.host}${path.verify}/${metaSiteId}/${siteData.siteId}`;
        logoutUrl = `//${siteData.currentUrl.host}${path.logout}/${metaSiteId}`;
        resendEmailVerification = `//${siteData.currentUrl.host}${path.resendEmailVerification}`;
        appUrl = siteData.currentUrl.full;
        customRegisterUrl = `https://${siteData.currentUrl.host}${path.customRegister}`;
        authorizedPagesUrl = `${publicBaseUrl}${path.authorize}/${siteData.siteId}/pages`;
    };

    function extractSignedToken(payload) {
        const val = _.get(payload, 'sessionToken') || _.get(payload, ['smSession', 'sessionToken']) || '';
        return _.get(val.match(/^JWS\.(.+)/), 0);
    }

    function handleResponse(onSuccess, onError, data, isPlatformizedEndpoint) {
        if (_.get(data, 'errorCode')) {
            onError(data.errorCode);
            return;
        }
        if (isPlatformizedEndpoint) {
            data = {
                payload: _.merge({}, {
                    sessionToken: _.get(data, ['session', 'token']),
                    status: _.get(data, ['member', 'status']),
                    siteMemberDto: {
                        id: _.get(data, ['member', 'id'])
                    },
                    raw: _.cloneDeep(data)
                })
            };
        }
        const signedToken = extractSignedToken(data.payload);
        if (signedToken) {
            sendVerifyTokenRequest(signedToken, onSuccess, onError, data.payload);
        } else {
            onSuccess(data);
        }
    }

    function sendSiteMemberTokenRequest(body, onSuccess, onError) {
        const query = `?${coreUtils.urlUtils.toQueryString({
            collectionId,
            metaSiteId
        })}`;
        sendSiteMembersRequestInternal({
            type: 'POST',
            url: baseUrl + path.handleOauthToken + query,
            data: body
        }, onSuccess, onError);
    }

    function sendSiteMembersRequest(reqPath, data, type, onSuccess, onError, svSession, initiator, previewMode) {
        const body = _.merge(data || {}, {collectionId, metaSiteId});
        if (svSession) {//optional parameters
            _.merge(body, {svSession, appUrl});
            if (initiator) { //login with initiator is only done when svsession is available
                body.initiator = initiator;
            }
        }
        sendSiteMembersRequestInternal({
            type,
            url: baseUrl + reqPath,
            data: body,
            asForm: true
        }, onSuccess, onError, previewMode);
    }

    function sendVerifyTokenRequest(signedToken, onSuccess, onError, previousPayload) {
        const onSuccessWrapper = function (response) {
            const mergedResponses = _.merge({}, response, {
                httpOnlySession: true,
                payload: previousPayload
            });
            onSuccess(mergedResponses);
        };
        sendSiteMembersRequestInternal({
            type: 'POST',
            url: verifyTokenUrl,
            data: {token: signedToken},
            asForm: true
        }, onSuccessWrapper, onError);
    }

    function sendSiteMembersRequestInternal(request, onSuccess, onError, previewMode) {
        let ajaxObj = {
            type: request.type,
            url: request.url,
            data: request.data,
            asForm: request.asForm,
            dataType: 'json',
            jsonp: false,
            success(data) {
                handleResponse(onSuccess, onError, data, request.isPlatformizedEndpoint);
            },
            error: onError
        };

        ajaxObj.headers = {
            'x-wix-site-revision': siteRevision
        };
        if (request.data) {
            _.assign(ajaxObj.headers, {
                accept: 'application/json',
                'Content-Type': request.asForm ? 'application/x-www-form-urlencoded' : 'application/json'
            });
        }
        if (request.headers) {
            _.assign(ajaxObj.headers, request.headers);
        }

        if (previewMode === true) {
            ajaxObj = _.merge(ajaxObj, {
                crossDomain: true,
                xhrFields: {
                    withCredentials: true
                }
            });
        }
        coreUtils.ajaxLibrary.ajax(ajaxObj);
    }

    SiteMembersAPI.login = function (loginData, onSuccess, onError, svSession, initiator) {
        sendSiteMembersRequest(path.login, loginData, types.POST, onSuccess, onError, svSession, initiator);
    };

    SiteMembersAPI.register = function (registerData, onSuccess, onError, svSession, initiator) {
        sendSiteMembersRequest(path.register, registerData, types.POST, onSuccess, onError, svSession, initiator);
    };

    SiteMembersAPI.apply = function (registerData, onSuccess, onError, svSession, initiator) {
        sendSiteMembersRequest(path.apply, registerData, types.POST, onSuccess, onError, svSession, initiator);
    };

    SiteMembersAPI.sendForgotPasswordMail = function (data, onSuccess, onError) {
        sendSiteMembersRequest(path.sendForgotPasswordMail, {
            email: data.email,
            returnUrl: data.homePageUrl,
            lang: data.lang
        }, types.POST, onSuccess, onError);
    };

    SiteMembersAPI.resetMemberPassword = function (forgotPasswordData, onSuccess, onError) {
        sendSiteMembersRequest(path.resetMemberPassword, forgotPasswordData, types.POST, onSuccess, onError);
    };

    SiteMembersAPI.getMemberDetails = function (smToken, onSuccess, onError, previewMode) {
        smToken = smToken ? smToken : '';
        sendSiteMembersRequest(`${path.getMemberDetails}/${smToken}`, null, types.GET, onSuccess, onError, null, null, previewMode);
    };

    SiteMembersAPI.handleOauthToken = function (onSuccess, onError, data) {
        sendSiteMemberTokenRequest(data, onSuccess, onError);
    };

    SiteMembersAPI.logout = function (onSuccess, onError) {
        sendSiteMembersRequestInternal({type: 'POST', url: logoutUrl, asForm: true}, onSuccess, onError);
    };

    SiteMembersAPI.resendEmailVerification = function (pendingMemberId, onSuccess, onError) {
        sendSiteMembersRequestInternal({type: 'GET', url: `${resendEmailVerification}/${pendingMemberId}`, asForm: false}, onSuccess, onError);
    };

    SiteMembersAPI.authByToken = function (token, onSuccess, onError) {
        sendVerifyTokenRequest(token, onSuccess, onError, {});
    };

    SiteMembersAPI.customRegister = function (registerData, onSuccess, onError) {
        const Authorization = registerData.instance;
        sendSiteMembersRequestInternal({
            type: types.POST,
            url: customRegisterUrl,
            data: {
                email: registerData.email,
                password: registerData.password,
                contactInfo: registerData.contactInfo
            },
            headers: {Authorization},
            isPlatformizedEndpoint: true
        }, onSuccess, onError);
    };

    SiteMembersAPI.getAuthorizedPages = function (onSuccess, onError) {
        sendSiteMembersRequestInternal({
            type: types.GET,
            url: authorizedPagesUrl,
            asForm: false,
            isPlatformizedEndpoint: true
        }, onSuccess, onError);
    };

    return SiteMembersAPI;
});
