/* eslint-disable max-statements, santa/no-module-state */

define([
    'lodash',
    'coreUtils',
    'componentsCore',
    'core/core/SiteMembersAPI',
    'core/core/requireCompCode',
    'core/bi/events',
    'utils',
    'pmrpc',
    'santa-site-auth-module',
    'experiment'
], function (_,
             coreUtils,
             componentsCore,
             SiteMembersAPI,
             requireCompCode,
             events,
             utils,
             pmrpc,
             santaSiteAuthModule,
             experiment) {
    'use strict';

    /** coreUtils.util.hashUtils */
    const hashUtils = coreUtils.hashUtils;
    const cookieUtils = coreUtils.cookieUtils;

    const privatesMap = new WeakMap();

    const COMPS_DOWNLOAD_DELAY = 500;

    const USER_AUTH_STATUS = {
        ACTIVE: 'ACTIVE',
        PENDING: 'APPLICANT'
    };

    const DIALOGS = {
        Login: 'login',
        SignUp: 'register',
        ResetPasswordEmail: 'resetPasswordEmail',
        ResetPasswordNewPassword: 'resetPasswordNewPassword',
        Notification: 'notification',
        Credits: 'credits',
        PasswordProtected: 'enterPassword',
        EmailVerification: 'emailVerification',
        SentConfirmationEmail: 'sentConfirmationEmail',
        Welcome: 'welcome',
        NoPermissionsToPage: 'noPermissionsToPage'
    };

    const AUTH_DIALOGS = [DIALOGS.Login, DIALOGS.SignUp];

    const NOTIFICATIONS = {
        Template: 'template',
        SiteOwner: 'siteowner',
        SignUp: 'register',
        ResetPasswordEmail: 'resetPasswordEmail',
        ResetPasswordNewPassword: 'resetPasswordNewPassword'
    };

    const DIALOGS_SKINS = {
        Login: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial',
            socialMobileThemeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.memberLoginDialogSkinMaterial'
        },
        SignUp: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial',
            socialMobileThemeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.signUpDialogSkinMaterial'
        },
        ResetPasswordEmail: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.requestPasswordResetDialogSkinMaterial'
        },
        ResetPasswordNewPassword: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.resetPasswordDialogSkinMaterial'
        },
        Notification: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.notificationDialogSkinMaterial'
        },
        PasswordProtected: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.enterPasswordDialogSkinMaterial'
        },
        EmailVerification: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.emailVerificationDialogSkinMaterial'
        },
        SentConfirmationEmail: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.sentConfirmationEmailMaterial'
        },
        Welcome: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.welcomeDialogSkinMaterial'
        },
        NoPermissionsToPage: {
            themeStyledMaterial: 'wysiwyg.viewer.skins.dialogs.siteMembersDialogs.noPermissionsToPageMaterial'
        }
    };

    const COOKIES = {
        SV_SESSION: 'svSession',
        SM_SESSION: 'smSession',
        WIX_CLIENT: 'wixClient',
        SM_EF: 'sm_ef'
    };

    const DEFAULT_DIALOG_LANG = 'en';

    const APPS = {
        MEMBER_AREA: '14cc59bc-f0b7-15b8-e1c7-89ce41d0e0c9',
        MEMBER_INFO: '14cffd81-5215-0a7f-22f8-074b0e2401fb'
    };

    const SECTION = {
        MEMBER_INFO: 'member_info'
    };

    const SIGNED_IN_PATTERN = '_signedInUser_';

    const ACCEPTED_APPDEF_IDS = ['675bbcef-18d8-41f5-800e-131ec9e08762', '14ce1214-b278-a7e4-1373-00cebd1bef7c'];

    function deleteSmEf(hostName, mainPagePath) {
        hostName = hostName.indexOf('www') === 0 ? hostName.substr(3) : hostName;
        cookieUtils.deleteCookie(COOKIES.SM_EF, hostName, mainPagePath);
        cookieUtils.deleteCookie(COOKIES.SM_EF, hostName, '/');
    }

    function getAppAPI(siteAPI, msg) {
        const PLATFORM_PUBLIC_API_PREFIX = 'viewer_platform_public_api_';

        const handler = this.aspectSiteApi.getSiteAPI().getSiteAspect('WidgetAspect').getWidgetHandler();
        /* constants.MESSAGE_TYPE.PLATFORM_PUBLIC_API_PREFIX + msg.appDefId + msg.workerId === pageID*/
        const apiName = `${PLATFORM_PUBLIC_API_PREFIX + msg.appDefId}_${msg.workerId}`;

        const postMessageTarget = handler && handler.getPostMessageTarget(msg.workerId);
        if (postMessageTarget) {
            return pmrpc.api.request(apiName, {target: postMessageTarget});
        }
        return Promise.reject(new Error('Application API not found.'));
    }

    function downloadAllComps() {
        const privates = privatesMap.get(this);
        if (typeof window !== 'undefined' && !privates.compsDownloaded) {
            privates.compsDownloaded = new Promise(resolve => {
                setTimeout(() => {
                    const packageNames = requireCompCode.all();
                    requirejs(packageNames,
                        (...loadedPackages) => {
                            const loadedPackagesWithPackagesNames = _.zipObject(packageNames, loadedPackages);
                            requireCompCode.callAllRequiredPackagesCallbacks(loadedPackagesWithPackagesNames);
                            resolve();
                        }
                    );
                }, COMPS_DOWNLOAD_DELAY);
            });
        }
    }

    function waitForCompsDownload(cb) {
        const privates = privatesMap.get(this);
        if (privates.compsDownloaded) {
            privates.compsDownloaded.then(cb);
        } else {
            cb();
        }
    }

    function getStructure(compType, id, skin) {
        return {
            componentType: compType,
            type: 'Component',
            id,
            key: id,
            skin
        };
    }

    function getNotificationStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.NotificationDialog', 'notificationDialog', getSkin(DIALOGS_SKINS.Notification, siteData));
    }

    function getLoginStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.MemberLoginDialog', 'memberLoginDialog', getSkin(DIALOGS_SKINS.Login, siteData));
    }

    function getSignUpStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog', 'signUpDialog', getSkin(DIALOGS_SKINS.SignUp, siteData));
    }

    function getResetPasswordEmailStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.RequestPasswordResetDialog', 'requestResetPasswordDialog', getSkin(DIALOGS_SKINS.ResetPasswordEmail, siteData));
    }

    function getResetPasswordNewPasswordStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog', 'resetPasswordDialog', getSkin(DIALOGS_SKINS.ResetPasswordNewPassword, siteData));
    }

    function getEnterPasswordStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.EnterPasswordDialog', 'enterPasswordDialog', getSkin(DIALOGS_SKINS.PasswordProtected, siteData));
    }

    function getEmailVerificationStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.EmailVerificationDialog',
            'emailVerificationDialog', getSkin(DIALOGS_SKINS.EmailVerification, siteData));
    }

    function getSentConfirmationEmailStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.SentConfirmationEmailDialog',
            'sentConfirmationEmailDialog', getSkin(DIALOGS_SKINS.SentConfirmationEmail, siteData));
    }

    function getWelcomeStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.WelcomeDialog',
            'welcomeDialog', getSkin(DIALOGS_SKINS.Welcome, siteData));
    }

    function getNoPermissionsToPageStructure(siteData) {
        return getStructure('wysiwyg.viewer.components.dialogs.siteMemberDialogs.NoPermissionsToPageDialog',
            'noPermissionsToPageDialog', getSkin(DIALOGS_SKINS.NoPermissionsToPage, siteData));
    }

    /**
     * @private
     */
    function getSkin(skin, siteData) {
        const isSocialMobile = isSocialLoginEnabled(siteData) && siteData.isMobileView();
        return isSocialMobile && skin.socialMobileThemeStyledMaterial ?
            skin.socialMobileThemeStyledMaterial : skin.themeStyledMaterial;
    }

    function isSocialLoginEnabled(siteData) {
        const settings = _.get(siteData.getDataByQuery('masterPage'), ['smSettings'], {});
        return !!(settings.socialLoginGoogleEnabled || settings.socialLoginFacebookEnabled);
    }

    function shouldNavigateHomeAfterLogout(logoutState, nextPageInfo, siteData) {
        return logoutState === true &&
            (nextPageInfo && nextPageInfo.pageId === siteData.getCurrentUrlPageId());
    }

    function getDialogToShowFirst(siteData, showLoginDialog) {
        if (_.isBoolean(showLoginDialog)) {
            return showLoginDialog ? DIALOGS.Login : DIALOGS.SignUp;
        }
        const siteStructureData = siteData.getDataByQuery('masterPage');
        const shouldShowLoginDialogFirst = _.get(siteStructureData, ['smSettings', 'smFirstDialogLogin'], false);
        return shouldShowLoginDialogFirst ? DIALOGS.Login : DIALOGS.SignUp;
    }

    /**
     * @private
     * @param propsDecorator
     * @returns {*}
     */
    function getDialogComponentProps(propsDecorator) {
        const lang = this.aspectState.read('languageToDisplay');
        const props = {};
        props.language = lang;
        props.translations = _.merge({},
            coreUtils.translationsLoader.getTranslationAllKeys('dialogMixinTranslations', lang),
            coreUtils.translationsLoader.getTranslationAllKeys('SM', lang));
        props.onCloseDialogCallback = closeDialog.bind(this);
        props.notClosable = this.aspectState.read('notClosable');
        props.checkCommunityCheckbox = this.aspectState.read('checkCommunityCheckbox');
        props.smCollectionExposure = _.get(this.siteData.getClientSpecMapEntriesByType('sitemembers'), '[0].collectionExposure');
        props.smCollectionId = _.get(this.siteData.getClientSpecMapEntriesByType('sitemembers'), '[0].smcollectionId');
        propsDecorator.call(this, props);
        return props;
    }

    /**
     * @private
     * @param props
     */
    function addLoginProps(props) {
        props.onSubmitCallback = loginHandler.bind(this);
        const lang = this.aspectState.read('languageToDisplay');
        props.onTokenMessage = tokenHandler.bind(this);
        props.onBackendSocialLogin = backendSocialLoginHandler.bind(this);
        props.onSwitchDialogLinkClick = function () {
            const isCustomSignup = showCustomSignupDialog.call(this, this.aspectState.read('nextPageInfo'), true);
            if (!isCustomSignup) {
                showDialog.call(this, DIALOGS.SignUp, undefined, lang);
            }
        }.bind(this);
        props.onForgetYourPasswordClick = function () {
            reportSmBi.call(this, events.SITE_MEMBER_CLICKED_FORGOT_PASSWORD);
            showDialog.call(this, DIALOGS.ResetPasswordEmail, undefined, lang);
        }.bind(this);
        props.needLoginMessage = this.aspectState.read('showDialogMessage');
    }

    /**
     * @private
     * @param props
     */
    function addSignupProps(props) {
        props.onSubmitCallback = registerHandler.bind(this);
        const lang = this.aspectState.read('languageToDisplay');
        props.onTokenMessage = tokenHandler.bind(this);
        props.onBackendSocialLogin = backendSocialLoginHandler.bind(this);
        props.onSwitchDialogLinkClick = showDialog.bind(this, DIALOGS.Login, undefined, lang);
        props.needLoginMessage = this.aspectState.read('showDialogMessage');
        props.hasSocialAppInstalled = hasSocialAppInstalled.call(this, this.siteData);
    }

    /**
     * @private
     * @param props
     */
    function addResetPasswordRequestProps(props) {
        props.onSubmitCallback = resetPasswordRequestHandler.bind(this);
    }

    /**
     * @private
     * @param props
     */
    function addEmailVerificationProps(props) {
        props.biContext = 'confirm email to start';
        props.onResendLinkClick = emailVerificationDialogResend.bind(this, props.biContext);
        props.onDialogReady = onEmailVerificationDialogReady.bind(this);
    }

    /**
     * @private
     * @param props
     */
    function addSentConfirmationEmailProps(props) {
        props.biContext = 'sent confirmation email';
        props.onResendLinkClick = sentConfirmationEmailDialogResend.bind(this, props.biContext);
        props.onDialogReady = onSentConfirmationEmailDialogReady.bind(this);
    }

    /**
     * @private
     * @param structure
     * @param siteAPI
     * @param loadedStyles
     * @returns {*}
     */
    function addResetPasswordNewPasswordProps(props) {
        // const props = {};
        const language = _.get(this.siteData.currentUrl.query, 'forgotPasswordLang', DEFAULT_DIALOG_LANG);
        props.language = language;
        props.onSubmitCallback = changePasswordHandler.bind(this, language);
        props.notClosable = this.aspectState.read('notClosable');
        // return props;
    }

    /**
     * @private
     * @param props
     */
    function addNotificationProps(props) {
        const lang = props.language;
        const siteData = this.siteData;
        switch (this.aspectState.read('notificationToDisplay')) {
            case DIALOGS.SignUp:
                const siteMember = privateMembers[siteData.siteId].siteMember;
                const nameToDisplay = siteMember.details.email;
                props.title = '';//getText(lang,"SMApply_Success1");
                props.description = `${getText(lang, 'SMApply_Success1')} ${getText(lang, 'SMApply_Success2').replace('{0}', nameToDisplay)}`;
                props.buttonText = getText(lang, 'SMContainer_OK');
                break;
            case DIALOGS.ResetPasswordEmail:
                props.title = getText(lang, 'siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TITLE');
                props.description = getText(lang, 'siteMembersTranslations_RESET_PASSWORD_CHECKEMAIL_TEXT');
                props.buttonText = getText(lang, 'Reset_Password_OK');
                break;
            case DIALOGS.ResetPasswordNewPassword:
                props.title = getText(lang, 'siteMembersTranslations_Reset_Password_Sucess_Title');
                props.description = '';
                props.buttonText = getText(lang, 'SMContainer_OK');
                props.onButtonClick = onCloseSuccessNotification.bind(this, DIALOGS.Login, 'resetPasswordSuccessNotification');
                break;
            case 'siteowner':
                props.title = getText(lang, 'SITEMEMBERMANGAGER_OWNER_LOGOUT_ACTION_TITLE');
                props.description = getText(lang, 'SITEMEMBERMANGAGER_OWNER_LOGOUT_ACTION_MESSAGE');
                props.buttonText = getText(lang, 'SMContainer_OK');
                break;
            case NOTIFICATIONS.Template:
                props.title = getText(lang, 'siteMembersTranslations_TEMPLATE_NOTIFICATION_TITLE');
                props.description = getText(lang, 'siteMembersTranslations_TEMPLATE_NOTIFICATION_MESSAGE');
                props.buttonText = getText(lang, 'SMContainer_OK');
                break;
            default:
                break;
        }
    }

    /**
     * @private
     * @param props
     */
    function addEnterPasswordProps(props) {
        props.onSubmitCallback = handlePasswordEntered.bind(this, this.aspectState.read('nextPageInfo'));
    }

    function hasMemberArea(siteData) {
        return !!siteData.getClientSpecMapEntryByAppDefinitionId(APPS.MEMBER_AREA);
    }

    function hasSocialAppInstalled(siteData) {
        return siteData.routers ? _(siteData.routers.configMap)
            .map(x => JSON.parse(x.config))
            .filter(x => x.type === 'public' && x.patterns && Object.keys(x.patterns).length > 0)
            .value().length > 0 : false;
    }

    function handleWelcomeSubmit(data, dialog) {
        const msg = {
            appDefId: APPS.MEMBER_AREA,
            workerId: this.siteData.getPrimaryPageId()
        };
        dialog.closeDialog(false);
        getAppAPI.call(this, this.aspectSiteApi.getSiteAPI(), msg)
            .then(function (api) {
                api.navigateToSection({
                    appDefinitionId: APPS.MEMBER_INFO,
                    sectionId: SECTION.MEMBER_INFO,
                    memberId: SIGNED_IN_PATTERN
                });
            });
    }


    function addWelcomeProps(props) {
        props.onSubmitCallback = handleWelcomeSubmit.bind(this);
        const smCookie = cookieUtils.getCookie(COOKIES.SM_EF);
        const smCookieLang = smCookie && smCookie.split('|')[1];
        props.language = smCookieLang && smCookieLang.trim() || DEFAULT_DIALOG_LANG; // eslint-disable-line no-mixed-operators
        const defaultOnCloseCallback = props.onCloseDialogCallback;
        props.onCloseDialogCallback = (...args) => {
            defaultOnCloseCallback(...args);
            const mainPagePath = this.siteData.getMainPagePath();
            deleteSmEf(this.siteData.currentUrl.hostname, mainPagePath);
        };
    }

    function addNoPermissionsToPageProps(props) {
        props.onSwitchAccountClick = () => {
            this.logout();
        };
    }

    /**
     * @private
     * @param password
     * @returns {*}
     */
    function encryptPassword(password) {
        return hashUtils.SHA256.b64_sha256(password);
    }

    /**
     * @private
     * @param cookieData
     * @param siteData
     */
    function setSMSessionCookie(cookieData, siteData) {
        const cookieName = cookieData.cookieName || COOKIES.SM_SESSION;
        const sessionToken = cookieData.sessionToken;
        const expirationDate = cookieData.rememberMe ? cookieData.expirationDate : 0;

        cookieUtils.setCookie(
            cookieName,
            sessionToken,
            expirationDate,
            siteData.currentUrl.hostname,
            siteData.getMainPagePath(),
            false
        );
    }

    /**
     * @private
     * @param dialogType
     * @param successCallback
     * @param language
     * @param cancelCallback
     * @param checkCommunityCheckbox
     */
    function showDialog(dialogType, successCallback, language, cancelCallback, checkCommunityCheckbox) {
        this.aspectState.update(_.merge({},
            {dialogToDisplay: dialogType},
            language ? {languageToDisplay: language} : {},
            _.isFunction(successCallback) ? {dialogProcessSuccessCallback: successCallback} : {},
            _.isFunction(cancelCallback) ? {onCancelCallback: cancelCallback} : {},
            _.isBoolean(checkCommunityCheckbox) ? {checkCommunityCheckbox} : {},
            {logoutState: false}
        ));
    }

    /**
     * @private
     * @param notificationType
     * @param language
     */
    function showNotification(notificationType, language) {
        this.aspectState.update(_.merge({}, {
            notificationToDisplay: notificationType,
            dialogToDisplay: DIALOGS.Notification
        }, language ? {languageToDisplay: language} : {}));
    }

    /**
     * Perform login logic.
     * @private
     * @param loginData
     * @param dialog
     */
    function loginHandler(loginData, dialog) {
        const svSession = cookieUtils.getCookie(COOKIES.SV_SESSION);
        const initiator = null;
        reportSmBi.call(this, events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id});
        SiteMembersAPI.login(loginData, onLoginSuccess.bind(this, dialog), function (msg) {
            dialog.endLoader();
            dialog.setErrorMessageByCode(msg);
        }, svSession, initiator);
        dialog.startLoader();
    }

    /**
     * Perform social login logic.
     * @param token
     * @param type
     * @param postMessageTarget
     * @param dialog
     */
    function tokenHandler(token, type, postMessageTarget, dialog) {
        const svSession = cookieUtils.getCookie(COOKIES.SV_SESSION);
        const onSuccess = onOauthSuccess.bind(this, dialog, postMessageTarget, type);
        const onError = onOauthError.bind(this, dialog, postMessageTarget, type);
        SiteMembersAPI.handleOauthToken(onSuccess, onError, {
            svSession,
            token,
            provider: type,
            visitorId: this.aspectSiteApi.getSiteAPI().getBiVisitorId(),
            mode: dialog.props.id,
            lang: dialog.props.language,
            privacyStatus: dialog.getJoinCommunityStatus()
        });
        dialog.startLoader();
    }

    /**
     * Perform backend social login when the token is already handled
     */
    function backendSocialLoginHandler(dialog, postMessageTarget, type, payload) {
        const onSuccess = onOauthSuccess.bind(this, dialog, postMessageTarget, type);
        const sessionToken = payload.smSession && payload.smSession.sessionToken;
        if (sessionToken) {
            SiteMembersAPI.authByToken(sessionToken, response => {
                onSuccess({httpOnlySession: true, payload: _.merge({}, payload, {pages: response.payload.pages})});
            }, errorCode => {
                dialog.endLoader();
                dialog.setOuathErrorMessageByCode(errorCode);
            });
        } else {
            onSuccess({payload});
        }
    }

    /**
     * Perform register/signup logic.
     * @private
     * @param registerData
     * @param dialog
     */
    function registerHandler(registerData, dialog) {
        const specMap = this.siteData.getClientSpecMap();
        const siteMembersConfig = _.find(specMap, {type: 'sitemembers'});
        const membersRegistrationType = siteMembersConfig.collectionType;
        const svSession = cookieUtils.getCookie(COOKIES.SV_SESSION);
        if (membersRegistrationType === 'Open') {
            SiteMembersAPI.register(registerData, onRegisterSuccess.bind(this, dialog), function (msg) {
                dialog.endLoader();
                dialog.setErrorMessageByCode(msg);
            }, svSession);
            dialog.startLoader();
        } else if (membersRegistrationType === 'ApplyForMembership') {
            SiteMembersAPI.apply(registerData, onApplyRegisterSuccess.bind(this, dialog.props.language), dialog.setErrorMessageByCode, svSession);
        }
        reportSmBi.call(this, events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id});
    }

    /**
     * @private
     * @param submitData
     * @param dialog
     */
    function resetPasswordRequestHandler(submitData, dialog) {
        const currentPageUrl = this.siteData.currentUrl.full;
        reportSmBi.call(this, events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id});
        SiteMembersAPI.sendForgotPasswordMail({
            email: submitData.email,
            homePageUrl: currentPageUrl,
            lang: dialog.props.language
        }, function () {
            const invokeResetPasswordDialogCb = this.aspectState.read('invokeResetPasswordDialogCb');
            const successCb = this.aspectState.read('dialogProcessSuccessCallback');
            if (invokeResetPasswordDialogCb) {
                successCb();
                this.aspectState.update({dialogProcessSuccessCallback: null});
                this.aspectState.delete('invokeResetPasswordDialogCb');
            }
            showNotification.call(this, NOTIFICATIONS.ResetPasswordEmail, dialog.props.language);
        }.bind(this),
        dialog.setErrorMessageByCode, DIALOGS.ResetPasswordEmail);
    }

    function emailVerificationDialogResend(biContext) {
        resendEmailVerification.call(this);
        reportBiClickLink.call(this, {context: biContext, name: 'resend confirmation email'});
        showDialog.call(this, DIALOGS.SentConfirmationEmail);
    }

    function sentConfirmationEmailDialogResend(biContext) {
        resendEmailVerification.call(this);
        reportBiClickLink.call(this, {context: biContext, name: 'resend confirmation email'});
    }

    function resendEmailVerification() {
        const pendingMemberId = this.aspectState.read('pendingMemberId');
        SiteMembersAPI.resendEmailVerification(pendingMemberId, _.noop, _.noop);
    }

    function onEmailVerificationDialogReady() {
        reportBiNotificationOpen.call(this, {notificationName: 'confirm email to access'});
    }

    function onSentConfirmationEmailDialogReady() {
        reportBiNotificationOpen.call(this, {notificationName: 'sent confirmation email'});
    }

    /**
     * @private
     */
    function onPasswordChangeSuccess(language) {
        delete this.siteData.currentUrl.query.forgotPasswordToken;
        showNotification.call(this, NOTIFICATIONS.ResetPasswordNewPassword, language);
    }

    /**
     * @private
     * @param newPassword
     * @param dialog
     */
    function changePasswordHandler(language, newPassword, dialog) {
        reportSmBi.call(this, events.SITE_MEMBER_SUBMIT_BUTTON, {context: dialog.props.id});
        SiteMembersAPI.resetMemberPassword(newPassword, onPasswordChangeSuccess.bind(this, language), dialog.setErrorMessageByCode);
    }

    function isPageProtectedByPasswordOnServer(siteData, pageId) {
        return _.includes(siteData.rendererModel.passwordProtectedPages, pageId);
    }

    function isPasswordProtected(siteData, pageSecurity, pageId) {
        return pageSecurity.passwordDigest || isPageProtectedByPasswordOnServer(siteData, pageId);
    }

    function isPasswordRequired(siteData, pageSecurity, pageId) {
        return !isPasswordPagePreviouslyApproved(siteData, pageId) && isPasswordProtected(siteData, pageSecurity, pageId);
    }

    function isAllowed(siteData, pageSecurity, pageId) {
        const notAuthorized = pageSecurity.requireLogin && (!this.isLoggedIn() || !siteData.hasPageData(pageId));
        return !(isPasswordRequired(siteData, pageSecurity, pageId) || notAuthorized);
    }

    function isPageExists(siteData, pageId) {
        return _.includes(siteData.getAllPageIds(), pageId) || utils.errorPages.isErrorPage(pageId);
    }

    function pageSecurityDialog(siteData, pageSecurity, pageId) {// eslint-disable-line complexity
        if (_.has(siteData.currentUrl.query, 'forgotPasswordToken')) {
            return DIALOGS.ResetPasswordNewPassword;
        }

        if (isPasswordRequired(siteData, pageSecurity, pageId)) {
            return DIALOGS.PasswordProtected;
        }

        if (pageSecurity.requireLogin) {
            if (!this.isLoggedIn()) {
                return getDialogToShowFirst.call(this, siteData);
            } else if (!siteData.hasPageData(pageId)) {
                const customNoPermissionsPageId = this.getCustomNoPermissionsPageId();

                if (customNoPermissionsPageId && (pageId !== customNoPermissionsPageId)) {
                    const appSectionParams = {restrictedPageId: pageId};
                    this.aspectSiteApi.navigateToPage({
                        pageId: customNoPermissionsPageId,
                        hasAppSectionParams: true,
                        queryParams: {appSectionParams: JSON.stringify(appSectionParams)}
                    });
                    return null;
                }

                return DIALOGS.NoPermissionsToPage;
            }
        }

        return null;
    }

    function pageSecurityData(siteData, pageId) {
        const pageData = siteData.getDataByQuery(pageId);
        return pageData && pageData.pageSecurity || {}; // eslint-disable-line no-mixed-operators
    }

    function cleanStateAfterDialogClosed() {
        this.aspectState.update({
            dialogToDisplay: null,
            checkCommunityCheckbox: null,
            nextPageInfo: null,
            onCancelCallback: null,
            dialogProcessSuccessCallback: null,
            showDialogMessage: false,
            smtoken: this.siteData.getSMToken()
        });
    }

    function getCustomSignupPageId() {
        const customSignupPageId = _.get(this.siteData.getDataByQuery('masterPage'), ['smSettings', 'customSignUpPageId'], null);
        const isExists = customSignupPageId && isPageExists.call(this, this.siteData, customSignupPageId);
        return isExists ? customSignupPageId : null;
    }

    function isRestrictedHomePage(pageId, pageSecurity) {
        return !this.isLoggedIn() && pageId === this.siteData.getMainPageId() && pageSecurity.requireLogin;
    }

    function shouldRenderCustomSignupPage(pageInfo, force) {
        const pageId = (pageInfo && pageInfo.pageId) || this.siteData.getCurrentUrlPageId();
        const pageSecurity = pageSecurityData.call(this, this.siteData, pageId);
        const dialogToRender = pageSecurityDialog.call(this, this.siteData, pageSecurity, pageId);
        return (dialogToRender === DIALOGS.SignUp || force) &&
            getCustomSignupPageId.call(this) &&
            (pageId !== this.siteData.getCurrentUrlPageId() || !isRestrictedHomePage.call(this, pageId, pageSecurity));
    }

    function showCustomSignupDialog(pageInfo, force) {
        const isCustomSignup = this.aspectState.read('customSignupWorkersHack') ||
            shouldRenderCustomSignupPage.call(this, pageInfo, force);
        if (isCustomSignup && pageInfo && pageInfo.pageId === this.siteData.getCurrentUrlPageId()) {
            this.aspectState.update({customSignupWorkersHack: pageInfo});
            this.aspectSiteApi.navigateToPage({pageId: this.siteData.getMainPageId()});
        } else if (isCustomSignup) {
            this.aspectState.update({dialogToDisplay: null, customSignupWorkersHack: null});
            this.aspectSiteApi.navigateToPage({pageId: getCustomSignupPageId.call(this)});
        }
        return isCustomSignup;
    }

    /**
     * @private
     * @param dialog
     * @param authSuccess
     * @param isUserAction
     * @param nextPageInfo
     */
    function closeDialog(dialog, authSuccess, isUserAction, nextPageInfo) {
        if (this.siteData.currentUrl.query.showSignUpDialog) {
            delete this.siteData.currentUrl.query.showSignUpDialog;
            const linkData = {
                pageId: {
                    id: this.siteData.getMainPageId()
                },
                type: 'PageLink',
                queryParams: this.siteData.currentUrl.query
            };
            const link = coreUtils.linkRenderer.renderLink(linkData, this.siteData, this.siteData.getRootNavigationInfo());
            this.aspectSiteApi.getSiteAPI().href(link.href);
            return;
        }
        if (isUserAction) {
            reportSmBi.call(this, events.SITE_MEMBER_EXIT_DIALOG, {context: dialog.props.biContext || dialog.props.id});
        }

        const siteMember = privateMembers[this.siteData.siteId].siteMember;
        const onCancelCallback = this.aspectState.read('onCancelCallback');
        if (_.isFunction(onCancelCallback) && !siteMember.details) {
            onCancelCallback();
        }

        dialog.performCloseDialog(function () {
            this.aspectSiteApi.getActionQueue().runImmediately(function () {
                const nextPage = nextPageInfo || this.aspectState.read('nextPageInfo');
                cleanStateAfterDialogClosed.call(this);
                this.aspectState.delete('invokeResetPasswordDialogCb');

                dialog.endLoader();
                if (authSuccess) {
                    if (nextPage) {
                        _.invokeMap(this.onLoginSuccessCallbacks, 'callback');
                        if (nextPage.routerDefinition) {
                            nextPage.isRefetchingPageAfterLogin = true;
                            delete nextPage.pageId;
                        }
                        this.aspectSiteApi.navigateToPage(nextPage);
                    }
                } else if (nextPage && nextPage.pageId === this.siteData.getCurrentUrlPageId()) {
                    this.aspectState.update({navigatingOutPageId: nextPage.pageId});
                    this.aspectSiteApi.navigateToPage({pageId: this.siteData.getMainPageId()});
                }
            }.bind(this));
        }.bind(this));
    }

    function forceUnmountingOfCurrentDialog() {
        if (this.aspectState.read('stopShowDialog') !== true) {
            this.aspectState.update({stopShowDialog: true});
        }
    }

    function releaseDialogFromForceClose() {
        if (this.aspectState.read('stopShowDialog') !== false) {
            this.aspectState.update({stopShowDialog: false});
        }
    }

    /**
     * @private
     * @param dialog
     * @param loginCallbackData
     */
    function onLoginSuccess(dialog, loginCallbackData) {
        handleSmSession.call(this, dialog, loginCallbackData, events.SITE_MEMBER_LOGIN_SUCCESS);
    }

    function revokeAppPermissions(postMessageTarget, type) {
        postMessageTarget.postMessage({src: 'wix-social-login', type, action: 'revoke'}, '*');
    }

    /**
     * @private
     * @param dialog
     * @param oAuthLoginCallbackData
     * @param postMessageTarget
     * @param type
     */
    function onOauthSuccess(dialog, postMessageTarget, type, oAuthLoginCallbackData) {
        const payload = oAuthLoginCallbackData.payload;
        const siteMember = payload.siteMemberDto;
        const smSession = payload.smSession;

        if (oAuthLoginCallbackData.httpOnlySession) {
            handleSmSession.call(this, dialog, oAuthLoginCallbackData);
        } else if (smSession) {
            handleSmSession.call(this, dialog, {payload: smSession});
        } else {
            onApplyRegisterSuccess.call(this, dialog.props.language, {payload: siteMember});
        }

        revokeAppPermissions(postMessageTarget, type);
    }

    function onOauthError(dialog, postMessageTarget, type, errorCode) {
        dialog.endLoader();
        dialog.setOuathErrorMessageByCode(errorCode);
        revokeAppPermissions(postMessageTarget, type);
    }

    /**
     * @private
     * @param dialog
     * @param registerCallbackData
     */
    function onRegisterSuccess(dialog, registerCallbackData) {
        handleSmSession.call(this, dialog, registerCallbackData);
    }

    function onClientSpecMapReloaded(dialog, callbackData, biEvent) {
        dialog.endLoader();
        const siteMember = privateMembers[this.siteData.siteId].siteMember;
        siteMember.details = callbackData.payload.siteMemberDto;
        this.aspectState.update({memberDetails: siteMember.details});

        const successCb = this.aspectState.read('dialogProcessSuccessCallback');
        if (_.isFunction(successCb)) {
            successCb(siteMember.details);
            this.aspectState.update({dialogProcessSuccessCallback: null});
        }

        let nextPageInfo;
        if (callbackData.payload.pages) {
            this.siteData.setPagesJsonFileName(callbackData.payload.pages);
            nextPageInfo = this.aspectState.read('nextPageInfo');
        }
        closeDialog.call(this, dialog, true, true, nextPageInfo);
        if (biEvent) {
            const userName = siteMember.details.attribute && siteMember.details.attribute.name || siteMember.details.email; // eslint-disable-line no-mixed-operators
            this.aspectSiteApi.reportBI(biEvent, {
                userName: utils.logger.sanitizePIIForBi(userName)
            });
        }
    }

    function resolveMembersPlatformizedResponse(resolve, payload) {
        if (payload.pages) {
            this.siteData.setPagesJsonFileName(payload.pages);
        }
        this.aspectSiteApi.getSiteAPI().getSiteAspect('WidgetAspect').updateWixCodeModelDataAfterLogin();
        resolve(payload.raw);
    }

    /**
     * @private
     * @param dialog
     * @param callbackData
     * @param biEvent
     */
    function handleSmSession(dialog, callbackData, biEvent) {
        waitForCompsDownload.call(this, function () {
            if (isEmailVerificationRequired(callbackData)) {
                this.aspectState.update({pendingMemberId: callbackData.payload.siteMemberDto.id});

                showDialog.call(this,
                    DIALOGS.EmailVerification,
                    undefined,
                    dialog.props.language,
                    function () {
                        this.aspectState.update({pendingMemberId: null});
                    }.bind(this)
                );
            } else {
                if (!callbackData.httpOnlySession) {
                    setSMSessionCookie(callbackData.payload, this.siteData);
                }
                reloadClientSpecMap.call(this, onClientSpecMapReloaded.bind(this, dialog, callbackData, biEvent));
            }
        }.bind(this));
    }

    function handleSmSessionSuccess(resolve, data) {
        const nextPage = this.aspectState.read('nextPageInfo');
        if (nextPage) {
            this.aspectSiteApi.navigateToPage(nextPage);
        }
        cleanStateAfterDialogClosed.call(this);
        resolve(data);
    }

    function handleSmSessionWithoutDialog(resolve, authResponse, forceReloadClientSpecMap) {
        waitForCompsDownload.call(this, function () {
            if (isEmailVerificationRequired(authResponse)) {
                cleanStateAfterDialogClosed.call(this);
                this.aspectState.update({pendingMemberId: authResponse.payload.siteMemberDto.id});
                showDialog.call(this,
                    DIALOGS.EmailVerification,
                    undefined,
                    this.aspectState.read('languageToDisplay'),
                    function () {
                        this.aspectState.update({pendingMemberId: null});
                    }.bind(this)
                );
            } else {
                const successCallback = handleSmSessionSuccess.bind(this, resolve);

                if (forceReloadClientSpecMap || authResponse.payload.status === USER_AUTH_STATUS.ACTIVE) {
                    reloadClientSpecMap.call(this, resolveMembersPlatformizedResponse.bind(this, successCallback, authResponse.payload));
                } else {
                    resolveMembersPlatformizedResponse.call(this, successCallback, authResponse.payload);
                }
            }
        }.bind(this));
    }

    function isEmailVerificationRequired(data) {
        return data.payload.hasOwnProperty('sessionToken') &&
            data.payload.sessionToken === null && !data.payload.emailVerified;
    }

    /**
     * @private
     * @param language
     * @param registerData
     */
    function onApplyRegisterSuccess(language, {payload, member}) {
        const siteMember = privateMembers[this.siteData.siteId].siteMember;
        siteMember.details = payload || member;
        siteMember.details.email = _.get(payload, 'loginEmail', siteMember.details.email || member.email);

        const dialogProcessSuccessCallback = this.aspectState.read('dialogProcessSuccessCallback');

        if (_.isFunction(dialogProcessSuccessCallback)) {
            dialogProcessSuccessCallback(siteMember.details);
            this.aspectState.update({dialogProcessSuccessCallback: null});
        }

        showNotification.call(this, NOTIFICATIONS.SignUp, language);
    }

    /**
     *
     * @param callback
     * @param config
     */
    function reloadClientSpecMap(callback) {
        const dynamicClientSpecMapAspect = this.aspectSiteApi.getSiteAspect('dynamicClientSpecMap');
        dynamicClientSpecMapAspect.reloadClientSpecMap(callback);
    }

    const privateMembers = {};

    function initPrivateMembers(siteData) {
        privateMembers[siteData.siteId] = {
            approvedPasswordPages: [],
            siteMember: {details: null} // FIXME - can easily move to aspectState
        };
    }

    /**
     * @private
     * @param pageId
     * @returns {boolean} whether the password-protected page has already been logged into
     */
    function isPasswordPagePreviouslyApproved(siteData, pageId) {
        return _.includes(privateMembers[siteData.siteId].approvedPasswordPages, pageId);
    }

    /**
     * @private
     * @param pageId
     * stores a password-protected page as 'approved' after a successful login
     */
    function storeApprovedPage(pageId) {
        privateMembers[this.siteData.siteId].approvedPasswordPages.push(pageId);
    }

    /**
     * @private
     * @param password
     * @param nextPageInfo
     * @param onSuccess
     * @param onError
     */
    function validatePasswordOnServer(password, nextPageInfo, onSuccess, onError) {
        const siteRevision = this.siteData.getSiteRevision();
        const payload = {
            password,
            metaSiteId: this.siteData.getMetaSiteId(),
            siteId: this.siteData.siteId,
            pageId: nextPageInfo.pageId
        };
        coreUtils.ajaxLibrary.ajax({
            type: 'POST',
            url: `${this.siteData.serviceTopology.protectedPageResolverUrl}?siteRevision=${siteRevision}`,
            data: payload,
            dataType: 'json',
            cache: false,
            contentType: 'application/json',
            success: onSuccess,
            error: onError
        });
    }

    /**
     * @private
     * @param givenPassword
     * @param onSuccess
     * @param onError
     */
    function validatePasswordAgainstJSON(givenPassword, onSuccess, onError) {
        const givenPasswordEncypted = encryptPassword(givenPassword);
        if (givenPasswordEncypted === this.aspectState.read('passwordDigest')) {
            onSuccess(this.aspectState.read('nextPageInfo'));
        } else {
            onError();
        }
    }

    function onCloseSuccessNotification(nextDialogType, notificationId) {
        reportSmBi.call(this, events.SITE_MEMBER_SUBMIT_BUTTON, {context: notificationId});
        showDialog.call(this, nextDialogType);
    }

    function reportBiAuthDialogOpened() {
        const params = _.merge({
            context: this.aspectState.read('dialogToDisplay'),
            has_social: !!isSocialLoginEnabled(this.siteData)
        }, {initator: 'unknown'});
        reportSmBi.call(this, events.SITE_MEMBER_AUTH_DIALOG_OPENED, params);
    }

    function reportBiNotificationOpen(biConfig) {
        reportSmBi.call(this, events.SITE_MEMBER_NOTIFICATION_OPEN, {'notification_name': biConfig.notificationName});
    }

    function reportBiClickLink(biConfig) {
        reportSmBi.call(this, events.SITE_MEMBER_CLICK_LINK, {context: biConfig.context, name: biConfig.name});
    }

    function reportSmBi(event, params) {
        const visitorId = this.aspectSiteApi.getSiteAPI().getBiVisitorId();
        if (visitorId) { // 'visitorId' is empty in preview mode
            this.aspectSiteApi.reportBI(event, _.merge(params || {}, {visitor_id: visitorId}));
        }
    }

    function isAuthDialog() {
        return _.includes(AUTH_DIALOGS, this.aspectState.read('dialogToDisplay'));
    }

    function reloadClientSpecMapCb(config, callback) {
        reloadClientSpecMap.call(this, function () {
            config.siteMember.details = null;
            this.aspectState.update({notificationToDisplay: null});
            this.aspectSiteApi.getSiteAPI().getSiteAspect('WidgetAspect').updateWixCodeModelDataAfterLogin();
            callback();
        }.bind(this));
    }

    function buildAspectState(aspectSiteApi) {
        return {
            read(path) {
                const data = aspectSiteApi.getAspectGlobalData('siteMembers');
                return path ? _.get(data, path) : data;
            },
            update(newData) {
                aspectSiteApi.updateAspectGlobalData('siteMembers', newData);
            },
            delete(path) {
                const newData = _.set(aspectSiteApi.getAspectGlobalData('siteMembers'), path, undefined);
                aspectSiteApi.updateAspectGlobalData('siteMembers', newData);
            }
        };
    }

    function getText(lang, textType) {
        return coreUtils.translationsLoader.getTranslation('siteMembersTranslations', lang, textType);
    }

    function closeDialogAndNavigateToPage(dialog, nextPage) {
        closeDialog.call(this, dialog, true);
        storeApprovedPage.call(this, nextPage.pageId);
        this.aspectSiteApi.navigateToPage(nextPage);
        this.aspectState.update({passwordDigest: null});
    }

    function handlePasswordEntered(nextPageInfo, data, dialog) {
        const onError = function (rawError) {
            let errorCode = 'SMForm_Error_General_Err';
            try {
                errorCode = JSON.parse(rawError.responseText).errorCode;
            } catch (e) { /* do nothing */
            }
            dialog.setErrorMessageByCode(errorCode);
        };

        if (_.isEmpty(data.password)) {
            return dialog.setErrorMessage('PasswordLogin_Wrong_Password');
        }

        if (isPageProtectedByPasswordOnServer(this.siteData, nextPageInfo.pageId)) {
            const self = this;
            validatePasswordOnServer.call(this, data.password, nextPageInfo, function (response) {
                nextPageInfo.jsonUrls = response.payload.urls;
                closeDialogAndNavigateToPage.call(self, dialog, nextPageInfo);
            }, onError);
        } else {
            validatePasswordAgainstJSON.call(this, data.password, closeDialogAndNavigateToPage.bind(this, dialog), onError);
        }
    }

    function getComponentStructuresInternal(siteData) { // eslint-disable-line complexity
        switch (this.aspectState.read('dialogToDisplay')) {
            case DIALOGS.Login:
                return getLoginStructure(siteData);
            case DIALOGS.SignUp:
                return getSignUpStructure(siteData);
            case DIALOGS.ResetPasswordEmail:
                return getResetPasswordEmailStructure(siteData);
            case DIALOGS.ResetPasswordNewPassword:
                return getResetPasswordNewPasswordStructure(siteData);
            case DIALOGS.Notification:
                return getNotificationStructure(siteData);
            case DIALOGS.PasswordProtected:
                return getEnterPasswordStructure(siteData);
            case DIALOGS.EmailVerification:
                return getEmailVerificationStructure(siteData);
            case DIALOGS.SentConfirmationEmail:
                return getSentConfirmationEmailStructure(siteData);
            case DIALOGS.Welcome:
                return getWelcomeStructure(siteData);
            case DIALOGS.NoPermissionsToPage:
                return getNoPermissionsToPageStructure(siteData);
            case DIALOGS.AdminLogin:
                return getEnterPasswordStructure(siteData);
            default:
                return null;
        }
    }

    function getReactComponentsInternal() { // eslint-disable-line complexity
        downloadAllComps.call(this);

        if (this.aspectState.read('stopShowDialog')) {
            // FIXME - need to investigae all usages of 'stopShowDialog'
            // this.aspectState.update({stopShowDialog: false});
            // return null;
        }
        let propsDecorator;
        switch (this.aspectState.read('dialogToDisplay')) {
            case DIALOGS.Login:
                propsDecorator = addLoginProps;
                break;
            case DIALOGS.SignUp:
                propsDecorator = addSignupProps;
                break;
            case DIALOGS.ResetPasswordEmail:
                propsDecorator = addResetPasswordRequestProps;
                break;
            case DIALOGS.ResetPasswordNewPassword:
                propsDecorator = addResetPasswordNewPasswordProps;
                break;
            case DIALOGS.Notification:
                propsDecorator = addNotificationProps.bind(this);
                break;
            case DIALOGS.Credits:
                propsDecorator = _.noop;
                break;
            case DIALOGS.PasswordProtected:
                propsDecorator = addEnterPasswordProps;
                break;
            case DIALOGS.EmailVerification:
                propsDecorator = addEmailVerificationProps;
                break;
            case DIALOGS.SentConfirmationEmail:
                propsDecorator = addSentConfirmationEmailProps;
                break;
            case DIALOGS.Welcome:
                propsDecorator = addWelcomeProps;
                break;
            case DIALOGS.NoPermissionsToPage:
                propsDecorator = addNoPermissionsToPageProps;
                break;
            default:
                return {};
        }
        if (isAuthDialog.call(this)) {
            reportBiAuthDialogOpened.call(this);
        }
        return getDialogComponentProps.call(this, propsDecorator);
    }

    function getComponentsToRenderInternal(aspectsSiteAPI) {
        if (this.siteData.isInSSR() || this.aspectState.read('isFirstRenderAfterSSR') || !this.aspectState.read('dialogToDisplay')) {
            return null;
        }

        return [{
            structure: getComponentStructuresInternal.call(this, aspectsSiteAPI.getSiteData()),
            props: getReactComponentsInternal.call(this)
        }];
    }


    function handleSiteMemberUpdate() {
        this.aspectState.update({
            smtoken: this.siteData.getSMToken()
        });
    }

    function notifyMemberDetailsChanged() {
        const memberDetails = this.aspectState.read('memberDetails');
        _.forEach(this._memberDetailsChangeRegisteredComps, function (comp) {
            comp.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'MEMBER_DETAILS_UPDATED',
                params: memberDetails
            });
        });
    }

    function showAuthenticationDialog(options) {
        if (this._santaSiteAuthAspect) {
            this._santaSiteAuthAspect('showAuthenticationDialog', options);
            return;
        } else if (this.siteData.isTemplate()) {
            showNotification.call(this, NOTIFICATIONS.Template, options.language);
            return;
        }

        this.aspectState.update({initiatorAppId: options.appId});

        const dialogToShow = getDialogToShowFirst.call(this, this.siteData, options.showLoginDialog);
        if (dialogToShow === DIALOGS.SignUp && this.getCustomSignupPageId()) {
            this.aspectSiteApi.navigateToPage({pageId: this.getCustomSignupPageId()});
        } else {
            showDialog.call(this, dialogToShow, options.successCallback, options.language, options.cancelCallback, options.checkCommunityCheckbox);
        }
    }

    function initSiteAuthAspect() {
        const siteAuth = this.aspectSiteApi.getSiteAPI().getSiteAspect('siteAuthAspect') || santaSiteAuthModule;
        const {errorPages, urlUtils, ajaxLibrary} = utils;
        const _aspect = siteAuth.createAspect({
            type: 'SITE_MEMBERS',
            runtimeData: {
                aspectSiteAPI: this.aspectSiteApi,
                experiment,
                privateMembers,
                onLoginSuccessCallbacks: this.onLoginSuccessCallbacks,
                downloadCompsUtils: {
                    downloadAllComps: downloadAllComps.bind(this),
                    waitForCompsDownload: waitForCompsDownload.bind(this)
                }
            },
            externals: {
                errorPages,
                pmrpc,
                coreUtils,
                urlUtils,
                ajaxLibrary,
                hashUtils
            },
            constants: {
                biEvents: events
            }
        });
        return (api, ...args) => _.invoke(_aspect, api, ...args);
    }

    /**
     *
     * @param {AspectsSiteAPI} aspectSiteApi
     * @implements {core.SiteAspectInterface}
     * @constructor
     */
    function SiteMembersAspect(aspectSiteApi) {
        privatesMap.set(this, {
            compsDownloaded: null
        });
        // FIXME - make inner state more elegant (remove all this refs)
        this.onLoginSuccessCallbacks = [];
        this._memberDetailsChangeRegisteredComps = {};
        this.aspectSiteApi = aspectSiteApi;
        this.siteData = aspectSiteApi.getSiteData();
        this.aspectState = buildAspectState(this.aspectSiteApi);
        const dialogToDisplay = getInitialDialogToDisplay(this.siteData);
        this.aspectState.update({
            navigatingOutPageId: null,
            dialogProcessSuccessCallback: null,
            onCancelCallback: null,
            showDialogMessage: false,
            passwordDigest: null,
            nextPageInfo: null,
            dialogToDisplay,
            notificationToDisplay: null,
            languageToDisplay: _.get(this.siteData.currentUrl.query, 'forgotPasswordLang') || DEFAULT_DIALOG_LANG,
            notClosable: false,
            smtoken: this.siteData.getSMToken(),
            memberDetails: null
        });

        const isFirstRenderAfterSSR = this.siteData.isFirstRenderAfterSSR();
        if (isFirstRenderAfterSSR) {
            this.aspectState.update({isFirstRenderAfterSSR: true});
            this.aspectSiteApi.registerToDidLayout(_.once(() => {
                this.aspectState.update({isFirstRenderAfterSSR: false});
            }));
        }

        initPrivateMembers(this.siteData);
        SiteMembersAPI.initializeData(this.siteData);

        _.bindAll(this, _.functionsIn(this));
        this.aspectSiteApi.registerClientSpecMapUpdateCallback(handleSiteMemberUpdate.bind(this));
        this.aspectSiteApi.registerToUrlPageChange(() => {
            this.aspectState.update({nextPageInfo: null});

            const navigatingOutPageId = this.aspectState.read('navigatingOutPageId');
            if (navigatingOutPageId) {
                const currentUrlPageId = this.siteData.getCurrentUrlPageId();
                if (currentUrlPageId !== navigatingOutPageId) {
                    this.aspectState.update({navigatingOutPageId: null});
                }
            }

            const pageInfo = this.aspectState.read('customSignupWorkersHack');
            if (pageInfo) {
                this.aspectState.update({dialogToDisplay: null});
                this.aspectSiteApi.navigateToPage(pageInfo);
            }
        });

        if (experiment.isOpen('sv_SantaSiteAuthPhase1', this.siteData)) {
            this._santaSiteAuthAspect = initSiteAuthAspect.call(this);
        }
    }

    function getInitialDialogToDisplay(siteData) {
        if (_.has(siteData.currentUrl.query, 'forgotPasswordToken')) {
            return showResetPasswordDialog();
        }

        if (_.has(siteData.currentUrl.query, 'showSignUpDialog')) {
            return DIALOGS.SignUp;
        }

        if (cookieUtils.getCookie(COOKIES.SM_EF)) {
            return showWelcomeDialog(siteData);
        }

        return null;
    }

    function showResetPasswordDialog() {
        return DIALOGS.ResetPasswordNewPassword;
    }

    function showWelcomeDialog(siteData) {
        if (hasMemberArea(siteData)) {
            return DIALOGS.Welcome;
        }

        deleteSmEf(siteData.currentUrl.hostname, siteData.getMainPagePath());
        return null;
    }

    SiteMembersAspect.prototype = {

        /**
         * Returns the dialog to render.
         * @returns {Array}
         */
        getComponentsToRender: getComponentsToRenderInternal,

        /**
         *
         * @returns {boolean}
         */
        isLoggedIn() {
            return !!this.aspectState.read('smtoken');
        },

        /**
         * Returns the custom sign-up pageId, or null.
         * @returns {String}
         */
        getCustomSignupPageId() {
            return getCustomSignupPageId.call(this);
        },

        /**
         * Returns the custom no permissions pageId, or null.
         * @returns {String}
         */
        getCustomNoPermissionsPageId() {
            const customNoPermissionsPageId = _.get(this.siteData.getDataByQuery('masterPage'), ['smSettings', 'customNoPermissionsPageId'], null);
            const customNoPermissionsPageExists = isPageExists(this.siteData, customNoPermissionsPageId);
            return customNoPermissionsPageExists ? customNoPermissionsPageId : null;
        },

        /**
         * Updates the site pages according to the current member pages
         */
        authorizeMemberPages(onSuccess, onError) {
            const onAuthCompleted = response => {
                const pageIdToFileNameMap = response.payload.raw.authorizedPages;
                this.siteData.setPagesJsonFileName(pageIdToFileNameMap);
                const pageList = Object.keys(pageIdToFileNameMap);
                onSuccess(pageList);
            };

            return SiteMembersAPI.getAuthorizedPages(onAuthCompleted, onError);
        },

        /**
         * Returns whether we should render a custom signup lightbox.
         * @returns {boolean}
         */
        shouldRenderCustomSignupPage(nextPageInfo) {
            return shouldRenderCustomSignupPage.call(this, nextPageInfo);
        },

        /**
         * Opens the Sign Up / Login dialog
         * @param options - an object with all optional dialog configuration params (see list below):
         * > successCallback
         * > cancelCallback
         * > language
         * > showLoginDialog
         * > appId
         */
        showAuthenticationDialog(options) {
            if (requirejs.defined('dialogs')) {
                showAuthenticationDialog.call(this, options);
            } else {
                requirejs(['dialogs'], showAuthenticationDialog.bind(this, options));
            }
        },

        getMemberDetailsInternal(onSuccess, onError, refreshCurrentMember, previewMode) {
            onError = _.isFunction(onError) ? onError : _.noop;

            const siteMember = privateMembers[this.siteData.siteId].siteMember;
            if ((this.isLoggedIn() || previewMode) && (!siteMember.details || refreshCurrentMember)) {
                SiteMembersAPI.getMemberDetails(this.aspectState.read('smtoken'), function (response) {
                    siteMember.details = response.payload;
                    this.aspectState.update({
                        smtoken: this.siteData.getSMToken(),
                        memberDetails: siteMember.details
                    });

                    if (refreshCurrentMember) {
                        notifyMemberDetailsChanged.call(this);
                    }

                    if (onSuccess) {
                        onSuccess(siteMember.details);
                    }
                }.bind(this), onError, previewMode);
                return null;
            }
            if (onSuccess) {
                onSuccess(siteMember.details);
            }
            return siteMember.details;
        },

        /**
         * Retrieves authenticated site-members details,
         * and invokes callback with member details as only argument.
         */
        getMemberDetails(onSuccess, onError, refreshCurrentMember) {
            return this.getMemberDetailsInternal(onSuccess, onError, refreshCurrentMember);
        },

        /**
         * Retrieves authenticated site-members details in preview,
         * and invokes callback with member details as only argument.
         */
        getMemberDetailsInPreview(onSuccess, onError, refreshCurrentMember) {
            SiteMembersAPI.initializeData(this.siteData);
            return this.getMemberDetailsInternal(onSuccess, onError, refreshCurrentMember, true);
        },

        /**
         *
         * @param nextPageInfo
         * @returns {boolean}
         */
        isPageAllowed(nextPageInfo) {
            if (this.aspectSiteApi.getRenderFlag('isPageProtectionEnabled')) {
                const pageId = nextPageInfo.pageId;
                return isAllowed.call(this, this.siteData, pageSecurityData(this.siteData, pageId), pageId);
            }
            return true;
        },

        showDialogOnNextRender(nextPageInfo) { // eslint-disable-line complexity
            releaseDialogFromForceClose.call(this);
            const logoutState = this.aspectState.read('logoutState');

            if (logoutState !== false) {
                this.aspectState.update({logoutState: false});
            }

            const pageId = nextPageInfo.pageId;
            const pageSecurity = pageSecurityData(this.siteData, pageId);
            const isPageAllowed = isAllowed.call(this, this.siteData, pageSecurity, pageId);
            const navigatingOutPageId = this.aspectState.read('navigatingOutPageId');

            if (this.aspectState.read('dialogToDisplay') || isPageAllowed || navigatingOutPageId === pageId) {
                return;
            }
            if (shouldNavigateHomeAfterLogout(logoutState, nextPageInfo, this.siteData)) {
                this.aspectSiteApi.navigateToPage({pageId: this.siteData.getMainPageId()});
            } else {
                this.aspectState.update({nextPageInfo});
                const isCustomSignup = showCustomSignupDialog.call(this, nextPageInfo);
                if (!isCustomSignup) {
                    this.aspectState.update({
                        showDialogMessage: true,
                        dialogToDisplay: pageSecurityDialog.call(this, this.siteData, pageSecurity, pageId),
                        languageToDisplay: pageSecurity && pageSecurity.dialogLanguage || DEFAULT_DIALOG_LANG, // eslint-disable-line no-mixed-operators
                        passwordDigest: pageSecurity && pageSecurity.passwordDigest || undefined, // eslint-disable-line no-mixed-operators
                        notClosable: nextPageInfo.pageId === this.siteData.getCurrentUrlPageId() && nextPageInfo.pageId === this.siteData.getMainPageId()
                    });
                }
            }
        },

        /**
         * Log out
         */
        logout(language, onSuccess, onError, options) { // eslint-disable-line complexity
            onSuccess = _.isFunction(onSuccess) ? onSuccess : _.noop;
            onError = _.isFunction(onError) ? onError : _.noop;
            options = _.isObject(options) ? options : {};

            const siteMember = privateMembers[this.siteData.siteId].siteMember;
            if (cookieUtils.getCookie(COOKIES.WIX_CLIENT) && siteMember.details && siteMember.details.owner) {
                showNotification.call(this, NOTIFICATIONS.SiteOwner, language);
                onError('Current member is the site owner, which can not be logout');
            } else {
                this.aspectState.update({logoutState: true});

                if (!experiment.isOpen('sv_dontDeleteSvSessionCookieOnLogout', this.siteData)) {
                    cookieUtils.deleteCookie(COOKIES.SV_SESSION, this.siteData.currentUrl.hostname, this.siteData.getMainPagePath());
                }

                const config = {siteMember};
                if (cookieUtils.getCookie(COOKIES.SM_SESSION)) {
                    cookieUtils.deleteCookie(COOKIES.SM_SESSION, this.siteData.currentUrl.hostname, this.siteData.getMainPagePath());
                    reloadClientSpecMapCb.call(this, _.merge(config, {isHttpOnlySession: false}), onSuccess);
                } else {
                    const navigate = () => _.isFunction(options.navigateToPage) ? options.navigateToPage() : this.aspectSiteApi.getSiteAPI().reloadPage();
                    SiteMembersAPI.logout(navigate, onError);
                }
            }
        },

        showResetPasswordDialog(language, onSuccess, onCancel) {
            if (this._santaSiteAuthAspect) {
                this._santaSiteAuthAspect('showResetPasswordDialog', {
                    language,
                    successCallback: onSuccess,
                    cancelCallback: onCancel
                });
                return;
            }
            showDialog.call(this, DIALOGS.ResetPasswordEmail, onSuccess, language, onCancel);
            if (_.isFunction(onSuccess)) {
                this.aspectState.update({invokeResetPasswordDialogCb: true});
            }
        },

        forceCloseDialog() {
            if (this._santaSiteAuthAspect) {
                this._santaSiteAuthAspect('forceCloseDialog');
                return;
            }
            forceUnmountingOfCurrentDialog.call(this);
        },

        reportSiteMembersBi(biEventKey, params) {
            reportSmBi.call(this, events[biEventKey], params);
        },

        registerToUserLogin(callback) {
            const callbackId = _.uniqueId('callback');
            this.onLoginSuccessCallbacks.push({id: callbackId, callback});
            return callbackId;
        },
        unRegisterToUserLogin(callbackId) {
            const index = _.findIndex(this.onLoginSuccessCallbacks, {id: callbackId});
            if (index !== -1) {
                _.pullAt(this.onLoginSuccessCallbacks, index);
            }
        },
        registerToMemberDetailsChange(comp) {
            this._memberDetailsChangeRegisteredComps[comp.props.id] = comp;
        },

        unRegisterMemberDetailsChange(comp) {
            delete this._memberDetailsChangeRegisteredComps[comp.props.id];
        },

        registerUser({email, password, contactInfo, defaultFlow}, callback) {
            const {instance} = _.find(
                this.siteData.getClientSpecMap(),
                ({appDefinitionId}) => _.includes(ACCEPTED_APPDEF_IDS, appDefinitionId)
            );

            const success = response => handleSmSessionWithoutDialog.call(this, authResponse => {
                if (defaultFlow && authResponse.member.status === USER_AUTH_STATUS.PENDING) {
                    onApplyRegisterSuccess.call(this, this.aspectState.read('languageToDisplay'), authResponse);
                }
                callback(authResponse);
            }, response);
            const onError = xhrError => callback(null, xhrError);

            SiteMembersAPI.customRegister({
                email, password, contactInfo, instance
            }, success, onError);
        },

        loginUser(email, password, callback) {
            const svSession = cookieUtils.getCookie(COOKIES.SV_SESSION);
            const loginData = {email, password};

            const success = response => handleSmSessionWithoutDialog.call(this, () => callback(response.payload.sessionToken), response, true);
            const onError = xhrError => callback(null, xhrError);
            SiteMembersAPI.login(loginData, success, onError, svSession, null);
        },

        authenticateSession(token, callback) {
            const success = response => handleSmSessionWithoutDialog.call(this, callback, response, true);
            const onError = xhrError => callback(null, xhrError);
            SiteMembersAPI.authByToken(token, success, onError);
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('siteMembers', SiteMembersAspect);

    return {
        getInitialDialogToDisplay
    };
});
/* eslint-enable max-statements */
