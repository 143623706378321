define([
    'lodash',
    'reactDOM'
], function (
    _,
    ReactDOM
) {
    'use strict';

    /**
     * AnimationsAspect constructor
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @constructor
     */
    function AnimationsAspect(aspectSiteAPI) {
        // Aspect lifetime shared vars
        /** @type {core.SiteAspectsSiteAPI} */
        this._aspectSiteAPI = aspectSiteAPI;
        this._siteData = aspectSiteAPI.getSiteData();
        this.animationsPackage = this._siteData.animations;
        this._liveSequenceIdsByGroup = {};
    }

    function getPageId(animation) {
        let targetId = animation.targetId || animation.sourceId;
        while (_.isArray(targetId)) {
            targetId = targetId[0];
        }
        return this._aspectSiteAPI.getRootOfComponentId(targetId) || animation.pageId;
    }

    /**
     * Preview an Animation on a component (for DocumentServices)
     * @param {string} compId
     * @param {string} pageId
     * @param {{name:string, duration:number, delay:number, params:object}} animationDef
     * @param {function} [onComplete]
     */
    AnimationsAspect.prototype.previewAnimation = function (compId, pageId, animationDef, onComplete) {
        let sequence, params;

        const page = this._aspectSiteAPI.getPageById(pageId);

        if (page) {
            sequence = page.sequence();
            params = {
                props: 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin',
                immediateRender: false
            };

            sequence.add(compId, animationDef.name, animationDef.duration, animationDef.delay, animationDef.params);
            sequence.add(compId, 'BaseClear', 0, 0, params);

            if (onComplete) {
                sequence.onCompleteAll(onComplete);
            }

            return sequence.execute();
        }
    };

    AnimationsAspect.prototype.addAnimationToSequence = function (animation, sequences, clear) {
        const pageId = getPageId.call(this, animation);
        const sequence = sequences[pageId];
        const {targetId, name} = animation;
        const {duration, delay} = animation.params;
        const params = _.omit(animation.params, ['duration', 'delay']);
        sequence.add(targetId, name, duration, delay, params, 0);
        if (clear) {
            const props = 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin';
            sequence.add(targetId, 'BaseClear', 0, 0, {props, immediateRender: false});
        }

        return animation.targetId;
    };

    /**
     * Play animations.
     */
    AnimationsAspect.prototype.playAnimations = function (animationGroup, animations, clear, callback) {
        const playedTargets = {};
        const sequences = {};
        const sequenceIds = [];

        // For every behavior entry, create an animation if behavior should run and
        // add to this page's sequence
        _.forEach(animations, function (animation) {
            const pageId = getPageId.call(this, animation);
            const parentComponents = this._aspectSiteAPI.getPageComponents(pageId);
            if (!parentComponents) {
                return;
            }
            const targetId = animation.targetId || animation.sourceId;
            if (_.isString(targetId) && !parentComponents[targetId]) {
                return;
            }
            // If no sequence for this page, create one
            if (!sequences[pageId]) {
                const parent = this._aspectSiteAPI.getPageById(pageId);
                sequences[pageId] = parent.sequence();
                playedTargets[pageId] = [];
            }
            this.addAnimationToSequence(animation, sequences, clear);
            playedTargets[pageId].push(targetId);
        }.bind(this));

        let activeSequences = 1;

        const countAndRelease = function (seqId) {
            activeSequences--;
            this._liveSequenceIdsByGroup[animationGroup] = _.reject(this._liveSequenceIdsByGroup[animationGroup], {sequenceId: seqId});
            if (activeSequences === 0) {
                callback();
            }
        }.bind(this);

        // For every set of sequences created for each page
        //
        _.forEach(sequences, function (sequence, pageId) {
            if (sequence.hasAnimations()) {
                activeSequences++;
                sequence.onCompleteAll(countAndRelease.bind(this, sequence.getId()));
                playedTargets[pageId] = _.compact(playedTargets[pageId]);
                sequenceIds.push({parentId: pageId, sequenceId: sequence.getId()});
                sequence.execute();
            }
        }.bind(this));
        countAndRelease(); // if no sequences actually started call callback now
        this._liveSequenceIdsByGroup[animationGroup] = this._liveSequenceIdsByGroup[animationGroup].concat(sequenceIds);
        return playedTargets;
    };

    function setElementsVisibilityByAnimationType(animations, visibility) {
        _.forEach(animations, function (animation) { // eslint-disable-line complexity
            const animationProperties = this.animationsPackage.getProperties(animation.name);
            const willAnimationRunOnCurrentViewMode = !animation.viewMode || animation.viewMode === this._siteData.getViewMode();

            if (animationProperties && animationProperties.hideOnStart && willAnimationRunOnCurrentViewMode) {
                const pageId = getPageId.call(this, animation);
                const component = this._aspectSiteAPI.getComponentByPageAndCompId(pageId, animation.targetId || animation.sourceId);
                const element = component ? ReactDOM.findDOMNode(component) : null;
                if (element) {
                    element.style.opacity = visibility;
                }
            }
        }.bind(this));
    }

    /**
     * Hide all elements with animations that has the hideOnStart flag enabled
     * @param {object} animations
     */
    AnimationsAspect.prototype.hideElementsByAnimationType = function (animations) {
        setElementsVisibilityByAnimationType.call(this, animations, 0);
    };

    /**
     * Un-hide all elements with animations that has the hideOnStart flag enabled
     * @param {object} animations
     */
    AnimationsAspect.prototype.revertHideElementsByAnimations = function (animations) {
        setElementsVisibilityByAnimationType.call(this, animations, '');
    };

    /**
     * Preview a transition on 2 components (for DocumentServices)
     * @param {Array} srcCompIds array of ids
     * @param {Array} targetCompIds array of ids
     * @param {string} pageId
     * @param {{name:string, duration:number, delay:number, params:object}} transitionDef
     * @param {function} [onComplete]
     */
    AnimationsAspect.prototype.previewTransition = function (srcCompIds, targetCompIds, pageId, transitionDef, onComplete) {
        let page;
        if (pageId === 'masterPage') {
            page = this._aspectSiteAPI.getMasterPage();
        } else {
            page = this._aspectSiteAPI.getCurrentPage();
        }
        if (page) {
            const sequence = page.sequence();
            const params = {
                props: 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin',
                immediateRender: false
            };

            sequence.add({
                sourceRefs: srcCompIds,
                destRefs: targetCompIds
            }, transitionDef.name, transitionDef.duration, transitionDef.delay, transitionDef.params);
            sequence.add(srcCompIds.concat(targetCompIds), 'BaseClear', 0, 0, params);

            if (onComplete) {
                sequence.onCompleteAll(onComplete);
            }

            return sequence.execute();
        }
    };

    AnimationsAspect.prototype.stopPreviewAnimation = function (sequenceId, seekTo = 1) {
        const pages = this._aspectSiteAPI.getAllRenderedRoots();
        _.forEach(pages, function (page) {
            page.stopSequence(sequenceId, seekTo);
        });
    };

    AnimationsAspect.prototype.stopAndClearAllAnimations = function () {
        _.forEach(this._liveSequenceIdsByGroup, (seq, group) => this.stopAndClearAnimations(group, 1));
    };

    /**
     * Stop and clear all sequences previously created by this animationGroup
     * @param {string} animationGroup
     * @param {number} [seek] a number between 0 and 1 to set the progress of the animation to stop at. defaults to 1
     */
    AnimationsAspect.prototype.stopAndClearAnimations = function (animationGroup, seek) {
        _.forEach(this._liveSequenceIdsByGroup[animationGroup], function (entry) {
            const parent = this._aspectSiteAPI.getPageById(entry.parentId);
            if (parent) {
                parent.stopSequence(entry.sequenceId, seek);
            }
        }.bind(this));
        this._liveSequenceIdsByGroup[animationGroup] = [];
    };

    AnimationsAspect.prototype.isAnimationPlayable = function (animation) {
        return getPageId.call(this, animation);
    };

    return AnimationsAspect;
});
