define([
    'utils',
    'lodash',
    'componentsCore',
    'coreUtils',
    'core/core/pageRequests',
    'core/core/externalStyles',
    'core/core/requireCompCode',
    'core/siteRender/SiteMembersAspect',
    'core/core/tempCompsCheckersForExternalComps',
    'experiment'
], function (utils, _, componentsCore, coreUtils, pageRequests, externalStyles, requireCompCode, SiteMembersAspect, compCheckersForExternalComps, experiment) {
    'use strict';
    const dataUtils = coreUtils.dataUtils;

    const MASTER_PAGE_ID = 'masterPage';

    const requirementsCheckerFactories = [];

    coreUtils.sessionFreezer.freeze(requirementsCheckerFactories);

    _.forOwn(compCheckersForExternalComps, (checker, compType) => componentsCore.dataRequirementsCheckerRegistrar.registerCheckerForCompType(compType, checker));

    function getCompSkins(siteData, pageId, compStructure) {
        const styleItem = compStructure.styleId && siteData.getDataByQuery(compStructure.styleId, pageId, siteData.dataTypes.THEME);
        const skin = styleItem ? styleItem.skin : compStructure.skin;
        const res = [skin];
        if (compStructure.modes) {
            return res.concat(_(compStructure.modes.overrides).flatMap(_.partial(getCompSkins, siteData, pageId)).compact().value());
        }
        return res;
    }

    function getCompInfo(siteData, pageId, compStructure) {
        return {
            design: compStructure.designQuery ? siteData.getDataByQuery(compStructure.designQuery, pageId, siteData.dataTypes.DESIGN) : null,
            data: compStructure.dataQuery ? siteData.getDataByQuery(compStructure.dataQuery, pageId) : null,
            properties: compStructure.propertyQuery ? siteData.getDataByQuery(compStructure.propertyQuery, pageId, siteData.dataTypes.PROPERTIES) : null,
            style: compStructure.styleId ? siteData.getDataByQuery(compStructure.styleId, pageId, siteData.dataTypes.THEME) : null,
            skins: getCompSkins(siteData, pageId, compStructure),
            layout: compStructure.layout,
            type: compStructure.type,
            id: compStructure.id,
            pageId
        };
    }

    function getRequestsForSignUp(siteData, fullPagesData, pageId) {
        const docData = _.get(fullPagesData.pagesData[MASTER_PAGE_ID], ['data', 'document_data', pageId]);
        const pageSecurity = docData && docData.pageSecurity;
        return pageSecurity &&
            (pageSecurity.requireLogin || pageSecurity.passwordDigest) && // eslint-disable-line no-mixed-operators
            requireCompCode.get(siteData, 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.SignUpDialog') || []; // eslint-disable-line no-mixed-operators
    }

    function isControllerType(componentType) {
        const CONTROLLER_TYPES = [
            'platform.components.AppController',
            'platform.components.AppWidget'
        ];

        return _.includes(CONTROLLER_TYPES, componentType);
    }

    function getPageJsonFilenameForAppStudioWidget(siteData, csmData, devCenterWidgetId, applicationId, widgetId, controllerType) {
        const appStudioData = _.get(csmData, ['appFields', 'platform', 'studio']);

        if (_.isEmpty(appStudioData)) {
            return;
        }

        const jsonFileName = _.get(csmData, ['widgets', devCenterWidgetId, 'componentFields', 'appStudioFields', 'pageJsonFilename']);

        if (!jsonFileName && experiment.isOpen('sv_loadGhostStructureReportErrors', siteData)) {
            utils.integrations.ravenUtils.captureError('missing page structure info on appWidget', {
                tags: {
                    'load-ghost-structure': true
                },
                extra: {
                    info: {
                        applicationId,
                        widgetId,
                        devCenterWidgetId,
                        controllerType
                    }
                }
            });

            return;
        }

        return jsonFileName;
    }

    function getRequestForGhostStructureIfNeeded(siteData, componentType, compInfo) {
        if (experiment.isOpen('sv_loadGhostStructure', siteData) && isControllerType(componentType)) {
            const {applicationId, settings, controllerType} = compInfo.data || {};
            const {devCenterWidgetId, type: widgetId} = settings || {};

            if (_.get(siteData, ['ghostStructureData', applicationId, widgetId])) {
                return;
            }

            const csmData = siteData.getClientSpecMapEntryByAppDefinitionId(applicationId);

            const jsonFileName = getPageJsonFilenameForAppStudioWidget(siteData, csmData, devCenterWidgetId, applicationId, widgetId, controllerType);

            if (!jsonFileName) {
                return;
            }

            return pageRequests.getRequestForGhostStructure(siteData, jsonFileName, applicationId, widgetId);
        }
    }

    function getRequestsForComponentInPage(siteData, urlData, pageId, compStructure, allCompsOfTypeMap, runtimeCheckers) {
        const compInfo = getCompInfo(siteData, pageId, compStructure);
        const updaterPlugin = coreUtils.jsonUpdaterRegistrar.getCompPlugin(compStructure.componentType);
        if (updaterPlugin) {
            const getNodeInfo = structure => {
                const {data: dataItem, properties: propertiesItem} = structure.id === compStructure.id ? compInfo : getCompInfo(siteData, pageId, structure);
                return {
                    dataItem,
                    propertiesItem
                };
            };
            compStructure = updaterPlugin(compStructure, getNodeInfo, {}, siteData);
        }

        const shouldAddToAllCompsOfTypesMap = componentsCore.dataRequirementsCheckerRegistrar.getAllCompsOfTypeChecker(compStructure.componentType);
        if (shouldAddToAllCompsOfTypesMap) {
            allCompsOfTypeMap[compStructure.componentType] = allCompsOfTypeMap[compStructure.componentType] || [];
            allCompsOfTypeMap[compStructure.componentType].push(compInfo);
        }

        _.forEach(runtimeCheckers, checker => {
            checker.registerCompInfo(compInfo);
        });

        const requestsGetter = componentsCore.dataRequirementsCheckerRegistrar.getCheckerForCompType(compStructure.componentType);
        const requests = requestsGetter ? requestsGetter(siteData, compInfo, urlData) : [];

        const codeRequest = requireCompCode.get(siteData, compStructure.componentType);
        if (codeRequest) {
            requests.push(codeRequest);
        }

        const ghostStructureRequest = getRequestForGhostStructureIfNeeded(siteData, compStructure.componentType, compInfo);
        if (ghostStructureRequest) {
            requests.push(ghostStructureRequest);
        }

        //TODO: getChildrenData should get is mobile
        const children = dataUtils.getChildrenData(compStructure, siteData.isMobileView());
        return _.reduce(children, function (result, childCompStructure) {
            return result.concat(getRequestsForComponentInPage(siteData, urlData, pageId, childCompStructure, allCompsOfTypeMap, runtimeCheckers));
        }, requests);
    }

    function getRequestsForZoomComponent(siteData, fullPagesData, urlData) {
        if (!urlData.pageItemId) {
            return [];
        }
        const pageData = fullPagesData.pagesData[MASTER_PAGE_ID];
        const permaLinkDataItem = pageData.data.document_data[urlData.pageItemId];
        if (!permaLinkDataItem || permaLinkDataItem.type !== 'PermaLink') {
            return [];
        }
        const zoomDataItem = pageData.data.document_data[permaLinkDataItem.dataItemRef.replace(/^#/, '')];
        if (!zoomDataItem) {
            return [];
        }
        const zoomProperties = pageData.data.component_properties[permaLinkDataItem.dataItemRef.replace(/^#/, '')];
        const requestsGetter = componentsCore.dataRequirementsCheckerRegistrar.getCheckerForCompType(`Zoom:${zoomDataItem.type}`);
        if (requestsGetter) {
            const compInfo = {
                data: zoomDataItem,
                properties: zoomProperties
            };
            return requestsGetter(siteData, compInfo, urlData);
        }
        return [];
    }

    function getRequestsForAllCompsOfTypes(siteData, urlData, allCompsOfTypeMap) {
        return _.flatMap(allCompsOfTypeMap, function (compInfoArr, compType) {
            const requestGetter = componentsCore.dataRequirementsCheckerRegistrar.getAllCompsOfTypeChecker(compType);
            return requestGetter(siteData, compInfoArr, urlData);
        });
    }

    function getRequestsForRuntimeCheckers(runtimeCheckers) {
        return _(runtimeCheckers).invokeMap('getRequests').flatten().value();
    }

    function getRequestsForClientSpecMap(siteData, urlData) {
        const appDefIdCheckerMap = componentsCore.dataRequirementsCheckerRegistrar.getAppDefIdCheckerMap();
        return _(appDefIdCheckerMap)
            .map(function (requestGetter, appDefId) { //eslint-disable-line array-callback-return
                const appService = siteData.getClientSpecMapEntryByAppDefinitionId(appDefId);
                if (appService) {
                    return requestGetter(siteData, appService, urlData);
                }
            })
            .compact()
            .flatten()
            .value();
    }

    function getRequestForDataItem(siteData) {
        const requests = [];
        const pageItemInfo = siteData.getExistingRootNavigationInfo(siteData.getFocusedRootId());
        if (pageItemInfo.pageItemId) {
            const dataItem = siteData.getDataByQuery(pageItemInfo.pageItemId);
            if (dataItem) {
                const codeRequest = requireCompCode.forDataItem(siteData, dataItem.type);
                if (codeRequest) {
                    requests.push(codeRequest);
                }
            }
        }
        return requests;
    }

    function getRequestsForComponents(siteData, fullPagesData, urlDataForPages) {
        const _urlDataForPages = _.isArray(urlDataForPages) ? urlDataForPages : [urlDataForPages];
        const allCompsOfTypeMap = {};

        const runtimeCheckers = [];
        _.forEach(requirementsCheckerFactories, checkerFactory => {
            const checker = checkerFactory(siteData);
            if (checker) {
                runtimeCheckers.push(checker);
            }
        });

        const pagesData = fullPagesData.pagesData;
        let requests = getRequestsForComponentInPage(siteData, _urlDataForPages[0], MASTER_PAGE_ID, pagesData[MASTER_PAGE_ID].structure, allCompsOfTypeMap, runtimeCheckers);

        requests = _.reduce(_urlDataForPages, function (r, urlData) {
            return r.concat(getRequestsForComponentInPage(siteData, urlData, urlData.pageId, pagesData[urlData.pageId].structure, allCompsOfTypeMap, runtimeCheckers),
                getRequestsForZoomComponent(siteData, fullPagesData, urlData),
                getRequestsForAllCompsOfTypes(siteData, urlData, allCompsOfTypeMap),
                getRequestsForRuntimeCheckers(runtimeCheckers),
                getRequestsForClientSpecMap(siteData, urlData),
                getRequestsForSignUp(siteData, fullPagesData, urlData.pageId));
        }, requests);

        const pages = _.get(siteData.getPageList(), 'pages');
        if (pages) {
            requests = _.reduce(pages, function (r, page) {
                return r.concat(getRequestsForSignUp(siteData, fullPagesData, page.pageId));
            }, requests);
        }

        return requests.concat(getRequestForDataItem(siteData));
    }

    function isAllActiveRootsLoaded(siteData, activeRootsUrlData, fullPagesData) {
        return !_.isEmpty(fullPagesData.pagesData[siteData.MASTER_PAGE_ID]) && _.every(activeRootsUrlData, function (navInfo) {
            return !_.isEmpty(fullPagesData.pagesData[navInfo.pageId]);
        });
    }

    function isAllExternalStylesLoaded(siteData) {
        if (!(siteData.isInSSR() || siteData.isClientAfterSSR())) { // At the moment testing only SSR flow
            return true;
        }

        return _.every(externalStyles.getStylesIds(siteData), function (styleId) {
            return _.has(siteData.externalStyles, styleId);
        });
    }

    function isPasswordProtectedPage(siteData, currUrlData) {
        return _.includes(siteData.rendererModel.passwordProtectedPages, currUrlData.pageId);
    }

    function getUrlDataForActiveRoots(siteData, urlData) {
        let urlDataForAllActiveRoots = [urlData];

        if (siteData.isPopupPage(urlData.pageId)) {
            urlDataForAllActiveRoots.push(siteData.getExistingRootNavigationInfo(siteData.getPrimaryPageId()));
        }

        if (siteData.publicModel) {
            urlDataForAllActiveRoots = _.filter(urlDataForAllActiveRoots, function (currUrlData) {
                const pageInfo = _.find(siteData.getPageList().pages, {pageId: currUrlData.pageId});
                return _.has(pageInfo, 'pageJsonFileName') ||
                    coreUtils.errorPages.isErrorPage(currUrlData.pageId) ||
                    isPasswordProtectedPage(siteData, currUrlData);
            });
        }
        return urlDataForAllActiveRoots;
    }


    /**
     * @class core.core.dataRequirementsChecker
     */
    return {
        /**
         * @name core.core.dataRequirementsChecker.compInfo
         * @type{{
         *   data: object,
         *   properties: object
         *   skin: string
         *   id: string
         * }}
         */

        /**
         *
         * @param {core.SiteData} siteData
         * @param fullPagesData the full JSON containing pages data
         * @param urlData
         * @returns {utils.Store.requestDescriptor[]} array of requests that needs to be done.
         */
        getNeededRequests(siteData, fullPagesData, urlData) { // eslint-disable-line complexity
            siteData.santaBase = siteData.santaBase || siteData.serviceTopology.scriptsLocationMap.santa; // eslint-disable-line santa/no-side-effects
            const urlDataForAllActiveRoots = getUrlDataForActiveRoots(siteData, urlData);
            let requests;

            if (isAllActiveRootsLoaded(siteData, urlDataForAllActiveRoots, fullPagesData)) {
                requests = getRequestsForComponents(siteData, fullPagesData, urlDataForAllActiveRoots);
            } else {
                requests = pageRequests.getRequestsForPages(siteData, fullPagesData, urlDataForAllActiveRoots);

                const initialDialogToDisplay = SiteMembersAspect.getInitialDialogToDisplay(siteData);
                if (initialDialogToDisplay) {
                    const r = requireCompCode.get(siteData, 'wysiwyg.viewer.components.dialogs.siteMemberDialogs.ResetPasswordDialog');
                    if (r) {
                        requests.push(r);
                    }
                }
            }

            const useSelectiveTranslations = siteData.renderFlags.componentViewMode === 'preview' &&
                siteData.rendererModel.siteInfo.documentType !== 'Template';

            if (useSelectiveTranslations) {
                const requestModel = coreUtils.siteDataUtils.getRequestModel(siteData);
                const userLanguage = coreUtils.wixUserApi.getLanguage(requestModel.cookie, siteData.currentUrl, requestModel.language);
                const rendererModelLanguage = coreUtils.siteDataUtils.getRendererModel(siteData).languageCode;
                const dialogLanguages = _(_.get(siteData, ['rendererModel', 'pageList', 'pages']))
                    .map(page => _.get(fullPagesData, ['pagesData', MASTER_PAGE_ID, 'data', 'document_data', page.pageId, 'pageSecurity', 'dialogLanguage']))
                    .compact()
                    .uniq()
                    .value();

                const translations = [userLanguage, rendererModelLanguage, ...dialogLanguages, 'en'];
                const languageRequest = coreUtils.translationsLoader.getRequest(siteData, translations, {initiator: 'models'});
                requests.push(languageRequest);
            } else {
                coreUtils.translationsLoader.setToUseOldTranslations();
            }

            if (!isAllExternalStylesLoaded(siteData)) {
                requests.push.apply(requests, externalStyles.getRequests(siteData));
            }

            if (siteData.shouldShowWixAds()) {
                requests.push(requireCompCode.get(siteData, 'wysiwyg.viewer.components.WixFreemiumBanner'));
            }

            return _.compact(requests);
        },

        /**
         *
         * @param {core.SiteData} siteData
         * @param fullPagesData the full JSON containing pages data
         * @param urlData
         * @returns {utils.Store.requestDescriptor[]} array of page requests.
         */
        getRequestsForPages(siteData, fullPagesData, urlData) {
            siteData.santaBase = siteData.santaBase || siteData.serviceTopology.scriptsLocationMap.santa; // eslint-disable-line santa/no-side-effects
            const urlDataForAllActiveRoots = getUrlDataForActiveRoots(siteData, urlData);

            const requests = !isAllActiveRootsLoaded(siteData, urlDataForAllActiveRoots, fullPagesData) ?
                pageRequests.getRequestsForPages(siteData, fullPagesData, urlDataForAllActiveRoots) :
                [];

            return _.compact(requests);
        },

        /**
         *
         * @param {function} requirementsCheckerFactory to be invoked in runtime
         */
        registerCheckerFactory(requirementsCheckerFactory) {
            requirementsCheckerFactories.push(requirementsCheckerFactory);
        }
    };
});
