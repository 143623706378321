define([
    'lodash',
    'coreUtils',
    'core/core/site',
    'core/core/dataRequirementsChecker',
    'core/core/pageRequests',
    'core/core/requireCompCode',
    'core/core/SiteDataAPI',
    'core/siteRender/SiteAPI',
    'core/core/data/RuntimeDal',
    'core/siteRender/WixSiteHeadRenderer',
    'core/siteRender/SiteAspectsSiteAPI',
    'core/bi/events',
    'core/siteRender/WixSiteReact',
    'core/bi/errors',
    'core/aspects/widgetAspectPropsFetcher',
    'core/aspects/popoverAspectPropsFetcher',
    'core/core/storageAPI',
    'core/core/data/structureInfoGetter',
    'core/core/data/ViewerDisplayedJsonUpdater',
    'core/core/data/wixCode/wixCodeComponentsFetcher',
    'core/core/viewerCompsService',
    'core/core/externalComponentsServicesRegistry',
    // side effects
    'core/components/siteAspects/aspectsCollector',
    'core/components/siteAspects/AudioAspect',
    'core/siteRender/siteBehaviorsRegistrar',
    'core/core/siteHooks/pageLoadHooks'
], function (
    _,
    coreUtils,
    site,
    dataRequirementsChecker,
    pageRequests,
    requireCompCode,
    SiteDataAPI,
    SiteAPI,
    RuntimeDal,
    WixSiteHeadRenderer,
    SiteAspectsSiteAPI,
    events,
    WixSiteReact,
    errors,
    widgetAspectPropsFetcher,
    popoverAspectPropsFetcher,
    storageAPI,
    structureInfoGetter,
    ViewerDisplayedJsonUpdater,
    wixCodeComponentsFetcher,
    viewerCompsService,
    externalComponentsServicesRegistry
) {
    'use strict';
    //externalScriptLoader & AudioAspect - are here because someone requires it!!, if you see that there are more than 2 creatures like this here
    //please extract to another file

    /**
     * @class core
     */
    return {
        SiteDataAPI,
        /** @type core.SiteAPI */
        SiteAPI,
        RuntimeDal,
        MobileDeviceAnalyzer: coreUtils.MobileDeviceAnalyzer,
        renderer: site,
        WixSiteHeadRenderer,
        /** @type core.styleCollector */
        styleCollector: coreUtils.styleCollector,
        dataRequirementsChecker,
        pageRequests,
        requireCompCode,
        SiteAspectsSiteAPI,
        behaviorHandlersFactory: coreUtils.behaviorHandlersFactory,
        behaviorsService: coreUtils.behaviorsService,
        biEvents: events,
        DataAccessPointers: coreUtils.pointers.DataAccessPointers,
        PointersCache: coreUtils.pointers.PointersCache,
        pointerGeneratorsRegistry: coreUtils.pointers.pointerGeneratorsRegistry,
        SimpleDisplayedJsonUpdater: ViewerDisplayedJsonUpdater,
        storageAPI,
        aspects: {
            widgetAspectPropsFetcher,
            popoverAspectPropsFetcher
        },
        wixCode: {
            componentsFetcher: wixCodeComponentsFetcher
        },
        forTests: {
            WixSiteReact
        },
        errors,
        structureInfoGetter,
        animationsService: coreUtils.animationsService,
        viewerCompsService,
        externalComponentsServicesRegistry
    };
});
