define(['lodash', 'componentsCore', 'coreUtils', 'experiment'], function (_, componentsCore, coreUtils, experiment) {
    'use strict';

    const metasiteApplicationId = -666;

    function calcExpirationTimeInMs(expirationDateStr) {
        const expirationDateObj = new Date(expirationDateStr);
        const thirtyMinutes = 30 * 60 * 1000;
        return expirationDateObj.getTime() - Date.now() - thirtyMinutes;
    }

    function expireInstances(clientSpecMap) {
        const expirationDateStr = _.get(clientSpecMap, [metasiteApplicationId, 'expirationDate']);
        if (expirationDateStr && experiment.isOpen('sv_expireAppsInstances', this._aspectSiteAPI.getSiteData())) {
            const expirationTime = calcExpirationTimeInMs(expirationDateStr);
            setTimeout(() => {
                const onSuccess = response => {
                    const newClientSpecMap = _.get(response, ['clientSpecMap']) || response;
                    if (newClientSpecMap) {
                        const instanceMap = _(newClientSpecMap)
                            .mapValues(({instance}) => instance)
                            .omitBy(instance => !instance)
                            .value();
                        _.forEach(instanceMap, (instance, applicationId) => {
                            updateAppInstance.call(this, applicationId, instance);
                            const appDefinitionId = _.get(clientSpecMap[applicationId], 'appDefinitionId');
                            this._aspectSiteAPI.getSiteAspect('WidgetAspect').triggerNotifyApplicationRequest(appDefinitionId, {
                                eventType: 'instanceChanged',
                                eventPayload: {
                                    instance
                                }});
                        });
                        this._aspectSiteAPI.triggerClientSpecMapUpdatedCallbacks(newClientSpecMap);
                        expireInstances.call(this, newClientSpecMap);
                    }
                };
                if (this._aspectSiteAPI.getSiteData().isPreviewMode()) {
                    const instance = _.get(clientSpecMap, [metasiteApplicationId, 'instance']);
                    callToEditorServer.call(this, onSuccess, instance);
                } else {
                    callToServer.call(this, onSuccess);
                }
            }, expirationTime);
        }
    }

    function getDynamicApiUrl(siteBaseUrl, urlParams) {
        let url = `${siteBaseUrl.replace(/\/$/, '')}/_api/dynamicmodel`;
        const params = _.isEmpty(urlParams) ? '' : `?${coreUtils.urlUtils.toQueryString(urlParams)}`;
        if (typeof window !== 'undefined') {
            url = url.replace(/^[^:]+:/, window.location.protocol);
        }
        return url + params;
    }

    let cache = true;//eslint-disable-line santa/no-module-state
    function reloadClientSpecMap(siteData, callback, config) {
        let status = 'error';
        const reqDesc = {
            url: getDynamicApiUrl(siteData.getExternalBaseUrl(), config.urlParams),
            destination: ['dynamicClientSpecMapLoaded'],
            force: true,
            cache,
            syncCache: true,
            transformFunc() {
                return true;
            },
            callback: function (val, response) { //eslint-disable-line complexity
                status = 'success';
                _.invokeMap(this.reloadSpecMapPlugin, 'callback');
                if (response.visitorId) {
                    this._aspectSiteAPI.setBiVisitorId(response.visitorId);
                }
                if (response.siteMemberId) {
                    this._aspectSiteAPI.setBiSiteMemberId(response.siteMemberId);
                }
                if (response.clientSpecMap) {
                    this.aspectState.delete('appInstanceMap');
                    this._aspectSiteAPI.setClientSpecMap(response.clientSpecMap);
                    expireInstances.call(this, response.clientSpecMap);
                }
                if (response.svSession) {
                    siteData.pubSvSession(response.svSession);
                    this._aspectSiteAPI._site.notifyAspects(this._aspectSiteAPI._site.supportedEvents.svSessionChange, response.svSession);
                }
                if (response.hs) {
                    siteData.setHubSecurityToken(response.hs);
                }
                if (response.ctToken) {
                    siteData.setCTToken(response.ctToken);
                }
                if (callback) {
                    callback(response);
                }
            }.bind(this)
        };
        siteData.store.loadBatch([reqDesc], function () {
            if (status === 'error') {
                callback({status});
            }
        });
        cache = false;
    }

    function getNewAppInstance(applicationId, callback) {
        const onSuccess = (response = {}) => {
            const instance = _.get(response.clientSpecMap, [applicationId, 'instance']);
            this.updateAppInstance(applicationId, instance);
            this._aspectSiteAPI.triggerClientSpecMapUpdatedCallbacks();
            callback(instance);
        };
        const onError = () => callback();
        callToServer.call(this, onSuccess, onError);
    }

    function callToServer(success, error) {
        coreUtils.ajaxLibrary.ajax({
            type: 'GET',
            url: getDynamicApiUrl(this._aspectSiteAPI.getSiteData().getExternalBaseUrl(), {}),
            dataType: 'json',
            contentType: 'application/json',
            success,
            error
        });
    }

    function callToEditorServer(success, instance, error) {
        coreUtils.ajaxLibrary.ajax({
            type: 'GET',
            url: '/_api/wix-html-editor-webapp/fetch-editor-client-spec-map',
            dataType: 'json',
            contentType: 'application/json',
            headers: {
                Authorization: instance
            },
            success,
            error
        });
    }

    function updateAppInstance(applicationId, instance) {
        const appInstanceMap = this.aspectState.read('appInstanceMap') || {};
        appInstanceMap[applicationId] = instance;
        this.aspectState.update({appInstanceMap});
        notifyInstanceChanged.call(this, applicationId, instance);
    }

    function notifyInstanceChanged(applicationId, instance) {
        _.forEach(this.instanceChangedRegisteredComps[applicationId], function (comp) {
            comp.sendPostMessage({
                intent: 'addEventListener',
                eventType: 'INSTANCE_CHANGED',
                params: {
                    instance
                }
            });
        });
    }

    function buildAspectState(aspectSiteApi) {
        return {
            read(path) {
                const data = aspectSiteApi.getAspectGlobalData('dynamicClientSpecMap');
                return path ? _.get(data, path) : data;
            },
            update(newData) {
                aspectSiteApi.updateAspectGlobalData('dynamicClientSpecMap', newData);
            },
            delete(path) {
                const newData = _.set(aspectSiteApi.getAspectGlobalData('dynamicClientSpecMap'), path, undefined);
                aspectSiteApi.updateAspectGlobalData('dynamicClientSpecMap', newData);
            }
        };
    }

    /**
     *
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @constructor
     */
    class DynamicClientSpecMapAspect {
        constructor(aspectSiteAPI) {
            this.reloadSpecMapPlugin = [];
            this.instanceChangedRegisteredComps = {};
            this._aspectSiteAPI = aspectSiteAPI;
            this.aspectState = buildAspectState(aspectSiteAPI);

            expireInstances.call(this, aspectSiteAPI.getClientSpecMap());
        }

        reloadClientSpecMap(callback, config) {
            const siteData = this._aspectSiteAPI.getSiteData();
            if (siteData && siteData.isViewerMode()) {
                reloadClientSpecMap.call(this, siteData, callback, _.isObject(config) ? config : {});
            }
        }

        getNewAppInstance(applicationId, callback) {
            getNewAppInstance.call(this, applicationId, callback);
        }

        updateAppInstance(applicationId, instance) {
            updateAppInstance.call(this, applicationId, instance);
        }

        getAppInstance(applicationId) {
            const appInstanceMap = this.aspectState.read('appInstanceMap');
            return _.get(appInstanceMap, [applicationId]);
        }

        registerToInstanceChanged(comp) {
            const applicationId = comp.props.compData.applicationId;
            _.set(this.instanceChangedRegisteredComps, [applicationId, comp.props.id], comp);
        }

        unRegisterToInstanceChanged(comp) {
            const applicationId = comp.props.compData.applicationId;
            if (this.instanceChangedRegisteredComps[applicationId]) {
                delete this.instanceChangedRegisteredComps[applicationId][comp.props.id];
            }
        }

        registerReloadSpecMapPlugin(compPageId, callback) {
            this.reloadSpecMapPlugin.push({compPageId, callback: () => {
                const currentPageId = this._aspectSiteAPI.getSiteData().getCurrentUrlPageId();
                if (currentPageId === compPageId) {
                    callback();
                }
            }});
        }
    }

    componentsCore.siteAspectsRegistry.registerSiteAspect('dynamicClientSpecMap', DynamicClientSpecMapAspect);
});
