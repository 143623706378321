define([
    'componentsCore',
    'lodash',
    'zepto'
], function (componentsCore, _, $) {
    'use strict';

    function SiteScrollingBlocker(api) {
        this._siteAPI = api.getSiteAPI();
        const siteData = this._siteAPI.getSiteData();
        this.fixOverflowScrollEdges = siteData.browserFlags().fixOverflowScrollEdges;
        this._blockersList = [];
        this._scrollCorrection = undefined;
        this.blockListeners = [];
    }

    function saveScrollPosition() {
        this._scrollCorrection = window.scrollY;
    }

    function restoreScrollPosition() {
        window.scrollTo(0, this._scrollCorrection);
    }

    function blockSiteScrolling(blocker) {
        function notifyBlocked() {
            return function (listener) {
                listener.handleBlockedBy(blocker);
            };
        }

        saveScrollPosition.call(this);
        $('html')
            .css({marginTop: `${-Math.max(0.5, window.scrollY)}px`})
            .addClass('blockSiteScrolling');

        this.blockListeners.forEach(notifyBlocked());
    }


    function unblockSiteScrolling(blocker) {
        function notifyUnblocked() {
            return function (listener) {
                listener.handleUnblockedBy(blocker);
            };
        }

        $('html')
            .removeClass('blockSiteScrolling')
            .css({marginTop: ''});

        restoreScrollPosition.call(this);
        this.blockListeners.forEach(notifyUnblocked());
    }

    SiteScrollingBlocker.prototype = {
        setSiteScrollingBlocked(blocker, value) {
            const isAlreadyBlocked = _.head(this._blockersList);

            this._blockersList = value ?
                _(this._blockersList).concat(blocker).uniq().value() :
                _.without(this._blockersList, blocker);

            const shouldBeBlocked = _.head(this._blockersList);

            if (isAlreadyBlocked === shouldBeBlocked) {
                return;
            }

            (shouldBeBlocked ? blockSiteScrolling : unblockSiteScrolling).call(this, blocker);
        },

        setSiteScrollingBlockedForModal(blocker, value, isiOS) {
            if (!isiOS) {
                return this.setSiteScrollingBlocked(blocker, value);
            }

            const siteContainerElement = $('#SITE_CONTAINER');
            if (!value && siteContainerElement.css('overflow') === 'hidden') {
                return siteContainerElement.css({height: 'auto', overflow: 'auto'});
            }
        },

        isSiteScrollingBlocked() {
            return !!_.size(this._blockersList);
        },


        registerScrollingLayer(layer) {
            if (!this.fixOverflowScrollEdges) {
                return;
            }

            layer.addEventListener('scroll', function () {
                layer.scrollTop = _.clamp(layer.scrollTop, 1, layer.scrollHeight - layer.clientHeight - 1);
            });
        },

        registerScrollBlockedListener(listener) {
            this.blockListeners.push(listener);
        },

        onHeightChanged(isiOS, height) {
            if (isiOS) {
                this._siteAPI.exitFullScreenMode();
                return $('#SITE_CONTAINER').css({height, overflow: 'hidden'});
            }
        },

        getDeviceSpecificProperties(isMobile, isiOS, height) {
            if (isMobile && isiOS && height > 0) {
                return {
                    '': {
                        style: {
                            position: 'absolute',
                            height
                        }
                    },
                    dialog: {
                        style: {
                            position: 'absolute'
                        }
                    },
                    iframe: {
                        style: {
                            height
                        }
                    }
                };
            }

            return {};
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('siteScrollingBlocker', SiteScrollingBlocker);
});
