define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const {SITE_STRUCTURE_ID, PAGES_CONTAINER_ID, SITE_PAGES_ID, HEADER_ID, FOOTER_ID} = coreUtils.siteConstants;

    return function fetchComponents(siteDataAPI, pageId, rootCompId) {
        const compsUnderRoot = siteDataAPI.document.getFullAndDisplayedOnlyCompsUnderRoot(pageId, rootCompId);

        let excludedComps = [SITE_STRUCTURE_ID, PAGES_CONTAINER_ID, SITE_PAGES_ID];

        if (siteDataAPI.siteData.isApplicationStudio()) {
            excludedComps = excludedComps.concat([HEADER_ID, FOOTER_ID, pageId]);
        }

        return _.omit(compsUnderRoot, excludedComps);
    };
});
