define(['lodash', 'componentsCore'],
    function (_, componentsCore) {
        'use strict';

        /**
         * @constructor
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         */
        function SvSessionChangeEventAspec(aspectSiteAPI) {
            this._aspectSiteAPI = aspectSiteAPI;
            this._registeredComps = {};

            aspectSiteAPI.registerToSvSessionChange(this.notifySessionChanged.bind(this));
        }

        SvSessionChangeEventAspec.prototype = {
            registerToSessionChanged(comp) {
                this._registeredComps[comp.props.id] = comp;
            },

            unRegisterToSessionChanged(comp) {
                delete this._registeredComps[comp.props.id];
            },

            notifySessionChanged(svSession) {
                _.forEach(this._registeredComps, function (comp) {
                    comp.sendPostMessage({
                        intent: 'addEventListener',
                        eventType: 'SESSION_CHANGED',
                        params: {
                            userSession: svSession
                        }
                    });
                });
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('svSessionChangeEvent', SvSessionChangeEventAspec);
    });
