define([
    'utils',
    'santa-components',
    'lodash',
    'react',
    'prop-types',
    'create-react-class',
    'reactDOM',
    'santaProps',
    'core/siteRender/WixPageReact',
    'core/siteRender/blockingLayer'
], function (
    utils,
    santaComponents,
    _,
    React,
    PropTypes,
    createReactClass,
    reactDOM,
    santaProps,
    WixPageReactClass,
    blockingLayerClass
) {
    'use strict';

    const wixPageConstructor = React.createFactory(WixPageReactClass);
    const blockingPopupLayerConstructor = React.createFactory(blockingLayerClass);

    function isInViewerOrPreview(siteAPI) {
        return siteAPI.getRenderFlag('componentViewMode') === 'preview';
    }

    const getPopupsWrapperStyles = (isMesh, isMobileView, siteWidth, screenHeight) => _.assign({
        margin: '0 auto',
        width: isMobileView ? siteWidth : 'auto'
    }, !isMesh && {
        minWidth: siteWidth,
        minHeight: screenHeight
    });

    return createReactClass({
        displayName: 'WixPopupRoot',

        propTypes: {
            siteAPI: PropTypes.shape({
                getSiteAspect: PropTypes.func.isRequired,
                getRuntimeDal: PropTypes.func.isRequired,
                getLayoutMechanism: PropTypes.func.isRequired,
                getRenderFlag: PropTypes.func.isRequired
            }).isRequired,
            viewerPrivateServices: PropTypes.shape({
                pointers: PropTypes.object.isRequired,
                displayedDAL: PropTypes.object.isRequired,
                siteDataAPI: PropTypes.object.isRequired
            }).isRequired,
            mobxObserverWrapperProps: PropTypes.object,
            siteData: PropTypes.object.isRequired,
            currentPopupId: PropTypes.string,
            measureMap: PropTypes.object
        },

        getInitialState() {
            return {
                activePopupId: this.props.currentPopupId,
                activePopupKey: 1,
                animating: false
            };
        },

        shouldComponentUpdate(nextProps, nextState) {
            if (!nextProps.currentPopupId && this.state.activePopupId && !this.state.animating) {
                const actionsAsepct = this.props.siteAPI.getSiteAspect('actionsAspect');
                const popupCompsId = _.keys(this.refs[this.state.activePopupId].refs);
                this.setState({animating: true});

                let didPopupExitSynchronously = false;

                actionsAsepct.registerComponentsExit(popupCompsId, function () {
                    if (!nextProps.currentPopupId) {
                        this.setState({activePopupId: null, animating: false});
                    }
                    actionsAsepct.handleNavigationComplete();
                    didPopupExitSynchronously = true;
                }.bind(this));

                return didPopupExitSynchronously;
            } else if (nextState.animating && !nextProps.currentPopupId) {
                return false;
            }
            return true;
        },

        componentWillReceiveProps(nextProps) {
            if (nextProps.currentPopupId !== this.state.activePopupId) {
                this.setState({
                    activePopupId: nextProps.currentPopupId,
                    activePopupKey: (this.state.activePopupKey || 0) + 1,
                    animating: false
                });
            }
        },

        componentDidUpdate() {
            const popupId = this.state.activePopupId;
            let runtimeDAL;

            if (popupId && isInViewerOrPreview(this.props.siteAPI)) {
                runtimeDAL = this.props.siteAPI.getRuntimeDal();
                runtimeDAL.markPopupAsBeenOpened(popupId);
            }
        },

        render() {
            const siteData = this.props.siteData;
            const siteAPI = this.props.siteAPI;
            const currentPopupId = this.state.activePopupId;
            const isMobileView = siteData.isMobileView();
            const isMeshLayoutMechanism = siteAPI.getLayoutMechanism() === utils.constants.LAYOUT_MECHANISMS.MESH;

            if (currentPopupId) {
                const popupProps = santaProps.componentPropsBuilder.getRootProps(WixPageReactClass, this.props.currentPopupId, this.props.siteAPI, this.props.viewerPrivateServices, this.props.measureMap);
                popupProps.mobxObserverWrapperProps = this.props.mobxObserverWrapperProps;

                popupProps.key = this.state.activePopupKey + (isMobileView ? '_mobile' : '_desktop');

                const popup = [
                    wixPageConstructor(popupProps),
                    blockingPopupLayerConstructor({
                        key: 'blockingPopupLayer',
                        siteAPI: this.props.siteAPI,
                        layerName: 'blockingPopupLayer'
                    })
                ];

                const wrapper = santaComponents.utils.createReactElement('div', {
                    id: 'POPUPS_WRAPPER',
                    className: 'POPUPS_WRAPPER',
                    key: 'POPUPS_WRAPPER',
                    style: getPopupsWrapperStyles(isMeshLayoutMechanism, isMobileView, siteData.getSiteWidth(), siteData.getScreenHeight()),
                    children: popup
                });

                return santaComponents.utils.createReactElement('div', {
                    id: 'POPUPS_ROOT',
                    className: isMobileView ? 'POPUPS_ROOT mobile' : 'POPUPS_ROOT',
                    key: 'POPUPS_ROOT',
                    style: {
                        overflow: this.props.siteAPI.getRenderFlag('allowSiteOverflow') ? null : 'hidden'
                    },
                    children: [wrapper]
                });
            }

            return null;
        },

        componentDidMount() {
            const DOMNode = reactDOM.findDOMNode(this);

            if (DOMNode) {
                DOMNode.focus();
            }
        }
    });
});
