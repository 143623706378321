/**
 * Created with IntelliJ IDEA.
 * User: avim
 * Date: 6/9/14
 * Time: 3:48 PM
 * To change this template use File | Settings | File Templates.
 */
define([
    'santa-components',
    'lodash',
    'create-react-class',
    'mobx',
    'mobx-react',
    'coreUtils',
    'core/siteRender/ExternalStylesRenderer'
], function (
    santaComponents,
    _,
    createReactClass,
    mobx,
    mobxReact,
    coreUtils,
    externalStylesRenderer
) {
    'use strict';
    const fontUtils = coreUtils.fontUtils;

    function getThemeDataItem(siteData, id) {
        return siteData.getDataByQuery(id, siteData.MASTER_PAGE_ID, siteData.dataTypes.THEME);
    }

    function loadFonts(pagesUsedFontsComputed) {
        const fontsLoaderAspect = this.props.siteAPI.getSiteAspect('fontsLoaderAspect');
        if (!fontsLoaderAspect) {
            return;
        }

        const fontsToLoad = mobx.computed(() => {
            const pagesUsedFonts = pagesUsedFontsComputed.get();
            const fontList = _(pagesUsedFonts)
                .flattenDeep()
                .uniq()
                .value();
            return fontsLoaderAspect.filterHandledFonts(fontList, this.props.requestRelayout);
        });
        const listener = () => {
            const fontList = fontsToLoad.get();
            fontsLoaderAspect.loadFonts(fontList, this.props.requestRelayout);
        };

        this.disposeLoadFonts = mobx.observe(
            fontsToLoad,
            listener
        );

        listener();
    }

    function collectAndLoadFonts() {
        const siteData = this.props.siteData;
        const themeData = getThemeDataItem(siteData, 'THEME_DATA');
        const pagesUsedFonts = mobx.computed(() => fontUtils.getPageUsedFontsList(siteData));

        if (themeData && themeData.font) {
            loadFonts.call(this, pagesUsedFonts);
        }
    }

    function enableCss(cssUrl, key) {
        const elem = window.document.getElementById(`font_${key}`);
        if (!elem) {
            return true;
        }
        elem.removeAttribute('media');
        return false;
    }

    function getExternalFonts(siteData) {
        const documentType = siteData.rendererModel.siteInfo.documentType;
        let cssUrls = fontUtils.getCssUrls(documentType, siteData.serviceTopology);

        if (siteData.isInSSR() || siteData.isClientAfterSSR()) {
            return {externalFontsStyles: siteData.externalStyles};
        }

        // For CSS that have already been prefetched
        // just enable the existing <link> instead of creating new <link>
        // (by removing media="none" attribute)
        // See fontUtils.js
        cssUrls = _.pickBy(cssUrls, enableCss);

        return {externalFontsUrls: cssUrls};
    }

    const wixThemeReactClasss = createReactClass({
        displayName: 'WixThemeReact',

        setTestStyleRef(styleComp) {
            this.testStyleComp = styleComp;
        },

        getInitialState() {
            return {};
        },

        render() {
            const {siteData, shouldRenderPage} = this.props; //eslint-disable-line react/prop-types
            const themeData = siteData.getDataByQuery('THEME_DATA', siteData.MASTER_PAGE_ID, siteData.dataTypes.THEME); //eslint-disable-line react/prop-types

            if (themeData && themeData.font) {
                const externalFonts = shouldRenderPage && getExternalFonts(siteData);
                const externalStyles = santaComponents.utils.createReactElement(externalStylesRenderer, externalFonts);

                return santaComponents.utils.createReactElement('div', {key: 'wixThemeReact'}, externalStyles);
            }
        },

        componentWillMount() {
            collectAndLoadFonts.call(this);
        },

        componentWillUnmount() {
            if (this.disposeLoadFonts) {
                this.disposeLoadFonts();
            }
        }

    });

    return mobxReact.observer(wixThemeReactClasss);
});
