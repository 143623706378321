define([
    'lodash',
    'componentsCore'
], function (
    _,
    componentsCore
) {
    'use strict';

    class ScrollableAspect {
        constructor(aspectSiteAPI) {
            if (aspectSiteAPI.getSiteData().isInSSR()) {
                return;
            }
            this.aspectSiteAPI = aspectSiteAPI;
            this.scrollableNodes = new Map();
            this.scrollableCompIds = new Map();
            this.compsToIgnoreOnNextScrollEvent = [];

            this.onScroll = ({target}) => {
                if (_.includes(this.compsToIgnoreOnNextScrollEvent, target)) {
                    this.compsToIgnoreOnNextScrollEvent = _.without(
                        this.compsToIgnoreOnNextScrollEvent,
                        target
                    );
                } else {
                    this.registerCurrentScrollToMaps(target);
                }
            };
        }

        registerCurrentScrollToMaps(target) {
            if (this.scrollableNodes.has(target)) {
                const {compId} = this.scrollableNodes.get(target);
                const compScrollPosition = {
                    x: target.scrollLeft,
                    y: target.scrollTop
                };

                this.scrollableNodes.set(target, {
                    compId,
                    value: compScrollPosition

                });

                this.scrollableCompIds.set(compId, {
                    scrollableNode: target,
                    value: compScrollPosition
                });
            }
        }

        registerComponent(compId, scrollableSelector) {
            const domNode = window.document.getElementById(compId);
            const scrollableNode = domNode.querySelector(scrollableSelector);
            const initialValue = {
                x: scrollableNode.scrollLeft,
                y: scrollableNode.scrollTop
            };

            this.scrollableNodes.set(scrollableNode, {
                compId,
                value: initialValue
            });

            this.scrollableCompIds.set(compId, {
                scrollableNode,
                value: initialValue
            });

            scrollableNode.addEventListener('scroll', this.onScroll);
        }

        unregisterComponent(compId) {
            const entry = this.scrollableCompIds.get(compId);
            if (!entry) {
                return;
            }
            const {scrollableNode} = entry;
            scrollableNode.scrollTo(0, 0);
            this.scrollableNodes.delete(scrollableNode);
            this.scrollableCompIds.delete(compId);
            scrollableNode.removeEventListener('scroll', this.onScroll);
        }

        scroll(compId, x, y, callback) {
            const entry = this.scrollableCompIds.get(compId);
            if (!entry) {
                return;
            }
            const {scrollableNode} = entry;
            scrollableNode.scrollTo(x, y);
            this.registerCurrentScrollToMaps(scrollableNode);
            this.compsToIgnoreOnNextScrollEvent.push(scrollableNode);
            if (_.isFunction(callback)) {
                callback();
            }
        }

        scrollBy(compId, x, y, callback) {
            const entry = this.scrollableCompIds.get(compId);
            if (!entry) {
                return;
            }
            const {scrollableNode} = entry;
            scrollableNode.scrollBy(x, y);
            this.registerCurrentScrollToMaps(scrollableNode);
            this.compsToIgnoreOnNextScrollEvent.push(scrollableNode);
            if (_.isFunction(callback)) {
                callback();
            }
        }

        getTotalScroll(compId) {
            const {components: componentsPointers} = this.aspectSiteAPI.getPointers();
            const siteData = this.aspectSiteAPI.getSiteData();
            const pagePointer = componentsPointers.getPage(siteData.getFocusedRootId(), siteData.getViewMode());
            const compPointer = componentsPointers.getComponent(compId, pagePointer);
            const ancestors = componentsPointers.getAncestors(compPointer);

            return _.reduce(ancestors, (acc, ancestorPointer) => {
                const scrollPosition = _.get(this.scrollableCompIds.get(ancestorPointer.id), 'value', {x: 0, y: 0});
                return {
                    x: acc.x + scrollPosition.x,
                    y: acc.y + scrollPosition.y
                };
            }, {x: 0, y: 0});
        }
    }

    componentsCore.siteAspectsRegistry.registerSiteAspect('scrollableAspect', ScrollableAspect);
});
