define(['componentsCore', 'lodash', 'coreUtils'], function (componentsCore, _, coreUtils) {
    'use strict';

    const ASPECT_NAME = 'VerticalAnchorsMenuAspect';

    function notifyObservers(observers) {
        _.forEach(observers, function (observer) {
            observer();
        });
    }

    function getCompLayoutFromMeasureMap(measureMap, id) {
        if (measureMap) {
            return {
                top: measureMap.absoluteTop[id],
                left: _.get(measureMap, `custom[${id}].backgroundLeft`, 0) + measureMap.absoluteLeft[id],
                height: measureMap.height[id],
                width: _.get(measureMap, `custom[${id}].backgroundWidth`, measureMap.width[id])
            };
        }

        return {};
    }

    function didLayoutHandler() {
        let aspectGlobalData = this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME) || {};
        const measureMap = this.aspectSiteAPI.getSiteData().measureMap;

        // To set the first one as active before scroll
        if (!aspectGlobalData.anchorsData && this.activeAnchorObservers.length > 0) {
            calculateActiveAnchors.call(this, 0);
            aspectGlobalData = this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME) || {};
        }

        const updatedAspectGlobalData = aspectGlobalData;
        updatedAspectGlobalData.colorInfo = _.mapValues(aspectGlobalData.colorInfo, function (colorsInfoMap) {
            return _.mapValues(colorsInfoMap, function (colorInfo, compId) {
                return _.assign(colorInfo, {
                    layout: getCompLayoutFromMeasureMap(measureMap, compId)
                });
            });
        });

        this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, updatedAspectGlobalData);

        notifyObservers(this.autoColorObservers);
    }

    function onRootChange(addedRoots, removedRoots) {
        const aspectGlobalData = this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME) || {};
        const updatedAspectGlobalData = aspectGlobalData;
        updatedAspectGlobalData.colorInfo = _.omit(aspectGlobalData.colorInfo, removedRoots);
        this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, updatedAspectGlobalData);
    }

    function checkMeanColorOfImage(aspect, imageData) {
        const onSuccess = function (brightness, alpha) {
            aspect.updateInformation(imageData.compId, imageData.rootId, {brightness, alpha});
        };

        const onError = function () {
            // todo TBD
            return null;
        };

        coreUtils.imageUtils.getImageMeanBrightness(imageData.thumbnailImageUrl, {width: 1, height: 1}, onSuccess, onError);
    }

    function checkMeanColorOfImages(aspect) {
        const forEachCheckColor = checkMeanColorOfImage.bind(null, aspect);

        _.forOwn(aspect.imagesToCheckColorOf, forEachCheckColor);

        aspect.imagesToCheckColorOf = [];
    }

    function calculateActiveAnchors(scrollY) {
        if (this.activeAnchorObservers.length > 0) {
            this.aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {
                anchorsData: coreUtils.scrollAnchors.getAnchorsDataSortedByY(this.aspectSiteAPI.getSiteData(), scrollY)
            });
        }
    }

    function onScrollHandler(position) {
        calculateActiveAnchors.call(this, position.y);
        notifyObservers(this.autoColorObservers);
    }

    /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
    const VerticalAnchorsMenuAspect = function (aspectSiteAPI) {
        this.aspectSiteAPI = aspectSiteAPI;
        this.autoColorObservers = [];
        this.activeAnchorObservers = [];
        this.imagesToCheckColorOf = {};

        _.bindAll(this, _.functionsIn(this));

        aspectSiteAPI.registerToDidLayout(didLayoutHandler.bind(this));
        aspectSiteAPI.registerToScroll(onScrollHandler.bind(this));
        aspectSiteAPI.registerToRenderedRootsChange(onRootChange.bind(this));
    };

    VerticalAnchorsMenuAspect.prototype = {

        updateInformation(compId, rootId, colorInfo) {
            const measureMap = this.aspectSiteAPI.getSiteData().measureMap;

            if (measureMap) {
                _.assign(colorInfo, {
                    layout: getCompLayoutFromMeasureMap(measureMap, compId)
                });
            }

            const aspectGlobalData = this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME) || {};
            _.set(aspectGlobalData, ['colorInfo', rootId, compId], colorInfo);
            this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, aspectGlobalData);

            notifyObservers(this.autoColorObservers);
        },

        updateImageInfo(compId, rootId, thumbnailImageUrl) {
            const imageData = {
                thumbnailImageUrl,
                compId,
                rootId
            };

            if (this.autoColorObservers.length) {
                checkMeanColorOfImage(this, imageData);
            } else {
                const mapKey = `${rootId.toString()}-${compId.toString()}`;

                const mapObj = this.imagesToCheckColorOf[mapKey];

                if (!mapObj) {
                    this.imagesToCheckColorOf[mapKey] = imageData;
                } else {
                    _.assign(this.imagesToCheckColorOf[mapKey], imageData);
                }
            }
        },

        registerToMeanColor(observer) {
            this.autoColorObservers.push(observer);
            checkMeanColorOfImages(this);
        },

        unregisterToMeanColor(observer) {
            _.pull(this.autoColorObservers, observer);
        },

        // This is here only to prevent calculating the active anchor when no one cares
        registerToActiveAnchor(observer) {
            this.activeAnchorObservers.push(observer);
        },

        unregisterToActiveAnchor(observer) {
            _.pull(this.activeAnchorObservers, observer);
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('VerticalAnchorsMenuAspect', VerticalAnchorsMenuAspect);
    return VerticalAnchorsMenuAspect;
});
