define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    function getOriginalCompStructure(sitePrivates, pageId, rootId, viewMode) {
        const fullJsonCache = sitePrivates.cache.getBoundCacheInstance(true);
        const pagePointer = sitePrivates.pointers.full.components.getPage(pageId, viewMode);
        const rootPointer = pagePointer && sitePrivates.pointers.full.components.getComponent(rootId, pagePointer);
        const nodePath = rootPointer && fullJsonCache.getPath(rootPointer);
        return nodePath ? _.get(sitePrivates.fullPagesData, nodePath) : null;
    }

    /**
     * Get pointers for all comps from the full json that are under the given node
     * @returns CompStructure[]
     */
    function getAllCompsUnderRoot(sitePrivates, pageId, rootCompId) {
        const originalId = coreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(rootCompId);
	    const fullNodeStructure = getOriginalCompStructure(sitePrivates, pageId, originalId, sitePrivates.siteData.getViewMode());
        return coreUtils.dataUtils.getAllCompsInStructure(fullNodeStructure, sitePrivates.siteData.isMobileView());
    }

    function getFullAndDisplayedOnlyCompsUnderRoot(sitePrivates, pageId, rootCompId) {
        const runtimeDal = sitePrivates.siteDataAPI.runtime;
        const fullComps = getAllCompsUnderRoot(sitePrivates, pageId, rootCompId);
        return _.reduce(fullComps, function (result, fullComp) {
            const displayedComps = runtimeDal.getDisplayedOnlyCompsForFullComp(fullComp.id, pageId);
            _.forEach(displayedComps, function (comp) {
                result[comp.id] = comp;
            });
            result[fullComp.id] = fullComp;
            return result;
        }, {});
    }

    function getFullStructureProperty(sitePrivates, compPointer, property) {
        const fullJsonCache = sitePrivates.cache.getBoundCacheInstance(true);
        const nodePath = fullJsonCache.getPath(sitePrivates.pointers.getInnerPointer(compPointer, property));
        return nodePath ? _.get(sitePrivates.fullPagesData, nodePath) : null;
    }

    function getFullDataItem(sitePrivates, dataPointer) {
        const fullJsonCache = sitePrivates.cache.getBoundCacheInstance(true);
        const nodePath = fullJsonCache.getPath(dataPointer);
        return nodePath ? _.get(sitePrivates.fullPagesData, nodePath) : null;
    }

    function getFullPagesData(sitePrivates) {
        return sitePrivates.fullPagesData.pagesData;
    }

    return function getAPI(privateServices) {
        const api = {
            getAllCompsUnderRoot: _.partial(getAllCompsUnderRoot, privateServices),
            getFullAndDisplayedOnlyCompsUnderRoot: _.partial(getFullAndDisplayedOnlyCompsUnderRoot, privateServices),
	        getFullStructureProperty: _.partial(getFullStructureProperty, privateServices),
            getFullDataItem: _.partial(getFullDataItem, privateServices)
        };

        if (privateServices.siteData.isInSSR() || window.isMockWindow) {
            api.getFullPagesData = _.partial(getFullPagesData, privateServices);
        }

        return api;
    };
});
