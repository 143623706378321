define([
    'core/core/site',
    'coreUtils'
], function (site, coreUtils) {
    'use strict';

    function activateSospModes(siteAPI, rootNavigationInfo) {
        if (rootNavigationInfo.pageId !== coreUtils.siteConstants.MASTER_PAGE_ID) {
            const siteDataAPI = siteAPI.getSiteDataAPI();
            const siteData = siteAPI.getSiteData();
            let pageIdToUpdate = rootNavigationInfo.pageId;
            if (siteData.isPopupPage(pageIdToUpdate)) {
                pageIdToUpdate = siteData.getPrimaryPageId();
            }
            siteDataAPI.updateActiveSOSPModes(pageIdToUpdate);
        }
    }

    site.hooks.registerHook(site.hooks.types.PAGE_LOADED_FIRST_RENDER, function (siteData, wixCodeAppApi, staticPageRedirect) {
        if (!siteData.rendererModel.previewMode && !site.isProtectedPage(siteData) && !coreUtils.errorPages.isErrorPage(siteData.getPrimaryPageId()) && !staticPageRedirect) {
            wixCodeAppApi.preInitWidgets(siteData, siteData.currentUrl.full);
        }
    });

    site.hooks.registerHook(site.hooks.types.PAGE_LOADED, function (siteAPI, rootNavigationInfo) { // eslint-disable-line complexity
        activateSospModes(siteAPI, rootNavigationInfo);

        const siteData = siteAPI.getSiteData();
        const isLoadingPageStaticOrPopup = !rootNavigationInfo.routerDefinition;

        const returningFromPopup = siteData.getFocusedRootId() === siteAPI.getCurrentPopupId();
        const popupToPrimaryPageNavigation = returningFromPopup && rootNavigationInfo.pageId === siteData.getPrimaryPageId();
        const didFocusedPageChange = siteData.getFocusedRootId() !== rootNavigationInfo.pageId;
        const didLanguageChange = rootNavigationInfo.isLanguageChange;

        if ((isLoadingPageStaticOrPopup && didFocusedPageChange && !popupToPrimaryPageNavigation) || didLanguageChange) {
            siteAPI.getSiteAspect('WidgetAspect').initApps([rootNavigationInfo.pageId]);
            return;
        }

        if (rootNavigationInfo.routerDefinition) {
            const navigationWithinSameDynamicPage = !didFocusedPageChange;
            const innerRouteChanged = rootNavigationInfo.innerRoute !== siteData.getRootNavigationInfo().innerRoute;
            if (navigationWithinSameDynamicPage && innerRouteChanged) {
                siteAPI.getSiteAspect('WidgetAspect').initApps([rootNavigationInfo.pageId]);
                return;
            }

            const navigationFromNonPopupPage = didFocusedPageChange && !returningFromPopup;
            if (navigationFromNonPopupPage) {
                siteAPI.getSiteAspect('WidgetAspect').initApps([rootNavigationInfo.pageId]);
                return;
            }

            if (returningFromPopup) {
                const navigatingToTheSameDynamicPage = rootNavigationInfo.pageId === siteData.getPrimaryPageId();
                if (!navigatingToTheSameDynamicPage || navigatingToTheSameDynamicPage && innerRouteChanged) { // eslint-disable-line no-mixed-operators
                    siteAPI.getSiteAspect('WidgetAspect').initApps([rootNavigationInfo.pageId]);
                }
            }
        }
    });
});
