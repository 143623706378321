define([
    'lodash',
    'zepto',
    'coreUtils',
    'core/bi/events'
], function (_,
             $,
             coreUtils,
             events) {
    'use strict';

    const logger = coreUtils.loggingUtils.logger;

    const MINIMUM_IMAGE_DOWNLOAD_DURATION = 25;
    const MAX_IMAGE_DOWNLOAD_KBPS = 9216;
    const MIN_IMAGE_SIZE = 5;

    const KEY_COUNT_LIMIT = 2;
    const MOUSE_COUNT_LIMIT = 44;
    const SCROLL_COUNT_LIMIT = 11;

    if (typeof window === 'undefined') {
        return {
            init: _.noop,
            send: _.noop
        };
    }
    const IS_PREVIEW = window.queryUtil && window.queryUtil.isParameterTrue('isEdited');
    const IMAGES_PER_BUCKET = 3;
    const LARGEST_IMAGES_AMOUNT = 10;

    // Retain initial timing values so that they won't be overwritten by additional browser activity
    const performance = window.performance || {}; // eslint-disable-line santa/no-module-state

    function reportLoadImageData(siteData) {
        const sizes = [100, 200, 300, 400, 500, 1000, 2000, 3000]; //should be sorted

        function toBucketName(size) {
            return `_${size}k`;
        }

        const lastBucketName = `_over${_.last(sizes)}k`;

        function createSizeBuckets(fillFunction) {
            const bucketNames = sizes
                .map(toBucketName)
                .concat([lastBucketName]);
            return _.zipObject(bucketNames, _.times(bucketNames.length, fillFunction));
        }

        function buildImageTiming(image, entry) {
            const result = {
                url: image.src,
                hadError: false,
                width: image.width,
                height: image.height,
                size: image.width * image.height
            };

            const start = entry ? entry.startTime : 0;
            const duration = entry ? entry.duration : 0;
            _.defaults(result, {
                hadError: !entry,
                start: Math.round(start),
                end: Math.round(start + duration),
                speed: Math.round(result.size / (duration + 0.01) * 100) / 100 // two digits after decimal
            });

            return result;
        }

        function getSizeKey(imageData) {
            const sizeIndex = _.findLastIndex(sizes, function (size) {
                return size * 1000 < imageData.size;
            }) + 1;
            return sizeIndex < sizes.length ? toBucketName(sizes[sizeIndex]) : lastBucketName;
        }

        const imagePerf = _(window.document.getElementsByTagName('img'))
            .filter(function (img) {
                return _.startsWith(img.src, window.serviceTopology.staticMediaUrl);
            })
            .reduce(function (perf, img) {
                if (!img.complete) {
                    perf.loading++;
                } else {
                    const entry = performance.getEntriesByName(img.src)[0];
                    const imageTiming = buildImageTiming(img, entry);
                    if (imageTiming.hadError) {
                        perf.errors++;
                    } else {
                        const sizeKey = getSizeKey(imageTiming);
                        perf.imagesBySize[sizeKey]++;
                        if (perf.imagesSamples[sizeKey].length < IMAGES_PER_BUCKET) {
                            perf.imagesSamples[sizeKey].push(imageTiming);
                        }
                    }
                }
                return perf;
            }, {
                loading: 0,
                imagesBySize: createSizeBuckets(_.constant(0)),
                imagesSamples: createSizeBuckets(function () {
                    return [];
                }),
                errors: 0
            });

        logger.reportBI(siteData,
            events.LOAD_IMAGES_DATA,
            {imagePerf: JSON.stringify(imagePerf)});
    }

    function reportLargestImages(siteData) {
        const staticMediaUrl = window.serviceTopology ? window.serviceTopology.staticMediaUrl : 'nothing';

        function getImagePerformanceEntries() {
            return _(performance.getEntriesByType('resource'))
                .toArray()
                .filter(function (entry) {
                    return entry.duration > MINIMUM_IMAGE_DOWNLOAD_DURATION && // not cached
                        _.startsWith(entry.name, staticMediaUrl);
                })
                .value();
        }

        function getImageProperties(entry) {
            const url = entry.name.replace(staticMediaUrl, '');
            const matches = url.match(/\.([^/]+).+w_(\d+),h_(\d+),/);
            if (matches) {
                const width = Number(matches[2]);
                const height = Number(matches[3]);
                return {
                    url,
                    format: matches[1],
                    width,
                    height,
                    size: width * height
                };
            }
            return {};
        }

        function getImageData(entry) {
            const imgProps = getImageProperties(entry);
            const timingProps = {
                start: Math.round(entry.startTime),
                end: Math.round(entry.startTime + entry.duration),
                ttfb: Math.round(entry.responseStart - entry.requestStart),
                dns: Math.round(entry.domainLookupEnd - entry.domainLookupStart),
                ssl: entry.secureConnectionStart ? Math.round(entry.connectEnd - entry.secureConnectionStart) : 0,
                speed: entry.duration > 0 ? Math.round(imgProps.size / entry.duration) : 0,
                fileSize: entry.fileSize,
                kbps: entry.duration > 0 ? Math.round(1000 * entry.fileSize / entry.duration) : 0
            };
            return _.assign({}, imgProps, timingProps);
        }

        function getImageSizePromise(entry) {
            return new Promise(function (resolve) {
                function report(fileSize) {
                    resolve(_.assign(entry, {fileSize: Math.round(fileSize / 1024)}));
                }

                const size = entry.transferSize || entry.encodedBodySize;
                if (size) {
                    report(size);
                } else {
                    coreUtils.ajaxLibrary.ajax({
                        type: 'HEAD',
                        url: entry.name,
                        success(data, status, xhr) {
                            const contentLength = xhr.getResponseHeader('Content-Length') || 0;
                            report(contentLength);
                        },
                        error() {
                            report(0);
                        }
                    });
                }
            });
        }

        const promises = getImagePerformanceEntries().map(getImageSizePromise);
        Promise.all(promises)
            .then(function (entries) {
                const imagePerf = _(entries)
                    .filter(function (entry) {
                        return entry.fileSize > MIN_IMAGE_SIZE;
                    })
                    .sortBy('fileSize')
                    .takeRight(LARGEST_IMAGES_AMOUNT)
                    .map(getImageData)
                    .filter(function (entry) {
                        return entry.kbps <= MAX_IMAGE_DOWNLOAD_KBPS;
                    })
                    .value();
                if (imagePerf.length) {
                    const total = _.reduce(imagePerf, function (r, ip) {
                        return {
                            size: r.size + ip.fileSize,
                            duration: r.duration + ip.end - ip.start,
                            ttfb: r.ttfb + ip.ttfb
                        };
                    }, {
                        size: 0,
                        duration: 0,
                        ttfb: 0
                    });
                    logger.reportBI(siteData, events.IMAGES_DOWNLOAD, {
                        imagePerf: JSON.stringify(imagePerf),
                        kbps: Math.round(1000 * total.size / total.duration),
                        ttfb: Math.round(total.ttfb / imagePerf.length)
                    });
                }
            }, _.noop);
    }

    function reportFontsLoaded(performanceApi, siteData) {
        if (!performanceApi.getEntriesByType) {
            return;
        }

        const downloadedFonts = _(performanceApi.getEntriesByType('resource'))
            .filter({initiatorType: 'css'})
            .map(({name}) => /\/([^/.]*)\.(woff2|woff|ttf|svg|eot)(?:\??#[\w\-\d]*)?$/.exec(name))
            .compact()
            .map(res => ({name: res[1], format: res[2]}))
            .value();

        const userFonts = downloadedFonts.filter(({name}) => name === 'file').length;
        const wixFonts = downloadedFonts.length - userFonts;
        logger.reportBI(siteData, events.FONTS_DOWNLOADED, {wixFonts, userFonts});
    }

    function reportUserInteraction(siteData) {
        if (!$) {
            return;
        }

        let report = function (type) {
            $(window).off('.bi');
            report = _.noop;
            logger.reportBI(siteData, events.USER_INTERACTION, {
                type
            });
        };

        let keyCount = 0;

        function reportKey() {
            if (++keyCount > KEY_COUNT_LIMIT) {
                report('key');
            }
        }

        let mouseCount = 0;

        function reportMouse() {
            if (++mouseCount > MOUSE_COUNT_LIMIT) {
                report('mouse');
            }
        }

        let scrollCount = 0;

        function reportScroll() {
            if (++scrollCount > SCROLL_COUNT_LIMIT) {
                report('scroll');
            }
        }

        $(window).on({
            'keydown.bi': reportKey,
            'mousemove.bi': reportMouse,
            'scroll.bi': reportScroll
        });
    }

    // When changing return structure, remember to fix server-side return at top

    /**
     * @exports core/core/siteBI
     */
    return {
        init(siteData) {
            if (IS_PREVIEW) {
                return;
            }

            if (logger.shouldSendReport(siteData, events.USER_INTERACTION)) {
                // Delay report to avoid interfering with Beat message
                setTimeout(function () {
                    reportUserInteraction(siteData);
                }, 300);
            }

            if (!logger.shouldSendReport(siteData)) {
                return;
            }

            if (performance.getEntriesByName) {
                setTimeout(function () {
                    reportLoadImageData(siteData);
                    reportLargestImages(siteData);
                    reportFontsLoaded(performance, siteData);
                }, 10500);
            }
        },

        reportFontsLoaded
    };
});
