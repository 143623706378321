define(['coreUtils'], function (coreUtils) {
    'use strict';
    // If adding or registering a new data item type, there must be a matching mapping in requireCompCode in TYPE_MAP
    let registry = {
        'Image'(imageData, propertyQuery, siteData) {
            const mediaZoom = {
                id: 'imageZoomComp',
                key: 'imageZoomComp',
                dataQuery: `#${imageData.id}`,
                skin: 'wysiwyg.skins.ImageZoomSkin',
                componentType: 'wysiwyg.components.imageZoom'
            };

            if (propertyQuery) {
                mediaZoom.propertyQuery = propertyQuery;
            }

            if (siteData.isMobileView()) {
                mediaZoom.skin = 'wysiwyg.viewer.skins.TouchMediaZoom';
                mediaZoom.componentType = 'wysiwyg.viewer.components.TouchMediaZoom';
            } else if (siteData.isMobileDevice() || siteData.isTabletDevice()) {
                mediaZoom.skin = 'wysiwyg.skins.NonOptimizedImageZoomSkin';
            }

            return mediaZoom;
        },
        'PermaLink'(permaLinkData, propertyQuery, siteData) {
            const rootNavigationInfo = siteData.getRootNavigationInfo();
            const itemId = rootNavigationInfo.pageAdditionalData.split('/')[0];
            const mediaZoom = {
                id: `appPartZoomComp_${itemId}`,
                key: 'appPartZoomComp',
                dataQuery: `#${permaLinkData.id}`,
                skin: 'wysiwyg.skins.AppPartZoomSkin',
                componentType: 'wixapps.integration.components.AppPartZoom'
            };

            if (propertyQuery) {
                mediaZoom.propertyQuery = propertyQuery;
            }

            if (siteData.isMobileView()) {
                mediaZoom.skin = 'wysiwyg.skins.MobileAppPartZoomSkin';
            }

            return mediaZoom;
        }

    };

    /**
     * @class core.pageItemRegistry
     */
    return {
        /**
         *
         * @param {string} dataItemType
         * @param {function(string): {object}} compStructureGetter gets an id and return the component structure
         */
        registerPageItem(dataItemType, compStructureGetter) {
            if (registry[dataItemType]) {
                coreUtils.log.error(`page item already registered ${dataItemType}`);
                return;
            }
            registry[dataItemType] = compStructureGetter;
        },

        /**
         *
         * @param {string} dataItemType
         * @return {object|null} return the structure of the component if registered
         * @param dataItem
         * @param propertyQuery
         * @param siteData
         */
        getCompStructure(dataItemType, dataItem, propertyQuery, siteData) {
            if (registry[dataItemType]) {
                return registry[dataItemType](dataItem, propertyQuery, siteData);
            }
            return null;
        },

        clear() {
            registry = {};
        }
    };
});
