define(['react',
    'lodash',
    'zepto',
    'warmupUtils',
    'coreUtils',
    'utils',
    'core/siteRender/WixSiteReact',
    'core/siteRender/WixSiteHeadRenderer',
    'core/core/siteBI',
    'core/bi/events',
    'core/core/SiteDataAPI',
    'experiment'],
function (React,
          _,
          $,
          warmupUtils,
          coreUtils,
          /** utils */ utils,
          wixSiteReactClass,
          wixSiteHeadRenderer,
          siteBI,
          events,
          SiteDataAPI,
          experiment) {
    'use strict';
    const urlUtils = coreUtils.urlUtils;
    const wixSiteReact = React.createFactory(wixSiteReactClass);
    const hookTypes = {
        PAGE_LOADED_FIRST_RENDER: 'page_loaded_first_render',
        PAGE_LOADED: 'page_loaded'
    };
    const registeredHooks = {};

    const INTERNAL_ERROR_PAGE_INFO = {
        pageId: coreUtils.errorPages.IDS.INTERNAL_ERROR
    };

    let relativeRedirectCounter = 0;

    coreUtils.sessionFreezer.freeze(registeredHooks);

    function getPagesList(siteModel, pagesData) {
        if (siteModel.loadDocumentServices) {
            // TODO must read from siteModel and not siteData due to autosave. This shouldn't be needed
            return _(pagesData).keys().pull('masterPage').value();
        }

        const pagesList = siteModel.rendererModel.pageList;
        return _.map(pagesList.pages, 'pageId');
    }

    function fixPages(siteModel, isServerSideRender, pagesData) {
        const pageIds = getPagesList(siteModel, pagesData);
        return _.mapValues(pagesData, function (data, pageId) {
            if (isServerSideRender && window.pagesData && window.pagesData[pageId]) {
                // don't fix pages that were rendered in the server and already fixed there
                return data;
            }
            return utils.dataFixer.fix(data, pageIds.slice(), siteModel.requestModel, siteModel.currentUrl, siteModel.urlFormatModel, siteModel.viewMode === 'site', siteModel.rendererModel);
        });
    }

    function createSitePrivates(ajaxHandler, siteModel, props) {
        let renderOptions = {};
        if (coreUtils.urlUtils.isQueryParamOn(siteModel.currentUrl, 'isSantaEditor')) {
            renderOptions = _.assign(renderOptions, {
                componentViewMode: 'editor'
            });
        }

        if (siteModel.rendererModel.previewMode) {
            renderOptions = _.assign(renderOptions, {
                isSocialInteractionAllowed: false,
                isPlayingAllowed: false
            });
        }

        siteModel.renderFlags = _.assign({}, siteModel.renderFlags, renderOptions);
        const fullSiteData = new utils.FullSiteData(siteModel);
        fullSiteData.updateScreenSize();
        const siteDataWrapper = SiteDataAPI.createSiteDataAPIAndDal(fullSiteData, ajaxHandler, props);
        const displayedSiteData = siteDataWrapper.siteData;
        const siteDataAPI = siteDataWrapper.siteDataAPI;
        const viewerPrivateServices = {
            pointers: siteDataWrapper.pointers,
            displayedDAL: siteDataWrapper.displayedDal,
            siteDataAPI
        };
        const isServerSideRender = displayedSiteData.isClientAfterSSR();

        return {
            fullSiteData,
            displayedSiteData,
            siteDataWrapper,
            viewerPrivateServices,
            isServerSideRender,
            siteModel,
            siteDataAPI
        };
    }

    function extendPrivates(privates) {
        const fullPagesData = privates.siteDataWrapper.fullPagesData.pagesData;
        if (fullPagesData) {
            fixPages(privates.siteModel, privates.isServerSideRender, fullPagesData);
            if (fullPagesData.masterPage) {
                privates.siteDataAPI.createDisplayedPage('masterPage');
                const pageInfo = privates.siteModel.landingPageNavigationInfo ||
                        coreUtils.wixUrlParser.parseUrl(privates.displayedSiteData, privates.displayedSiteData.currentUrl.full);
                privates.displayedSiteData.setRootNavigationInfo(pageInfo);
                _.forEach(fullPagesData, (page, pageId) => {
                    privates.siteDataAPI.createDisplayedPage(pageId);
                });
            }
        }
        return privates;
    }

    function createSitePrivatesEx() {
        return extendPrivates(createSitePrivates.apply(this, arguments));
    }

    function updateHistory(url, title, addToHistory) {
        if (typeof window !== 'undefined' && _.get(window, 'history.pushState') && addToHistory) {
            try {
                window.history.pushState(null, title, url);
            } catch (e) {
                /**
                     * Can throw an exception when the new URL is not the site origin:
                     * In certain cases the site is viewed through a different domain than wix, or users's premium domain (e.g. via Yandex Webvisor)
                     */
            }
        }
    }

    function handleRelativeUrlRedirect(siteData, relativeUrl, callback) {
        //router returned an internal redirect path
        const newUrl = urlUtils.joinURL(siteData.getExternalBaseUrl(), relativeUrl);
        const pageInfo = coreUtils.wixUrlParser.parseUrl(siteData, newUrl);

        if (pageInfo.routerDefinition) {
            getDynamicPageRealPage(siteData, pageInfo, function (navPageInfo) {
                callback(_.assign(pageInfo, navPageInfo));
            });
        } else {
            pageInfo.isStaticPageRedirect = true;
            callback(pageInfo);
        }
    }

    function handleDynamicRedirectResponse(siteData, routerResponse, routerDefinition, callback) {
        const isPreviewMode = !!siteData.documentServicesModel;
        if (isPreviewMode) {
            //When in editor mode, it is not allowed to do redirect, just handle as regular dynamic page
            //on the routerResponse for redirect we expect to have a page id with the current primary page id
            handleDynamicPageResponse(siteData, routerResponse, routerDefinition, callback);
            return;
        }

        const {redirectUrl} = routerResponse;
        if (urlUtils.isExternalUrl(redirectUrl)) {
            window.location = redirectUrl;
        } else if (urlUtils.isRelativeUrl(redirectUrl) && relativeRedirectCounter < 4) {
            relativeRedirectCounter++;
            handleRelativeUrlRedirect(siteData, redirectUrl, callback);
        } else {
            //unexpected redirectUrl, display error page
            callback(INTERNAL_ERROR_PAGE_INFO);
        }
    }

    function handleDynamicPageResponse(siteData, routerResponse, routerDefinition, callback) {
        siteData.addDynamicPageData(routerResponse.pageId, routerResponse.pageData, routerDefinition);
        siteData.addDynamicPageHeadData(routerResponse.pageId, routerResponse.pageHeadData);

        callback({
            pageId: routerResponse.pageId,
            title: _.get(routerResponse.pageHeadData, 'title', ''),
            errorInfo: routerResponse.errorInfo,
            tpaInnerRoute: routerResponse.tpaInnerRoute,
            routerPublicData: routerResponse.publicData
        });
    }

    function getDynamicPageRealPage(siteData, navInfo, callback) {
        const routerDefinition = navInfo.routerDefinition;
        const routerBackEndRequestParamObject = utils.routersBackEndRequests.makeParamObjFromSiteData(siteData, routerDefinition, navInfo);
        utils.routersBackEndRequests.getPage(routerBackEndRequestParamObject, function (routerResponse) { // eslint-disable-line complexity
            if (siteData.isInSSR()) {
                const {redirectUrl, sendStatus, status, message} = routerResponse;
                const absoluteUrl = urlUtils.isRelativeUrl(redirectUrl) ?
                    urlUtils.joinURL(siteData.getExternalBaseUrl(), redirectUrl) :
                    redirectUrl;
                if (sendStatus || redirectUrl) {
                    // TODO WEED-12766 remove once we go live with SEO via SSR
                    if (siteData.wixCodeSeo) {
                        return callback(INTERNAL_ERROR_PAGE_INFO);
                    }

                    return callback({redirectUrl: absoluteUrl, status, message});
                }
            }

            if (routerResponse.redirectUrl) {
                handleDynamicRedirectResponse(siteData, routerResponse, routerDefinition, callback);
            } else {
                handleDynamicPageResponse(siteData, routerResponse, routerDefinition, callback);
            }
        }, function (data, errorName, error) {
            const isPreview = !!siteData.documentServicesModel;
            if (!isPreview) {
                if (siteData.isInSSR()) {
                    siteData.onPageRequestFailed(_.get(routerBackEndRequestParamObject, 'publicBaseUrl'), 500, error);
                } else {
                    handleDynamicPageResponse(siteData, {pageId: coreUtils.errorPages.IDS.INTERNAL_ERROR}, routerDefinition, callback);
                }
            }
        }, siteData);
    }

    function replaceHistory(url, title, addToHistory) {
        if (typeof window !== 'undefined' && _.get(window, 'history.replaceState') && addToHistory) {
            try {
                window.history.replaceState(null, title, url);
            } catch (e) {
                /**
                     * Can throw an exception when the new URL is not the site origin:
                     * In certain cases the site is viewed through a different domain than wix, or users's premium domain (e.g. via Yandex Webvisor)
                     */
            }
        }
    }

    function registerHook(hookName, hookFunc) {
        registeredHooks[hookName] = registeredHooks[hookName] || [];
        registeredHooks[hookName].push(hookFunc);
    }

    function executeHooks(hookName, args) {
        _.forEach(registeredHooks[hookName], function (hook) {
            hook.apply(null, args);
        });
    }

    function isNavigatingFromPopupToPrimaryPage(siteData, rootNavigationInfo) {
        const primaryPageId = siteData.getPrimaryPageId();
        const navigatingToPrimaryPage = primaryPageId === rootNavigationInfo.pageId;
        return siteData.isPopupOpened() && navigatingToPrimaryPage;
    }

    function shouldUpdateSEOMetaTags(siteData, rootNavigationInfo) {
        const navigatingToPopup = siteData.isPopupPage(rootNavigationInfo.pageId);
        const navigatingFromPopupToPrimaryPage = isNavigatingFromPopupToPrimaryPage(siteData, rootNavigationInfo);
        return !navigatingToPopup && !navigatingFromPopupToPrimaryPage;
    }

    function shouldReportPageEvent(siteData, currentFullUrl, targetURL, rootNavigationInfo) {
        const loadingPageIsPopup = siteData.isPopupPage(rootNavigationInfo.pageId);
        const usingSlashUrlFormat = siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH);
        const navigatingFromPopupToPrimaryPage = isNavigatingFromPopupToPrimaryPage(siteData, rootNavigationInfo);

        return !loadingPageIsPopup && targetURL !== currentFullUrl && usingSlashUrlFormat && !navigatingFromPopupToPrimaryPage;
    }

    function reportPerformance(siteData) {
        if (!(siteData.isInSSR() || window.isMockWindow) && window.performance && performance.getEntriesByType) {
            try {
                const reName = /(skins|require)\.min\.js$/;
                const params = _(performance.getEntriesByType('resource'))
                    .toArray()
                    .filter(function (resource) {
                        return reName.test(resource.name);
                    })
                    .map(function (resource) {
                        const match = resource.name.match(reName);
                        const responseTime = resource.responseEnd - (resource.responseStart || resource.startTime);
                        const timing = {
                            Name: match[1],
                            DnsTime: Math.round(resource.domainLookupEnd - resource.domainLookupStart),
                            ConnectTime: Math.round(resource.connectEnd - resource.connectStart),
                            Ttfb: Math.round(resource.duration - responseTime),
                            ResponseTime: Math.round(responseTime)
                        };
                        const size = resource.transferSize || resource.encodedBodySize;
                        if (size && resource.duration) {
                            timing.Kbps = Math.round(size / resource.duration);
                        }
                        return timing;
                    })
                    .map(function (timings) {
                        const size = _.includes(timings.Name, 'require') ? 'small' : 'large';
                        return _.mapKeys(timings, function (value, key) {
                            return size + key;
                        });
                    })
                    .reduce(function (results, item) {
                        return _.assign(results, item);
                    }, {});
                coreUtils.loggingUtils.logger.reportBI(siteData, events.CDN_PERFORMANCE, params);
            } catch (e) {
                // If failed just don't send BI
            }
        }
    }


    function shouldLoadPage(rootNavigationInfo, siteData) {
        const isNavigatingToImageZoom = rootNavigationInfo.pageId === siteData.getFocusedRootId() && rootNavigationInfo.imageZoom;

        const isPageAlreadyLoaded = !!siteData.hasPage(rootNavigationInfo.pageId);
        const isReturningToPrimaryPage = rootNavigationInfo.pageId === siteData.getPrimaryPageId();
        const navigatingWithNoAdditionalData = rootNavigationInfo.pageAdditionalData === siteData.getExistingRootNavigationInfo().pageAdditionalData;
        const {isLanguageChange} = rootNavigationInfo;

        return isLanguageChange || !(isNavigatingToImageZoom || isPageAlreadyLoaded && isReturningToPrimaryPage && navigatingWithNoAdditionalData); // eslint-disable-line no-mixed-operators
    }

    /**
         *
         * @param site
         * @param siteDataAPI
         * @param url
         * @param {site.rootNavigationInfo} rootNavigationInfo the additional data that can be added on the link element
         * @param {boolean} addToHistory
         * @returns {boolean} true if the navigation succeeded
         * @param leaveOtherRootsAsIs
         */
    function navigateTo(site, siteDataAPI, url, rootNavigationInfo, addToHistory, leaveOtherRootsAsIs, shouldReplaceHistory) { // eslint-disable-line complexity
        coreUtils.loggingUtils.fedopsLogger.interactionStarted(coreUtils.loggingUtils.fedopsLogger.INTERACTIONS.NAVIGATE_TO_PAGE);
        const siteData = site.props.siteData;
        const siteAPI = site.siteAPI;
        const widgetAspect = siteAPI.getSiteAspect('WidgetAspect');

        if (siteData.getCurrentUrlPageId() !== rootNavigationInfo.pageId) {
            siteAPI.reportBeatStart(rootNavigationInfo.pageId); //this is a report for users, so only pages with urls
        }

        //we have anchors only on primary pages for now
        if (siteData.getPrimaryPageId() !== rootNavigationInfo.pageId && rootNavigationInfo.anchorData || rootNavigationInfo.anchorData && siteData.getCurrentUrlPageId() === rootNavigationInfo.pageId && rootNavigationInfo.routerDefinition) { // eslint-disable-line no-mixed-operators
            const actions = siteAPI.getSiteAspect('actionsAspect');
            actions.registerNextAnchorScroll(rootNavigationInfo.anchorData);
        }

        let firstPass = true;
        const currentFullUrl = siteData.currentUrl.full;
        const pageLoadedCallback = function () {
            executeHooks(hookTypes.PAGE_LOADED, [siteAPI, rootNavigationInfo]);

            if (firstPass) {
                firstPass = false;
                if (url.indexOf('#') === 0) {
                    siteData.currentUrl.hash = url;
                } else {
                    siteData.currentUrl = urlUtils.parseUrl(url);
                }

                if (shouldReportPageEvent(siteData, currentFullUrl, url, rootNavigationInfo)) {
                    siteAPI.reportCurrentPageEvent(url, rootNavigationInfo.pageId);
                }

                const shouldUpdateSEO = shouldUpdateSEOMetaTags(siteData, rootNavigationInfo);

                siteData.setRootNavigationInfo(rootNavigationInfo, !leaveOtherRootsAsIs);
                widgetAspect.pageNavigationInfoChanged(rootNavigationInfo.pageId);

                if (siteData.isViewerMode()) {
                    if (shouldReplaceHistory) {
                        replaceHistory(url, rootNavigationInfo.title, shouldReplaceHistory);
                    } else {
                        updateHistory(url, rootNavigationInfo.title, addToHistory);
                    }
                }

                if (shouldUpdateSEO) {
                    updatePageTitleAndHeadTags(siteData, rootNavigationInfo.pageId);
                }

                //if (rootNavigationInfo.anchorData) {
                //    site.siteAPI.getSiteAspect('anchorChangeEvent').changeAnchorToClicked(siteData, rootNavigationInfo.pageId, rootNavigationInfo.anchorData);
                //}
            } else {
                // site.forceUpdate();
            }

            coreUtils.loggingUtils.fedopsLogger.interactionEnded(coreUtils.loggingUtils.fedopsLogger.INTERACTIONS.NAVIGATE_TO_PAGE);
        };

        widgetAspect.setContextIdToIgnore(rootNavigationInfo.pageId);

        if (!shouldLoadPage(rootNavigationInfo, siteData)) {
            siteAPI.getActionQueue().runImmediately(pageLoadedCallback);
            return true;
        }
        siteDataAPI.loadPage(rootNavigationInfo, function () {
            siteAPI.getActionQueue().runImmediately(pageLoadedCallback);
        });
        return true;
    }

    function renderSiteWithData(siteData, viewerPrivateServices, props) {
        let wixSiteClasses = 'wixSiteProperties';

        if (experiment.isOpen('mobileNonOptimizedOverflow', siteData)) {
            wixSiteClasses += ' mobile-non-optimized-overflow';
        }

        return wixSiteReact(_.assign({}, props, {
            className: siteData.rendererModel.siteInfo.documentType === 'WixSite' ? wixSiteClasses : 'noop',
            siteData,
            viewerPrivateServices,
            rootId: 'masterPage',
            navigateMethod: navigateTo,
            updateUrlIfNeededMethod: updateUrlIfNeeded,
            getDynamicPageRealPage,
            updateHeadMethod,
            getSiteContainer
        }));
    }

    function getSiteContainer() {
        return window;
    }

    const RENDER_TAGS = {
        'ld+json': _updateJsonld
    };

    const updateHeadMethod = (siteData, pageId, tagTypes) => {
        if (tagTypes) {
            _.forEach(tagTypes, type => {
                RENDER_TAGS[type](siteData, pageId, _getDocumentHead(siteData));
            });
        } else {
            updatePageHeadTags(siteData, pageId);
        }
    };

    function updatePageHeadTags(siteData, pageId) {
        updatePageTitleAndHeadTags(siteData, pageId);

        // Adding GTM to Wix sites
        if (siteData.rendererModel.siteInfo.documentType === 'WixSite') {
            const head = _getDocumentHead(siteData);
            _append(head, {name: 'googleTagManagerScript', markup: wixSiteHeadRenderer.getGoogleTagManagerScript()}, false);
        }
    }

    function updatePageTitleAndHeadTags(siteData, pageId) {
        if (siteData.isInSeo() && !siteData.isInSSR()) {
            return;
        }

        _removeExistingMetaTags(siteData); // TODO WEED-12084 do not remove identical head tags rendered on the server

        const head = _getDocumentHead(siteData);
        _updateCurrentPageTitle(siteData, head);
        _addPageSEOMetaTags(siteData, pageId, head);
        _updateJsonld(siteData, pageId, head);
        _updateAMPLinkTag(siteData, pageId, head);
        _.forEach(warmupUtils.constants.TPA_LINK_TAGS.SUPPORTED_RELS, linkRel => {
            _updateLinkTag(siteData, pageId, head, linkRel);
        });
    }

    function _updateJsonld(siteData, pageId, head) {
        _remove(head, 'script[type="application/ld+json"][data-pageid]');
        const jsonldTag = wixSiteHeadRenderer.getPageJsonldTag(siteData, pageId);

        if (jsonldTag) {
            _append(head, {name: 'jsonld', markup: jsonldTag});
        }
    }

    function _addPageSEOMetaTags(siteData, pageId, head) {
        if (siteData.isInSSR()) {
            _.assign(head, wixSiteHeadRenderer.getPageMetaData(siteData, pageId));
            return;
        }

        const tags = wixSiteHeadRenderer.getPageMetaTags(siteData, pageId);
        _.forEach(tags, function (tag) {
            _append(head, {markup: tag});
        });
    }

    function _updateAMPLinkTag(siteData, pageId, head) {
        if (_.get(siteData, 'publicModel.hasBlogAmp')) {
            const linkTag = wixSiteHeadRenderer.getPageLinkTag(siteData, pageId);

            if (linkTag) {
                _append(head, {name: 'amphtml', markup: linkTag});
            }
        }
    }

    function _updateLinkTag(siteData, pageId, head, linkRel) {
        const linkTag = wixSiteHeadRenderer.getTpaLinkTag(siteData, pageId, linkRel);

        if (linkTag !== '') {
            const existingLinks = $(`link[rel$="${linkRel}"]`, head).length;
            if (!existingLinks) {
                _append(head, {name: linkRel, markup: linkTag}, false);
            }
        }
    }

    function _removeExistingMetaTags(siteData) {
        if (siteData.isInSSR()) {
            siteData.ssr.headTags = {};
            siteData.resetCurrDynamicPageMetaTags();
            return;
        }

        let selectorsOfTagsToRemove = [
            '[name=description]',
            '[name=keywords]',
            '[property="og:title"]',
            '[property="og:description"]',
            '[property="og:url"]',
            '[property="og:image"]',
            '[rel="amphtml"]'
        ];

        _.forEach(warmupUtils.constants.TPA_LINK_TAGS.SUPPORTED_RELS, linkRel => {
            selectorsOfTagsToRemove.push(`[data-id="${warmupUtils.constants.TPA_LINK_TAGS.DATA_ID}"][rel="${linkRel}"]`);
        });

        if (_.get(siteData, 'publicModel.indexable')) {
            selectorsOfTagsToRemove.push('[name=robots]');
        }

        const userDefinedTagsToRemove = siteData.getCurrDynamicPageMetaTags();

        _.forEach(userDefinedTagsToRemove, function (content, name) {
            if (_.startsWith(name, 'og:')) {
                selectorsOfTagsToRemove.push(`[property="${name}"]`);
            } else {
                selectorsOfTagsToRemove.push(`[name="${name}"]`);
            }
        });
        siteData.resetCurrDynamicPageMetaTags();

        if (experiment.isOpen('sv_twitterMetaTags', siteData)) {
            selectorsOfTagsToRemove = selectorsOfTagsToRemove.concat([
                '[name="twitter:card"]',
                '[name="twitter:title"]',
                '[name="twitter:description"]',
                '[name="twitter:image"]'
            ]);
        }

        selectorsOfTagsToRemove.forEach(function (selector) {
            $(selector, window.document.head).remove();
        });
    }

    function _getDocumentHead(siteData) {
        if (!(siteData.isInSSR() || window.isMockWindow)) {
            return window.document.head;
        }

        siteData.ssr.headTags = siteData.ssr.headTags || {};
        return siteData.ssr.headTags;
    }

    function _append(head, tag, filterHtmlString = true) {
        const tagMarkup = filterHtmlString ?
            utils.xss.filterHtmlString(tag.markup, {allowHeadTags: true, allowOneSelfClosingMetaTag: true}) :
            tag.markup;

        if (head.append) {
            $(head).append(tagMarkup);
        } else {
            head[tag.name] = tagMarkup;
        }
    }

    function _remove(head, selector) {
        if (head.remove) {
            $(selector, head).remove();
        }
    }

    function _updateCurrentPageTitle(siteData) {
        if (!(siteData.isInSSR() || window.isMockWindow)) {
            window.document.title = siteData.getCurrentUrlPageTitle();
        }
    }

    function updateUrl(newUrl, pageTitle, siteData) {
        replaceHistory(newUrl, pageTitle, true);
        siteData.currentUrl = urlUtils.parseUrl(newUrl);
    }

    function updateUrlIfNeeded(siteData, pageInfo) {
        if (coreUtils.errorPages.isErrorPage(pageInfo.pageId)) {
            return;
        }

        const expectedUrl = coreUtils.wixUrlParser.getUrl(siteData, pageInfo);
        if (!pageInfo.routerDefinition && siteData.isViewerMode() && expectedUrl !== siteData.currentUrl.full) {
            updateUrl(expectedUrl, pageInfo.title, siteData);
        }
    }

    function addErrorPageMasterData(viewerPrivateServices, errorPageId) {
        const errorPageData = coreUtils.errorPages.getErrorPageMasterData(errorPageId);
        const masterPageDataPointer = viewerPrivateServices.pointers.page.getPageData('masterPage');
        const errorPageDataPointer = viewerPrivateServices.pointers.getInnerPointer(masterPageDataPointer, [errorPageId]);
        const themeDataPointer = viewerPrivateServices.pointers.general.getAllStylesInPage(errorPageId);
        viewerPrivateServices.displayedDAL.set(errorPageDataPointer, errorPageData.masterPageData);
        viewerPrivateServices.displayedDAL.merge(themeDataPointer, errorPageData.themeData);
    }

    function resolveRootNavigationInfo(siteData) {
        return new Promise(resolve => {
            if (siteData.landingPageNavigationInfo) {
                resolve(siteData.landingPageNavigationInfo);
                return;
            }

            const pageInfo = coreUtils.wixUrlParser.parseUrl(siteData, siteData.currentUrl.full);

            if (_.get(pageInfo, 'routerDefinition')) {
                relativeRedirectCounter = 0; //this is the first call for getDynamicPageRealPage, init the redirect counter to prevent loops
                getDynamicPageRealPage(siteData, pageInfo, resolve);
                return;
            }

            resolve(pageInfo);
        }).then(pageInfo => {
            siteData.setRootNavigationInfo(pageInfo);
            coreUtils.loggingUtils.logger.reportBeatEvent(siteData, 6, pageInfo.pageId);
            reportPerformance(siteData);
            siteBI.init(siteData);

            return pageInfo;
        });
    }

    function loadPagesData(siteData, viewerPrivateServices, wixCodeAppApi, pageInfo, loadComponentsData) {
        return new Promise(resolve => {
            const pagesLoadedCallback = function () {
                /*
                 * We need to parse url because now that the master page data has loaded, we can extract more info from url
                 */
                //todo if dynamicRouter we should know pageId by now, fix pageInfo so that this code may do exactly what it's doing for static pages
                if (!pageInfo.isStaticPageRedirect) {
                    _.assign(pageInfo, coreUtils.wixUrlParser.parseUrl(siteData, siteData.currentUrl.full));
                }

                if (coreUtils.errorPages.isErrorPage(pageInfo.pageId)) {
                    if (siteData.isInSSR()) {
                        addErrorPageMasterData(viewerPrivateServices, pageInfo.pageId);
                    } else {
                        coreUtils.errorPages.setIsFixingDisplayedMasterPage();
                        coreUtils.errorPages.updateErrorPageMasterData('masterPage', siteData.pagesData.masterPage);
                    }
                }

                siteData.setRootNavigationInfo(pageInfo);
                updateUrlIfNeeded(siteData, pageInfo);

                if (!siteData.isClientAfterSSR()) {
                    coreUtils.mobileViewportFixer.fixViewportTag(siteData);
                }

                executeHooks(hookTypes.PAGE_LOADED_FIRST_RENDER, [siteData, wixCodeAppApi, !!pageInfo.isStaticPageRedirect]);

                resolve(pageInfo);
            };

            viewerPrivateServices.siteDataAPI.loadPage(pageInfo, pagesLoadedCallback, loadComponentsData);
        });
    }

    function prepareSiteForRender(siteData, viewerPrivateServices, {wixCodeAppApi}) {
        return resolveRootNavigationInfo(siteData)
            .then(pageInfo => loadPagesData(siteData, viewerPrivateServices, wixCodeAppApi, pageInfo, true));
    }

    function doRenderSite(siteData, viewerPrivateServices, props, renderFunction) {
        function render() {
            coreUtils.loggingUtils.performance.finish(coreUtils.loggingUtils.performanceMetrics.RENDER_PREPARE, {category: coreUtils.loggingUtils.performance.VERBOSE});
            return renderFunction(renderSiteWithData(siteData, viewerPrivateServices, props));
        }

        if (!siteData.isInSSR() && siteData.isViewerMode() && props.wixCodeAppApi && props.wixCodeAppApi.isAppInitiated()) {
            setTimeout(render, 10);
        }

        return render();
    }

    function renderSite(siteData, viewerPrivateServices, props, renderFunction) {
        return prepareSiteForRender(siteData, viewerPrivateServices, props)
            .then(() => doRenderSite(siteData, viewerPrivateServices, props, renderFunction));
    }

    function isProtectedPage(siteData, pageId) {
        pageId = pageId || siteData.getPrimaryPageId();

        if (coreUtils.errorPages.isErrorPage(pageId)) {
            return false;
        }

        const pages = _.get(siteData, 'rendererModel.pageList.pages');
        const pageData = _.find(pages, {pageId});
        return pageData && !pageData.pageJsonFileName;
    }

    return {
        isProtectedPage,
        createSitePrivates,
        extendPrivates,
        createSitePrivatesEx,
        fixPages,

        resolveRootNavigationInfo,
        loadPagesData,
        prepareSiteForRender,
        doRenderSite,
        renderSite,
        WixSiteReact: wixSiteReactClass,
        hooks: {
            types: hookTypes,
            registerHook
        }
    };
});
