define(['lodash', 'prop-types', 'create-react-class', 'mobx-react', 'core/components/siteAspects/externalScriptLoaderAspect/getIframeReactComponent'], function (_, PropTypes, createReactClass, mobxReact, getIframeReactComponent) {
    'use strict';

    const ASPECT_NAME = 'ecomCheckout';

    return mobxReact.observer(createReactClass({
        displayName: 'ecomCheckoutAspectContrainer',
        propTypes: {
            siteAPI: PropTypes.shape({
                getAspectGlobalData: PropTypes.func.isRequired
            })
        },
        render() {
            const aspectGlobalData = this.props.siteAPI.getAspectGlobalData(ASPECT_NAME);
            const modalOpened = _.get(aspectGlobalData, ['modalOpened']);
            if (modalOpened) {
                const src = _.get(aspectGlobalData, ['src']);
                return getIframeReactComponent(src);
            }
            return null;
        }
    }));
});

