define(['lodash', 'zepto', 'componentsCore', 'core/core/storageAPI', 'core/siteRender/SiteAspectsSiteAPI', 'core/aspects/widgetAspectPropsFetcher', 'core/aspects/popoverAspectPropsFetcher', 'utils', 'coreUtils'], function (_, $, componentsCore, storageAPI, SiteAspectsSiteAPI, widgetAspectPropsFetcher, popoverAspectPropsFetcher, utils, coreUtils) {
    'use strict';

    const EMPTY_ARRAY = [];

    function invokeIfDefined(objects, methodsName, args) {
        return coreUtils.renderUtils.mapInAlphabeticalOrder(objects, function (object) {
            const method = object[methodsName];
            return (_.isFunction(method) && method.apply(object, args)) || EMPTY_ARRAY; // eslint-disable-line no-mixed-operators
        });
    }

    const siteEvents = {
        mount: 'mount',
        unmount: 'unmount',
        urlPageChange: 'urlPageChange',
        renderedRootsChanged: 'renderedRootsChanged',
        addedRenderedRootsDidLayout: 'addedRenderedRootsDidLayout',
        modeChange: 'modeChange',
        fakeModeChange: 'fakeModeChange',
        slideChange: 'slideChange',
        didLayout: 'didLayout',
        siteReady: 'siteReady',
        willMount: 'willMount',
        willUpdate: 'willUpdate',
        aspectsReady: 'aspectsReady',
        viewportChange: 'viewportChange',
        svSessionChange: 'svSessionChange',
        siteMetadataChange: 'siteMetadataChange',
        onRendered: 'onRendered',
        fullyRendered: 'fullyRendered',
        sssrSuccess: 'sssrSuccess'
    };
    const windowEvents = {
        scroll: 'scroll',
        resize: 'resize',
        focus: 'focus',
        blur: 'blur',
        message: 'message',
        keydown: 'keydown',
        keyup: 'keyup',
        touchstart: 'touchstart',
        touchend: 'touchend',
        touchmove: 'touchmove',
        touchcancel: 'touchcancel',
        orientationchange: 'orientationchange'
    };
    const documentEvents = {
        visibilitychange: 'visibilitychange',
        click: 'click'
    };

    const supportedEvents = _.assign(_.clone(siteEvents), windowEvents, documentEvents);
    Object.freeze(supportedEvents);

    function fireOrientationEvent() {
        this.notifyAspects('orientationchange');
        this.timeout = window.setTimeout(this.notifyAspects.bind(this, windowEvents.resize), 300);
    }

    function notifyRootsChangeIfNeeded() {
        const renderedRoots = this.siteAPI.getRootIdsWhichShouldBeRendered();
        if (!_.isEqual(renderedRoots, this._previouslyRenderedRoots)) {
            const removed = _.difference(this._previouslyRenderedRoots, renderedRoots);
            const added = _.difference(renderedRoots, this._previouslyRenderedRoots);
            this._rootsAddedAndNotYetLayouted = _(this._rootsAddedAndNotYetLayouted)
                .concat(added)
                .difference(removed)
                .uniq()
                .value();
            this._previouslyRenderedRoots = _.clone(renderedRoots);
            this.notifyAspects(siteEvents.renderedRootsChanged, added, removed);
        }
    }

    function handleNavigationInfoChange() {
        const navigationInfo = this.siteAPI.getSiteData().getRootNavigationInfo();
        const prevNavigationInfo = this._previousNavigationInfo;
        if (!_.isEqual(navigationInfo, prevNavigationInfo)) {
            const shouldNotifyDynamicPageChanged = didDynamicPageChange(navigationInfo, prevNavigationInfo);
            const shouldNotifyLanguageChanged = didLanguageChange(navigationInfo, prevNavigationInfo);
            if (shouldNotifyDynamicPageChanged || shouldNotifyLanguageChanged) {
                const actionsAspect = this.siteAPI.getSiteAspect('actionsAspect');
                actionsAspect.handlePagePageNavigationCanceled();
                this.notifyAspects(supportedEvents.urlPageChange);
            }

            this._previousNavigationInfo = navigationInfo;
        }
    }

    function didDynamicPageChange(navigationInfo, prevNavigationInfo) {
        return coreUtils.dynamicPagesUtils.isSamePageNavigation(navigationInfo, prevNavigationInfo) && coreUtils.dynamicPagesUtils.isInnerRouteChanged(navigationInfo, prevNavigationInfo);
    }

    function didLanguageChange(navigationInfo, prevNavigationInfo) {
        const currentLanguage = _.get(navigationInfo, 'query.lang');
        const previousLanguage = _.get(prevNavigationInfo, 'query.lang');
        return currentLanguage !== previousLanguage;
    }

    function addHostLibsAspects() {
        const aspectsToLoad = {
            WidgetAspect: {
                constructorArgs: [storageAPI.getStorage(), this.props.eventsManager, this._aspectsSiteAPI],
                mapStateToProps: widgetAspectPropsFetcher
            },
            popoverAspect: {
                constructorArgs: [storageAPI.getStorage()],
                mapStateToProps: popoverAspectPropsFetcher
            }
        };
        _.forEach(aspectsToLoad, (aspectData, aspectName) => {
            if (!this.siteAspects[aspectName] && componentsCore.siteAspectsRegistry.getHostLibsAspectConstructor(aspectName)) {
                const aspect = this._aspectsSiteAPI.initHostLibsAspect(aspectName, aspectData.mapStateToProps, aspectData.constructorArgs);
                this.siteAspects[aspectName] = aspect;
            }
        });
    }

    function addMissingAspects() {
        const afterSSR = this.siteAPI.getSiteData().isClientAfterSSR();
        const {siteAspects, _aspectsSiteAPI, props} = this;
        const {displayedDAL} = props.viewerPrivateServices;
        _(componentsCore.siteAspectsRegistry.getAllAspectConstructors())
            .pickBy((AspectConstructor, aspectName) => !siteAspects[aspectName])
            .forEach((AspectConstructor, aspectName) => {
                //TODO: pass here only the API not the whole site
                displayedDAL.setByPath(['siteAspectsData', aspectName], {
                    globalData: undefined,
                    dataByCompId: {}
                });
                const aspect = new AspectConstructor(_aspectsSiteAPI);
                siteAspects[aspectName] = aspect;
                if (afterSSR && aspect.applySsrChanges) {
                    aspect.applySsrChanges();
                }
            });

        addHostLibsAspects.call(this);
        this.notifyAspects(this.supportedEvents.aspectsReady);
    }

    const PRE_EVENT_FUNCTIONS = {
        didLayout() {
            if (!_.isEmpty(this._rootsAddedAndNotYetLayouted)) {
                const rootsAddedAndNotYetLayouted = this._rootsAddedAndNotYetLayouted;
                this._rootsAddedAndNotYetLayouted = [];
                this.notifyAspects(siteEvents.addedRenderedRootsDidLayout, rootsAddedAndNotYetLayouted);
            }
        }
    };

    /**
     * @class core.siteAspectsMixin
     */
    return {
        /**
         *
         */
        supportedEvents,

        /**
         *
         * @returns {*}
         */
        getAllSiteAspects() {
            addMissingAspects.call(this);
            return this.siteAspects;
        },

        getInitialState() {
            this._aspectsSiteAPI = new SiteAspectsSiteAPI(this);
            if (this.props.viewerPrivateServices.siteAspectsSiteAPI) {
                this.props.viewerPrivateServices.siteAspectsSiteAPI.setSite(this);
            }
            this.siteAspects = this.props.viewerPrivateServices.siteAspects || {};
            this._listenersOnWindow = [];
            this._listenersOnDocument = [];
            this._previouslyRenderedRoots = [];
            this._previousNavigationInfo = {};
            this._rootsAddedAndNotYetLayouted = [];
            return {};
        },

        componentWillMount() {
            addMissingAspects.call(this);
            const widgetAspect = this._aspectsSiteAPI.getSiteAspect('WidgetAspect'); // when moved to host libs it will come from props
            widgetAspect.syncAppsState();
        },

        componentWillUpdate() {
            addMissingAspects.call(this);
            const widgetAspect = this._aspectsSiteAPI.getSiteAspect('WidgetAspect'); // when moved to host libs it will come from props
            widgetAspect.syncAppsState();
        },

        getAspectsContainer() {
            return this.refs.siteAspectsContainer;
        },

        getAspectComponentDescriptions() {
            return _.flattenDeep(invokeIfDefined(this.siteAspects, 'getComponentsToRender', [this._aspectsSiteAPI]));
        },

        getAspectsComponentStructures() {
            const structures = _.flattenDeep(invokeIfDefined(this.siteAspects, 'getComponentStructures'));
            const structuresFromAspectCompsToRender = _.map(this.getAspectComponentDescriptions(), 'structure');
            return structures.concat(structuresFromAspectCompsToRender);
        },

        componentDidMount() {
            const $window = $(window);
            const $document = $(window.document);

            _.forEach(
                windowEvents,
                function (eventName) {
                    const eventListenerProxy = {eventName, listener: this.notifyAspects.bind(this, eventName)};
                    this._listenersOnWindow.push(eventListenerProxy);
                    $window.on(eventName, eventListenerProxy.listener);
                }.bind(this)
            );

            _.forEach(
                documentEvents,
                function (eventName) {
                    const eventListenerProxy = {eventName, listener: this.notifyAspects.bind(this, eventName)};
                    this._listenersOnDocument.push(eventListenerProxy);
                    $document.on(eventName, eventListenerProxy.listener);
                }.bind(this)
            );

            // Wire orientationchange event to simulate resize event
            const orientationChangeEventListener = {
                eventName: windowEvents.orientationchange,
                listener: fireOrientationEvent.bind(this)
            };
            this._listenersOnWindow.push(orientationChangeEventListener);
            $window.on(orientationChangeEventListener.eventName, orientationChangeEventListener.listener);
            this.notifyAspects('mount');
            notifyRootsChangeIfNeeded.call(this);

            handleNavigationInfoChange.call(this);
        },

        componentDidUpdate() {
            notifyRootsChangeIfNeeded.call(this);
            handleNavigationInfoChange.call(this);
        },

        componentWillUnmount() {
            const $window = $(window);
            const $document = $(window.document);

            _.forEach(this._listenersOnWindow, function (listenerObject) {
                $window.off(listenerObject.eventName, listenerObject.listener);
            });

            _.forEach(this._listenersOnDocument, function (listenerObject) {
                $document.off(listenerObject.eventName, listenerObject.listener);
            });
            window.clearTimeout(this.timeout);

            this._aspectsSiteAPI.onSiteUnmount();
            this.notifyAspects('unmount');
        },

        notifyAspects(eventName, ...eventArgs) {
            const preEventFunction = PRE_EVENT_FUNCTIONS[eventName];
            if (preEventFunction) {
                preEventFunction.apply(this, eventArgs);
            }

            this.props.eventsManager.emit(eventName, ...eventArgs);
        },

        /**
         *
         * @param eventName
         * @param callback
         */
        registerAspectToEvent(eventName, callback) {
            // eslint-disable-next-line no-extra-parens
            if ((eventName === siteEvents.siteReady && this.siteIsReady) || (eventName === siteEvents.fullyRendered && this.siteIsFullyRendered)) {
                callback();
                return;
            }

            if (!supportedEvents[eventName]) {
                utils.log.error(`this event isn't supported by site ${eventName}`);
                return;
            }

            this.props.eventsManager.on(eventName, callback);
        },

        unregisterAspectFromEvent(eventName, callback) {
            this.props.eventsManager.off(eventName, callback);
        }
    };
});
