/*eslint no-unused-vars:0*/
define([
    'lodash',
    'coreUtils',
    'core/core/data/PointersRuntimeCache',
    'core/core/data/RuntimeToDisplayed'
], function (_, coreUtils, PointersRuntimeCache, RuntimeToDisplayed) {
    'use strict';


    const clientRectProperties = ['height', 'width'];
    const DATA_TYPES = coreUtils.constants.DATA_TYPES;

    const CONSTANTS_TYPES_TO_REFS_TYPES = coreUtils.constants.PAGE_DATA_DATA_TYPES;

    const DATA_CHANGE_NOTIFICATION = {
        data: 'dataChange',
        props: 'propsChange',
        design: 'designChange'
    };

    const COMPONENTS_ROOT = ['runtime', 'components'];

    function compRoot(compId) {
        return COMPONENTS_ROOT.concat([compId]);
    }

    // references
    function compState(compId) {
        return compRoot(compId).concat('state');
    }

    // runtime overrides
    function compOverrides(compId) {
        return compRoot(compId).concat('overrides');
    }

    function compProps(compId) {
        return compOverrides(compId).concat('props');
    }

    function compData(compId) {
        return compOverrides(compId).concat('data');
    }

    function compStyle(compId, styleId) {
        const styleOverrides = compOverrides(compId).concat('style');
        if (styleId) {
            return styleOverrides.concat(styleId);
        }
        return styleOverrides;
    }

    function compDesign(compId) {
        return compOverrides(compId).concat('design');
    }

    function compLayout(compId) {
        return compOverrides(compId).concat('layout');
    }

    function actionsAndBehaviors(compId) {
        return compOverrides(compId).concat('actionsBehaviors');
    }

    function popupsInfo(popupId) {
        return ['runtime', 'popups', popupId];
    }

    function popupOpened(popupId) {
        return popupsInfo(popupId).concat('opened');
    }

    function popupContext(popupId) {
        return popupsInfo(popupId).concat('context');
    }

    function containsObject(container, obj) {
        return _.isEqual(obj, _.pick(container, _.keys(obj)));
    }

    /**
     * Site Data Access Layer for manipulating data in the viewer
     * @constructor
     */
    function RuntimeDal(siteData, siteDataApi, displayedDal, pointers, eventsManager) {
        this._siteData = siteData;
        this._siteDataApi = siteDataApi;
        this._pointers = pointers;
        this._displayedDal = displayedDal;
        this._changeListeners = [];
        this._isFirstSetToRuntime = true;
        this.snapshotId = 0;
        this._roots = [];
        this._pointersCache = new PointersRuntimeCache(pointers, displayedDal, siteDataApi.document);
        this._runtimeToDisplayed = new RuntimeToDisplayed(siteData, displayedDal, pointers, this._pointersCache, siteDataApi.document);
        this._eventsManager = eventsManager;
        // This is because the ctor is being called without parameters when require fake
        if (this._siteDataApi) {
            //why do we do this??
            this._siteDataApi.registerDisplayedJsonUpdateCallback(this._pointersCache.reset.bind(this._pointersCache));
        }

        // displayedDal is mandatory but the require plugin "fake!" is calling the ctor without any params
        if (this._displayedDal && !this._displayedDal.isPathExist(COMPONENTS_ROOT)) {
            reset.call(this);
        }

        _.bindAll(this, ['registerChangeListener', 'getCompStructure', 'setCompState', 'removeCompState', 'getCompState', 'getCompType',
            'getCompLayout', 'updateCompLayout', 'getCompDesign', 'setCompDesign', 'getCompData', 'setCompData', 'updateCompStyle', 'getCompStyle',
            'getCompConnections', 'getCompProps', 'setCompProps', 'getActionsAndBehaviors', 'addActionsAndBehaviors', 'removeActionsAndBehaviors',
            'getCompName', 'getBoundingClientRect', 'getPageId', 'getParentId', 'isDisplayed', 'hasBeenPopupOpened', 'markPopupAsBeenOpened',
            'getDynamicPageData', 'setPopupContext', 'getPopupContext', 'getDisplayedOnlyCompsForFullComp', 'getChildrenIds', 'getAncestorOfType', 'clearCompRuntimeOverrides']);
    }

    function reset() {
        const componentsData = _.reduce(_.get(this._siteData, COMPONENTS_ROOT), function (res, value, compId) {
            if (!_.has(value, 'state')) {
                return res;
            }
            const componentState = _.pick(value, 'state');
            return _.set(res, [compId], componentState);
        }, {});

        this._siteData.runtime = {};
        _.set(this._siteData, COMPONENTS_ROOT, componentsData);

        this._pointersCache.reset();
    }

    function getStore(siteData, runtimePath) {
        return _.get(siteData, _.head(runtimePath));
    }

    function getStorePath(runtimePath) {
        return _.drop(runtimePath);
    }

    function get(siteData, path, defaultValue) {
        const store = getStore(siteData, path);
        const pathInStore = getStorePath(path);
        return _.get(store, pathInStore, defaultValue);
    }

    function setToRuntime(siteData, runtimePath, value) {
        if (this._isFirstSetToRuntime && !siteData.isFirstRenderAfterSSR() && siteData.ssr.runtime) {
            this._isFirstSetToRuntime = false;
            this._siteDataApi.resetRuntimeData();
        }
        const store = getStore(siteData, runtimePath);
        const pathInStore = getStorePath(runtimePath);
        _.set(store, pathInStore, value);
    }

    function clearRuntimePath(siteData, runtimePath) {
        const store = getStore(siteData, runtimePath);
        const pathInStore = getStorePath(runtimePath);
        _.unset(store, pathInStore);
    }

    function isFullOnlyComponent(compPointer) {
        let isTemplateComponentResult = false;
        const isCompDisplayedOnly = coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id);
        if (!isCompDisplayedOnly) {
            const displayedCompPointers = this._pointers.components.getAllDisplayedOnlyComponents(compPointer);
            isTemplateComponentResult = !(displayedCompPointers.length === 1 && _.isEqual(displayedCompPointers[0], compPointer));
        }
        return isTemplateComponentResult;
    }

    function genericSetDataItem(valueUpdates, compId, runtimePath, dataType) {
        this._siteDataApi.getActionQueue().runImmediately(() => {
            const compPointer = getCompPointer.call(this, compId);

            const runtimeValue = get(this._siteData, runtimePath, {});
            const newRuntimeValue = _.assign({}, runtimeValue, valueUpdates);

            setToRuntime.call(this, this._siteData, runtimePath, newRuntimeValue);

            if (!compPointer) {
                return null;
            }

            const isFullOnly = isFullOnlyComponent.call(this, compPointer);
            const pageId = getPageId.call(this, compPointer);
            let dataId;
            if (isFullOnly) {
                const dataPointer = this._pointersCache.getCompDataItemPointer(compPointer, dataType, true);
                dataId = dataPointer ? `#${dataPointer.id}` : null;
            } else {
                dataId = this._pointersCache.getCompDataId(compPointer, dataType);
            }
            const currentData = this._siteData.getDataByQuery(dataId, pageId, CONSTANTS_TYPES_TO_REFS_TYPES[dataType]);
            this._runtimeToDisplayed.updateDataItem(compPointer, dataType, newRuntimeValue, isFullOnly);

            coreUtils.wSpy.log('setDataItem', [compId, currentData, ...arguments]);
            if (!containsObject(currentData, valueUpdates)) {
                const changeObject = {type: DATA_CHANGE_NOTIFICATION[dataType], value: valueUpdates};
                coreUtils.wSpy.log('setDataItem', ['notifyListeners', compId, changeObject, ...arguments]);
                notifyListeners.call(this, compId, changeObject, currentData);
            }
        });

        return ++this.snapshotId;
    }

    function notifyListeners(compId, changeObject, currentData) {
        _.forEach(this._changeListeners, function (listenerCallback) {
            listenerCallback(compId, changeObject, currentData);
        });
        if (this._eventsManager) {
            this._eventsManager.emit('runtimeCompChange', compId, changeObject, currentData);
        }
    }

    function getCompDataItemPointer(compId, dataType, shouldResolveFromFull) {
        const compPointer = getCompPointer.call(this, compId);
        if (!compPointer) {
            return null;
        }
        return this._pointersCache.getCompDataItemPointer(compPointer, dataType, shouldResolveFromFull);
    }

    function getResolvedData(dataPointer, dataType) {
        //TODO: decide whether to take it from site data (already resolved) or from dal (which will clone for now..)
        const pageId = this._pointers.data.getPageIdOfData(dataPointer);
        return this._siteData.getDataByQuery(dataPointer.id, pageId, CONSTANTS_TYPES_TO_REFS_TYPES[dataType]);
    }

    function getOriginalConnectionsItem(compId) {
        const compPointer = getCompPointer.call(this, compId);
        if (!compPointer) {
            return null;
        }
        const connectionQuery = this._siteDataApi.document.getFullStructureProperty(compPointer, 'connectionQuery');
        if (!connectionQuery) {
            return null;
        }

        const dataType = DATA_TYPES.connections;
        const pageId = getPageId.call(this, compPointer);
        const connectionPointer = this._pointers.data.getItem(dataType, connectionQuery.replace('#', ''), pageId);
        const connection = this._displayedDal.get(connectionPointer);
        const resolvedConnection = this._siteData.resolveData(connection, pageId, CONSTANTS_TYPES_TO_REFS_TYPES[dataType]);
        return resolvedConnection.items;
    }

    function getPageId(pointer) {
        return this._pointers.full.components.getPageOfComponent(pointer).id;
    }

    function resetOverridesIfNeeded() {
        const currentRoots = this._siteData.getAllPossiblyRenderedRoots();
        const currentComps = this._pointersCache.getAllCompIds(this._siteData.getViewMode(), currentRoots);
        let hasRemovedAnything = false;

        if (!_.isEqual(currentRoots, this._roots)) {
            const allComps = _.get(this._siteData, COMPONENTS_ROOT);
            const redundantOverrides = _.difference(_.keys(allComps), currentComps);
            const componentsStore = _.get(this._siteData, COMPONENTS_ROOT);
            if (!_.isEmpty(redundantOverrides)) {
                hasRemovedAnything = true;
                _.forEach(redundantOverrides, function (compId) {
                    delete componentsStore[compId];
                });
            }
        }

        this._roots = currentRoots;
        return hasRemovedAnything;
    }

    function resetPage(rootId) {
        const currentRoots = this._siteData.getAllPossiblyRenderedRoots();
        let hasRemovedAnything = false;
        const currentComps = this._pointersCache.getAllCompIds(this._siteData.getViewMode(), [rootId]);

        const componentsStore = _.get(this._siteData, COMPONENTS_ROOT);
        if (componentsStore && !_.isEmpty(currentComps)) {
            _.forEach(currentComps, function (compId) {
                delete componentsStore[compId];
            });
            hasRemovedAnything = true;
        }

        this._roots = currentRoots;
        return hasRemovedAnything;
    }

    function getCompPointer(compId) {
        return this._pointersCache.getCompPointer(compId, this._siteData.getViewMode(), this._siteData.getAllPossiblyRenderedRoots());
    }

    _.assign(RuntimeDal.prototype, {

        registerChangeListener(listenerCallback) {
            this._changeListeners.push(listenerCallback);
        },

        getCompStructure(compId) {
            const compPointer = getCompPointer.call(this, compId);
            return compPointer && this._displayedDal.get(compPointer);
        },

        /**
         * THIS METHOD SHOULD BE IN USE ONLY BY compStateMixin TO UPDATE THE STORE, IT DOESN'T UPDATE THE COMPONENT REAL STATE
         *
         * Set partial state of the component.
         * @param {string} compId The component id
         * @param {object} newState The updated partial state
         */
        setCompState(compId, newState) {
            const previousState = this.getCompState(compId);
            const path = compState(compId);
            const store = getStore(this._siteData, path);
            const pathInStore = getStorePath(path);
            _.set(store, pathInStore, _.assign({}, previousState, newState));

            if (!containsObject(previousState, newState)) {
                const changeObject = {type: 'stateChange', value: this.getCompState(compId)};
                notifyListeners.call(this, compId, changeObject, previousState);
            }
            return ++this.snapshotId;
        },

        /**
         * Removes the state of the component
         * @param {String} compId The component id
         */
        removeCompState(compId) {
            const statePath = compState(compId);
            const parentObj = _.get(this._siteData, _.initial(statePath));
            const keyToDelete = _.last(statePath);

            if (_.get(parentObj, keyToDelete)) {
                delete parentObj[keyToDelete];
            }
        },

        /**
         * Get the component runtime state
         * @param {string} compId The component id
         * @returns {object} The component state
         */
        getCompState(compId) {
            return get(this._siteData, compState(compId));
        },

        /**
         * Get the component runtime type
         * @param compId
         * @returns {String}
         */
        getCompType(compId) {
            const compPointer = getCompPointer.call(this, compId);
            if (this._displayedDal.isExist(compPointer)) {
                const componentTypePointer = this._pointers.getInnerPointer(compPointer, 'componentType');
                return this._displayedDal.get(componentTypePointer);
            }
            return this._siteDataApi.document.getFullStructureProperty(compPointer, 'componentType');
        },

        getCompLayout(compId) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, compLayout(compId));
            const compPointer = getCompPointer.call(this, compId);
            if (!compPointer) {
                return;
            }
            const layoutPointer = this._pointers.getInnerPointer(compPointer, 'layout');
            return this._displayedDal.get(layoutPointer);
        },

        updateCompLayout(compId, newLayout) {
            const previousValue = this.getCompLayout(compId);
            const runtimePath = compLayout(compId);
            const runtimeValue = get(this._siteData, runtimePath, {});
            const newRuntimeValue = _.assign({}, runtimeValue, newLayout);

            //for now, until we have mobx in displayedDal, we need this after the set to displayed, so the computation will happen after the data was really updated
            setToRuntime.call(this, this._siteData, runtimePath, newRuntimeValue);

            this._runtimeToDisplayed.updateCompLayout(compId, newRuntimeValue);

            if (!containsObject(previousValue, newLayout)) {
                const changeObject = {type: 'layoutChange', value: newLayout};
                notifyListeners.call(this, compId, changeObject, previousValue);
            }

            return ++this.snapshotId;
        },

        getCompStyle(compId) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, compStyle(compId));
            const dataPointer = getCompDataItemPointer.call(this, compId, DATA_TYPES.theme);
            return dataPointer && getResolvedData.call(this, dataPointer, DATA_TYPES.theme);
        },

        updateCompStyle(compId, newStyle) {
            const runtimePath = compStyle(compId, newStyle.id);
            const runtimeValue = get(this._siteData, runtimePath, {});
            const newRuntimeValue = _.assign({}, runtimeValue, newStyle);

            //for now, until we have mobx in displayedDal, we need this after the set to displayed, so the computation will happen after the data was really updated
            setToRuntime.call(this, this._siteData, runtimePath, newRuntimeValue);

            this._runtimeToDisplayed.updateCompStyle(compId, newRuntimeValue);
        },

        getCompDesign(compId, shouldResolveCompDataFromFull) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, compDesign(compId));
            const dataPointer = getCompDataItemPointer.call(this, compId, DATA_TYPES.design, shouldResolveCompDataFromFull);
            return dataPointer && getResolvedData.call(this, dataPointer, DATA_TYPES.design);
        },

        setCompDesign(compId, newDesign) {
            return genericSetDataItem.call(this, newDesign, compId, compDesign(compId), DATA_TYPES.design);
        },

        getCompData(compId, shouldResolveFromFull) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, compData(compId));
            const dataPointer = getCompDataItemPointer.call(this, compId, DATA_TYPES.data, shouldResolveFromFull);
            return dataPointer && getResolvedData.call(this, dataPointer, DATA_TYPES.data);
        },

        setCompData(compId, newData) {
            return genericSetDataItem.call(this, newData, compId, compData(compId), DATA_TYPES.data);
        },

        registerComponentEvent(compId, newEventData) {
            const actionBehavior = {
                action: {
                    type: 'comp',
                    name: newEventData.eventType,
                    sourceId: compId
                },
                behavior: {
                    type: 'widget',
                    targetId: newEventData.contextId,
                    params: {
                        callbackId: newEventData.callbackId,
                        compId
                    },
                    name: 'runCode'
                }
            };
            this.addActionsAndBehaviors(compId, actionBehavior);
        },

        getCompConnections(compId) {
            return getOriginalConnectionsItem.call(this, compId) || [];
        },

        getCompProps(compId, shouldResolveCompDataFromFull) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, compProps(compId));
            const dataPointer = getCompDataItemPointer.call(this, compId, DATA_TYPES.prop, shouldResolveCompDataFromFull);
            return dataPointer && getResolvedData.call(this, dataPointer, DATA_TYPES.prop);
        },

        setCompProps(compId, newProps) {
            return genericSetDataItem.call(this, newProps, compId, compProps(compId), DATA_TYPES.prop);
        },

        getActionsAndBehaviors(compId) {
            //noinspection JSUnusedLocalSymbols
            const soThatMobxWillKnow = get(this._siteData, actionsAndBehaviors(compId));
            const dataPointer = getCompDataItemPointer.call(this, compId, DATA_TYPES.behaviors);
            //we need to resolve the data, cause otherwise we'll get a string and not an array
            const dataItem = dataPointer && getResolvedData.call(this, dataPointer, DATA_TYPES.behaviors);
            return dataItem && dataItem.items || []; // eslint-disable-line no-mixed-operators
        },

        addActionsAndBehaviors(compId, newActionsAndBehaviors) {
            const runtimePath = actionsAndBehaviors(compId);
            const existingActionsBehaviors = get(this._siteData, runtimePath, []);
            const newRuntimeValue = existingActionsBehaviors.concat(newActionsAndBehaviors);

            //for now, until we have mobx in displayedDal, we need this after the set to displayed, so the computation will happen after the data was really updated
            setToRuntime.call(this, this._siteData, runtimePath, newRuntimeValue);

            this._runtimeToDisplayed.updateActionsAndBehaviorsItems(compId, newRuntimeValue);

            return ++this.snapshotId;
        },

        removeActionsAndBehaviors(compId, predicateObject) {
            const runtimePath = actionsAndBehaviors(compId);
            const existingActionsBehaviors = get(this._siteData, runtimePath, []);
            _.remove(existingActionsBehaviors, predicateObject);

            //for now, until we have mobx in displayedDal, we need this after the set to displayed, so the computation will happen after the data was really updated
            setToRuntime.call(this, this._siteData, runtimePath, existingActionsBehaviors);

            this._runtimeToDisplayed.updateActionsAndBehaviorsItems(compId, existingActionsBehaviors);

            return ++this.snapshotId;
        },

        resetActionsAndBehaviors(compId) {
            this._runtimeToDisplayed.resetActionsAndBehaviorsItems(compId);
            return ++this.snapshotId;
        },

        getCompName(compId) {
            const compConnections = this.getCompConnections(compId);
            const wixCodeConnection = _.get(compConnections, [0, 'type']) === 'WixCodeConnectionItem' && compConnections[0];
            return wixCodeConnection ? wixCodeConnection.role : compId;
        },

        getCompPointer,

        getBoundingClientRect(compId) {
            const measureMap = this._siteData.measureMap;
            if (measureMap) {
                return _.transform(clientRectProperties, function (acc, prop) {
                    acc[prop] = measureMap[prop][compId];
                }, {});
            }
            return _.pick(this.getCompLayout(compId), clientRectProperties);
        },

        getPageId(compId) {
            const compPointer = getCompPointer.call(this, compId);
            if (!compPointer) {
                return null;
            }
            return getPageId.call(this, compPointer);
        },

        getParentId(compId) {
            const compPointer = getCompPointer.call(this, compId);
            const isDisplayedOnly = coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compId);
            const pointers = isDisplayedOnly ? this._pointers : this._pointers.full;

            const parentPointer = compPointer && pointers.components.getParent(compPointer);
            return parentPointer && parentPointer.id;
        },

        isDisplayed(compId) {
            const compPointer = getCompPointer.call(this, compId);
            return !!compPointer && this._displayedDal.isExist(compPointer);
        },

        hasBeenPopupOpened(popupId) {
            return get(this._siteData, popupOpened(popupId), false);
        },

        markPopupAsBeenOpened(popupId) {
            const popupInfoPath = popupOpened(popupId);
            setToRuntime.call(this, this._siteData, popupInfoPath, true);
        },

        getDynamicPageData() {
            return this._siteData.getDynamicPageData();
        },

        getCompDataOverrides(compId) {
            return get(this._siteData, compData(compId));
        },

        getCompDesignOverrides(compId) {
            return get(this._siteData, compDesign(compId));
        },

        getCompBehaviorsOverrides(compId) {
            return get(this._siteData, actionsAndBehaviors(compId));
        },

        getCompPropsOverrides(compId) {
            return get(this._siteData, compProps(compId));
        },

        setPopupContext(popupId, context) {
            const popupContextPath = popupContext(popupId);
            setToRuntime.call(this, this._siteData, popupContextPath, context);
        },

        getPopupContext(popupId) {
            return get(this._siteData, popupContext(popupId));
        },

        getDisplayedOnlyCompsForFullComp(fullCompId, contextId) {
            const displayedDal = this._displayedDal;
            const pagePointer = this._pointers.full.components.getPage(contextId, this._siteData.getViewMode());
            const fullCompPointer = this._pointers.full.components.getComponent(fullCompId, pagePointer);
            const displayedCompPointers = this._pointers.components.getAllDisplayedOnlyComponents(fullCompPointer);
            const displayedOnlyComps = _.reduce(displayedCompPointers, function (result, displayedCompPointer) {
                if (coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(displayedCompPointer.id)) {
                    result.push(displayedDal.get(displayedCompPointer));
                }
                return result;
            }, []);
            return displayedOnlyComps;
        },

        getChildrenIds(compId) {
            const isDisplayedOnly = coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compId);
            const pointers = isDisplayedOnly ? this._pointers : this._pointers.full;
            const compPointer = getCompPointer.call(this, compId);

            return _.map(pointers.components.getChildren(compPointer), 'id');
        },

        getAncestorOfType(compId, compType) {
            const self = this;
            const pointers = this._pointers.full;
            const compPointer = getCompPointer.call(this, compId);

            return pointers.components.getAncestorByPredicate(compPointer, function (parentPointer) {
                return self.getCompType(parentPointer.id) === compType;
            });
        },

        clearCompRuntimeOverrides(compId) {
            clearRuntimePath(this._siteData, compRoot(compId));
        }


    });

    // return RuntimeDal;

    return {
        createRuntimeDal(siteData, siteDataApi, displayedDal, pointers, eventsManager) {
            const runtime = new RuntimeDal(siteData, siteDataApi, displayedDal, pointers, eventsManager);
            return {
                runtimeDal: runtime,
                resetRuntime: reset.bind(runtime),
                runtimeToDisplayed: runtime._runtimeToDisplayed,
                getAllCompsData() {
                    return get(runtime._siteData, COMPONENTS_ROOT, {});
                },
                resetNonDisplayedPages: resetOverridesIfNeeded.bind(runtime),
                resetPage: resetPage.bind(runtime)
            };
        }
    };
});
