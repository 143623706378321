define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const balataConsts = coreUtils.mediaConsts.balataConsts;

    const behaviorsMap = {
        'FadeIn': {type: 'animation', defaults: {params: {}}},
        'FadeOut': {type: 'animation', defaults: {params: {}}},
        'Scale': {type: 'animation', defaults: {params: {scale: 1}}}
    };

    Object.freeze(behaviorsMap);

    /**
     * Get reference to the balata related to this action
     * @param {SiteAPI} siteAPI
     * @param {string} pageId
     * @param {string} id
     * @returns {string}
     */
    function getBalataRef(siteAspectSiteAPI, id) {
        const comp = siteAspectSiteAPI.getComponentById(id);
        return comp.refs.balata;
    }

    /**
     * Get the behavior to be passed along to the next handler (e.g. animationBehaviorHandler)
     * @param {string} compId
     * @param {object} behaviorDef
     * @param {object} transformParams
     * @param {object} nextTransforms
     * @returns {{}}
     */
    function getParsedBehavior(compId, behaviorDef, transformParams, nextTransforms) {
        const newBehavior = {
            name: behaviorDef.name,
            type: behaviorDef.type,
            targetId: [[compId, coreUtils.mediaConsts.balataConsts.BALATA, behaviorDef.part]],
            params: _.clone(behaviorDef.params) || {}

        };
        // Assign transformations from data to behavior
        if (!_.isEmpty(transformParams)) {
            _.assign(newBehavior.params, transformParams, _.pick(nextTransforms, _.keys(transformParams)));
        }
        return newBehavior;
    }

    /**
     * Get the state to send back to balata
     * @param {object} previousDesignData
     * @param {array<object>} behaviorDefs
     * @returns {{}}
     */
    function getTransformState(previousDesignData, behaviorDefs) {
        const parts = _(behaviorDefs).map('part').uniq().value();

        const transformParamsByParts = _.transform(behaviorDefs, function (result, def) {
            const paramsFromMap = _.get(behaviorsMap, [def.name, 'defaults', 'params']);
            result[def.part] = _.assign({}, result[def.part], paramsFromMap);
        }, {});

        const transformState = _.transform(parts, function (result, part) {
            const previousPartTransforms = _.get(previousDesignData, ['background', balataConsts[part]]);
            result[part] = _.merge({}, transformParamsByParts[part], _.pick(previousPartTransforms, _.keys(transformParamsByParts[part])));
        }, {});

        return transformState;
    }

    /**
     * Clear previous animations
     * @param siteAPI
     * @param groupName
     * @param shouldSeek
     * @param seekValue
     */
    function clearPreviousAnimations(siteAspectSiteAPI, groupName) {
        const animAspect = siteAspectSiteAPI.getSiteAspect('animationsAspect');
        animAspect.stopAndClearAnimations(groupName);
    }

    /**
     * Call handlers for behaviors and return the transformed values for each balata part
     * @param {SiteAPI} siteAPI
     * @param {string} compId
     * @param {object} nextData
     * @param {object} collectedBehaviorsDefs
     * @returns {{}}
     */
    function executeBehaviors(siteAspectSiteAPI, compId, nextData, collectedBehaviorsDefs) {
        const behaviorsAspect = siteAspectSiteAPI.getSiteAspect('behaviorsAspect');
        _.forEach(collectedBehaviorsDefs, function (def) {
            const nextTransforms = _.get(nextData, ['background', balataConsts[def.part]], {});
            const paramsFromMap = _.get(behaviorsMap, [def.name, 'defaults', 'params']);

            // Send to behavior to new handler (by type)
            behaviorsAspect.handleBehavior(getParsedBehavior(compId, def, paramsFromMap, nextTransforms), {group: compId});
        });
    }

    /**
     * Collect behaviors from design data
     * @param {object} prevData
     * @param {object} nextData
     * @returns {[{}]}
     */
    function collectBehaviorsDefs(prevData, nextData, balataRef) {
        const collected = [];
        // get all out behaviors
        _.forEach(_.filter(prevData.dataChangeBehaviors, {trigger: 'out'}), function (behavior) {
            collected.push(behavior);
        });
        // get only in behaviors that doesn't collide with outs
        _.forEach(_.filter(nextData.dataChangeBehaviors, {trigger: 'in'}), function (behavior) {
            if (!_.find(collected, _.pick(behavior, ['type', 'part', 'name']))) {
                collected.push(behavior);
            }
        });


        const paramsWithCallbacks = {
            callbacks: {
                onComplete() {
                    balataRef.setState({transforms: {}});
                }
            }
        };

        // fixes WEED-1841. Only works when balata has a single behavior at a time. For multiple behaviors, call setState only when all of them are completed
        return _.map(collected, function (behavior) {
            const params = _.defaults({}, paramsWithCallbacks, behavior.params);
            return _.defaults({params}, behavior);
        });
    }

    /**
     * Call handlers for behaviors and set the state of calling balata with transformed values
     * @param {SiteAPI} siteAPI
     * @param {string} compId
     * @param {object} previousData
     * @param {object} nextData
     */
    function executeBehaviorsAndSetState(siteAspectSiteAPI, compId, previousData, nextData) {
        const balataRef = getBalataRef(siteAspectSiteAPI, compId);
        const previousDesignData = previousData || {};
        // Get behaviors definitions from data
        const collectedBehaviorsDefs = collectBehaviorsDefs(previousData, nextData, balataRef);

        // TODO: we need to find a way to not 'know' what animations are here.
        clearPreviousAnimations(siteAspectSiteAPI, compId);
        // Execute behaviors handler for collected behaviors and return transformed values
        executeBehaviors(siteAspectSiteAPI, compId, nextData, collectedBehaviorsDefs);
        // Set state on balata with revised transform values
        balataRef.setState({
            transforms: getTransformState(previousDesignData, collectedBehaviorsDefs)
        });
    }

    /**
     * Handle design data behaviors
     * @param {SiteAPI} siteAPI
     * @param {string} compId
     * @param {object} previousData
     * @param {object} nextData
     */
    function handle(siteAspectSiteAPI, compId, previousData, nextData) {
        //todo: see SE-21185 - we fixed the symptom, not the bug. should be revisited
        if (_.isEmpty(_.get(previousData, 'dataChangeBehaviors')) && // eslint-disable-line no-mixed-operators
            _.isEmpty(_.get(nextData, 'dataChangeBehaviors')) || // eslint-disable-line no-mixed-operators
            _.get(nextData, 'background.mediaRef.type') !== _.get(previousData, 'background.mediaRef.type')) {
            return;
        }
        executeBehaviorsAndSetState(siteAspectSiteAPI, compId, previousData, nextData);
    }

    return {
        handle
    };
});
