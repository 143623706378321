/**
 * Created with IntelliJ IDEA.
 * User: avim
 * Date: 6/9/14
 * Time: 3:48 PM
 * To change this template use File | Settings | File Templates.
 */

define([
    'lodash',
    'prop-types',
    'create-react-class',
    'reactDOM',
    'mobx',
    'utils',
    'compUtils',
    'santa-components',
    'experiment'
], function (
    _,
    PropTypes,
    createReactClass,
    ReactDOM,
    mobx,
    utils,
    compUtils,
    santaComponents,
    experiment
) {
    'use strict';

    function getReactConstructor(componentType, compFactoryRuntimeState) {
        return componentType && compUtils.compFactory.getCompClass(componentType, false, compFactoryRuntimeState) || santaComponents.utils.createReactElement.bind(null, 'div'); // eslint-disable-line no-mixed-operators
    }

    function shouldPageBeStubByProps(siteAPI, rootId) {
        const rootIdsWhichShouldBeRendered = siteAPI.getRootIdsWhichShouldBeRendered();
        return !utils.componentUtils.shouldBeRendered(rootIdsWhichShouldBeRendered, rootId);
    }

    //TODO-mobx - check if somebody needs it. (popup does't use it)
    function registerPageBehaviors() {
        const pageStaticBehaviors = this.props.compBehaviors;
        if (!pageStaticBehaviors) {
            return;
        }

        const rootId = this.props.rootId;

        const actionAspect = this.props.siteAPI.getSiteAspect('actionsAspect');
        const behaviorsAspect = this.props.siteAPI.getSiteAspect('behaviorsAspect');

        actionAspect.registerBehaviors(rootId, rootId, pageStaticBehaviors);
        behaviorsAspect.setBehaviorsForActions(behaviorsAspect.convertBehaviors(pageStaticBehaviors, rootId), rootId, rootId);
    }

    const wixPageReact = createReactClass({
        displayName: 'WixPageReact',

        propTypes: {
            siteAPI: PropTypes.shape({
                resetRuntimeOverrides: PropTypes.func.isRequired,
                setComponentRenderEnd: PropTypes.func.isRequired,
                getSiteAspect: PropTypes.func.isRequired,
                getSiteData: PropTypes.func.isRequired,
                setComponentRenderStart: PropTypes.func.isRequired
            }),
            componentType: PropTypes.string.isRequired,
            mobxObserverWrapperProps: PropTypes.object.isRequired,
            structure: PropTypes.object,
            rootId: PropTypes.string,
            onRendered: PropTypes.func
        },

        getDefaultProps() {
            return {
                onRendered: _.noop
            };
        },

        mixins: [santaComponents.mixins.baseCompMixin, santaComponents.mixins.animationsMixin],

        getInitialState() {
            return {
                allContextsReadyOrLifecycleFailed: experiment.isOpen('sv_handleFailingWixCodeSdk', this.props.siteAPI.getSiteData()) ? this.props.siteAPI.getSiteAspect('WidgetAspect').allContextsReadyOrLifecycleFailed() : this.props.siteAPI.getSiteAspect('WidgetAspect').allContextsReady()
            };
        },

        componentWillMount() {
            this.childrenAnimations = {};
            this.transitioningChildrenPrevLayout = {};
            this.compRefs = {};

            this.mobxObserverWrapperProps = _.assign({}, this.props.mobxObserverWrapperProps, {
                propsForSitePages: {pageClass: wixPageReact},
                addComponentRef: this.addComponentRef
            });

            this.disposeReaction = mobx.reaction(
                function areAllContextsReady() {
                    return experiment.isOpen('sv_handleFailingWixCodeSdk', this.props.siteAPI.getSiteData()) ? this.props.siteAPI.getSiteAspect('WidgetAspect').allContextsReadyOrLifecycleFailed() : this.props.siteAPI.getSiteAspect('WidgetAspect').allContextsReady();
                },
                function renderIfContextsReady(allContextsReadyOrLifecycleFailed) {
                    if (this.state.allContextsReadyOrLifecycleFailed !== allContextsReadyOrLifecycleFailed) {
                        this.setState({
                            allContextsReadyOrLifecycleFailed
                        });
                    }
                },
                {context: this}
            );

            registerPageBehaviors.call(this);
            const siteData = this.props.siteAPI.getSiteData();
            this.props.siteAPI.setComponentRenderStart(`WixPageReact_${this.props.rootId}_${siteData.getViewMode()}`);
        },

        addComponentRef(componentValue, componentId) {
            if (componentValue) {
                this.compRefs[componentId] = componentValue;
            } else {
                delete this.compRefs[componentId];
            }
        },

        componentWillUnmount() {
            this.props.siteAPI.resetRuntimeOverrides(this.props.rootId);
            this.disposeReaction();
        },

        componentWillUpdate() {
            if (shouldPageBeStubByProps(this.props.siteAPI, this.props.rootId)) {
                this.props.siteAPI.resetRuntimeOverrides(this.props.rootId);
            }

            registerPageBehaviors.call(this);

            const siteData = this.props.siteAPI.getSiteData();
            this.props.siteAPI.setComponentRenderStart(`WixPageReact_${this.props.rootId}_${siteData.getViewMode()}`);
        },

        componentDidUpdate() {
            const siteData = this.props.siteAPI.getSiteData();
            this.props.siteAPI.setComponentRenderEnd(`WixPageReact_${this.props.rootId}_${siteData.getViewMode()}`);
        },

        componentDidMount() {
            const siteData = this.props.siteAPI.getSiteData();
            this.props.siteAPI.setComponentRenderEnd(`WixPageReact_${this.props.rootId}_${siteData.getViewMode()}`);
        },

        render() {
            if (!this.state.allContextsReadyOrLifecycleFailed) {
                return this.renderedPage || santaComponents.utils.createReactElement('div');
            }

            const props = {
                id: this.props.rootId,
                key: this.props.rootId,
                'data-ref': this.props.rootId,
                rootId: this.props.rootId,
                mobxObserverWrapperProps: this.mobxObserverWrapperProps,
                compRef: comp => {this._comp = comp;}
            };

            const reactConstructor = getReactConstructor(this.props.componentType, this.props.siteAPI.getSiteData().compFactoryRuntimeState);
            this.renderedPage = reactConstructor(props);

            return this.renderedPage;
        },

        updateVisibility() {
            const siteAPI = this.props.siteAPI;
            const rootId = this.props.rootId;
            const isContextReadyOrLifeCycleFailed = siteAPI.getSiteAspect('WidgetAspect').isContextReadyOrLifeCycleFailed(rootId); // TODO: should come from props

            if (isContextReadyOrLifeCycleFailed && !shouldPageBeStubByProps(siteAPI, rootId)) {
                ReactDOM.findDOMNode(this._comp).style.visibility = '';
                this.props.onRendered();
            }
        },

        hide() {
            ReactDOM.findDOMNode(this._comp).style.visibility = 'hidden';
        },

        componentDidLayout() {
            this.updateVisibility();
        }
    });

    return wixPageReact;
});
