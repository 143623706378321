define(['santa-components', 'lodash', 'prop-types', 'create-react-class', 'mobx-react'], function (santaComponents, _, PropTypes, createReactClass, mobxReact) {
    'use strict';

    const zIndex = Math.pow(2, 31) - 2; //NOTE: z-index.scss
    return mobxReact.observer(createReactClass({
        displayName: 'blockingLayer',
        propTypes: {
            siteAPI: PropTypes.object.isRequired,
            layerName: PropTypes.string.isRequired
        },
        getStyle() {
            const blockingLayerStyle = this.props.siteAPI.getRenderFlag(this.props.layerName);

            return _.merge({
                display: 'inline-block',
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                top: 0,
                left: 0,
                zIndex
            }, blockingLayerStyle);
        },
        render() {
            if (!this.props.siteAPI.getRenderFlag(this.props.layerName)) {
                return null;
            }
            return santaComponents.utils.createReactElement('div', {
                style: this.getStyle()
            });
        }
    }));
});
