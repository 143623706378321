define(['lodash', 'experiment', 'warmupUtils'], function (_, experiment, warmupUtils) {
    'use strict';

    function WixSiteHeadRenderer() {
        this.blankFavicons = null;
    }

    function isFaviconIncludedInPremium(siteData) {
        return _.includes(siteData.rendererModel.premiumFeatures, 'AdsFree');
    }

    function getMetaDataForDynamicPage(siteData, pageId) {
        const metaTags = siteData.getDynamicPageSEOMetaData(pageId);
        const metaData = {metaTags: {}, openGraphMetaTags: {}};

        _.forEach(metaTags, function (value, key) {
            if (_.startsWith(key, 'og:')) {
                metaData.openGraphMetaTags[key] = value;
            } else {
                metaData.metaTags[key] = value;
            }
        });

        return metaData;
    }

    function getMetaTagsForDynamicPage(siteData, pageId) {
        const metaTags = siteData.getDynamicPageSEOMetaData(pageId);
        const metaTagsElemetnts = [];

        _.forEach(metaTags, function (content, name) {
            if (_.startsWith(name, 'og:')) {
                metaTagsElemetnts.push(`<meta property="${name}" content="${content}">`);
            } else {
                metaTagsElemetnts.push(`<meta name="${name}" content="${content}">`);
            }
        });

        return metaTagsElemetnts;
    }

    function getPageIdForMetaTags(siteData, pageId) {
        pageId = siteData.getCurrentUrlPageId() || pageId;

        if (!pageId || pageId === 'masterPage') {
            pageId = siteData.getMainPageId();
        }

        return pageId;
    }

    WixSiteHeadRenderer.prototype = {

        /* Public Methods */

        getFavicons(siteData) {
            if (siteData.isPremiumUser && siteData.isPremiumUser() && isFaviconIncludedInPremium(siteData)) {
                return this._getPremiumUserFavicon(siteData);
            }
            return this._getDefaultWixFavicon();
        },

        createBlankFavicons() {
            if (this.blankFavicons === null) {
                this.blankFavicons = this._createEmptyFaviconElements();
            }
            return this.blankFavicons;
        },

        getPageMetaData(siteData, pageId) {
            const pageIdForMetaTags = getPageIdForMetaTags(siteData, pageId);

            const metaData = siteData.isDynamicPage(pageIdForMetaTags) ?
                getMetaDataForDynamicPage(siteData, pageIdForMetaTags) :
                this._getMetaDataForPage(siteData, pageIdForMetaTags);

            if (siteData.isInSeo()) {
                const tpaMetaData = this._getTpaMetaData(siteData);
                _.assign(metaData.metaTags, tpaMetaData.metaTags);
                _.assign(metaData.openGraphMetaTags, tpaMetaData.openGraphMetaTags);

                metaData.linkTags = metaData.linkTags || {};
                _.assign(metaData.linkTags, tpaMetaData.linkTags);

                if (tpaMetaData.title) {
                    metaData.title = tpaMetaData.title;
                }
            }
            metaData.advancedSeoData = this.getAdvancedSeoData(siteData, pageIdForMetaTags);
            return metaData;
        },

        getPageMetaTags(siteData, pageId) {
            const pageIdForMetaTags = getPageIdForMetaTags(siteData, pageId);

            if (siteData.isDynamicPage(pageIdForMetaTags)) {
                return getMetaTagsForDynamicPage(siteData, pageIdForMetaTags);
            }

            return this._getMetaTagsForPage(siteData, pageIdForMetaTags);
        },

        getPageJsonldTag(siteData, pageId) {
            const pageIdForMetaTags = getPageIdForMetaTags(siteData, pageId);
            const jsonld = siteData.getPageJsonld(pageIdForMetaTags);

            if (_.size(jsonld) === 0) {
                return '';
            }

            return [
                `<script type="application/ld+json" data-pageid="${pageId}">`,
                JSON.stringify(jsonld),
                '</script>'
            ].join('');
        },

        getPageLinkTag(siteData, pageId) {
            const pageIdForMetaTags = getPageIdForMetaTags(siteData, pageId);
            const linkTag = siteData.getPageLinkTag(pageIdForMetaTags);

            if (_.size(linkTag) === 0) {
                return '';
            }

            return `<link rel="amphtml" href="${linkTag}">`;
        },

        getTpaLinkTag(siteData, pageId, linkRel) {
            const pageIdForMetaTags = getPageIdForMetaTags(siteData, pageId);
            const href = siteData.getPageSEOLinkHref(pageIdForMetaTags, linkRel);
            if (_.isNil(href) || href === '') {
                return '';
            }

            return `<link rel="${linkRel}" href="${href}" data-id="${warmupUtils.constants.TPA_LINK_TAGS.DATA_ID}">`;
        },

        getAdvancedSeoData(siteData, pageId) {
            const advancedSeoData = siteData.getPageAdvancedSeoData(pageId);
            const localizedAdvancedSeoData = siteData.getPageLocalizedAdvancedSeoData(pageId);
            const contextProps = siteData.getPageAdvancedSeoContextProps(pageId);

            return {
                advancedSeoData,
                localizedAdvancedSeoData,
                contextProps
            };
        },

        /* Private Methods */

        _getPremiumUserFavicon(siteData) {
            let favicon = siteData.getFavicon();
            if (favicon) {
                const faviconMime = this._getFaviconMimeType(favicon);
                // TODO nirm 6/3/15 1:19 PM - We dont have the dimensions of the original favicon image so we cant keep the correct ratio when asking for a smaller version from the server :(
                favicon = siteData.getMediaFullStaticUrl(favicon);
                return this._createFaviconLinkElements(favicon, faviconMime);
            }
            return this._createEmptyFaviconElements();
        },

        _getDefaultWixFavicon() {
            const favicon = 'http://www.wix.com/favicon.ico';
            const faviconMime = this._getFaviconMimeType(favicon);
            return this._createFaviconLinkElements(favicon, faviconMime);
        },

        _createEmptyFaviconElements() {
            return this._createFaviconLinkElements(null, null);
        },

        _createFaviconLinkElements(href, mimeType) {
            const result = [];
            if (href && mimeType) {
                result.push({rel: 'shortcut icon', href, type: mimeType});
                result.push({rel: 'apple-touch-icon', href, type: mimeType});
            }

            return result;
        },

        _getStaticMediaUrl(siteData) {
            return siteData.serviceTopology.mediaRootUrl;
        },

        _getFaviconMimeType(favicon) {
            let mimeType = null;
            if (favicon) {
                if (this._isExtension(favicon, 'png')) {
                    mimeType = 'image/png';
                } else if (this._isExtension(favicon, 'gif')) {
                    mimeType = 'image/gif';
                } else if (this._isExtension(favicon, 'ico') || this._isExtension(favicon, 'icon')) {
                    mimeType = 'image/x-icon';
                } else if (this._isExtension(favicon, 'jpg')) {
                    mimeType = 'image/jpg';
                }
            }
            return mimeType;
        },

        _isExtension(name, extension) {
            return this._getExtension(name).toLowerCase() === extension;
        },

        _getExtension(name) {
            if (name) {
                const dotIndex = name.lastIndexOf('.');
                if (dotIndex >= 0) {
                    return name.substr(dotIndex + 1);
                }
            }
            return '';
        },

        _getTpaMetaData(siteData) {
            const tpaMetaData = siteData.getTpaSEOMetaData();
            const metaData = {metaTags: {}, openGraphMetaTags: {}, linkTags: {}};

            const linkTagRels = {canonical: 'canonical', prevLink: 'prev', nextLink: 'next'};

            _.forEach(tpaMetaData, (value, key) => {
                if (linkTagRels[key]) {
                    metaData.linkTags[linkTagRels[key]] = value;
                } else if (_.startsWith(key, 'og')) {
                    metaData.openGraphMetaTags[`og:${key.substring(2).toLowerCase()}`] = value;
                } else if (key === 'title') {
                    metaData.title = value;
                } else {
                    metaData.metaTags[key] = value;
                }
            });

            return metaData;
        },

        _getMetaDataForPage(siteData, pageId) {
            const metaTags = siteData.getPageSEOMetaData(pageId);
            const metaData = {metaTags: {}, openGraphMetaTags: this._getOgMetaData(metaTags)};
            _.assign(metaData.metaTags, this._getDescriptionSEOMetaData(metaTags), this._getKeywordsSEOMetaData(metaTags));

            if (_.get(siteData, 'publicModel.indexable')) {
                _.assign(metaData.metaTags, this._getRobotsIndexMetaData(metaTags));
            }

            if (experiment.isOpen('sv_twitterMetaTags', siteData)) {
                _.assign(metaData.metaTags, this._getTwitterMetaData(metaTags));
            }

            return metaData;
        },

        _getMetaTagsForPage(siteData, pageId) {
            const metaTags = siteData.getPageSEOMetaData(pageId);
            const tagGetters = [this._getDescriptionSEOMetaTag, this._getKeywordsSEOMetaTag, this._getOgMetaTag];
            if (_.get(siteData, 'publicModel.indexable')) {
                tagGetters.push(this._getRobotsIndexMetaTag);
            }

            if (experiment.isOpen('sv_twitterMetaTags', siteData)) {
                tagGetters.push(this._getTwitterMetaTags);
            }

            return _.flatMap(tagGetters, function (getterFn) {
                return getterFn(metaTags);
            });
        },

        _getRobotsIndexMetaData(metaTags) {
            if (metaTags && metaTags.robotIndex) {
                return {robots: metaTags.robotIndex};
            }
        },

        _getRobotsIndexMetaTag(metaTags) {
            if (metaTags && metaTags.robotIndex) {
                return `<meta name="robots" content="${metaTags.robotIndex}">`;
            }
        },

        _getDescriptionSEOMetaData(metaTags) {
            if (metaTags && metaTags.description) {
                return {description: metaTags.description};
            }
        },

        _getDescriptionSEOMetaTag(metaTags) {
            if (metaTags && metaTags.description) {
                return [`<meta name="description" content="${metaTags.description}">`];
            }
        },

        getGoogleTagManagerScript() {
            const gtmScript = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='//www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-MDD5C4');";
            return `<script async>${gtmScript}</script>`;
        },

        _getKeywordsSEOMetaData(metaTags) {
            if (metaTags && metaTags.keywords) {
                return {keywords: metaTags.keywords};
            }
        },

        _getKeywordsSEOMetaTag(metaTags) {
            if (metaTags && metaTags.keywords) {
                return [`<meta name="keywords" content="${metaTags.keywords}">`];
            }
        },

        _getOgMetaData(metaTags) {
            return _.chain(metaTags)
                .get('ogTags')
                .keyBy('property')
                .mapValues('content')
                .value();
        },

        _getOgMetaTag(metaTags) {
            return _.map(_.get(metaTags, 'ogTags'), function (tag) {
                return `<meta property="${tag.property}" content="${tag.content}">`;
            });
        },

        _getTwitterMetaData(metaTags) {
            return _.chain(metaTags)
                .get('twitterTags')
                .keyBy('name')
                .mapValues('content')
                .value();
        },

        _getTwitterMetaTags(metaTags) {
            return _.map(_.get(metaTags, 'twitterTags'), function (tag) {
                return `<meta name="${tag.name}" content="${tag.content}">`;
            });
        }
    };

    return new WixSiteHeadRenderer();
});
