define(['lodash', 'componentsCore', 'coreUtils'], function (_, componentsCore, coreUtils) {
    'use strict';

    const stringUtils = coreUtils.stringUtils;

    /**
     * component's registry to contain "event types": compIds objects map
     * @type {Object} - supported focus events
     */
    const compsRegistry = {}; // eslint-disable-line santa/no-module-state

    /**
     * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/TouchEvent|MDN}
     *
     * @type {Array} - supported focus events
     */
    const touchEvents = [
        'touchstart',
        'touchmove',
        'touchend',
        'touchcancel'
    ];

    /**
     *
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @implements {core.SiteAspectInterface}
     * @constructor
     */
    function WindowTouchEventsAspect(aspectSiteAPI) {
        touchEvents.forEach(function (type) {
            compsRegistry[type] = {};
        }, this);

        if (aspectSiteAPI.getSiteData().isInSSR()) {
            return;
        }

        this.siteAspectAPI = aspectSiteAPI;
        register.call(this);
    }

    /**
     * creates components registry and registers to all supported touch events
     *
     * @private
     */
    function register() {
        touchEvents.forEach(function (type) {
            this.siteAspectAPI.registerToWindowTouchEvent(type, propagateTouchEvent.bind(this));
        }.bind(this));
    }

    /**
     * calls appropriate method in a listener
     *
     * @param {TouchEvent} e - native TouchEvent object
     * @private
     */
    function propagateTouchEvent(e) {
        const touchType = e.type.slice('touch'.length);
        const eventType = `WindowTouch${stringUtils.capitalize(touchType)}`;
        const registeredComps = compsRegistry[e.type];
        const methodName = `on${eventType}`;
        let listener;

        _.forEach(registeredComps, function (compId) {
            listener = this.siteAspectAPI.getComponentById(compId);

            if (!listener) {
                delete registeredComps[compId];
            } else if (listener[methodName]) {
                listener[methodName](e);
            }
        }.bind(this));
    }

    WindowTouchEventsAspect.prototype = {

        /**
         * registers to the touch event
         *
         * @param {string} type - event type
         * @param {ReactComponent} comp - component to bind to the event
         */
        registerToWindowTouchEvent(type, comp) {
            compsRegistry[type.toLowerCase()][comp.props.id] = comp.props.id;
        },

        /**
         * unregisters from the touch event
         *
         * @param {string} type - event type
         * @param {ReactComponent} comp - component to unbind from the event
         */
        unregisterFromWindowTouchEvent(type, comp) {
            delete compsRegistry[type.toLowerCase()][comp.props.id];
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('windowTouchEvents', WindowTouchEventsAspect);
});
