define(['lodash', 'coreUtils', 'componentsCore'], function (_, coreUtils, componentsCore) {
    'use strict';
    const ASPECT_NAME = 'QuickActionBarAspect';
    const supportedAppIds = _.map(coreUtils.constants.SUPPORTED_DYNAMIC_ACTIONS, 'appId');
    Object.freeze(supportedAppIds);

    function validateActions(newActions) {
        const unsupportedAppIds = _(newActions).map('appId').difference(supportedAppIds).value();
        if (_.some(unsupportedAppIds)) {
            throw new Error(`failed to add unsupported dynamic actions with app ids: "${unsupportedAppIds}"`);
        }
    }

    function addOrUpdateSingle(currentActions, newAction) {
        const existingActionWithSameId = _.find(currentActions, {appId: newAction.appId});
        if (existingActionWithSameId) {
            _.merge(existingActionWithSameId, newAction);
        } else {
            currentActions.push(newAction);
        }
    }

    function addOrUpdate(currentActions, newActions) {
        _.forEach(newActions, _.partial(addOrUpdateSingle, currentActions, _));
    }

    function QuickActionBarAspect(aspectSiteApi) {
        this.aspectSiteApi = aspectSiteApi;
        this.aspectSiteApi.updateAspectGlobalData(ASPECT_NAME, {dynamicActions: []});
        _.bindAll(this, _.functionsIn(this));
    }

    QuickActionBarAspect.prototype = {
        updateDynamicActions(newActions) {
            newActions = [].concat(newActions);
            validateActions(newActions);
            const currentActions = this.aspectSiteApi.getAspectGlobalData(ASPECT_NAME).dynamicActions;
            addOrUpdate(currentActions, newActions);
            this.aspectSiteApi.updateAspectGlobalData(ASPECT_NAME, {dynamicActions: currentActions});
            this.aspectSiteApi.forceUpdate();
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect(ASPECT_NAME, QuickActionBarAspect);
});
