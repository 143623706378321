define([
    'lodash',
    'componentsCore',
    'core/components/siteAspects/externalScriptLoaderAspect/externalScriptRegistry'
], function (
    _,
    componentsCore,
    externalScriptRegistry) {
    'use strict';


    const ASPECT_NAME = 'externalScriptLoader';

    /**
     * @constructor
     */
    function ExternalScriptLoader(siteAPI) {
        this.siteAPI = siteAPI;
    }

    ExternalScriptLoader.prototype = {

        /**
         * Load a specific script, if it hasn't been loaded before
         *
         * @param {string} name - script name
         * @param {function} callback - callback to be called after the script load
         * @param {Object} context - context to call callback function
         *
         */
        loadScript(name, callback, context) { // eslint-disable-line complexity
            let scriptData = _.get(this.siteAPI.getAspectGlobalData(ASPECT_NAME), name);
            const isNewScript = !scriptData;
            if (isNewScript) {
                const script = externalScriptRegistry.getScriptDescription(name, context);
                if (!script) {
                    return;
                }
                scriptData = {
                    script,
                    callbacks: []
                };
            }
            if (callback) {
                scriptData.callbacks.push({callback, context});
            }
            if (isNewScript) {
                if (_.isFunction(scriptData.script.actionBefore)) {
                    scriptData.script.actionBefore();
                }
            }
            if (callback || isNewScript) {
                this.siteAPI.updateAspectGlobalData(ASPECT_NAME, _.fromPairs([[name, scriptData]]));
            }
            if (isNewScript) {
                this.siteAPI.forceUpdate();
            }
        },

        /**
         * Unsubscribe from script load event. Use this method in subscriber.componentWillUnmount
         *
         * @param {string} name - script name
         * @param {function} callback - callback function that should be unsubscribed
         *
         */
        unsubscribe(name, callback) {
            const scriptData = _.get(this.siteAPI.getAspectGlobalData(ASPECT_NAME), name);
            if (scriptData) {
                _.pull(scriptData.callbacks, callback);
                this.siteAPI.updateAspectGlobalData(ASPECT_NAME, _.fromPairs([[name, scriptData]]));
            }
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect(ASPECT_NAME, ExternalScriptLoader);
    return ExternalScriptLoader;
});
