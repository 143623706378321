define([
    'lodash',
    'componentsCore'
], function (_, componentsCore) {
    'use strict';

    const allEventTypes = ['in', 'out', 'while_in'];

    /**
     * Return an object containing a boolean list of the supported event, where the occurring events are mared as true
     * @param {object} state
     * @param {object} previousState
     * @returns {{in: boolean, out: boolean, while_in: boolean}}
     */
    function getEventFlags(state, previousState) {
        return {
            in: state.in && previousState.out,
            out: state.out && previousState.in,
            while_in: state.in && previousState.in
        };
    }

    /**
     * Calculate and return information about the component position relative to the viewport
     * @param {number} compTop
     * @param {number} compHeight
     * @param {number} windowHeight
     * @param {number} scrollY
     * @param {boolean} isFixed
     * @returns {{in: boolean, inPartialAbove: boolean, inPartialBelow: boolean, inCover: boolean, inContained: boolean, out: boolean, outAbove: boolean, outBelow: boolean, percentVisible: number, percentOfViewport: number, isFixed: boolean}}
     */
    function getViewportStates(compTop, compHeight, windowHeight, scrollY, isFixed) { // eslint-disable-line complexity
        if (_.isUndefined(compTop) || _.isUndefined(compHeight)) {
            return;
        }
        const compBottom = compTop + compHeight;
        const windowBottom = scrollY + windowHeight;

        const isBottomAbove = compBottom < scrollY;
        const isBottomBelow = compBottom > windowBottom;
        const isTopAbove = compTop < scrollY;
        const isTopBelow = compTop > windowBottom;

        const isTopIn = !(isTopAbove || isTopBelow);
        const isBottomIn = !(isBottomAbove || isBottomBelow);
        const isWrapping = isTopAbove && isBottomBelow;
        const isSomeIn = isTopIn || isBottomIn || isWrapping;

        const inPartialAbove = isBottomIn && !isTopIn;
        const inPartialBelow = isTopIn && !isBottomIn;
        const isContained = isBottomIn && isTopIn;

        // TODO - Tom B. 25/12/2016 - should we do real math?
        // Intersecting rect of 2 rects r1 and r2:
        //  left = max(r1.left, r2.left)
        //  right = min(r1.right, r2.right)
        //  bottom = max(r1.bottom, r2.bottom)
        //  top = min(r1.top, r2.top)
        //

        let visibleHeight = 0;
        if (isWrapping) {
            visibleHeight = windowHeight;
        } else if (inPartialAbove) {
            visibleHeight = compBottom - scrollY;
        } else if (inPartialBelow) {
            visibleHeight = windowBottom - compTop;
        } else if (isContained) {
            visibleHeight = compHeight;
        }

        const percentVisible = visibleHeight / compHeight;
        const percentOfViewport = visibleHeight / windowHeight;

        return {
            in: isSomeIn,
            inPartialAbove,
            inPartialBelow,
            inCover: isWrapping,
            inContained: isContained,
            out: !isSomeIn,
            outAbove: isBottomAbove,
            outBelow: isTopBelow,
            percentVisible,
            percentOfViewport,
            isFixed: !!isFixed
        };
    }

    /**
     * Get component measures
     * @param {SiteData} siteData
     * @param {string} compId
     * @returns {{windowHeight: number, compAbsTop: number, compHeight: number, isFixed: boolean}}
     */
    function getMeasures(siteData, compId) {
        // TODO - Tom B. 22/12/2016 - Getting measures from measure map, should we add a DOM fallback?
        return {
            windowHeight: siteData.getScreenHeight(),
            compAbsTop: _.get(siteData, ['measureMap', 'absoluteTop', compId]),
            compHeight: _.get(siteData, ['measureMap', 'height', compId]),
            isFixed: _.get(siteData, ['measureMap', 'shownInFixed', compId])
        };
    }

    /**
     * Get current site scroll
     * @param {SiteAspectsSiteAPI} aspectSiteAPI
     * @returns {{x: number, y: number}}
     */
    function getCurrentScroll(aspectSiteAPI) {
        // TODO - Tom B. 22/12/2016 - We need to check if this method with popups actually works
        if (aspectSiteAPI.isPopupOpened()) {
            return aspectSiteAPI.getCurrentPopupScroll();
        }
        return aspectSiteAPI.getAspectGlobalData('windowScrollEvent') || {x: 0, y: 0};
    }

    /**
     * Propagate the viewport state event to all listeners
     * @param {SiteAspectsSiteAPI} aspectSiteAPI
     * @param {Array<object>} registeredListeners
     */
    function propagateEvent(aspectSiteAPI, registeredListeners) {
        const scroll = getCurrentScroll(aspectSiteAPI);
        const siteData = aspectSiteAPI.getSiteData();

        _.forEach(registeredListeners, function (listener, compId) {
            const measures = getMeasures(siteData, compId);
            const state = getViewportStates(measures.compAbsTop, measures.compHeight, measures.windowHeight, scroll.y, measures.isFixed);

            if (state) {
                const events = getEventFlags(state, listener.previousState);
                const isRegisteredEvent = _.some(listener.eventTypes, function (eventType) {
                    return events[eventType];
                });

                listener.previousState = state;
                if (isRegisteredEvent) {
                    listener.callback(state);
                }
            }
            // TODO - Tom B. 16/07/2017 - error on else?
        });
    }

    /**
     * ViewPort Aspect - returns information about the position of a component relative to the viewport window
     * @param aspectSiteAPI
     * @constructor
     */
    function ViewportAspect(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this.registeredListeners = {};
        const handleEvent = _.partial(propagateEvent, aspectSiteAPI, this.registeredListeners);

        aspectSiteAPI.registerToSiteReady(() => {
            aspectSiteAPI.registerToScroll(handleEvent);
            aspectSiteAPI.registerToResize(handleEvent);
            aspectSiteAPI.registerToOrientationChange(handleEvent);
            aspectSiteAPI.registerToDidLayout(handleEvent);
            // And handle once
            handleEvent();
        });
    }

    ViewportAspect.prototype = {
        /**
         * @type Array<string>
         */
        eventTypes: allEventTypes,

        /**
         * Register a component for viewport state listener
         * @param {string} compId
         * @param {function} callback
         * @param {['in', 'out', 'while_in']} [eventTypes]
         */
        register(compId, callback, eventTypes) {
            this.registeredListeners[compId] = {
                callback,
                eventTypes: eventTypes || allEventTypes,
                previousState: {
                    out: true,
                    in: false
                }
            };
        },

        /**
         * Unregistered a component form viewport state
         * @param compId
         */
        unregister(compId) {
            delete this.registeredListeners[compId];
        },

        /**
         * Get the current viewport state of a component
         * @param compId
         * @returns {{in: boolean, inPartialAbove: boolean, inPartialBelow: boolean, inCover: boolean, inContained: boolean, out: boolean, outAbove: boolean, outBelow: boolean, percentVisible: number, percentOfViewport: number, isFixed: boolean}}
         */
        get(compId) {
            const scroll = getCurrentScroll(this._aspectSiteAPI);
            const siteData = this._aspectSiteAPI.getSiteData();
            const measures = getMeasures(siteData, compId);

            return getViewportStates(measures.compAbsTop, measures.compHeight, measures.windowHeight, scroll.y, measures.isFixed);
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('viewportChange', ViewportAspect);

    return ViewportAspect;
});
