define([
    'componentsCore',
    'core/components/siteAspects/BehaviorsAspect',
    'core/components/siteAspects/animationsAspect',
    'core/components/siteAspects/DesignDataChangeAspect',

    'core/components/siteAspects/parentFrameAspect',
    'core/core/pageItemAspect',
    'core/core/nonPageItemZoomAspect',
    'core/components/siteAspects/externalScriptLoaderAspect',
    'core/siteRender/SiteMembersAspect',
    'core/components/siteAspects/scrollableAspect',
    'core/components/siteAspects/welcomeScreenAspect',
    'core/components/siteAspects/windowFocusEventsAspect',
    'core/components/siteAspects/windowScrollEventAspect',
    'core/components/siteAspects/windowResizeEventAspect',
    'core/components/siteAspects/windowClickEventAspect',
    'core/components/siteAspects/windowKeyboardEventAspect',
    'core/components/siteAspects/addComponentAspect',
    'core/components/siteAspects/AudioAspect',
    'core/components/siteAspects/quickActionBarAspect',
    'core/components/siteAspects/actionsAspect',
    'core/components/siteAspects/DynamicClientSpecMapAspect',
    'core/components/siteAspects/VerticalAnchorsMenuAspect',
    'core/components/siteAspects/SiteVisibilityAspect',
    'core/components/siteAspects/ViewportAspect',
    'core/components/siteAspects/MediaAspect',
    'core/components/siteAspects/InlinePopupAspect',

    'core/components/siteAspects/touchEventsAspect',
    'core/components/siteAspects/anchorLinkChangeEventAspect',
    'core/components/siteAspects/siteScrollingBlockerAspect',
    'core/components/siteAspects/mouseWheelOverrideAspect',
    'core/components/siteAspects/svSessionChangeEventAspect',
    'core/components/siteAspects/htmlPostMessageAspect',
    'core/components/siteAspects/siteMetadataChangeAspect',
    'core/components/siteAspects/popupPageAspect',
    'core/components/siteAspects/visualFocusAspect',
    'core/components/siteAspects/codeEmbedsAspect'
],
function (componentsCore, BehaviorsAspect, AnimationsAspect, DesignDataChangeAspect) {
    'use strict';

    componentsCore.siteAspectsRegistry.registerSiteAspect('behaviorsAspect', BehaviorsAspect);
    componentsCore.siteAspectsRegistry.registerSiteAspect('animationsAspect', AnimationsAspect);
    componentsCore.siteAspectsRegistry.registerSiteAspect('designDataChangeAspect', DesignDataChangeAspect);
});
