define(['lodash', 'coreUtils', 'compUtils'], function (_, coreUtils, compUtils) {
    'use strict';

    const compType = 'wysiwyg.viewer.components.SiteBackground';
    const structure = coreUtils.structuresDescription.getSiteBackgroundStructure();

    Object.freeze(structure);

    return {
        getWixBgStructure() {
            return structure;
        },

        getWixBgComponent(mobxObserverWrapperProps) {
            const props = {
                mobxObserverWrapperProps,
                structure,
                rootId: 'masterPage',
                key: structure.id,
                id: structure.id
            };

            const wixBgConstructor = compUtils.compFactory.getCompClass(compType, false, mobxObserverWrapperProps.siteAPI.getSiteData().compFactoryRuntimeState);
            return wixBgConstructor(props);
        }
    };
});
