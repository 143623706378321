define(['lodash', 'coreUtils', 'componentsCore'],
    function (_, coreUtils, componentsCore) {
        'use strict';

        /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function AnchorLinkChangeEventAspect(aspectSiteAPI) {
            /** @type {core.SiteAspectsSiteAPI} */
            this._aspectSiteAPI = aspectSiteAPI;
            this._registeredCompCallbacks = {};

            aspectSiteAPI.registerToScroll(this.onScroll.bind(this));
            aspectSiteAPI.registerToRenderedRootsChange(this.onRenderedRootsChange.bind(this));
        }

        function getAnchorComp(siteData, anchorId, pageId) {
            pageId = pageId || siteData.getPrimaryPageId();
            const anchors = coreUtils.scrollAnchors.getPageAnchors(siteData, pageId);
            const sortedAnchors = coreUtils.scrollAnchors.getSortedAnchorsByY(siteData, anchors);
            return _.find(sortedAnchors, {id: anchorId.replace('#', '')});
        }

        function setAnchor(newActiveAnchor) {
            const currActiveAnchor = this._aspectSiteAPI.getAspectGlobalData('anchorChangeEvent');
            const currActiveAnchorCompId = _.get(currActiveAnchor, 'activeAnchorComp.id');
            const newActiveAnchorCompId = _.get(newActiveAnchor, 'activeAnchorComp.id');

            if (newActiveAnchorCompId && newActiveAnchorCompId !== currActiveAnchorCompId) {
                this._aspectSiteAPI.updateAspectGlobalData('anchorChangeEvent', newActiveAnchor);
                this.propagateAnchorChangeEvent(newActiveAnchor);
            }
        }

        function setSelectedAnchor(siteData, anchorId, pageId) {
            const anchorComp = anchorId ? getAnchorComp(siteData, anchorId, pageId) : anchorId;
            if (anchorComp) {
                const activeAnchor = {
                    activeAnchorComp: anchorComp
                };
                setAnchor.call(this, activeAnchor);
            }
        }

        function setActiveAnchorByScroll(scrollPos) {
            const siteData = this._aspectSiteAPI.getSiteData();
            const isPopupOpened = siteData.isPopupOpened();
            if (!scrollPos || isPopupOpened || _.isUndefined(scrollPos.y)) {
                return;
            }
            const newActiveAnchor = coreUtils.scrollAnchors.getActiveAnchor(siteData, scrollPos.y);
            setAnchor.call(this, newActiveAnchor);
        }

        AnchorLinkChangeEventAspect.prototype = {

            registerToAnchorChange(comp) {
                if (comp.onAnchorChange) {
                    this._registeredCompCallbacks[comp.props.id] = comp.onAnchorChange;
                }
            },

            unregisterToAnchorChange(comp) {
                delete this._registeredCompCallbacks[comp.props.id];
            },

            onScroll(scrollPos) {
                if (_.isEmpty(this._registeredCompCallbacks)) {
                    return;
                }
                setActiveAnchorByScroll.call(this, scrollPos);
            },

            onRenderedRootsChange(addedRoots) {
                if (_.isEmpty(addedRoots)) {
                    const windowScrollAspect = this._aspectSiteAPI.getSiteAspect('windowScrollEvent');
                    const scrollPos = windowScrollAspect.getScrollPosition();
                    setActiveAnchorByScroll.call(this, scrollPos);
                    return;
                }

                const currentPageId = this._aspectSiteAPI.getSiteData().getPrimaryPageId();
                const activeAnchor = {
                    activeAnchorComp: coreUtils.scrollAnchors.getPageTopAnchor(currentPageId)
                };
                setAnchor.call(this, activeAnchor);
            },

            setSelectedAnchorAsync(siteData, anchorId, pageId, timeout) {
                if (anchorId) {
                    anchorId = anchorId.replace('#', '');
                    setTimeout(setSelectedAnchor.bind(this, siteData, anchorId, pageId), timeout);
                }
            },


            propagateAnchorChangeEvent(activeAnchor) {
                _.forEach(this._registeredCompCallbacks, function (callback) {
                    callback(activeAnchor);
                });
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('anchorChangeEvent', AnchorLinkChangeEventAspect);
        return AnchorLinkChangeEventAspect;
    });
