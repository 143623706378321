define([
    'lodash',
    'coreUtils'
], function (_, coreUtils) {
    'use strict';

    /** @type core.core.dataRequirementsChecker */

    function checkSvgRequirement(siteData, compInfo) {
        return _.compact([
            coreUtils.svg.createSvgFetchRequest(
                siteData.serviceTopology.mediaRootUrl,
                siteData,
                _.get(compInfo, ['data', 'svgId'])
            )
        ]);
    }



    return {
        'wixui.RatingsDisplay': checkSvgRequirement,
        'wixui.RatingsInput': checkSvgRequirement,
        'wixui.StylableButton': checkSvgRequirement
    };
});
