define([
    'lodash',
    'utils',
    'core/components/actionsAspectActions/baseAction'
], function (_, utils, baseAction) {
    'use strict';


    const triggerTypes = utils.triggerTypesConsts;
    const VIEW_PORT_ENTER = 'viewportEnter';
    const VIEW_PORT_LEAVE = 'viewportLeave';

    /**
     * Constructor for ScreenIn action, starts disabled.
     * @param aspectSiteAPI
     * @constructor
     */
    function viewportEventAction() {
        baseAction.apply(this, arguments);
        this._componentsLastVisableState = {};
        this._registeredActions = {};
        this._isEnabled = true;
        this._currentPageId = null;
    }

    viewportEventAction.prototype = _.create(baseAction.prototype, {
        constructor: viewportEventAction,
        ACTION_TRIGGERS: [triggerTypes.ACTIONS_ADDED_LAYOUTED, triggerTypes.ACTIONS_REMOVED, triggerTypes.DID_LAYOUT, triggerTypes.PAGE_CHANGED],
        ACTION_NAME: 'viewport',
        ACTIONS_SUPPORTED: ['viewportEnter', 'viewportLeave'],
        shouldEnable: _.constant(true),

        /**
         *
         * @param compId
         * @returns {{componentHeight: *, componentTop: *, offsetPosition: number}}
         */
        isComponentHiddenOrCollapsed(compId) {
            const measureMap = this._siteData.measureMap || {};
            const isHidden = measureMap.isHidden[compId];
            const isCollapsed = measureMap.collapsed[compId];
            return isHidden || isCollapsed;
        },
        triggerViewPortEventIfNeeded(compId, compActions) {
            const viewportChangeAspect = this._aspectSiteAPI.getSiteAspect('viewportChange');
            const state = viewportChangeAspect.get(compId);
            if (!state || !state.in) {
                return;
            }
            const isCompHidden = this._componentsLastVisableState[compId];
            if (!isCompHidden) {
                return;
            }
            const viewportLeaveAction = _.find(compActions, {name: VIEW_PORT_LEAVE});
            if (!viewportLeaveAction) {
                return;
            }
            const behaviorsAspect = this._aspectSiteAPI.getSiteAspect('behaviorsAspect');
            behaviorsAspect.handleAction(viewportLeaveAction);
        },
        compVisibilityChange(compId) {
            if (!this._componentsLastVisableState[compId]) {
                const compActions = _(this._currentActions).filter({sourceId: compId}).values().value();
                _.forEach(compActions, this.registerAction);
            }
            const currentRegisteredActions = _.values(this._registeredActions[compId]);
            this.triggerViewPortEventIfNeeded(compId, currentRegisteredActions);
            if (this._componentsLastVisableState[compId]) {
                _.forEach(currentRegisteredActions, this.unRegisterAction);
            }
        },
        checkIfCompVisibilityChanged(compId) {
            let prevVisibilityState;
            if (!_.isNil(this._componentsLastVisableState[compId])) {
                prevVisibilityState = this._componentsLastVisableState[compId];
            }
            this._componentsLastVisableState[compId] = this.isComponentHiddenOrCollapsed(compId);
            if (!_.isNil(prevVisibilityState) && prevVisibilityState !== this._componentsLastVisableState[compId]) {
                this.compVisibilityChange(compId, prevVisibilityState, this._componentsLastVisableState[compId]);
            }
        },
        registerAction(addedAction) {
            const compId = addedAction.sourceId;
            const name = addedAction.name;
            let alreadyRegistered = true;
            if (!this._registeredActions[compId]) {
                alreadyRegistered = false;
                this._registeredActions[compId] = {};
            }
            if (this._registeredActions[compId][name]) {
                return;
            }
            this._registeredActions[compId][name] = addedAction;
            if (alreadyRegistered) {
                return;
            }
            const viewportChangeAspect = this._aspectSiteAPI.getSiteAspect('viewportChange');
            viewportChangeAspect.register(compId, function (viewportStates) {
                const behaviorsAspect = this._aspectSiteAPI.getSiteAspect('behaviorsAspect');
                if (viewportStates.in) {
                    const viewPortEnter = _.get(this._registeredActions[compId], VIEW_PORT_ENTER);
                    if (viewPortEnter) {
                        behaviorsAspect.handleAction(viewPortEnter);
                    }
                }
                if (viewportStates.out) {
                    const viewPortLeave = _.get(this._registeredActions[compId], VIEW_PORT_LEAVE);
                    if (viewPortLeave) {
                        behaviorsAspect.handleAction(viewPortLeave);
                    }
                }
            }.bind(this), ['in', 'out']);
        },
        unRegisterAction(action) {
            const compId = action.sourceId;
            const name = action.name;
            if (!_.isEmpty(this._registeredActions[compId])) {
                delete this._registeredActions[compId][name];
            }
            if (_.isEmpty(this._registeredActions[compId])) {
                delete this._registeredActions[compId];
                const viewportChangeAspect = this._aspectSiteAPI.getSiteAspect('viewportChange');
                viewportChangeAspect.unregister(compId);
            }
        },
        handleActionTrigger(triggerType) {
            const compIds = _(this._currentActions).map('sourceId').uniq().value();

            switch (triggerType) {
                case triggerTypes.DID_LAYOUT:
                    _.forEach(compIds, this.checkIfCompVisibilityChanged);
                    break;
                case triggerTypes.PAGE_CHANGED:
                    const focusedPageId = this._siteData.getFocusedRootId();
                    if (focusedPageId !== this._currentPageId) {
                        this._currentPageId = focusedPageId;
                        this.resetActionState();
                    }
                    break;
                case triggerTypes.ACTIONS_ADDED_LAYOUTED:
                    _.forEach(compIds, this.checkIfCompVisibilityChanged);
                    _.forEach(this._currentActions, function (action) {
                        if (!this._componentsLastVisableState[action.sourceId]) {
                            this.registerAction(action);
                        }
                    }.bind(this));

                    break;
            }
        },
        resetActionState() {
            _.forEach(this._componentsLastVisableState, function (val, compId) {
                if (!this._aspectSiteAPI.getComponentByPageAndCompId('masterPage', compId)) {
                    delete this._componentsLastVisableState[compId];
                }
            }.bind(this));
            _.forEach(this._registeredActions, function (val, compId) {
                if (!this._aspectSiteAPI.getComponentByPageAndCompId('masterPage', compId)) {
                    delete this._registeredActions[compId];
                }
            }.bind(this));
        }
    });

    /**
     * @exports viewportEventAction
     */
    return viewportEventAction;
});
