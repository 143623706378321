define(['lodash', 'warmupUtils', 'coreUtils', 'utils', 'experiment', 'core/bi/errors'], function (_, warmupUtils, coreUtils, utils, experiment, errors) {
    'use strict';

    const balataConsts = coreUtils.mediaConsts.balataConsts;
    const triggerTypes = utils.triggerTypesConsts;

    const elementPathsByBehavior = {
        BackgroundParallax: [balataConsts.BALATA, balataConsts.MEDIA],
        BackgroundReveal: [balataConsts.BALATA, balataConsts.MEDIA],
        BackgroundZoom: [balataConsts.BALATA, balataConsts.MEDIA],
        BackgroundFadeIn: [balataConsts.BALATA],
        BackgroundBlurIn: [balataConsts.BALATA, balataConsts.MEDIA],
        SiteBackgroundParallax: []
    };

    Object.freeze(elementPathsByBehavior);

    function getScrollPosition(triggerType, scrollPosition) {
        if (triggerType === triggerTypes.SCROLL) {
            return scrollPosition;
        }

        const windowScrollAspect = this._aspectSiteAPI.getSiteAspect('windowScrollEvent');
        return windowScrollAspect && windowScrollAspect.getScrollPosition ? windowScrollAspect.getScrollPosition() : this._aspectSiteAPI.getSiteScroll();
    }

    /**
     * Constructor for action, starts disabled.
     * @param aspectSiteAPI
     * @constructor
     */
    function BgScrubAction(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this._siteData = aspectSiteAPI.getSiteData();
        this.animations = this._siteData.animations;
        this._behaviors = [];
        this._isEnabled = false;
        this._animateOnNextTick = false;
        /** @type {Array<{compId: string, parentId:string, sequenceId:string}>} */
        this._liveSequenceIds = [];
        this._currentPageBehaviors = [];
        this._behaviorsUpdated = false;
        this._viewportHeightUpdated = false;
        this.didFireInitialResizeEvent = false;
    }

    BgScrubAction.prototype = _.create(Object.prototype, {
        constructor: BgScrubAction,
        ACTION_TRIGGERS: [
            triggerTypes.PAGE_CHANGED,
            triggerTypes.PAGE_RELOADED,
            triggerTypes.TRANSITION_ENDED,
            triggerTypes.SCROLL,
            triggerTypes.RESIZE
        ],
        ACTION_NAME: 'bgScrub',
        _currentScroll: {x: 0, y: 0},
        _currentPopupScroll: {x: 0, y: 0},

        /**
         * If this returns false, we shouldn't enable this action.
         * @returns {boolean}
         */
        shouldEnable() {
            const isBrowser = typeof window !== 'undefined';
            const isTablet = this._siteData.isTabletDevice();
            const isMobile = this._siteData.isMobileDevice();
            const isMobileView = this._siteData.isMobileView();

            return isBrowser && !isTablet && !isMobile && !isMobileView;
        },

        /**
         * Enable ScreenIn Action
         * - Register current page behaviors
         * - hide all elements with 'hideOnStart' on their animation
         * - Start querying if animation should run on each tick
         */
        enableAction() {
            if (this._isEnabled) {
                return;
            }

            this.initiateBehaviors();

            this._tickerCallback = this._executeActionOnTick.bind(this);
            this.animations.addTickerEvent(this._tickerCallback);

            this._isEnabled = true;
        },

        /**
         * Disable ScreenIn Action
         * - Stop the ticker listener
         * - Stop and clear all running sequences
         * - un-hide all hidden elements  with 'hideOnStart' on their animation
         * - reset playedOnce list and lastVisitedPage state
         */
        disableAction() {
            if (!this._isEnabled) {
                return;
            }

            this._animateOnNextTick = false;

            this.animations.removeTickerEvent(this._tickerCallback);
            this._tickerCallback = null;

            this.stopAndClearAnimations();
            this._currentPageBehaviors = [];
            this._isEnabled = false;
        },

        isEnabled() {
            return this._isEnabled;
        },

        /**
         * Execute the action
         */
        executeAction() {
            if (_.isEmpty(this._currentPageBehaviors)) {
                return;
            }
            this.setAnimationsProgress();
        },

        setSequenceProgress(entry, scrollPercentage, parent) {
            const sequence = parent.getSequence(entry.sequenceId);
            if (sequence) {
                sequence.progress(scrollPercentage);
            } else {
                warmupUtils.loggingUtils.logger.reportBI(this._siteData, errors.NO_SEQUENCE_TO_RUN_ANIMATIONS_ON, {
                    sequenceId: entry.sequenceId
                });
            }
        },

        /**
         * This is the main function of this action:
         * Runs through all existing animations and changes their progress
         */
        setAnimationsProgress() {
            const currentScroll = Math.max(this._currentScroll.y, 0);

            _.forEach(this._liveSequenceIds, function (entry) {
                if (this.isComponentInViewport(entry.compId, entry.parentId)) {
                    const currentTravelPosition = this._viewportHeight + currentScroll - entry.componentTop;
                    const progress = currentTravelPosition / entry.maxTravel;

                    this.setSequenceProgress(entry, progress, this.getComponentParent(entry.parentId));
                }
            }.bind(this));
        },

        /**
         *
         * @param compId
         * @returns {{componentHeight: *, componentTop: *, offsetPosition: number}}
         */
        getComponentMeasure(compId) {
            const measureMap = this._siteData.measureMap || {};
            const componentHeight = _.get(measureMap, ['height', compId], 0);
            const componentTop = _.get(measureMap, ['absoluteTop', compId], 0);
            return {
                height: componentHeight,
                top: componentTop
            };
        },

        /**
         * stores the siteHeight and the viewport height
         */
        setSiteMeasure() {
            //var measureMap = this._siteData.measureMap || {};
            this._viewportHeight = this._siteData.getScreenHeight(); //_.get(measureMap, ['height', 'screen'], 0);
            this._siteHeight = this.getSiteHeight() - this._siteData.getWixTopAdHeight();
        },


        /**
         * Collect all behaviors for this action and initiate hiding components that their animations demands hiding
         */
        initiateBehaviors() {
            this._currentPageBehaviors = this.getCurrentPageBehaviors(this._behaviors, this._siteData.getPrimaryPageId());
        },

        /**
         * @override
         * Handle siteBackground as a page
         *
         * @param {String} parentId
         * @returns {ReactCompositeComponent}
         */
        getComponentParent(parentId) {
            let parent;
            if (parentId === 'masterPage') {
                parent = this._aspectSiteAPI.getMasterPage();
            } else if (parentId === 'siteBackground') {
                parent = this._aspectSiteAPI.getComponentById('SITE_BACKGROUND');
            } else {
                parent = this._aspectSiteAPI.getCurrentPage();
            }
            return parent;
        },


        /**
         * Test if a component is in the viewport
         * "In viewport" mans that either the top position of the component is in screen bounds,
         * or bottom of component is in screen bounds
         * or bottom is under the screen and top is above the screen, meaning the component "wraps" the viewport
         * @param {string} compId
         * @returns {boolean}
         */
        isComponentInViewport(compId, parentId) {
            if (coreUtils.viewportUtils.isAlwaysInViewport(this._aspectSiteAPI, parentId)) {
                return true;
            }
            let currScroll = this._currentScroll;

            const popupId = this._aspectSiteAPI.getCurrentPopupId();
            if (popupId && popupId === this._aspectSiteAPI.getRootOfComponentId(compId)) {
                currScroll = this._currentPopupScroll;
            }

            return coreUtils.viewportUtils.isInViewport(this._aspectSiteAPI, currScroll, compId);
        },

        /**
         * Return some parameters that are specific to this action:
         * suppressReactRendering = false: Don't tell react to stop updaing the component while animation is running
         * forgetSequenceOnComplete = false: Don't remove the animation from the running animations queue when it reaches its end.
         * paused = true: Start the animations paused so they can be scrubbed
         * @returns {{suppressReactRendering: boolean, forgetSequenceOnComplete: boolean, paused: boolean}}
         */
        getSequenceParams() {
            return {
                suppressReactRendering: false,
                forgetSequenceOnComplete: false,
                paused: true
            };
        },

        /**
         * @override
         * This override will also looks for children of siteBackground
         *
         * Return a filtered list of actions of this page and masterPage only, indexed by targetId
         * @param {Array<ParsedBehavior>} behaviors
         * @param {string} pageId
         * @returns {object}
         */
        getCurrentPageBehaviors(behaviors, pageId) {
            return _.filter(behaviors, function (behavior) {
                return behavior.action === this.ACTION_NAME &&
                (behavior.pageId === pageId || behavior.pageId === 'masterPage' || behavior.pageId === 'siteBackground');
            }.bind(this));
        },

        /**
         * Create the parallax sequences
         */
        createSequences() {
            let shouldDisableSmoothScrolling = false;
            this._liveSequenceIds = _.map(this._currentPageBehaviors, function (behavior) {
                const isSiteBackground = behavior.pageId === 'siteBackground';
                const parent = this.getComponentParent(behavior.pageId);
                const sequence = parent.sequence();

                const componentMeasure = this.getComponentMeasure(behavior.targetId);

                const properties = this.animations.getProperties(behavior.name);
                const getMaxTravel = properties.getMaxTravel || _.noop;
                const maxTravel = getMaxTravel(componentMeasure, this._viewportHeight, this._siteHeight) || this._viewportHeight;

                const params = behavior.params || {};
                params.browserFlags = this._siteData.browserFlags();
                params.componentTop = componentMeasure.top;
                params.componentHeight = componentMeasure.height;
                params.viewPortHeight = this._viewportHeight;

                const mediaPath = [[behavior.targetId].concat(elementPathsByBehavior[behavior.name])];
                sequence.add(mediaPath, behavior.name, behavior.duration, behavior.delay, params);

                shouldDisableSmoothScrolling = properties.shouldDisableSmoothScrolling || shouldDisableSmoothScrolling;

                const sequenceId = sequence.execute(this.getSequenceParams());

                return {
                    path: mediaPath,
                    compId: behavior.targetId,
                    parentId: behavior.pageId,
                    behaviorName: behavior.name,
                    componentTop: isSiteBackground ? this._viewportHeight : componentMeasure.top,
                    componentHeight: componentMeasure.height,
                    maxTravel,
                    sequenceId
                };
            }.bind(this));
            if (experiment.isOpen('DisableSmoothScrolling', this._siteData)) {
                if (shouldDisableSmoothScrolling && this._siteData.browserFlags().shouldDisableSmoothScrolling) {
                    const mouseWheelAspect = this._aspectSiteAPI.getSiteAspect('mouseWheelOverride');
                    mouseWheelAspect.overrideMouseWheel();
                }
            }
        },
        /**
         * @override
         * Added the ability to refresh the animations list if the behaviors list was changed
         * Also passing 0 to clear animations so it will seek to beginning of animation and not end
         *
         * Handle triggers passed from actionsAspect
         * @param {ActionsTriggerTypes} triggerType
         */
        handleTrigger(triggerType, scrollPosition) { // eslint-disable-line complexity
            if (!this._isEnabled) {
                return;
            }

            this._currentScroll = getScrollPosition.call(this, triggerType, scrollPosition);
            this._currentPopupScroll = this._aspectSiteAPI.getCurrentPopupScroll();

            switch (triggerType) {
                case triggerTypes.PAGE_CHANGED:
                case triggerTypes.TRANSITION_ENDED:
                    this.clearAllAndReload(0);
                    this._animateOnNextTick = true;
                    this._behaviorsUpdated = false;
                    break;
                case triggerTypes.PAGE_RELOADED:
                case triggerTypes.SCROLL:
                case triggerTypes.RESIZE:
                    if (this._behaviorsUpdated || this._viewportHeightUpdated) {
                        this.clearAllAndReload(0);
                        this._behaviorsUpdated = false;
                        this._viewportHeightUpdated = false;
                    }
                    this._animateOnNextTick = true;
                    break;
            }
        },

        /**
         * poll if a window size has changed and force a synthetic resize event if it had
         * @private
         */
        _shouldForceResizeEvent() {
            if (this._siteData.measureMap && (!this.didFireInitialResizeEvent || this._siteData.getScreenHeight() !== this._viewportHeight)) {
                this.didFireInitialResizeEvent = true;
                this._viewportHeight = this._siteData.getScreenHeight();
                this._viewportHeightUpdated = true;
                this.handleTrigger(triggerTypes.RESIZE);
            }
        },

        /**
         * Execute the action only if it is scheduled to run on next tick
         * @private
         */
        _executeActionOnTick() {
            this._shouldForceResizeEvent();
            if (!this._animateOnNextTick) {
                return;
            }

            this.executeAction();
            this._animateOnNextTick = false;
        },

        /**
         * A shorthand to refresh the entire action state
         * @param {number} [seek]
         */
        clearAllAndReload(seek) {
            this.stopAndClearAnimations(seek);
            this.initiateBehaviors();
            this.setSiteMeasure();
            this.createSequences();
        },

        /**
         * Stop and clear all sequences previously created by this action
         * @param {number} [seek]
         */
        stopAndClearAnimations(seek) {
            const mouseWheelAspect = this._aspectSiteAPI.getSiteAspect('mouseWheelOverride');
            if (mouseWheelAspect && mouseWheelAspect.releaseMouseWheel) {
                mouseWheelAspect.releaseMouseWheel();
            }
            seek = _.isNumber(seek) ? seek : 1;
            _.forEach(this._liveSequenceIds, entry => {
                const parent = this.getComponentParent(entry.parentId);
                if (parent && parent.getSequence(entry.sequenceId)) {
                    parent.stopSequence(entry.sequenceId, seek);
                }
            });
            this._liveSequenceIds = [];
        },

        /**
         * @override
         * Update animations on every behavior update,
         * so the action could also work while in editor mode.
         *
         * Handle updates of behaviors list by actionsAspect
         * @param {Array<ParsedBehavior>} behaviors
         */
        handleBehaviorsUpdate(behaviors) {
            this._behaviors = behaviors;
            this._behaviorsUpdated = true;
        },

        getSiteHeight() {
            const siteAPI = this._aspectSiteAPI;
            const measureMap = siteAPI.getSiteData().measureMap || {};
            const extraSiteHeight = this._aspectSiteAPI.getRenderFlag('extraSiteHeight');
            const siteHeight = _.get(measureMap, 'height.masterPage', 0) + (measureMap.siteMarginBottom || 0) + (extraSiteHeight || 0);
            return siteHeight;
        }
    });

    return BgScrubAction;
});
