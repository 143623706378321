define([
    'santa-components',
    'lodash',
    'react',
    'reactDOM',
    'prop-types',
    'create-react-class',
    'mobx',
    'mobx-react',
    'core/siteRender/aspectComponentsPortal',
    'core/components/siteAspects/externalScriptLoaderAspect/externalScriptContainer',
    'core/components/siteAspects/externalScriptLoaderAspect/ecomCheckoutAspectContainer'
], function (
    santaComponents,
    _,
    React,
    ReactDOM,
    PropTypes,
    createReactClass,
    mobx,
    mobxReact,
    aspectComponentsPortalClass,
    externalScriptContainer,
    ecomCheckoutAspectContainer
) {
    'use strict';

    const aspectComponentsPortal = React.createFactory(aspectComponentsPortalClass);

    const aspectContainer = mobxReact.observer(createReactClass({//eslint-disable-line react/display-name
        propTypes: {
            siteAPI: PropTypes.shape({
                getSiteAspect: PropTypes.func.isRequired
            }).isRequired
        },

        render() {
            // TODO: remove this and read the aspect name and the method name from the renderer json
            const fontsLoaderAspect = this.props.siteAPI.getSiteAspect('fontsLoaderAspect');
            const children = fontsLoaderAspect && fontsLoaderAspect.getReactComponents();

            return santaComponents.utils.createReactElement('div', null, children);
        }
    }));

    const popoverLayer = createReactClass({
        propTypes: {
            siteAPI: PropTypes.shape({
                setPopoversLayer: PropTypes.func.isRequired
            }).isRequired
        },
        render() {
            return santaComponents.utils.createReactElement('div', {id: 'popoverLayer'});
        },
        componentDidMount() {
            this.props.siteAPI.setPopoversLayer(ReactDOM.findDOMNode(this));
        }
    });

    return mobxReact.observer(createReactClass({
        displayName: 'siteAspectsDomContainer',

        propTypes: {
            siteAPI: PropTypes.shape({
                setComponentRenderStart: PropTypes.func.isRequired,
                setComponentRenderEnd: PropTypes.func.isRequired,
                cancelReLayoutPending: PropTypes.func.isRequired
            }).isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth,
            requestRelayout: PropTypes.func,
            getAspectComponentDescriptions: PropTypes.func
        },

        componentWillMount() {
            this.computedAspectComponentsDesc = mobx.computed(function () {
                return this.props.getAspectComponentDescriptions();
            }, {name: 'computedAspectComponentsDesc', context: this});

            this.props.siteAPI.setComponentRenderStart(this.constructor.displayName);
        },

        componentWillUpdate() {
            this.props.siteAPI.setComponentRenderStart(this.constructor.displayName);
        },

        componentDidMount() {
            this.props.siteAPI.setComponentRenderEnd(this.constructor.displayName);
        },

        componentDidUpdate() {
            this.props.siteAPI.setComponentRenderEnd(this.constructor.displayName);
        },

        componentWillUnmount() {
            this.props.siteAPI.cancelReLayoutPending(this.constructor.displayName);
        },

        render() {
            const containerStyle = {};
            if (this.props.isMobileView) {
                _.assign(containerStyle, {width: this.props.siteWidth});
            }

            const children = [
                aspectComponentsPortal({
                    key: 'aspectPortal',
                    ref: 'aspectPortal',
                    siteAPI: this.props.siteAPI,
                    requestRelayout: this.props.requestRelayout,
                    compDescriptions: this.computedAspectComponentsDesc.get()
                }),
                santaComponents.utils.createReactElement(externalScriptContainer, {siteAPI: this.props.siteAPI, key: 'externalScriptContainer'}),
                santaComponents.utils.createReactElement(ecomCheckoutAspectContainer, {siteAPI: this.props.siteAPI, key: 'ecomCheckoutAspectContrainer'}),
                santaComponents.utils.createReactElement(aspectContainer, {key: 'aspectContainer', siteAPI: this.props.siteAPI}),
                santaComponents.utils.createReactElement(popoverLayer, {key: 'popoverLayerContainer', siteAPI: this.props.siteAPI})
            ];

            return santaComponents.utils.createReactElement('div', {
                className: 'siteAspectsContainer',
                style: containerStyle
            }, children);
        }
    }));
});
