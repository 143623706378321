define([
    'lodash',
    'create-react-class',
    'mobx',
    'mobx-react',
    'coreUtils',
    'santa-components',
    'compUtils',
    'experiment'
], function (_, createReactClass, mobx, mobxReact, coreUtils, santaComponents, compUtils, experiment) {
    'use strict';

    const createReactElement = santaComponents.utils.createReactElement;

    return _.memoize(siteAPI => mobxReact.observer(createReactClass({
        displayName: 'wixAdsRenderer',

        componentWillMount() {
            const siteData = siteAPI.getSiteData();
            siteAPI.setComponentRenderStart(coreUtils.constants.COMP_IDS.WIX_ADS);

            this.disposeReaction = mobx.reaction(function whenWixAdsShouldMeasure() {
                return {
                    isHeaderFixedPosition: coreUtils.siteDataUtils.isHeaderFixedPosition(siteData),
                    isPortrait: siteData.isPortrait,
                    isWixAdsAllowed: coreUtils.siteDataUtils.isWixAdsAllowed(siteData),
                    screenSize: siteData.getScreenSize()
                };
            }, function () {
                siteAPI.registerReLayoutPending(coreUtils.constants.COMP_IDS.WIX_ADS);
                siteAPI.reLayoutIfPending();
            }, {context: this, name: 'WixADS_measure_change'});
        },

        componentWillUnmount() {
            this.disposeReaction();
        },

        componentDidMount() {
            siteAPI.setComponentRenderEnd(coreUtils.constants.COMP_IDS.WIX_ADS);
        },

        componentWillReact() {
            siteAPI.setComponentRenderStart(coreUtils.constants.COMP_IDS.WIX_ADS);
        },

        componentWillUpdate() {
            siteAPI.setComponentRenderStart(coreUtils.constants.COMP_IDS.WIX_ADS);
        },

        componentDidUpdate() {
            siteAPI.setComponentRenderEnd(coreUtils.constants.COMP_IDS.WIX_ADS);
        },

        render() {
            const siteData = siteAPI.getSiteData();
            const isMobileView = siteData.isMobileView();
            const isPreview = siteData.viewMode === 'preview';
            const wixAdsId = coreUtils.constants.COMP_IDS.WIX_ADS;
            const isWixAdsAllowed = coreUtils.siteDataUtils.isWixAdsAllowed(siteData);
            const isHeaderFixedPosition = coreUtils.siteDataUtils.isHeaderFixedPosition(siteData);
            const language = coreUtils.wixUserApi.getLanguageFromSiteData(siteData);
            const metaSiteId = siteData.getMetaSiteId();

            if (!siteData.shouldShowWixAds()) {
                return null;
            }

            const bannerCompType = experiment.isOpen('displayWixAdsNewVersion', siteData) ?
                'wysiwyg.viewer.components.WixFreemiumBanner2' :
                'wysiwyg.viewer.components.WixFreemiumBanner';

            const bannerClass = compUtils.compFactory.getCompReactClass(bannerCompType, false, siteData.compFactoryRuntimeState);

            const translations = coreUtils.translationsLoader.getTranslationAllKeys('Wix_Ads', language);
            const isWixAdsForOneApp = experiment.isOpen('oneAppWixAds', siteData);
            const reportBI = siteAPI.reportBI;
            const overlay = _.get(siteData, 'currentUrl.query.isqa') === 'true' && _.get(siteData, 'currentUrl.query.wixAdsOverlay') === 'true';
            const bannerProps = {wixAdsId, isMobileView, isPreview, isWixAdsAllowed, metaSiteId, isHeaderFixedPosition, translations, isWixAdsForOneApp, reportBI, overlay};

            return createReactElement(bannerClass, bannerProps);
        }
    })));
});
