define([
    'lodash',
    'utils'
], function (
    _,
    utils
) {
    'use strict';

    const triggerTypes = utils.triggerTypesConsts;

    /**
     * Constructor for ScreenIn action, starts disabled.
     * @param aspectSiteAPI
     * @constructor
     */
    function BaseAction(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this._siteData = aspectSiteAPI.getSiteData();
        this.animations = this._siteData.animations;
        this._isEnabled = false;
        this._triggeredOnce = {};

        _.bindAll(this, _.functionsIn(this));
    }

    BaseAction.prototype = _.create(Object.prototype, {
        constructor: BaseAction,
        ACTION_TRIGGERS: [],
        ACTION_NAME: 'BASE_ACTION',
        ACTIONS_SUPPORTED: ['BASE_ACTION'],

        /**
         * If this returns false, we shouldn't enable this action.
         * @returns {boolean}
         */
        shouldEnable() {
            const {_siteData} = this;
            return typeof window !== 'undefined' && !_siteData.isMobileDevice() && !_siteData.isMobileView() && !_siteData.isTabletDevice();
        },

        enableAction() {
            if (this._isEnabled) {
                return;
            }
            this._triggeredOnce = {};
            this._currentActions = {};
            this._delayActionByTransitionDuration = 0;
            this._executeOnNextTick = [];
            this.resetActionState();
            this._tickerCallback = this._executeActionOnTick;
            this.animations.addTickerEvent(this._tickerCallback);
            this._isEnabled = true;
        },

        disableAction() {
            if (!this._isEnabled) {
                return;
            }

            this._executeOnNextTick = [];
            this.animations.removeTickerEvent(this._tickerCallback);
            this._tickerCallback = null;
            this._isEnabled = false;
            this._delayActionByTransitionDuration = 0;
            this.resetActionState();
        },

        isEnabled() {
            return this._isEnabled;
        },

        triggerActions(actionIds, event) {
            const behaviorsAspect = this._aspectSiteAPI.getSiteAspect('behaviorsAspect');
            const {_triggeredOnce} = this;
            _.forEach(actionIds, actionId => {
                _triggeredOnce[actionId] = true;
            });
            event = _.defaults(event || {}, {action: this.ACTION_NAME});
            return behaviorsAspect.handleActions(_(this._currentActions).pick(actionIds).values().value(), event);
        },

        /**
         * This is a quick and dirty way to sync between page transitions and screen in.
         * @param {number} duration the duration of the next page transition
         */
        registerPageTransitionDuration(duration) {
            this._delayActionByTransitionDuration = duration;
        },

        /**
         * remove when refactor is complete
         */
        handleBehaviorsUpdate: _.noop,
        executeAction: _.noop,

        /**
         * Execute the action only if it is scheduled to run on next tick
         * @private
         */
        _executeActionOnTick() {
            if (_.isEmpty(this._executeOnNextTick)) {
                return;
            }
            const callbacks = this._executeOnNextTick;
            this._executeOnNextTick = [];
            _.forEach(callbacks, function (callback) {
                callback();
            });
        },

        executeOnNextTick(callback) {
            this._executeOnNextTick = _(this._executeActionOnTick)
                .concat([callback])
                .uniq()
                .value();
        },

        handleTrigger(triggerAction) {
            const args = _.toArray(arguments);
            const {ACTIONS_SUPPORTED} = this;
            switch (triggerAction) {
                case triggerTypes.ACTIONS_ADDED_LAYOUTED:
                    args[1] = _.pickBy(args[1], action => _.includes(ACTIONS_SUPPORTED, action.name));
                    _.assign(this._currentActions, args[1]);
                    break;
                case triggerTypes.ACTIONS_REMOVED:
                    args[1] = _.pickBy(args[1], action => _.includes(ACTIONS_SUPPORTED, action.name));
                    this._currentActions = _.omit(this._currentActions, _.keys(args[1]));
                    break;
            }

            if (this._isEnabled) {
                this.handleActionTrigger.apply(this, args);
            }
        },

        handleActionTrigger() {
            throw new Error('Need to implement handleTrigger in specific actions');
        },

        resetActionState() {
            throw new Error('Need to implement resetActionState in specific actions');
        }

    });

    /**
     * @exports BaseAction
     */
    return BaseAction;
});
