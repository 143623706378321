define(['core/bi/events.json'], function (events) {
    'use strict';

    /**
     * Please ctrl/cmd + click on biEvents to see the schema :)
     * @type {Object.<String, biEvent>}
     */


    return events;
});
