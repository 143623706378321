define([
    'coreUtils',
    'core/components/behaviorHandlers/siteBehaviorHandler'
], function (
    coreUtils,
    siteBehaviorHandler
) {
    'use strict';

    coreUtils.behaviorHandlersFactory.registerHandler('site', siteBehaviorHandler);
});
