define([
    'coreUtils',
    'lodash',
    'core/core/dataRequirementsChecker',
    'core/core/data/ViewerDisplayedJsonUpdater',
    'core/core/data/apiSections/documentDataAPI',
    'core/core/data/RuntimeDal',
    'core/core/data/apiSections/AnchorsDataAPI',
    'core/core/data/apiSections/OriginalValuesAPI',
    'core/core/data/ActionQueue',
    'core/core/data/apiSections/ModesApplicationAPI'
], function (coreUtils, _, dataRequirementsChecker, ViewerDisplayedJsonUpdater, documentDataAPI, RuntimeDal, AnchorsDataAPI, OriginalValuesAPI, ActionQueue, ModesApplicationAPI) {
    'use strict';

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state
    const PLAIN_JSON_STORES_NAMES = _.keyBy(['wixapps', 'externalStyles', 'ghostStructureData']);

    function createSiteDataAPIAndDal(fullSiteData, ajaxHandler, props) {
        const fullPagesData = coreUtils.DALFactory.getFullPagesData(fullSiteData, _.pick(fullSiteData, 'pagesData'));
        const cache = coreUtils.DALFactory.getCacheInstance(fullSiteData, fullPagesData);
        const dalAndPointers = initDisplayedDalAndPointers(fullSiteData, cache);
        const sitePrivates = createPrivatesForSite(fullSiteData, dalAndPointers, cache, fullPagesData, ajaxHandler, props);
        privates.set(fullSiteData, sitePrivates);

        _.forEach(fullSiteData.getAllPossiblyRenderedRoots(), function (rootId) {
            sitePrivates.siteDataAPI.createDisplayedPage(rootId);
        });

        return _.clone(sitePrivates);
    }

    function createPrivatesForSite(fullSiteData, dalAndPointers, cache, fullPagesData, ajaxHandler, props) {
        const privatesForSite = {};
        privatesForSite.fileChangeCallback = _.noop;
        privatesForSite.displayedDal = dalAndPointers.dal;
        privatesForSite.pointers = dalAndPointers.pointers;
        privatesForSite.cache = cache;
        privatesForSite.fullPagesData = fullPagesData;
        privatesForSite.siteData = fullSiteData;
        privatesForSite.displayedDal.setByPath(['pagesData'], {});
        privatesForSite.siteDataAPI = new SiteDataAPI(privatesForSite, ajaxHandler, props && props.eventsManager);
        privatesForSite.wixCodeAppApi = props && props.wixCodeAppApi;
        return privatesForSite;
    }

    function initDisplayedJsonUpdater(sitePrivates, updateDisplayedNodesLayoutFunc) {
        const fullPagesData = sitePrivates.fullPagesData;
        const pointersCache = sitePrivates.cache;
        const displayedDal = sitePrivates.displayedDal;
        const pointers = sitePrivates.pointers;

        const fullJsonCache = pointersCache.getBoundCacheInstance(true);
        return new ViewerDisplayedJsonUpdater(fullPagesData, displayedDal, fullJsonCache, pointers, updateDisplayedNodesLayoutFunc);
    }

    function initDisplayedDalAndPointers(fullSiteData, cache) {
        return {
            dal: coreUtils.DALFactory.getInstance(fullSiteData),
            pointers: new coreUtils.pointers.DataAccessPointers(cache)
        };
    }

    function waitForDataToBeLoaded(actionQueue, store, siteData, fullPagesData, urlData, wixCodeAppApi, loadComponentsData, callback, pagesToLoad, timedOut) {
        const shouldDelay = !siteData.isInSSR() && siteData.isViewerMode() && wixCodeAppApi && wixCodeAppApi.isAppInitiated();

        function doWaitForData() {
            // eslint-disable-next-line complexity
            actionQueue.runImmediately(function () {
                /** @type {utils.Store.requestDescriptor[]} */
                if (_.size(siteData.failedRequests) && siteData.isInSSR()) {
                    callback(pagesToLoad);
                    return;
                }

                if (timedOut) {
                    callback(pagesToLoad);
                }

                const dataRequirementsCheckerMethod = loadComponentsData ? dataRequirementsChecker.getNeededRequests : dataRequirementsChecker.getRequestsForPages;

                const requests = dataRequirementsCheckerMethod(siteData, fullPagesData, urlData);
                const requestsForPage = _.filter(requests, function (req) {
                    return _.head(req.destination) === 'pagesData' && req.destination.length === 2;
                });

                pagesToLoad = (pagesToLoad || []).concat(_.map(requestsForPage, 'destination[1]'));

                if (requests.length === 0 && !timedOut) {
                    callback(pagesToLoad);
                } else {
                    store.loadBatch(requests, waitForDataToBeLoaded.bind(null, actionQueue, store, siteData, fullPagesData, urlData, wixCodeAppApi, loadComponentsData, callback, pagesToLoad));
                }
            });
        }

        if (shouldDelay) {
            setTimeout(doWaitForData, 10);
        } else {
            doWaitForData();
        }
    }

    function notifyDataLoaded(privatesForSite, fullJson, path, data) {
        setDataToFullAndDisplayedJson.call(this, privatesForSite, fullJson, path, data);
        if (_.head(path) !== 'pagesData' && privatesForSite.dataLoadedCallback) {
            privatesForSite.dataLoadedCallback(path, data);
        }
    }

    function resolveDisplayedPageCreation(pageId, privatesForSite) {
        if (pageId !== 'masterPage' && !privatesForSite.siteData.getMasterPageData()) {
            this.pagesPendingForMasterPage.push(pageId);
        } else if (pageId === 'masterPage') {
            this.updateDisplayedJsonAfterPageLoaded(pageId);
            _.forEach(this.pagesPendingForMasterPage, this.updateDisplayedJsonAfterPageLoaded.bind(this));
            this.pagesPendingForMasterPage = [];
        } else {
            this.updateDisplayedJsonAfterPageLoaded(pageId);
        }
    }

    function setDataToFullAndDisplayedJson(privatesForSite, fullJson, path, data) {
        if (isAFullJsonPath(path)) {
            // write to full JSON and create displayedJson
            _.set(fullJson, path, data);
            const pageId = path[1];
            resolveDisplayedPageCreation.call(this, pageId, privatesForSite);
        } else if (isPlainJsonStorePath(path)) {
            _.set(privatesForSite.siteData, path, data);
        } else {
            privatesForSite.displayedDal.setByPath(path, data);
        }
    }

    function isAFullJsonPath(path) {
        return _.head(path) === 'pagesData' && _.size(path) > 1;
    }

    function isPlainJsonStorePath(path) {
        const storeName = path[0];
        return !!PLAIN_JSON_STORES_NAMES[storeName];
    }

    function notifyOnDisplayedJsonUpdate(rootCompPointer) {
        const pagePointer = privates.get(this.siteData).pointers.full.components.getPageOfComponent(rootCompPointer);
        _.forEach(this._displayedJsonUpdateCallbacks, function (callback) {
            callback(pagePointer.id, rootCompPointer.id);
        });
        if (this.eventsManager) {
            this.eventsManager.emit('displayedJsonUpdated', pagePointer.id, rootCompPointer.id);
        }
    }

    function activateDefaultModesIfNeeded(sitePrivates, rootId) {
        if (!rootId) {
            return;
        }
        const fullPage = sitePrivates.fullPagesData.pagesData[rootId];
        const isMobile = sitePrivates.siteData.isMobileView();
        const pageActiveModesPointer = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
        const currentRootActiveModes = sitePrivates.displayedDal.get(pageActiveModesPointer);
        const updatedRootActiveModes = coreUtils.modesUtils.resolveCompActiveModesRecursive(fullPage.structure, currentRootActiveModes, isMobile, fullPage.data.component_properties);
        sitePrivates.displayedDal.set(pageActiveModesPointer, updatedRootActiveModes);

        const modesHaveChanged = !_.isEqual(currentRootActiveModes, updatedRootActiveModes);
        return modesHaveChanged;
    }

    function getStoreDataGetterFunc(sitePrivates) {
        const fullPagesData = sitePrivates.fullPagesData;
        const displayedDal = sitePrivates.displayedDal;
        const siteData = sitePrivates.siteData;

        return function getDataForStore(path) {
            if (isAFullJsonPath(path)) {
                return _.get(fullPagesData, path);
            }

            if (isPlainJsonStorePath(path)) {
                return _.get(siteData, path);
            }

            return displayedDal.getByPath(path);
        };
    }

    function getSOSPModePages(siteData, modeDef) {
        const sitePrivates = privates.get(siteData);
        const pagesGroupId = modeDef.settings.pagesGroupId.replace('#', '');
        const modePagesGroupPointer = sitePrivates.pointers.data.getDataItem(pagesGroupId, 'masterPage');

        return sitePrivates.displayedDal.get(modePagesGroupPointer).pages;
    }

    function getShowOnSomePagesModes(siteData) {
        const sitePrivates = privates.get(siteData);
        const masterPagePointer = sitePrivates.pointers.components.getMasterPage(siteData.getViewMode());
        const modesPointer = sitePrivates.pointers.componentStructure.getModes(masterPagePointer);

        if (!modesPointer) {
            return [];
        }
        if (modesPointer) {
            const modesDefinitions = _.get(sitePrivates.displayedDal.get(modesPointer), 'definitions', []);

            return _.filter(modesDefinitions, {type: coreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES});
        }
    }

    function SiteDataAPI(sitePrivates, ajaxHandler, eventsManager) {
        sitePrivates.displayedJsonUpdater = initDisplayedJsonUpdater(sitePrivates, this.updateDisplayedNodesLayout.bind(this));
        sitePrivates.dataLoadedCallback = null;
        this.siteData = sitePrivates.siteData;
        this.eventsManager = eventsManager;
        this.store = new coreUtils.Store(getStoreDataGetterFunc(sitePrivates), ajaxHandler);
        this.store.registerDataLoadedCallback(_.bind(notifyDataLoaded, this, sitePrivates, sitePrivates.fullPagesData));
        this.siteData.setStore(this.store);
        this._displayedJsonUpdateCallbacks = [];
        this.pagesPendingForMasterPage = [];
        this.actionQueue = new ActionQueue(this.siteData.hasDebugQueryParam() || this.siteData.isQaMode());

        // api sections
        this.document = documentDataAPI(sitePrivates);
        const runtimeStuff = RuntimeDal.createRuntimeDal(sitePrivates.siteData, this, sitePrivates.displayedDal, sitePrivates.pointers, this.eventsManager);
        sitePrivates.runtimeToDisplayed = runtimeStuff.runtimeToDisplayed;
        sitePrivates.resetRuntimeData = runtimeStuff.resetRuntime;
        sitePrivates.resetRuntimeNonDisplayedPages = runtimeStuff.resetNonDisplayedPages;
        sitePrivates.resetPage = runtimeStuff.resetPage;
        sitePrivates.getRuntimeCompsData = runtimeStuff.getAllCompsData;
        this.runtime = runtimeStuff.runtimeDal;
        this.anchors = new AnchorsDataAPI(this.siteData, sitePrivates.pointers, sitePrivates.displayedDal);
        this.originalValues = new OriginalValuesAPI(this.siteData);
        this.modes = new ModesApplicationAPI(this.siteData, this.createDisplayedPage.bind(this), this.createDisplayedNode.bind(this),
            sitePrivates.pointers, sitePrivates.displayedDal, this.actionQueue);
    }

    SiteDataAPI.prototype = {
        registerDataLoadedCallback(callback) {
            const privatesForSite = privates.get(this.siteData);
            privatesForSite.dataLoadedCallback = callback;
        },

        refreshRenderedRootsData(callback = _.noop) {
            const privatesForSite = privates.get(this.siteData);

            const rootId = this.siteData.getFocusedRootId();
            const navigationInfo = this.siteData.getExistingRootNavigationInfo(rootId);

            waitForDataToBeLoaded(this.actionQueue, this.store, privatesForSite.siteData, privatesForSite.fullPagesData, navigationInfo, privatesForSite.wixCodeAppApi, true, callback);
        },

        registerDisplayedJsonUpdateCallback(callback) {
            this._displayedJsonUpdateCallbacks.push(callback);
        },

        loadPage(urlData, callback, loadComponentsData = true, updateDisplayedJsonAfterPageLoaded = true) {
            const privatesForSite = privates.get(this.siteData);

            function invokeDataLoadedCallback(pageId) {
                if (privatesForSite.dataLoadedCallback) {
                    const fullPage = privatesForSite.fullPagesData.pagesData[pageId];
                    privatesForSite.dataLoadedCallback(['pagesData', pageId], fullPage);
                }
            }

            waitForDataToBeLoaded(this.actionQueue, this.store, privatesForSite.siteData, privatesForSite.fullPagesData, urlData, privatesForSite.wixCodeAppApi, loadComponentsData, pagesMaybeLoadedIds => {
                if (updateDisplayedJsonAfterPageLoaded && urlData.pageId && !_.includes(pagesMaybeLoadedIds, urlData.pageId)) {
                    this.updateDisplayedJsonAfterPageLoaded(urlData.pageId, urlData.isLanguageChange);
                }

                _.forEach(pagesMaybeLoadedIds, invokeDataLoadedCallback.bind(this));
                callback();
            }, []);
        },

        /**
         * this method resets the runtime data for the page
         * should be called when hiding the page
         * @param rootId
         */
        resetRuntimeOverrides(rootId, samePageNavigation) {
            const sitePrivates = privates.get(this.siteData);
            let hasRemovedAnything;
            if (samePageNavigation) {
                hasRemovedAnything = sitePrivates.resetPage(rootId);
            } else {
                hasRemovedAnything = sitePrivates.resetRuntimeNonDisplayedPages();
            }
            return hasRemovedAnything;
        },

        removeResolvedDataMapForPage(pageId) {
            if (this.siteData.resolvedDataMaps) {
                this.siteData.resolvedDataMaps.delete(pageId);
            }
        },

        updateActiveSOSPModes(pageId) {
            pageId = pageId || this.siteData.getCurrentUrlPageId();

            const sitePrivates = privates.get(this.siteData);
            const sospModes = getShowOnSomePagesModes(this.siteData);

            if (sospModes) {
                const masterPagePointer = sitePrivates.pointers.components.getMasterPage(this.siteData.getViewMode());
                const activeModes = this.modes.getPageActiveModes(coreUtils.siteConstants.MASTER_PAGE_ID);

                let modeIdToActivate, modeIdToDeactivate;
                _.forEach(sospModes, function (modeDefinition) {
                    const modePages = getSOSPModePages(this.siteData, modeDefinition);

                    if (_.includes(modePages, `#${pageId}`)) {
                        if (!activeModes[modeDefinition.modeId]) {
                            modeIdToActivate = modeDefinition.modeId;
                        }
                    } else if (activeModes[modeDefinition.modeId]) {
                        modeIdToDeactivate = modeDefinition.modeId;
                    }
                }.bind(this));

                if (modeIdToActivate && modeIdToDeactivate) {
                    this.modes.switchModes(masterPagePointer, modeIdToDeactivate, modeIdToActivate);
                } else if (modeIdToActivate) {
                    this.modes.activateMode(masterPagePointer, modeIdToActivate);
                } else if (modeIdToDeactivate) {
                    this.modes.deactivateMode(masterPagePointer, modeIdToDeactivate);
                }
            }
        },

        updateDisplayedJsonAfterPageLoaded(rootId, shouldCreateAllDisplayedPages) {
            const sitePrivates = privates.get(this.siteData);
            const pageActiveModesPointer = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
            sitePrivates.displayedDal.set(pageActiveModesPointer, {});
            shouldCreateAllDisplayedPages ? this.createDisplayedPages() : this.createDisplayedPage(rootId); // eslint-disable-line no-unused-expressions

            if (rootId === this.siteData.getCurrentUrlPageId()) {
                this.updateActiveSOSPModes(rootId);
            }
        },

        createDisplayedPage(rootId) {
            const sitePrivates = privates.get(this.siteData);
            const fullPage = sitePrivates.fullPagesData.pagesData[rootId];
            if (!fullPage) {
                const pagePointer = sitePrivates.pointers.page.getPagePointer(rootId);
                if (pagePointer && sitePrivates.displayedDal.isExist(pagePointer)) {
                    sitePrivates.displayedDal.remove(pagePointer);
                }
                return false;
            }

            const allActiveModesPointer = sitePrivates.pointers.activeModes.getAllActiveModes();
            this.actionQueue.runImmediately(function () {
                coreUtils.wSpy.log('createDisplayedPage', [rootId]);
                activateDefaultModesIfNeeded(sitePrivates, rootId);
                const activeModes = sitePrivates.displayedDal.get(allActiveModesPointer);
                sitePrivates.displayedJsonUpdater.updateDisplayedRoot(activeModes, fullPage, rootId, {rendererModel: sitePrivates.siteData.rendererModel, getQueryParams: sitePrivates.siteData.getQueryParams, renderFlags: sitePrivates.siteData.renderFlags});
                this.anchors.createPageAnchors(rootId);
                sitePrivates.runtimeToDisplayed.updateAll(sitePrivates.getRuntimeCompsData());
                //width? -> should it be before or after the creating the displayed
            }.bind(this));
            return true;
        },

        updateDisplayedNodesLayout(rootPointer) {
            if (coreUtils.layoutUtils.getLayoutMechanism(this.siteData) === coreUtils.constants.LAYOUT_MECHANISMS.MESH) {
                return;
            }

            function getCompLayouter(sitePrivates, compPointer) {
                const compTypePointer = sitePrivates.pointers.getInnerPointer(compPointer, ['componentType']);
                const compType = sitePrivates.displayedDal.get(compTypePointer);
                return coreUtils.jsonUpdaterRegistrar.getCompLayouter(compType);
            }

            const sitePrivates = privates.get(this.siteData);
            const viewMode = this.siteData.getViewMode();
            const rootsPointers = rootPointer ? [rootPointer] : _(this.siteData.getAllPossiblyRenderedRoots())
                .map(function (rootId) {
                    return sitePrivates.pointers.components.getPage(rootId, viewMode);
                })
                .compact()
                .value();

            _.forEach(rootsPointers, root => {
                const childrenLayouterComponents = sitePrivates.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(root, getCompLayouter.bind(null, sitePrivates));

                _.forEach(childrenLayouterComponents, compPointer => {
                    const layouter = getCompLayouter(sitePrivates, compPointer);
                    const screenSize = this.siteData.getScreenSize();
                    const siteWidth = this.siteData.getSiteWidth();
                    const compLayoutPointer = sitePrivates.pointers.getInnerPointer(compPointer, ['layout']);
                    const compLayout = sitePrivates.displayedDal.get(compLayoutPointer);
                    const compDimensions = coreUtils.positionAndSize.getPositionAndSize(compLayout, screenSize, screenSize, siteWidth);
                    layouter.setLayout(sitePrivates.pointers, sitePrivates.displayedDal, compPointer, null, compDimensions);
                });
            });
        },

        updateDisplayedNodesLayoutAndUpdateAnchors() {
            this.updateDisplayedNodesLayout();
            const roots = this.siteData.getAllPossiblyRenderedRoots();
            _.forEach(roots, _.bind(function (rootId) {
                this.anchors.createPageAnchors(rootId);
            }, this));
        },

        createDisplayedNode(pointer) {
            this.actionQueue.runImmediately(function () {
                const sitePrivates = privates.get(this.siteData);
                const pagePointer = sitePrivates.pointers.components.getPageOfComponent(pointer);
                activateDefaultModesIfNeeded(sitePrivates, pagePointer.id);
                const allActiveModesPointer = sitePrivates.pointers.activeModes.getAllActiveModes();
                const activeModes = sitePrivates.displayedDal.get(allActiveModesPointer);
                sitePrivates.displayedJsonUpdater.updateDisplayedJsonByPointer(pointer, activeModes);
                this.anchors.createPageAnchors(pagePointer.id);
                sitePrivates.cache.resetValidations();
                const compsUnderRoot = sitePrivates.pointers.components.getChildrenRecursivelyRightLeftRootIncludingRoot(pointer);
                const compsToOverride = _.pick(sitePrivates.getRuntimeCompsData(), _.map(compsUnderRoot, 'id'));
                sitePrivates.runtimeToDisplayed.updateAll(compsToOverride);
                notifyOnDisplayedJsonUpdate.call(this, pointer);
            }.bind(this));
        },

        createDisplayedPages() {
            const roots = this.siteData.getAllPossiblyRenderedRoots();
            _.forEach(roots, this.createDisplayedPage.bind(this));
            //TODO: remove this!!! this is here because we have a double bug in mobx - which doesn't recalculate other props + matrixGallery
            this.siteData.observableUpdateIndex++;
        },

        resetRuntimeData() {
            const sitePrivates = privates.get(this.siteData);
            sitePrivates.resetRuntimeData();
            this.createDisplayedPages();
        },

        getActionQueue() {
            return this.actionQueue;
        },

        setRenderRealtimeConfigItem(name, value) {
            const sitePrivates = privates.get(this.siteData);
            const renderRealtimeConfigItem = sitePrivates.pointers.general.getRenderRealtimeConfigItem(name);

            return sitePrivates.displayedDal.set(renderRealtimeConfigItem, value);
        }
    };

    return {
        createSiteDataAPIAndDal
    };
});
