define(['lodash', 'coreUtils', 'utils', 'warmupUtils', 'core/components/actionsAspectActions/baseAction'], function (_, coreUtils, utils, warmupUtils, baseAction) {
    'use strict';

    const DELAY_ADJUSTMENT = 0.2;
    const DEFAULT_THRESHOLD = 15 / 100; // 15%

    const triggerTypes = utils.triggerTypesConsts;

    /**
     * Constructor for ScreenIn action, starts disabled.
     * @param aspectSiteAPI
     * @constructor
     */
    function ScreenInAction() {
        baseAction.apply(this, arguments);
        this._currentScroll = {x: 0, y: 0};
        this._currentPopupScroll = {x: 0, y: 0};
    }

    ScreenInAction.prototype = _.create(baseAction.prototype, {
        constructor: ScreenInAction,
        ACTION_TRIGGERS: [
            triggerTypes.SCROLL,
            triggerTypes.RESIZE,
            triggerTypes.ACTIONS_ADDED_LAYOUTED,
            triggerTypes.ACTIONS_REMOVED,
            triggerTypes.DID_LAYOUT
        ],
        ACTION_NAME: 'screenIn',
        ACTIONS_SUPPORTED: ['screenIn'],

        /**
         *
         * @param {SiteAspectsSiteAPI} siteAPI
         * @param {String} parentId
         * @returns {ReactCompositeComponent}
         */
        getComponentParent(parentId) {
            return this._aspectSiteAPI.getPageById(parentId);
        },

        /**
         * Test if a component is in the viewport
         * "In viewport" mans that either the top position of the component is in screen bounds,
         * or bottom of component is in screen bounds
         * or bottom is under the screen and top is above the screen, meaning the component "wraps" the viewport
         * @param action
         * @returns {boolean}
         */
        isComponentInViewport(action, threshold) {
            const currScroll = this._aspectSiteAPI.getCurrentPopupId() === action.pageId ?
                this._currentPopupScroll :
                this._currentScroll;

            threshold = _.isNumber(threshold) ? threshold : DEFAULT_THRESHOLD;
            return coreUtils.viewportUtils.isAlwaysInViewport(this._aspectSiteAPI, action.sourceId) ||
                coreUtils.viewportUtils.isInViewport(this._aspectSiteAPI, currScroll, action.sourceId, threshold) ||
                this.isChildOfFixedFooter(action.pageId, action.sourceId) ||
                this.isPinnedElement(action.sourceId) ||
                this.isChildOfPopover(action.sourceId, action.pageId);
        },

        /**
         * find if component is a child of footer
         * @param {SiteAspectsSiteAPI} siteAPI
         * @param {string} pageId
         * @param {string} id
         * @returns {boolean}
         */
        isChildOfFixedFooter(pageId, id) {
            let isFixed = false;

            const isFooterFixedPosition = warmupUtils.measurerUtils.isFooterFixedPosition();
            if (pageId === 'masterPage' && isFooterFixedPosition) {
                const footer = this._aspectSiteAPI.getComponentById('SITE_FOOTER');
                isFixed = this.isDescendantOf(footer.props.structure, id);
            }

            return isFixed;
        },

        isChildOfPopover(id, rootId) {
            const parentComponentType = this._aspectSiteAPI.getParentComponentType(id, rootId);
            return parentComponentType === 'wysiwyg.viewer.components.Popover';
        },

        isPinnedElement(id) {
            const comp = this._aspectSiteAPI.getComponentById(id);
            if (comp) {
                return _.get(comp, 'props.structure.layout.fixedPosition');
            }
        },

        /**
         * Run recursively over a component structure and return true if a component with the given id is a descendent of it.
         * Note: this will not work for pages_container and down into the page
         * @param {object} compStructure
         * @param {string} id
         * @returns {boolean}
         */
        isDescendantOf(compStructure, id) {
            if (!compStructure.components) {
                return false;
            }

            return _.some(compStructure.components, function (child) {
                if (child.id === id) {
                    return true;
                }
                return this.isDescendantOf(child, id);
            }.bind(this));
        },

        runScreenInActions(actions) {
            const actionIdsToTrigger = _(actions)
                .omit(_.keys(this._triggeredOnce))
                .pickBy(this.isComponentInViewport)
                .keys()
                .value();

            this.triggerActions(actionIdsToTrigger);
        },

        runAction() {
            const screenInActions = _.pickBy(this._currentActions, ['name', 'screenIn']);
            this.runScreenInActions(screenInActions);
        },

        /**
         * Handle triggers passed from actionsAspect
         * @param {ActionsTriggerTypes} triggerType
         * @param {object} [triggerEventObject]
         */
        handleActionTrigger(triggerType, triggerEventObject) { // eslint-disable-line complexity
            const windowScrollAspect = this._aspectSiteAPI.getSiteAspect('windowScrollEvent');
            if (triggerType === triggerTypes.SCROLL) {
                this._currentScroll = _.pick(triggerEventObject, ['x', 'y']);
            } else {
                this._currentScroll = windowScrollAspect.getScrollPosition() || this._currentScroll;
            }
            this._currentPopupScroll = this._aspectSiteAPI.getCurrentPopupScroll();

            switch (triggerType) {
                case triggerTypes.ACTIONS_REMOVED:
                    this._triggeredOnce = _.omit(this._triggeredOnce, _.keys(_.toArray(arguments)[1]));
                    break;
                case triggerTypes.DID_LAYOUT:
                case triggerTypes.SCROLL:
                case triggerTypes.RESIZE:
                case triggerTypes.ACTIONS_ADDED_LAYOUTED:
                    const delay = Math.max(this._delayActionByTransitionDuration - DELAY_ADJUSTMENT, 0) * 1000;
                    if (delay > 0) {
                        _.delay(function () {
                            // A quick and dirty hack no. 2 to sequence between page transition and screen in
                            this._delayActionByTransitionDuration = 0;
                            this.executeOnNextTick(this.runAction);
                        }.bind(this), delay);
                    } else {
                        this.executeOnNextTick(this.runAction);
                    }
                    break;
            }
        },

        shouldEnable() {
            const isBrowser = typeof window !== 'undefined';

            return isBrowser;
        },

        resetActionState: _.noop
    });

    /**
     * @exports ScreenInAction
     */
    return ScreenInAction;
});
