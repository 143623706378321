define([
    'lodash',
    'componentsCore'
], function (
    _,
    componentsCore
) {
    'use strict';

    function activateScrollModes(scrollTop, scrollModes) {
        _.forEach(scrollModes, function (scrollModesInRoot) {
            activateScrollModesForRoot(scrollTop, scrollModesInRoot);
        });
    }

    function activateScrollModesForRoot(scrollTop, scrollModesByComp) {
        _.forEach(scrollModesByComp, function (compRegisteredModes) {
            const modeToActivate = _.findLast(compRegisteredModes.scrollModes, function (modeDef) {
                return modeDef.settings.scrollPos <= scrollTop;
            });

            if (modeToActivate) {
                compRegisteredModes.debouncedActivateModeById(modeToActivate.modeId);
            }
        });
    }

    function getDebouncedActivateMode(aspectSiteAPI, compId, rootId) {
        let timer;
        let modeToActivate;
        return function (modeId) {
            if (modeId !== modeToActivate) {
                modeToActivate = modeId;
                clearTimeout(timer);
                timer = setTimeout(function () {
                    aspectSiteAPI.activateModeById(compId, rootId, modeToActivate);
                }, 50);
            }
        };
    }

    /**
     *
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @implements {core.SiteAspectInterface}
     * @constructor
     */
    function WindowScrollEventAspect(aspectSiteAPI) {
        /** @type {core.SiteAspectsSiteAPI} */
        this._aspectSiteAPI = aspectSiteAPI;
        this._registeredCompIds = {};
        this.invokeOnce = [];
        this._registeredScrollModes = {};
        this._prevScrollPosition = {x: 0, y: 0};
        this._prevScrollDirection = '';
        if (this._aspectSiteAPI.getSiteData().isInSSR()) {
            this._aspectSiteAPI.updateAspectGlobalData('windowScrollEvent', {x: 0, y: 0});
        } else {
            this._aspectSiteAPI.updateAspectGlobalData('windowScrollEvent', this._aspectSiteAPI.getSiteScroll());
        }
        this._aspectSiteAPI.registerToSiteReady(() => {
            this._aspectSiteAPI.updateAspectGlobalData('windowScrollEvent', this._aspectSiteAPI.getSiteScroll());
            aspectSiteAPI.registerToScroll(this.propagateScrollEvent.bind(this));
            if (this._aspectSiteAPI.isExperimentOpen('scrollModes')) {
                aspectSiteAPI.registerToRenderedRootsChange(this.cleanNonRenderedScrollModes.bind(this));
            }
        });
    }

    function willScrollY(siteHeight, screenHeight, scrollPosition, y) {
        if (siteHeight <= screenHeight) {
            return false;
        }

        const currentPositionTop = scrollPosition.y;
        const currentPositionBottom = currentPositionTop + screenHeight;
        return y > currentPositionTop && siteHeight > currentPositionBottom || y < currentPositionTop && currentPositionTop > 0; // eslint-disable-line no-mixed-operators
    }

    function willScrollX(siteWidth, screenWidth, scrollPosition, x) {
        if (siteWidth <= screenWidth) {
            return false;
        }

        const currentPositionLeft = scrollPosition.x;
        const currentPositionRight = currentPositionLeft + screenWidth;
        return x > currentPositionLeft && siteWidth > currentPositionRight || x < currentPositionLeft && currentPositionLeft > 0; // eslint-disable-line no-mixed-operators
    }

    WindowScrollEventAspect.prototype = {
        scrollSiteTo(x, y, callback) {
            const siteData = this._aspectSiteAPI.getSiteData();
            const scrollPosition = this.getScrollPosition();
            const screenSize = siteData.getScreenSize();
            const siteHeight = siteData.measureMap.height.masterPage;
            const siteWidth = siteData.measureMap.width.masterPage;

            const canScrollY = willScrollY(siteHeight, screenSize.height, scrollPosition, y);
            const canScrollX = willScrollX(siteWidth, screenSize.width, scrollPosition, x);

            if (!canScrollY && !canScrollX) {
                if (callback) {
                    callback();
                }
                return;
            }

            if (callback) {
                this.invokeOnce.push(callback);
            }
            this._aspectSiteAPI.scrollSiteTo(canScrollX ? x : scrollPosition.x, canScrollY ? y : scrollPosition.y);
        },
        registerToScroll(comp) {
            this._registeredCompIds[comp.props.id] = comp.props.id;
        },

        unregisterToScroll(comp) {
            delete this._registeredCompIds[comp.props.id];
        },
        propagateScrollEvent(position) {
            this._aspectSiteAPI.updateAspectGlobalData('windowScrollEvent', position);
            const direction = this.getScrollDirection(position);
            if (this._aspectSiteAPI.isExperimentOpen('scrollModes')) {
                activateScrollModes(position.y, this._registeredScrollModes);
            }
            this._prevScrollPosition = position;
            this._prevScrollDirection = direction;
            _.forEach(this._registeredCompIds, compId => {
                const listener = this._aspectSiteAPI.getComponentById(compId);
                if (!listener) {
                    delete this._registeredCompIds[compId];
                } else if (listener.onScroll) {
                    listener.onScroll(position, direction);
                }
            });
            _.forEach(this.invokeOnce, function (callback) {
                callback(position, direction);
            });
            this.invokeOnce = [];
        },
        getScrollDirection(position) {
            if (position.y !== this._prevScrollPosition.y) {
                if (position.y > this._prevScrollPosition.y) {
                    return 'DOWN';
                }
                return 'UP';
            }
            if (position.x !== this._prevScrollPosition.x) {
                if (position.x > this._prevScrollPosition.x) {
                    return 'RIGHT';
                }
                return 'LEFT';
            }
            return this._prevScrollDirection;
        },
        getScrollPosition() {
            return this._aspectSiteAPI.getAspectGlobalData('windowScrollEvent');
        },
        registerCompScrollModes(compId, rootId, scrollModes) {
            this._registeredScrollModes[rootId] = this._registeredScrollModes[rootId] || {};
            const previousCompRoot = _.findKey(this._registeredScrollModes, compId);
            if (previousCompRoot && previousCompRoot !== rootId) {
                delete this._registeredScrollModes[previousCompRoot][compId];
            }
            const aspectSiteAPI = this._aspectSiteAPI;

            this._registeredScrollModes[rootId][compId] = {
                scrollModes: _.sortBy(scrollModes, 'settings.scrollPos'),
                debouncedActivateModeById: getDebouncedActivateMode(aspectSiteAPI, compId, rootId)
            };
        },
        clearCompScrollModes(compId) {
            const rootWithRegisteredCompModes = _.findKey(this._registeredScrollModes, compId);
            if (rootWithRegisteredCompModes) {
                delete this._registeredScrollModes[rootWithRegisteredCompModes][compId];
            }
        },
        cleanNonRenderedScrollModes(rootsAdded, rootsRemoved) {
            if (this._aspectSiteAPI.isExperimentOpen('scrollModes')) {
                this._registeredScrollModes = _.omit(this._registeredScrollModes, rootsRemoved);
            }
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('windowScrollEvent', WindowScrollEventAspect);
});
