define([
    'lodash',
    'coreUtils'
], function (
    _,
    coreUtils
) {
    'use strict';

    /**
     *
     * @typedef {object} Action
     * @property {string} type
     * @property {string} name
     * @property {string} [sourceId]
     */

    /**
     *
     * @typedef {object} Behavior
     * @property {string} type
     * @property {string} name
     * @property {string} [targetId]
     * @property {object} [params]
     */

    // Identifier fields should be in sync with documentServices actionsAndBehaviors.js ACTION/BEHAVIORS_PROPS_TO_COMPARE
    const actionIdentifierFields = ['sourceId', 'type', 'name'];
    const registeredActionsPath = ['behaviors', 'registeredActionsByTypeSource'];
    const {behaviorsService} = coreUtils;

    function uniqueActionIdentifier(action) {
        return _.at(action, actionIdentifierFields).join(',');
    }

    function typeAndSourceIdentifier(action) {
        return `${action.type}:${action.sourceId}`;
    }

    function classifyAddedRemoved(newList, prevMap, keyInNewList, identifierFunction) {
        const newMap = _(newList)
            .map(keyInNewList)
            .keyBy(identifierFunction)
            .value();
        const newKeys = _.keys(newMap);
        const oldKeys = _.keys(prevMap);
        const stayed = _.intersection(newKeys, oldKeys);
        const added = _.difference(newKeys, stayed);
        const removed = _.difference(oldKeys, stayed);
        return {map: newMap, added, removed};
    }

    function updateActionsAndBehaviorsMaps(actionsAndBehaviors, pageId) {
        const behaviorsData = classifyAddedRemoved(actionsAndBehaviors, this._registeredBehaviors[pageId] || {}, 'behavior', behaviorsService.behaviorUniqueIdentifier);
        this._behaviorsAdded[pageId] = behaviorsData.added.concat(this._behaviorsAdded[pageId] || []);
        this._behaviorsRemoved[pageId] = behaviorsData.removed.concat(this._behaviorsRemoved[pageId] || []);
        _(this._registeredBehaviors[pageId])
            .pick(behaviorsData.removed)
            .forEach(function (removedBehavior) {
                const handler = coreUtils.behaviorHandlersFactory.getHandler(removedBehavior.type);
                const triggeredBehavior = _.find(this._triggeredBehaviors, function (behavior) {
                    return _.isEqual(behavior, removedBehavior);
                });
                const wasBehaviorTriggered = !!triggeredBehavior;
                if (_.has(handler, 'cancelPreCondition') && !wasBehaviorTriggered) {
                    handler.cancelPreCondition(removedBehavior, this._aspectSiteAPI);
                }
                if (_.has(handler, 'cancel')) {
                    handler.cancel(removedBehavior, this._aspectSiteAPI);
                }
                this._triggeredBehaviors = _.without(this._triggeredBehaviors, triggeredBehavior);
            }.bind(this));
        this._registeredBehaviors[pageId] = behaviorsData.map;

        const actionsData = classifyAddedRemoved(actionsAndBehaviors, this._registeredActions[pageId] || {}, 'action', uniqueActionIdentifier);
        this._actionsRemoved[pageId] = this._actionsRemoved[pageId] || {};
        _.assign(this._actionsRemoved[pageId], _.pick(this._registeredActions[pageId], actionsData.removed));
        this._registeredActions[pageId] = actionsData.map;
        this._actionsAdded[pageId] = actionsData.added.concat(this._actionsAdded[pageId] || []);

        this._actionsToBehaviorsMap[pageId] = _(actionsAndBehaviors)
            .groupBy(actionBehavior => uniqueActionIdentifier(actionBehavior.action))
            .mapValues(function (actionBehaviors) {
                return _(actionBehaviors)
                    .map('behavior')
                    .map(behaviorsService.behaviorUniqueIdentifier)
                    .value();
            })
            .value();
        this._behaviorsToActionsMap[pageId] = _(actionsAndBehaviors)
            .groupBy(actionBehavior => behaviorsService.behaviorUniqueIdentifier(actionBehavior.behavior))
            .mapValues(function (actionBehaviors) {
                return _(actionBehaviors)
                    .map('action')
                    .map(uniqueActionIdentifier)
                    .value();
            })
            .value();
    }

    function getFilteredMap(baseMap, pageIds, idsByPage) {
        const filtered = _(baseMap)
            .pick(pageIds)
            .map(function (content, pageId) {
                return idsByPage === true ? content : _.pick(content, idsByPage[pageId]);
            })
            .values()
            .value();
        return _.assign.apply(_, [{}].concat(filtered));
    }

    function needToRunPreConditions(action) {
        const actionsAspect = this._aspectSiteAPI.getSiteAspect('actionsAspect');
        return actionsAspect.needToRunPreConditions(action);
    }


    function filterBehaviors(behaviors, ranOnceArr) {
        return _.filter(behaviors, function (behavior) {
            const id = behaviorsService.behaviorUniqueIdentifier(behavior);
            if (behavior.playOnce && ranOnceArr[id]) {
                return false;
            }
            ranOnceArr[id] = true;
            return true;
        });
    }

    function getRegisteredActionForBehavior(renderedRootIds, actionBehaviorMap, behavior) {
        /*eslint lodash/no-double-unwrap: 0*/
        return _(actionBehaviorMap)
            .chain()
            .pick(renderedRootIds)
            .map(function (compIdToBehaviorsMap) {
                return _.flatten(_.values(compIdToBehaviorsMap));
            })
            .find(actionsBehaviorsArr => _(actionsBehaviorsArr).map('behavior').some(behavior))
            .find(actionBehaviorObj => _.isEqual(actionBehaviorObj.behavior, behavior))
            .get('action')
            .value();
    }

    function handleBehaviors(behaviors, event, type) {
        const siteAPI = this._aspectSiteAPI.getSiteAPI();
        const renderedRootIds = siteAPI.getAllRenderedRootIds();
        const processedBehaviors = _.map(behaviors, function (behavior) {
            const action = getRegisteredActionForBehavior(renderedRootIds, this._rawBehaviorsForActions, behavior);
            if (type === 'widget' && !action) {
                return null;
            }
            return coreUtils.behaviorHandlersFactory.getBehaviorPreprocessor(type)(behavior, action, siteAPI);
        }.bind(this));
        behaviorsService.handleBehaviors(siteAPI, _.compact(processedBehaviors), event, type);
    }

    function transformMapByTypeSource(actionBehaviorArr) {
        return _(actionBehaviorArr)
            .map('action')
            .groupBy(typeAndSourceIdentifier)
            .value();
    }

    function isBehaviorEnabled(aspectSiteAPI, actionAndBehavior) {
        const behaviorHandler = coreUtils.behaviorHandlersFactory.getHandler(_.get(actionAndBehavior, 'behavior.type', 'animation'));
        return _.isUndefined(behaviorHandler.isEnabled) ||
            behaviorHandler.isEnabled(actionAndBehavior.behavior, aspectSiteAPI.getSiteAPI());
    }

    function filterActionsBehaviors(aspectSiteAPI, actionsAndBehaviors, pageId) {
        const actionsAspect = aspectSiteAPI.getSiteAspect('actionsAspect');
        return _(actionsAndBehaviors)
            .reject(actionAndBehavior => actionsAspect.isActionDisabled(actionAndBehavior.action.name))
            .filter(actionAndBehavior => isBehaviorEnabled(aspectSiteAPI, actionAndBehavior))
            .map(function (actionAndBehavior) {
                return _.defaultsDeep(actionAndBehavior, {
                    behavior: {type: 'animation', pageId},
                    action: {pageId}
                });
            })
            .value();
    }

    function removePendingBehaviorsThatRequireFullRender(behaviorsWaitingToExecute, behaviorName) {
        return _.remove(behaviorsWaitingToExecute, ['name', behaviorName]);
    }

    /**
     *
     * @typedef {Object} BehaviorHandler
     * @property {function} handle
     */

    /**
     *
     * @param {SiteAspectSiteAPI} aspectSiteAPI
     * @constructor
     */
    function BehaviorsAspect(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this._registeredActions = {};
        this._registeredBehaviors = {};
        this._ranOnce = {};
        this._rawBehaviorsForActions = {};
        this._behaviorsAdded = {};
        this._behaviorsRemoved = {};
        this._actionsAdded = {};
        this._actionsRemoved = {};
        this._actionsToBehaviorsMap = {};
        this._behaviorsToActionsMap = {};
        this._triggeredBehaviors = [];
        this._behaviorsToExecute = {};
        this._behaviorsToExecuteListeners = {};
        _.set(aspectSiteAPI.getSiteData(), registeredActionsPath, {});
        _.bindAll(this, [
            'handleActions',
            'handleAction',
            'setBehaviorsForActions',
            'resetBehaviorsForActions',
            'getActions',
            'getCompActions',
            'convertBehaviors',
            '_handleDidLayout',
            '_handleRootsChanged',
            '_handleRootsAddedDidLayout',
            'resetBehaviorsRegistration',
            '_registerPendingBehaviors',
            'registerBehavior',
            'handleBehavior',
            'isBehaviorEnabled'
        ]);

        this._aspectSiteAPI.registerToDidLayout(this._handleDidLayout);
        this._aspectSiteAPI.registerToAddedRenderedRootsDidLayout(this._handleRootsAddedDidLayout);
        this._aspectSiteAPI.registerToRenderedRootsChange(this._handleRootsChanged);
        this.applySsrChanges = this._registerPendingBehaviors.bind(this);
    }


    BehaviorsAspect.prototype = {

        /**
         * handle an action.
         * @param {Action} action
         */
        handleActions(actions, event) {
            _.forEach(
                this._actionsToBehaviorsMap,
                function (actionsToBehaviors, pageId) {
                    _(actionsToBehaviors)
                        .pick(_.map(actions, uniqueActionIdentifier))
                        .values()
                        .flatten()
                        .map(_.get.bind(_, this._registeredBehaviors[pageId]))
                        .groupBy('type')
                        .forEach(function (behaviors, type) {
                            this._triggeredBehaviors = this._triggeredBehaviors.concat(behaviors);
                            behaviors = filterBehaviors(behaviors, this._ranOnce);
                            event = _.defaults({}, event, {action: 'action'});
                            actions = actions || [{name: 'action'}];
                            event.action = actions[0].name;
                            handleBehaviors.call(this, behaviors, event, type);
                        }.bind(this));
                }.bind(this)
            );
        },

        handleAction(action, event) {
            this.handleActions([action], event);
        },

        /**
         * Set/Replace the actions and behaviors for the given page id.
         * @param {{action: Action, behavior: Behavior}[]} actionsAndBehaviors
         * @param {string} pageId
         */
        setBehaviorsForActions(actionsAndBehaviors, pageId, compId) {
            const filteredActionsAndBehaviors = filterActionsBehaviors(this._aspectSiteAPI, actionsAndBehaviors, pageId);
            _.set(this._aspectSiteAPI.getSiteData(), registeredActionsPath.concat([pageId, compId]), transformMapByTypeSource(actionsAndBehaviors));
            _.set(this._rawBehaviorsForActions, [pageId, compId], filteredActionsAndBehaviors);
        },

        resetBehaviorsForActions(compId) {
            const pageId = this._aspectSiteAPI.getRootOfComponentId(compId);
            const siteAPI = this._aspectSiteAPI.getSiteAPI();
            siteAPI.resetCompBehavioursByPageAndCompId(pageId, compId);
        },
        
        /**
         * Get all the different actions for this type and source id.
         * @param {string} type The action type (i.e. 'comp')
         * @param {string} sourceId The source id of the action (i.e. compId, applicationId, etc.)
         * @returns {Object.<string, Action>} An object of unique actions for this type and source id group by the action name.
         */
        getActions(type, sourceId) {
            const registeredActionsByTypeSource = _.get(this._aspectSiteAPI.getSiteData(), registeredActionsPath, {});
            return _(registeredActionsByTypeSource)
                .map(sourceId)
                .flatMap(`${type}:${sourceId}`)
                .compact()
                .uniq()
                .mapKeys(action => action.name)
                .value();
        },

        getCompActions(type, sourceId, rootId, actionsBehaviorsArr) {//TODO:Shahar - should replace getActions when componentPropsBuilder doesn't use it anymore
            const filteredActionsAndBehaviors = filterActionsBehaviors(this._aspectSiteAPI, actionsBehaviorsArr, rootId);
            const actionsByTypeMap = transformMapByTypeSource(filteredActionsAndBehaviors);
            return _(_.get(actionsByTypeMap, [`${type}:${sourceId}`]))
                .compact()
                .uniq()
                .mapKeys(action => action.name)
                .value();
        },

        convertBehaviors(behaviors, compId) {
            return _.map(behaviors, function (behaviorObj) {
                if (behaviorObj.behavior && behaviorObj.action) {
                    return behaviorObj;
                }
                const action = {name: behaviorObj.action, sourceId: behaviorObj.sourceId || compId, type: 'comp'};
                const behavior = _.omit(behaviorObj, ['action', 'sourceId', 'pageId', 'duration', 'delay', 'params']);
                behavior.targetId = behavior.targetId || compId;
                behavior.params = _.assign(_.cloneDeep(behaviorObj.params), _.pick(behaviorObj, ['duration', 'delay']));

                return {
                    action,
                    behavior
                };
            });
        },

        _handleDidLayout() {
            const renderedRoots = this._aspectSiteAPI.getAllRenderedRootIds();
            _.forEach(renderedRoots, function (renderedRoot) {
                let actionsAndBehaviors = _.flatten(_.values(this._rawBehaviorsForActions[renderedRoot]));
                const measureMapHeights = _.get(this._aspectSiteAPI.getSiteData(), 'measureMap.top', {});
                actionsAndBehaviors = _.filter(actionsAndBehaviors, function (actionAndBehavior) {
                    const sourceId = actionAndBehavior.action.sourceId;
                    const actionType = actionAndBehavior.action.type;
                    const behaviorType = actionAndBehavior.behavior.type;
                    const targetId = actionAndBehavior.behavior.targetId;

                    const legalSource = actionType !== 'comp' || _.isNumber(measureMapHeights[sourceId]);
                    const legalTarget = behaviorType === 'widget' || behaviorType === 'site' || _.isNumber(measureMapHeights[targetId]);
                    return legalSource && legalTarget;
                });
                updateActionsAndBehaviorsMaps.call(this, actionsAndBehaviors, renderedRoot);
            }.bind(this));
            const addedActions = getFilteredMap(this._registeredActions, renderedRoots, this._actionsAdded);
            const removedActions = _(this._actionsRemoved)
                .pick(renderedRoots)
                .values()
                .reduce(_.assign, {});

            const currentActions = _(this._registeredActions)
                .pick(renderedRoots)
                .values()
                .reduce(_.assign, {});

            const behaviorsAdded = getFilteredMap(this._registeredBehaviors, renderedRoots, this._behaviorsAdded);
            _.forEach(behaviorsAdded, function (behavior, id) {
                const shouldRunPre = _(this._behaviorsToActionsMap)
                    .pick(renderedRoots)
                    .values()
                    .map(id)
                    .compact()
                    .flatten()
                    .map(_.get.bind(_, currentActions))
                    .every(needToRunPreConditions.bind(this));
                if ((!behavior.playOnce || !this._ranOnce[id]) && shouldRunPre) {
                    const handler = coreUtils.behaviorHandlersFactory.getHandler(behavior.type);
                    if (_.has(handler, 'handlePreCondition')) {
                        handler.handlePreCondition(behavior, this._aspectSiteAPI);
                    }
                }
            }.bind(this));
            _.forEach(renderedRoots, function (pageId) {
                this._actionsAdded[pageId] = [];
                this._actionsRemoved[pageId] = {};
                this._behaviorsAdded[pageId] = [];
                this._behaviorsRemoved[pageId] = [];
            }.bind(this));
            if (!_.isEmpty(removedActions)) {
                this._aspectSiteAPI.getSiteAspect('actionsAspect').actionsRemoved(removedActions);
            }
            if (!_.isEmpty(addedActions)) {
                this._aspectSiteAPI.getSiteAspect('actionsAspect').actionsAddedLayouted(addedActions);
            }
        },

        _handleRootsChanged(rootsAdded, rootsRemoved) {
            const removedActions = getFilteredMap(this._registeredActions, rootsRemoved, true);
            _.forEach(rootsRemoved, function (rootRemoved) {
                this._rawBehaviorsForActions[rootRemoved] = [];
                this._registeredActions[rootRemoved] = {};
                this._registeredBehaviors[rootRemoved] = {};
                this._behaviorsAdded[rootRemoved] = [];
                this._behaviorsRemoved[rootRemoved] = [];
                this._actionsAdded[rootRemoved] = [];
                this._actionsRemoved[rootRemoved] = {};
                this._actionsToBehaviorsMap[rootRemoved] = {};
                this._behaviorsToActionsMap[rootRemoved] = {};
                _.set(this._aspectSiteAPI.getSiteData(), registeredActionsPath.concat(rootRemoved), {});
            }.bind(this));
            this._aspectSiteAPI.getSiteAspect('actionsAspect').actionsRemoved(removedActions);
        },

        _handleRootsAddedDidLayout() {
        },

        resetBehaviorsRegistration() {
            this._ranOnce = {};
        },

        _registerPendingBehaviors() {
            _.forEach(this._aspectSiteAPI.getSiteData().ssr.pendingBehaviours, function (pendingBehaviour) {
                const isApplyingSsrChanges = true;
                this.registerBehavior(pendingBehaviour, pendingBehaviour.callback, isApplyingSsrChanges);
            }.bind(this));
        },

        registerBehavior(behavior, callback, isApplyingSsrChanges) {
            const behaviorsWaitingToExecute = _.toArray(this._behaviorsToExecute[behavior.targetId]).concat({
                name: behavior.name,
                params: behavior.params,
                callback
            });

            if (isApplyingSsrChanges && _.get(behavior, 'params.requiresDom.requiresFullRender')) {
                removePendingBehaviorsThatRequireFullRender(behaviorsWaitingToExecute, behavior.name);
            }

            if (this._behaviorsToExecuteListeners[behavior.targetId]) {
                this._behaviorsToExecuteListeners[behavior.targetId](behaviorsWaitingToExecute);
            } else {
                this._behaviorsToExecute[behavior.targetId] = behaviorsWaitingToExecute;
            }
        },

        trackBehaviorsToExecute(compId, listener) {
            this._behaviorsToExecuteListeners[compId] = listener;
            if (this._behaviorsToExecute[compId]) {
                listener(this._behaviorsToExecute[compId]);
                delete this._behaviorsToExecute[compId];
            }
            return () => {
                if (this._behaviorsToExecuteListeners[compId] === listener) {
                    delete this._behaviorsToExecuteListeners[compId];
                    delete this._behaviorsToExecute[compId];
                }
            };
        },

        handleBehavior(behavior, event) {
            handleBehaviors.call(this, [behavior], event, behavior.type);
        },

        handleProcessedBehavior(behavior, event) {
            const siteAPI = this._aspectSiteAPI.getSiteAPI();
            behaviorsService.handleBehaviors(siteAPI, [behavior], event, behavior.type);
        },

        isBehaviorEnabled(behavior) {
            const actionAndBehaviors = this.convertBehaviors([behavior]);
            return isBehaviorEnabled(this._aspectSiteAPI, _.head(actionAndBehaviors));
        }
    };

    return BehaviorsAspect;
});
