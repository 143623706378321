define(['lodash', 'componentsCore'], function (_, componentsCore) {
    'use strict';

    const ASPECT_NAME = 'InlinePopupAspect';
    function InlinePopupAspect(aspectSiteApi) {
        this._aspectSiteAPI = aspectSiteApi;
        this._aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {
            openedId: null
        });

        _.bindAll(this, _.functionsIn(this));
    }

    InlinePopupAspect.prototype = {
        open(compId) {
            this.close();
            this._aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {openedId: compId});
        },
        close() {
            this._aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {openedId: null});
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect(ASPECT_NAME, InlinePopupAspect);

    return InlinePopupAspect;
});
