define(['lodash', 'coreUtils', 'componentsCore'], function (_, coreUtils, componentsCore) {
    'use strict';

    const ASPECT_NAME = 'AudioAspect';

    const SOUND_MANAGER_ISREADY_POLLING_INTERVAL_IN_MS = 10;
    let currentSoundManagerPollingInterval = SOUND_MANAGER_ISREADY_POLLING_INTERVAL_IN_MS; // eslint-disable-line santa/no-module-state
    const INTERVAL_GROWTH_FACTOR = 1.5;
    const POLLING_INTERVAL_LIMIT_IN_MS = 10 * 1000;

    function getSoundManagerSetupConfig(aspect, customSettings) {
        return _.assign({
            url: getSoundManagerBaseUrl(aspect.siteAPI.getSiteData().serviceTopology),
            flashVersion: 9,
            preferFlash: false,
            useHTML5Audio: true,
            forceUseGlobalHTML5Audio: false,
            html5PollingInterval: 100,
            onready() {
                aspect.siteAPI.updateAspectGlobalData(ASPECT_NAME, {
                    soundManagerReady: true
                });
            }
        }, customSettings || {});
    }

    function resetCurrentPollingInterval() {
        currentSoundManagerPollingInterval = SOUND_MANAGER_ISREADY_POLLING_INTERVAL_IN_MS;
    }

    function getSoundManagerBaseUrl(serviceTopology) {
        let scriptsLocation = serviceTopology.scriptsDomainUrl;
        if (!coreUtils.stringUtils.endsWith(scriptsLocation, '/')) {
            scriptsLocation += '/';
        }
        return `${scriptsLocation}services/third-party/soundmanager2/V2.97a.20150601/`;
    }

    function onHTMLAudioLoadError(aspect) {
        if (setupSoundManager !== setupSoundManagerFallback) {
            aspect.siteAPI.updateAspectGlobalData(ASPECT_NAME, {soundManagerOnResetup: true, soundManagerReady: false});
            setupSoundManager = setupSoundManagerFallback;
            setupSoundManagerFallback(aspect);
        }
    }

    function setupSoundManagerFallback(aspect) {
        const setupConfig = getSoundManagerSetupConfig(aspect, {
            preferFlash: true,
            useHTML5Audio: false,
            onready() {
                aspect.siteAPI.updateAspectGlobalData(ASPECT_NAME, {soundManagerOnResetup: false, soundManagerReady: true});
            }
        });
        aspect.soundManager.destruct();
        aspect.soundManager.reset().setup(setupConfig);
        aspect.soundManager.beginDelayedInit();
    }

    let setupSoundManager = function (aspect) {
        const setupConfig = getSoundManagerSetupConfig(aspect);
        aspect.soundManager.setup(setupConfig);
        setTimeout(function () {
            tryLoadingSoundManager(aspect);
        }, currentSoundManagerPollingInterval);
    };

    const tryLoadingSoundManager = function (aspect) {
        if (aspect.soundManager === null) {
            requirejs(['SoundManager'], function (soundManager) {
                aspect.soundManager = soundManager.getInstance();
                tryLoadingSoundManager(aspect);
            });
        } else if (aspect.siteAPI.getAspectGlobalData(ASPECT_NAME).soundManagerReady) {
            resetCurrentPollingInterval();
        } else if (POLLING_INTERVAL_LIMIT_IN_MS > currentSoundManagerPollingInterval) {
            setupSoundManager(aspect);
            currentSoundManagerPollingInterval *= INTERVAL_GROWTH_FACTOR;
        } else {
            resetCurrentPollingInterval();
            coreUtils.log.verbose('Failed to setup SoundManager.');
        }
    };

    /**
     *
     * @constructor
     * @param {core.SiteAspectsSiteAPI} aspectSiteApi
     *
     */
    function AudioAspect(aspectSiteApi) {
        this.siteAPI = aspectSiteApi;
        this.soundManager = null;

        this.siteAPI.updateAspectGlobalData(ASPECT_NAME, {
            nowPlayingComp: '',
            soundManagerReady: false,
            soundManagerOnResetup: false
        });

        _.bindAll(this, _.functionsIn(this));
    }

    AudioAspect.prototype = {
        loadSoundManagerAPI() {
            if (!(this.siteAPI.getSiteData().isInSSR() || window.isMockWindow)) {
                tryLoadingSoundManager(this);
            }
        },
        onHTML5ErrorTryToReloadWithFlash() {
            onHTMLAudioLoadError(this);
        },
        createAudioObj(config) {
            if (!this.siteAPI.getAspectGlobalData(ASPECT_NAME).soundManagerReady) {
                this.loadSoundManagerAPI();
                return false;
            }

            if (config.id && this.soundManager.getSoundById(config.id)) {
                this.soundManager.destroySound(config.id);
            }
            return this.soundManager.createSound(config);
        },
        updatePlayingComp(comp) {
            if (!this.siteAPI.getAspectGlobalData(ASPECT_NAME).soundManagerReady) {
                this.loadSoundManagerAPI();
            }
            this.siteAPI.updateAspectGlobalData(ASPECT_NAME, {nowPlayingComp: comp.props.id});
        },

        updatePausingComp() {
            this.siteAPI.updateAspectGlobalData(ASPECT_NAME, {nowPlayingComp: ''});
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect(ASPECT_NAME, AudioAspect);
    return AudioAspect;
});
