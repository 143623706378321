define(['lodash', 'componentsCore', 'coreUtils'], function (_, componentsCore, coreUtils) {
    'use strict';

    /**
     *
     * @constructor
     * @param {core.SiteAspectsSiteAPI} siteAPI
     */
    const behaviorsService = coreUtils.behaviorsService;
    const HtmlPostMessageAspect = function (siteAPI) {
        siteAPI.registerToMessage(this.handleMessage.bind(this));
        this.siteAPI = siteAPI;
        this.callbacks = {};
        this.components = {};
        this.queue = {};

        _.bindAll(this, ['handleMessage', 'registerComponent', 'unRegisterComponent', 'postMessage']);
    };

    function drainQueue() {
        if (_.size(this.queue) > 0) {
            _.forEach(this.queue, function (message, compId) {
                const actionsAndBehaviors = this.siteAPI.getRuntimeDal().getActionsAndBehaviors(compId);
                behaviorsService.handleBehaviors(this.siteAPI, _.map(actionsAndBehaviors, 'behavior'), message, 'widget');
                delete this.queue[compId];
            }.bind(this));
        }
    }

    HtmlPostMessageAspect.prototype = {
        handleMessage(event) {
            _.forEach(this.components, function (source, compId) {
                if (event.source === source) {
                    const actionsAndBehaviors = this.siteAPI.getRuntimeDal().getActionsAndBehaviors(compId);
                    if (_.size(actionsAndBehaviors) === 0) {
                        this.queue[compId] = event;
                    } else {
                        drainQueue.call(this);
                        behaviorsService.handleBehaviors(this.siteAPI, _.map(actionsAndBehaviors, 'behavior'), event, 'widget');
                    }
                }
            }.bind(this));
        },
        registerComponent(id, window) {
            this.components[id] = window;
        },
        unRegisterComponent(id) {
            delete this.components[id];
        },
        postMessage(compId, msg) {
            const iframe = this.components[compId];
            if (iframe) {
                iframe.postMessage(msg, '*');
            }
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('HtmlPostMessageAspect', HtmlPostMessageAspect);
    return HtmlPostMessageAspect;
});
