define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const {PAGE_DATA_DATA_TYPES} = coreUtils.constants;

    function getCompNickname({connectionQuery}, pageStructure) {
        const connections = coreUtils.dataResolver.getSinglePageDataByQuery(pageStructure, PAGE_DATA_DATA_TYPES.connections, connectionQuery);

        if (connections) {
            const wixCodeConnectionItem = _.find(connections.items, {type: 'WixCodeConnectionItem'});

            return wixCodeConnectionItem && wixCodeConnectionItem.role;
        }
    }

    function resolveComponent(comp, pageStructure) {
        const clonedComp = _.cloneDeep(comp);
        const {dataQuery, propertyQuery, designQuery, styleId} = clonedComp;

        if (dataQuery) {
            clonedComp.data = coreUtils.dataResolver.getSinglePageDataByQuery(pageStructure, PAGE_DATA_DATA_TYPES.data, dataQuery);
        }

        if (propertyQuery) {
            clonedComp.props = coreUtils.dataResolver.getSinglePageDataByQuery(pageStructure, PAGE_DATA_DATA_TYPES.props, propertyQuery);
        }

        if (designQuery) {
            clonedComp.design = coreUtils.dataResolver.getSinglePageDataByQuery(pageStructure, PAGE_DATA_DATA_TYPES.design, designQuery);
        }

        if (styleId) {
            clonedComp.style = coreUtils.dataResolver.getSinglePageDataByQuery(pageStructure, PAGE_DATA_DATA_TYPES.style, styleId);
        }

        delete clonedComp.dataQuery;
        delete clonedComp.propertyQuery;
        delete clonedComp.connectionQuery;
        delete clonedComp.designQuery;
        delete clonedComp.styleId;

        return clonedComp;
    }

    function buildGhostStructure(pageStructure) {
        const components = {};

        const compsToTransform = [..._.get(pageStructure, ['structure', 'components'], [])];
        while (compsToTransform.length > 0) {
            const comp = compsToTransform.shift();
            const nickname = getCompNickname(comp, pageStructure);

            if (nickname) {
                comp.id = coreUtils.guidUtils.getUniqueId('ghost', '-');
                components[nickname] = resolveComponent(comp, pageStructure);
                compsToTransform.push(...comp.components || []);
                if (comp.components) {
                    components[nickname].children =
                        _(comp.components)
                            .map(child => getCompNickname(child, pageStructure))
                            .compact()
                            .value();
                    delete components[nickname].components;
                }
            }
        }

        return components;
    }

    return {
        buildGhostStructure
    };
});
