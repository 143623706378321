define(['lodash', 'core/core/pageItemRegistry', 'componentsCore'],
    function (_, /** core.pageItemRegistry */ pageItemRegistry, componentsCore) {
        'use strict';

        function NonPageItemZoomAspect(aspectSiteAPI) {
            this._aspectSiteAPI = aspectSiteAPI;
            _.bindAll(this, ['zoom', 'unzoom']);
        }

        NonPageItemZoomAspect.prototype = {
            zoom(zoomedImageData, galleryData) {
                if (!zoomedImageData || zoomedImageData.type !== 'Image' || !galleryData) {
                    throw new Error('nonPageItemZoom should be used only with (imageData, galleryData)');
                }
                this._aspectSiteAPI.setAspectGlobalData('nonPageItemZoom', {
                    image: zoomedImageData,
                    galleryData
                });
            },
            unzoom() {
                this._aspectSiteAPI.removeAspectGlobalData('nonPageItemZoom');
            },
            getComponentsToRender(aspectSiteAPI) {
                const zoomData = aspectSiteAPI.getAspectGlobalData('nonPageItemZoom');

                if (zoomData) {
                    const siteData = aspectSiteAPI.getSiteData();
                    const structure = pageItemRegistry.getCompStructure('Image', zoomData.image, null, siteData);
                    structure.compData = zoomData.image;
                    delete structure.dataQuery;

                    return [{
                        structure,
                        props: {
                            key: structure.key,
                            compData: zoomData.image,
                            closeFunction: this.unzoom,
                            pageItemAdditionalData: zoomData.galleryData
                        }
                    }];
                }
                return null;
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('nonPageItemZoom', NonPageItemZoomAspect);

        return NonPageItemZoomAspect;
    });
