define([
    'lodash',
    'coreUtils'
], function (_, coreUtils) {
    'use strict';

    const defaultFormat = 'mp4';
    const MIN_ELAPSED_INTERVAL = 60000;
    const consts = coreUtils.mediaConsts;

    /**
     * get the availability status of requested video quality
     * @param {array} readyQualities
     * @param {string} targetQuality
     * @returns {string} IDLE | PLAYING_PREVIEW | IN_PROCESS
     */
    function getAvailabilityReadyState(readyQualities, targetQuality) {
        if (_.isEmpty(readyQualities)) {
            return consts.availabilityReadyStates.IN_PROCESS;
        } else if (_.includes(readyQualities, targetQuality)) {
            return consts.availabilityReadyStates.IDLE;
        }
        return consts.availabilityReadyStates.PLAYING_PREVIEW;
    }

    function _isNextQualityReady(nextIndex, qualityItem) {
        return _.includes(qualityItem.readyQualities, qualityItem.qualities[nextIndex].quality);
    }

    /**
     * Get the video url
     * @param {string} videoId
     * @param {object} currentQuality
     * @param headRequestStaticUrl
     * @returns {string}
     */
    function getUrl(videoId, currentQuality, headRequestStaticUrl) {
        if (currentQuality.url) {
            return coreUtils.urlUtils.joinURL(headRequestStaticUrl, currentQuality.url);
        }
        return coreUtils.urlUtils.joinURL(headRequestStaticUrl, videoId, currentQuality.quality, defaultFormat, `file.${defaultFormat}`);
    }

    /**
     * gets the next timeout interval , trying to optimize by video duration (min-30 sec , max-video duration x 5 )
     * @param qualityItem
     * @param videoQualityCheckInterval
     * @returns {number}
     */
    function getQualityTestNextInterval(qualityItem, videoQualityCheckInterval) {
        const elapsed = _.now() - qualityItem.timeStamp;
        if (elapsed > MIN_ELAPSED_INTERVAL && elapsed > qualityItem.duration * 5) {
            return 0;
        }
        const divider = elapsed > qualityItem.duration && elapsed <= qualityItem.duration * 2 ? 4 : 8;
        return Math.max(videoQualityCheckInterval, qualityItem.duration / divider);
    }

    /**
     *
     * @param {config} config
     * @constructor
     */
    function VideoQualityUtils(config) {
        this.config = config;
        this.videoAvailabilityCollection = {};
        this.componentsCallbackCollection = {};
    }

    /**
     * gets the quality array by type
     * @param data
     * @param type
     */
    function getQualities(data, type) {
        let qualities;
        switch (type) {
            case 'video':
                qualities = _.reject(data.qualities, {quality: 'storyboard'});
                break;
            case 'storyboard':
                qualities = _.filter(data.qualities, {quality: 'storyboard'});
                break;
        }
        return qualities;
    }


    VideoQualityUtils.prototype = {

        /**
         * @param {string} playerId
         * @param {object} data , the video data (WixVideo)
         * @param {string} targetQuality , target testing quality
         * @param {string}  videoPartType video | storyboard | clip
         * @param {function} callback
         */
        register(playerId, data, targetQuality, videoPartType, callback) {
            const qualities = getQualities(data, videoPartType);
            if (_.isEmpty(qualities)) {
                return;
            }
            // add to callback Collection (unique per part type and component )
            this.componentsCallbackCollection[videoPartType + playerId] = {
                type: videoPartType,
                playerId,
                videoId: data.videoId,
                onSuccessCallback: callback,
                targetQuality
            };
            //check if this video id already initiated
            let qualityItem = this.videoAvailabilityCollection[videoPartType + data.videoId];
            if (!qualityItem) {
                //initiate item
                qualityItem = {
                    type: videoPartType,
                    videoId: data.videoId,
                    key: videoPartType + data.videoId,
                    readyQualities: [],
                    testingQuality: targetQuality,
                    qualities,
                    timeStamp: new Date().getTime(),
                    duration: data.duration * 1000,
                    timerInterval: 0
                };
                this.videoAvailabilityCollection[qualityItem.key] = qualityItem;

                const currentTestingQuality = _.find(qualityItem.qualities, {quality: qualityItem.testingQuality});
                this.loadDummyVideo(qualityItem.key, qualityItem.videoId, currentTestingQuality);
            } else if (!_.isEmpty(qualityItem.readyQualities)) {
                // invoke immediate callback when ready quality already exists
                this.notifySingleReadyState(
                    this.componentsCallbackCollection[videoPartType + playerId],
                    qualityItem,
                    qualityItem.key
                );
            }
        },

        /**
         * unregister to quality , removes quality item only if no other component is registered with this video id.
         * @param playerId
         */
        unregister(playerId) {
            this.componentsCallbackCollection = _.omit(this.componentsCallbackCollection, playerId);
        },

        /**
         * sends a head request to check availability
         * @param videoId
         * @param currentQuality
         */
        loadDummyVideo(key, videoId, currentQuality) {
            coreUtils.ajaxLibrary.ajax({
                url: getUrl(videoId, currentQuality, this.config.headRequestStaticUrl),
                type: 'HEAD',
                success: function () {
                    this.canPlay(key);
                }.bind(this),
                error: function () {
                    this.onError(key);
                }.bind(this)
            });
        },

        /**
         * handles 404 video failure,
         * @param key
         */
        onError(key) {
            const qualityItem = this.videoAvailabilityCollection[key];
            if (qualityItem) {
                const nextIndex = this.getNextIndexToCheck(qualityItem);
                //console.log('error ' , qualityItem.testingQuality, 'nextIndex', nextIndex);
                if (typeof window !== 'undefined' && nextIndex >= 0 && !_isNextQualityReady(nextIndex, qualityItem)) {
                    //step down quality

                    qualityItem.testingQuality = qualityItem.qualities[nextIndex].quality;
                    setTimeout(function () {
                        this.loadDummyVideo(key, qualityItem.videoId, qualityItem.qualities[nextIndex]);
                    }.bind(this), 100);
                } else {
                    this.resetQualityTest(qualityItem);
                }
                this.notifyReadyState(qualityItem.key);
            }
        },

        getNextIndexToCheck(qualityItem) {
            const qualityIndex = _.findIndex(qualityItem.qualities, {quality: qualityItem.testingQuality});
            return qualityIndex - 1;
        },

        /**
         * handles video metadata loaded event.
         * updates the videoAvailabilityCollection with current success
         * and retry to load better quality if available
         * @param {string} key
         */
        canPlay(key) {
            const qualityItem = this.videoAvailabilityCollection[key];
            if (qualityItem) {
                //mark as ready
                if (!_.includes(qualityItem.readyQualities, qualityItem.testingQuality)) {
                    //console.log('can play ' , qualityItem.testingQuality);
                    qualityItem.readyQualities.push(qualityItem.testingQuality);
                }
                if (!this.isAllQualitiesAvailable(qualityItem)) {
                    //set to optimal quality
                    const nextIndex = this.getNextIndexToCheck(qualityItem);
                    if (typeof window !== 'undefined' && nextIndex >= 0 && !_isNextQualityReady(nextIndex, qualityItem)) {
                        //step down quality
                        qualityItem.testingQuality = qualityItem.qualities[nextIndex].quality;
                        setTimeout(function () {
                            this.loadDummyVideo(key, qualityItem.videoId, qualityItem.qualities[nextIndex]);
                        }.bind(this), 100);
                    } else {
                        this.resetQualityTest(qualityItem);
                    }
                }
                this.notifyReadyState(key);
            }
        },

        /**
         * start quality test again in videoQualityCheckInterval milliseconds
         * @param {object} qualityItem
         */
        resetQualityTest: function resetQualityTest(qualityItem) {
            if (qualityItem) {
                qualityItem.testingQuality = _.last(qualityItem.qualities).quality;
                const nextInterval = getQualityTestNextInterval(qualityItem, this.config.videoQualityCheckInterval);
                if (typeof window !== 'undefined' && nextInterval > 0) {
                    clearTimeout(qualityItem.timerInterval);
                    qualityItem.timerInterval = setTimeout(function () {
                        const currentTestingQuality = _.find(qualityItem.qualities, {quality: qualityItem.testingQuality});
                        this.loadDummyVideo(qualityItem.key, qualityItem.videoId, currentTestingQuality);
                    }.bind(this), nextInterval);
                }
            }
        },


        notifyReadyState(key) {
            const qualityItem = this.videoAvailabilityCollection[key];
            if (qualityItem) {
                _.forEach(_.filter(this.componentsCallbackCollection, {videoId: qualityItem.videoId}), _.bind(function (registrar) {
                    this.notifySingleReadyState(registrar);
                }, this));
            }
        },

        notifySingleReadyState(registrar) {
            const itemKey = registrar.type + registrar.videoId;
            const qualityItem = this.videoAvailabilityCollection[itemKey];
            registrar.onSuccessCallback({
                videoId: qualityItem.videoId,
                readyQualities: qualityItem.readyQualities,
                availabilityState: getAvailabilityReadyState(qualityItem.readyQualities, registrar.targetQuality),
                type: qualityItem.type

            });
            if (this.isAllQualitiesAvailable(qualityItem)) {
                this.unregister(registrar.type + registrar.playerId);
            }
        },

        isAllQualitiesAvailable(qualityItem) {
            return qualityItem.readyQualities.length === qualityItem.qualities.length;
        }

    };

    return VideoQualityUtils;
});
