define([
    'santa-components',
    'lodash',
    'prop-types',
    'create-react-class',
    'mobx-react',
    'santaProps',
    'compUtils',
    'utils'
], function (santaComponents, _, PropTypes, createReactClass, mobxReact, santaProps, compUtils, utils) {
    'use strict';

    return mobxReact.observer(createReactClass({
        propTypes: {
            siteAPI: PropTypes.object,
            requestRelayout: PropTypes.func,
            compDescriptions: PropTypes.arrayOf(
                PropTypes.shape({
                    structure: PropTypes.object,
                    props: PropTypes.object
                })
            )
        },
        displayName: 'AspectSantaCompsPortal',
        componentDidCatch(error, info) {
            utils.integrations.ravenUtils.captureError(error, {
                tags: {
                    aspect: true
                },
                extra: {
                    info,
                    compDescriptions: this.props.compDescriptions
                }
            });
        },
        render() {
            const children = _.map(this.props.compDescriptions, function (compInfo) {
                const siteAPI = this.props.siteAPI;
                const compFactoryRuntimeState = siteAPI.getSiteData().compFactoryRuntimeState;
                const compClass = compUtils.compFactory.getCompReactClass(compInfo.structure.componentType, false, compFactoryRuntimeState);
                const compClassFactory = compUtils.compFactory.getCompClass(compInfo.structure.componentType, true, compFactoryRuntimeState);
                const props = santaProps.componentPropsBuilder.getCompProps(compClass, compInfo.structure, siteAPI, compInfo.pageId, null, compInfo.props);
                _.assign(props, compInfo.props, {key: props.key || compInfo.structure.id, ref: compInfo.structure.id});

                const styleProps = {
                    compClass,
                    key: `${props.key}Style`,
                    siteAPI,
                    structure: compInfo.structure,
                    requestRelayout: this.props.requestRelayout,
                    invalidateCache: true
                };

                const styleClass = compUtils.styleNodeFactory(props.key, compClass);
                return [compClassFactory(props), santaComponents.utils.createReactElement(styleClass, styleProps)];
            }.bind(this));

            return santaComponents.utils.createReactElement('div', {}, _.flatten(children));
        }
    }));
});
