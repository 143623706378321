define([
    'lodash',
    'componentsCore'
], function (_, componentsCore) {
    'use strict';

    /**
     *
     * @param aspectsSiteAPI
     * @constructor
     */
    function SiteVisibilityAspect(aspectsSiteAPI) {
        this._aspectSiteAPI = aspectsSiteAPI;
        this._aspectSiteAPI.registerToSiteReady(() => this._aspectSiteAPI.registerToVisibilityChange(this.propagateEvent.bind(this)));
        this.registeredCallbacks = {};
    }

    SiteVisibilityAspect.prototype = {
        propagateEvent() {
            const state = this.get();
            _.forEach(this.registeredCallbacks, function (callback) {
                callback(state);
            });
        },
        register(id, callback, immediate) {
            this.registeredCallbacks[id] = callback;
            if (immediate) {
                callback(this.get());
            }
        },
        unregister(id) {
            this.registeredCallbacks = _.omit(this.registeredCallbacks, id);
        },
        get() {
            return this._aspectSiteAPI.getVisibilityState();
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('siteVisibilityChange', SiteVisibilityAspect);

    return SiteVisibilityAspect;
});
