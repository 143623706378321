define([
    'lodash',
    'coreUtils',
    'core/components/behaviorHandlers/prefetchPagesBehaviorHandler'
], function (
    _,
    coreUtils,
    prefetchPagesBehaviorHandler
) {
    'use strict';

    function createPopupsHandler() {
        function isInEditorMode(siteAPI) {
            return siteAPI.getRenderFlag('componentViewMode') === 'editor';
        }

        function hasBeenPopupOpened(siteAPI, popupId) {
            const runtimeDal = siteAPI.getRuntimeDal();
            return runtimeDal.hasBeenPopupOpened(popupId);
        }

        function shouldShowPopup(siteAPI, siteData, behavior) {
            const popupId = behavior.targetId;

            if (siteAPI.isZoomOpened()) {
                return false;
            }

            if (isInEditorMode(siteAPI)) {
                return false;
            }

            if (hasBeenPopupOpened(siteAPI, popupId) || siteAPI.isPopupOpened()) {
                return false;
            }

            if (siteData.isMobileView()) {
                return Boolean(behavior.params.openInMobile);
            }

            return Boolean(behavior.params.openInDesktop);
        }

        function getDelayInMs(behavior) {
            return 1000 * (behavior.params.delay || 0);
        }

        let delayedCall;

        return function openPopup(behavior, siteAPI) {
            clearTimeout(delayedCall);

            const siteData = siteAPI.getSiteData();

            if (!shouldShowPopup(siteAPI, siteData, behavior)) {
                return;
            }

            delayedCall = setTimeout(function openPopupCallback(primaryPageId) {
                // TODO: I'm sorry. Can we check if we can remove side effects in editor navigation itself (so it does not call at all the .navigateTo() in viewer)?
                const hasPageUnexpectedlyChanged = siteData.getPrimaryPageId() !== primaryPageId;

                if (hasPageUnexpectedlyChanged) {
                    return;
                }

                if (shouldShowPopup(siteAPI, siteData, behavior)) {
                    siteAPI.getSiteAspect('PopupPageAspect').openPopupPage(behavior.targetId);
                }
            }, getDelayInMs(behavior), siteData.getPrimaryPageId());
        };
    }

    function isEnabled() {
        return true;
    }

    function init() {
        return {
            default: function missingBehavior(behavior) {
                coreUtils.log.error('Invalid behavior', behavior);
            },
            openPopup: createPopupsHandler(),
            prefetchPages: prefetchPagesBehaviorHandler.prefetchPages
        };
    }

    function initIsEnabledFunctions() {
        return {
            default: function missingBehavior(behavior) {
                coreUtils.log.error('Invalid behavior', behavior);
            },
            openPopup: isEnabled,
            prefetchPages: prefetchPagesBehaviorHandler.isEnabled
        };
    }

    let siteBehaviors; // eslint-disable-line santa/no-module-state
    let isEnabledFunctions; // eslint-disable-line santa/no-module-state

    return {
        handle: function handle(behaviors, siteAPI) {
            siteBehaviors = siteBehaviors || init();
            _.forEach(behaviors, function (behavior) {
                const behaviorFunc = siteBehaviors[behavior.name] || siteBehaviors.default;
                behaviorFunc(behavior, siteAPI);
            });
        },
        isEnabled(behavior, siteAPI) {
            isEnabledFunctions = isEnabledFunctions || initIsEnabledFunctions();

            const isEnableFunc = isEnabledFunctions[behavior.name] || isEnabledFunctions.default;
            return isEnableFunc(siteAPI);
        }
    };
});
