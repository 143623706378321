define([
    'santa-components',
    'zepto',
    'lodash',
    'react',
    'prop-types',
    'create-react-class',
    'reactDOM',
    'mobx',
    'santaProps',
    'coreUtils',
    'core/siteRender/WixThemeReact',
    'core/siteRender/WixPageReact',
    'core/siteRender/wixBackgroundInstantiator',
    'core/siteRender/beatsGenerator',
    'layout',
    'utils',
    'core/siteRender/SiteAPI',
    'core/siteRender/siteAspectsMixin',
    'core/siteRender/siteAspectsDomContainer',
    'core/siteRender/extraSiteHeight',
    'core/siteRender/blockingLayer',
    'core/siteRender/wixAdsRenderer',
    'core/siteRender/WixPopupRoot',
    'core/siteRender/siteClickHandler',
    'core/siteRender/siteTraversalMixin',
    'core/components/selectionSharer/selectionSharer',
    'core/core/requireCompCode',
    'core/bi/events',
    'compUtils',
    'warmupUtils',
    'experiment'
], function (
    santaComponents,
    $,
    _,
    React,
    PropTypes,
    createReactClass,
    ReactDOM,
    mobx,
    santaProps,
    coreUtils,
    WixThemeReactClass,
    wixPageReactClass,
    wixBackgroundInstantiator,
    beatsGenerator,
    layout,
    /** utils */ utils,
    SiteAPI,
    siteAspectsMixin,
    siteAspectsDomContainerClass,
    extraSiteHeightClass,
    blockingLayerClass,
    wixAdsRenderer,
    WixPopupRootClass,
    siteClickHandler,
    siteTraversalMixin,
    selectionSharer,
    requireCompCode,
    events,
    compUtils,
    warmupUtils,
    experiment
) {
    'use strict';

    const BEAT = {
        START_WSR_RENDER: 7,
        FINISH_WSR_RENDER: 8,
        REVEAL: 14
    };

    const RELAYOUT_DEBOUNCE = {
        WAIT: 200,
        MAX_WAIT: 500
    };

    const componentPropsBuilder = santaProps.componentPropsBuilder;

    const MOUSE_TRAP_DELAY = 500;

    const MAX_REQUESTS_TO_REPORT = 32;
    const wixThemeReactFactory = React.createFactory(WixThemeReactClass);

    const wixPageReact = React.createFactory(wixPageReactClass);
    const wixPopupRootConstructor = React.createFactory(WixPopupRootClass);
    const siteAspectsDomContainer = React.createFactory(siteAspectsDomContainerClass);
    const extraSiteHeight = React.createFactory(extraSiteHeightClass);
    const blockingLayer = React.createFactory(blockingLayerClass);

    function initComponentsRelayoutHandling(reLayoutFunc) {
        this.pendingRenderCounter = mobx.computed(function () {
            return _(this.props.siteData.renderingCompsMap.values())
                .reject()
                .size() + (this.props.siteData.relayoutBlockedByQueue ? 1 : 0);
        }, {context: this, name: 'pendingRenderCounter'});

        mobx.observe(this.pendingRenderCounter, mobx.action(changeEvent => {
            if (changeEvent.newValue === 0 && this.props.siteData.renderingCompsMap.size > 0) {
                const renderedComps = this.props.siteData.renderingCompsMap.toJS();
                this.pendingReLayoutCompsMap = _.assign(this.pendingReLayoutCompsMap || {}, renderedComps);
                this.reLayoutPending = true;
                this.props.siteData.renderingCompsMap.clear();

                reLayoutFunc();
            }
        }), true);
    }

    function isDesktopMode(siteAPI) {
        return !siteAPI.getSiteData().isMobileView();
    }

    function getReactRef(component, childRefName) {
        const componentCompRef = _.get(component, ['compRefs', childRefName]);
        const componentReactRef = _.get(component, ['refs', childRefName]);
        return componentCompRef || componentReactRef;
    }

    /**
     * finds a node in a react component, if it is a react ref
     * @this {ReactComponent}
     * @returns {Element|undefined}
     */
    const reactPageFind = function (ref, a, b, c, d, e, f) { // eslint-disable-line complexity
        ref = ref && a ? getReactRef(ref, a) : ref;
        ref = ref && b ? getReactRef(ref, b) : ref;
        ref = ref && c ? getReactRef(ref, c) : ref;
        ref = ref && d ? getReactRef(ref, d) : ref;
        ref = ref && e ? getReactRef(ref, e) : ref;
        ref = ref && f ? getReactRef(ref, f) : ref;
        return ref && ReactDOM.findDOMNode(ref);
    };

    function getMobxObserverWrapperProps(viewerPrivateServices, siteAPI, addComponentRefFunc, scopedRoot, requestRelayout) {
        return {
            viewerPrivateServices,
            siteAPI,
            addComponentRef: addComponentRefFunc,
            styleRoot: scopedRoot ? `#${scopedRoot}` : null,
            requestRelayout
        };
    }

    function getRootProps(siteAPI, mobxObserverWrapperProps) {
        const measureMap = this.deletedMeasureMap || this.props.siteData.measureMap;

        const props = componentPropsBuilder.getRootProps(wixPageReactClass, this.props.rootId, siteAPI, this.props.viewerPrivateServices, measureMap);
        props.structure = _.merge({}, props.structure, {
            id: 'masterPage',
            layout: {position: this.props.siteRootPosition},
            componentType: 'mobile.core.components.MasterPage' //TODO-mobx: this type should be added to official pageData that is get from siteData
        }); //this is needed so that the SITE_ROOT, which is relative, gets it's height in a landing page
        props.ref = this.props.rootId;
        props.refInParent = props.ref;
        props.className = 'SITE_ROOT';
        props.firstPage = true;
        props.style = {width: '100%'}; //this is correct for page, since it overrides it's default width
        props.key = `${siteAPI.getSiteData().isMobileView() ? 'mobile_' : 'desktop_'}siteRoot`;
        props.mobxObserverWrapperProps = mobxObserverWrapperProps;

        return props;
    }

    function getResizeHandler() {
        const self = this;
        return _.throttle(function () {
            if (!self.dead && self.getMasterPage()) {
                self.siteAPI.resetSiteReacted();
                self.siteAPI.getActionQueue().runImmediately(self.props.siteData.updateScreenSize);
                if (!self.siteAPI.didReact()) {
                    self.registerReLayoutPending('WixSiteReact');
                    self.reLayout();
                }
            }
        }, 500, {
            leading: false,
            trailing: true
        });
    }

    function useMouseTrap(keys, handler) {
        setTimeout(function () {
            requirejs(['mousetrap'], function (mousetrap) {
                mousetrap.bind(keys, handler);
            }, _.noop);
        }, MOUSE_TRAP_DELAY);
    }

    function activeExperimentsNotifier() {
        if (window.rendererModel) {
            const siteData = this.props.siteData;
            if (siteData.isViewerMode() && !siteData.isAddPanelView && !siteData.isMobileDevice() && !siteData.isTabletDevice()) {
                useMouseTrap('ctrl+alt+shift+e', function () {
                    const activeExperiments = _(window.rendererModel.runningExperiments)
                        .pickBy(function (value) {
                            return value === 'new';
                        })
                        .keys().value();
                    window.alert(`Active Experiments:\n${activeExperiments.join('\n')}`); //eslint-disable-line no-alert
                });
            }
        }
    }

    function reactVersionNotifier() {
        const siteData = this.props.siteData;
        const version = siteData.baseVersion || 'could not retrieve version!';

        if (siteData.isMobileDevice() || siteData.isTabletDevice()) {
            const alertVersion = _.find(siteData.currentUrl.query, function (paramValue, paramKey) {
                return paramKey.toLowerCase() === 'alertversion';
            });
            if (alertVersion) {
                window.alert(`You are running React!\nVersion: ${version}`); //eslint-disable-line no-alert
            }
        } else if (siteData.isViewerMode() && !siteData.isAddPanelView) {
            useMouseTrap('ctrl+alt+shift+v', function () {
                window.alert(`You are running React!\nVersion: ${version}`); //eslint-disable-line no-alert
            });
        }
    }

    function notifyComponentDidLayout(topLevelComps) {
        _.forEach(topLevelComps, notifyComponentDidLayoutRecursive);
    }

    function notifyComponentDidLayoutRecursive(comp) {
        if (comp.componentDidLayout) {
            comp.componentDidLayout.call(comp);
        }
        if (comp.componentDidLayoutAnimations) { //this is temp, should be more generic
            comp.componentDidLayoutAnimations.call(comp);
        }
        _.forOwn(comp.refs, notifyComponentDidLayoutRecursive);
        _.forOwn(comp.compRefs, notifyComponentDidLayoutRecursive);
    }

    function findCompInAspect(siteAspectsContainer) {
        return function () {
            const args = _.toArray(arguments);
            return reactPageFind.apply(null, [siteAspectsContainer].concat(args)) || reactPageFind.apply(null, [siteAspectsContainer.refs.aspectPortal].concat(args));
        };
    }

    function getPagesAndBackgroundStructuresDescription() {
        const layoutAPI = warmupUtils.layoutAPI(this.siteAPI);
        const currentRenderedPageId = layoutAPI.getCurrentUrlPageId();

        const pageDomNodeFunc = reactPageFind.bind(null, this.getPageById(currentRenderedPageId));
        const masterPageDomNodeFunc = reactPageFind.bind(null, this.getMasterPage());
        const siteBackgroundDomNodeFunc = reactPageFind.bind(null, this);

        const structuresDesc = {
            inner: coreUtils.structuresDescription.getInner(layoutAPI, pageDomNodeFunc),
            outer: coreUtils.structuresDescription.getOuter(layoutAPI, masterPageDomNodeFunc),
            siteBackground: coreUtils.structuresDescription.getSiteBackground(siteBackgroundDomNodeFunc)
        };

        const currentPopupId = layoutAPI.getCurrentPopupId();
        if (currentPopupId) {
            structuresDesc[currentPopupId] = coreUtils.structuresDescription.getRootDescriptor(layoutAPI, currentPopupId, reactPageFind.bind(null, this.getPageById(currentPopupId)));
        }

        return structuresDesc;
    }

    function getStructuresDescription(includePages) {
        const layoutAPI = warmupUtils.layoutAPI(this.siteAPI);
        const aspectStructures = this.getAspectsComponentStructures();

        const findCompNodeInAspect = findCompInAspect(this.refs.siteAspectsContainer);
        const structuresDesc = _.transform(aspectStructures, function (result, structure) {
            result[structure.id] = {
                structure,
                getDomNodeFunc: findCompNodeInAspect
            };
        }, {});

        if (!includePages) {
            return structuresDesc;
        }

        _.assign(structuresDesc, getPagesAndBackgroundStructuresDescription.call(this));

        if (layoutAPI.shouldShowWixAds()) {
            structuresDesc.wixAds = coreUtils.structuresDescription.getWixAds(layoutAPI);
        }

        return structuresDesc;
    }

    function removeServerGeneratedClassesForHTMLembeds(siteData) {
        const elementsHiddenByServer = $('.hiddenTillReady');
        if (siteData.isMobileDevice()) {
            $('.wix-menu-enabled').removeClass('wix-menu-enabled'); //remove server class from the menu so it doesn't mess with our site placement... they have top:60px!important
            elementsHiddenByServer.css({display: 'none'}); //the server sets the opacity to 0, we want display:none in mobile so it doesn't take up space in DOM
        } else {
            elementsHiddenByServer.removeClass('hiddenTillReady'); //we want it to be visible after site is rendered in client.
        }
    }

    function getCurrentScroll(siteContainer) {
        return {
            x: siteContainer.pageXOffset || siteContainer.scrollX || 0,
            y: siteContainer.pageYOffset || siteContainer.scrollY || 0
        };
    }

    function clearComponentIsAliveFromTPA(siteAPI, currentRoute) {
        if (currentRoute.routerDefinition) {
            const tpaComponentsDomAspect = siteAPI.getSiteAspect('tpaComponentsDomAspect');
            const allTPAsOnPage = siteAPI.getTPAComponentsOnPage(currentRoute.pageId);
            _.forEach(allTPAsOnPage, function (compId) {
                tpaComponentsDomAspect.clearComponentIsAlive(compId);
            });
        }
    }

    const loadPlatformApps = mobx.action(function (siteAPI, pageInfo) { //eslint-disable-line santa/no-module-state, complexity
        const siteData = siteAPI.getSiteData();
        const isLoadingPageStaticOrPopup = !pageInfo.routerDefinition;

        const returningFromPopup = siteData.getFocusedRootId() === siteAPI.getCurrentPopupId();
        const popupToPrimaryPageNavigation = returningFromPopup && pageInfo.pageId === siteData.getPrimaryPageId();
        const didFocusedPageChanged = siteData.getFocusedRootId() !== pageInfo.pageId;

        const langHasChanged = pageInfo.isLanguageChange;

        if (isLoadingPageStaticOrPopup && didFocusedPageChanged && !popupToPrimaryPageNavigation) {
            siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
            return;
        }

        if (pageInfo.routerDefinition) {
            const navigationWithinSameDynamicPage = !didFocusedPageChanged;
            const innerRouteChanged = pageInfo.innerRoute !== siteData.getRootNavigationInfo().innerRoute;
            if (navigationWithinSameDynamicPage && innerRouteChanged) {
                siteAPI.resetRuntimeOverrides(pageInfo.pageId, true);
                siteAPI.getSiteAspect('WidgetAspect').stopApps([pageInfo.pageId]);
                siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
                return;
            }

            const navigationFromNonPopupPage = didFocusedPageChanged && !returningFromPopup;
            if (navigationFromNonPopupPage) {
                siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
                return;
            }

            if (returningFromPopup) {
                const navigateToTheSameDynamicPage = pageInfo.pageId === siteData.getPrimaryPageId();
                if (!navigateToTheSameDynamicPage) {
                    siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
                } else if (innerRouteChanged) {
                    siteAPI.resetRuntimeOverrides(pageInfo.pageId, true);
                    siteAPI.getSiteAspect('WidgetAspect').stopApps([pageInfo.pageId]);
                    siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
                }
            }
        }

        if (langHasChanged) {
            siteAPI.getAllRenderedRootIds().forEach(rootId => {
                siteAPI.resetRuntimeOverrides(rootId, true);
            });
            siteAPI.getSiteAspect('WidgetAspect').stopApps([pageInfo.pageId]);
            siteAPI.getSiteAspect('WidgetAspect').loadApps([pageInfo.pageId], [pageInfo]);
        }
    });

    function getCompsToReLayout(siteData, structuresDesc, compsToRelayoutMap, pendingReLayoutCompsMap) {
        if (!siteData.measureMap || _.isEmpty(compsToRelayoutMap) && _.isEmpty(pendingReLayoutCompsMap)) { // eslint-disable-line no-mixed-operators
            return null;
        }

        const aspectsComponentsIds = _.transform(structuresDesc, function (acc, val, key) {
            if (!_.includes(['inner', 'outer', 'siteBackground', 'wixAds'], key)) {
                acc[key] = true;
            }
        }, {});

        return _.assign({}, pendingReLayoutCompsMap, compsToRelayoutMap, aspectsComponentsIds);
    }

    function navigatingToTheSameDynamicPage(siteData, pageInfo) {
        return siteData.getFocusedRootId() === pageInfo.pageId && !pageInfo.isRefetchingPageAfterLogin;
    }

    function registerCompBehavioursToAspects(siteAPI) {
        const actionAspect = siteAPI.getSiteAspect('actionsAspect');
        const behaviorsAspect = siteAPI.getSiteAspect('behaviorsAspect');
        const compBehavioursMap = siteAPI.getCompBehaviours();

        _.forOwn(compBehavioursMap, function (pageBehaviours, rootId) {
            _.forOwn(pageBehaviours, function (compBehaviors, compId) {
                actionAspect.registerBehaviors(compId, rootId, compBehaviors);
                behaviorsAspect.setBehaviorsForActions(behaviorsAspect.convertBehaviors(compBehaviors, compId), rootId, compId);
            });
        });
    }

    function reportComponents(siteAPI) {
        const siteData = siteAPI.getSiteData();
        const requested = requireCompCode.requested(siteData);
        if (requested.length) {
            siteAPI.reportBI(events.PAGE_COMP_TYPES_LIST, {
                firstPage: siteData.biData.getPageNumber() === 1,
                compList: JSON.stringify(_.take(requested, MAX_REQUESTS_TO_REPORT))
            });
        }
    }

    function createWixThemeReactFactory(shouldRenderPage, siteData) {
        return wixThemeReactFactory({
            siteData,
            styleRoot: this.props.scopedRoot ? `#${this.props.scopedRoot}` : null,
            ref: 'theme',
            key: 'wixThemeReactFactory',
            shouldRenderPage,
            siteAPI: this.siteAPI,
            requestRelayout: this.requestRelayout
        });
    }

    function createWixBg() {
        return wixBackgroundInstantiator.getWixBgComponent(this.mobxObserverWrapperProps);
    }

    function createWixAdsRenderer() {
        return santaComponents.utils.createReactElement(wixAdsRenderer(this.siteAPI), {
            key: 'wixAdsRenderer'
        });
    }

    function createSiteRoot() {
        const style = this.getRootStyle();
        style.minWidth = `${this.props.siteData.getSiteWidth()}px`;

        return santaComponents.utils.createReactElement('div', {
            className: 'SITE_ROOT',
            key: 'SITE_ROOT',
            id: this.props.scopedRoot ? null : 'SITE_ROOT',
            style,
            'aria-hidden': this.state.isAriaHidden
        }, wixPageReact(getRootProps.call(this, this.siteAPI, this.mobxObserverWrapperProps)));
    }

    function createWixPopupRootConstructor(siteData) {
        return wixPopupRootConstructor({
            currentPopupId: this.props.siteData.getCurrentPopupId(),
            viewerPrivateServices: this.props.viewerPrivateServices,
            siteAPI: this.siteAPI,
            siteData,
            ref: 'popupRoot',
            key: 'popupRoot',
            siteRootStyle: this.getRootStyle(),
            blockingPopupLayer: this.siteAPI.getRenderFlag('blockingPopupLayer'),
            measureMap: this.deletedMeasureMap,
            mobxObserverWrapperProps: this.mobxObserverWrapperProps
        });
    }

    function createSiteAspectsDomContainer() {
        return siteAspectsDomContainer({
            ref: 'siteAspectsContainer',
            key: 'siteAspectsContainer',
            siteWidth: this.props.siteData.getSiteWidth(),
            isMobileView: this.props.siteData.isMobileView(),
            getAspectComponentDescriptions: this.getAspectComponentDescriptions,
            siteAPI: this.siteAPI,
            requestRelayout: this.requestRelayout
        });
    }

    function createSelectionSharer(siteData) {
        return santaComponents.utils.createReactElement(selectionSharer, {
            ref: 'selectionSharer',
            key: 'selectionSharer',
            siteBasePath: siteData.santaBase,
            siteAPI: this.siteAPI
        });
    }

    function createExtraSiteHeight() {
        return extraSiteHeight({
            key: 'extraSiteHeight',
            siteAPI: this.siteAPI
        });
    }

    function createBlockingLayer() {
        return blockingLayer({
            siteAPI: this.siteAPI,
            key: 'blockingLayer',
            layerName: 'blockingLayer'
        });
    }

    function createThemeRenderer() {
        const themeRenderer = compUtils.compFactory.getCompClass(santaComponents.components.ThemeRenderer.compType, false, this.mobxObserverWrapperProps.siteAPI.getSiteData().compFactoryRuntimeState);
        const themeRendererStructure = {
            id: 'theme_renderer',
            componentType: santaComponents.components.ThemeRenderer.compType
        };
        const props = {
            rootId: 'masterPage',
            styleRoot: this.props.scopedRoot ? `#${this.props.scopedRoot}` : null,
            id: themeRendererStructure.id,
            structure: themeRendererStructure,
            mobxObserverWrapperProps: this.mobxObserverWrapperProps,
            key: 'wix_theme_renderer'
        };

        return themeRenderer(props);
    }

    function getDesktopModeSiteChildren(shouldRenderPage, shouldRenderCustomSignup, siteData) {
        const siteChildren = [
            createThemeRenderer.call(this),
            createWixThemeReactFactory.call(this, shouldRenderPage, siteData),
            shouldRenderPage ? createWixBg.call(this) : null,
            shouldRenderPage ? createSiteRoot.call(this) : null,
            createWixAdsRenderer.call(this),
            shouldRenderCustomSignup || shouldRenderPage ? createWixPopupRootConstructor.call(this, siteData) : null,
            createSiteAspectsDomContainer.call(this),
            createSelectionSharer.call(this, siteData),
            createExtraSiteHeight.call(this),
            createBlockingLayer.call(this)
        ];

        return _.compact(siteChildren);
    }

    function getMobileModeSiteChildren(shouldRenderPage, shouldRenderCustomSignup, siteData) {
        const siteChildren = [
            createThemeRenderer.call(this),
            createWixThemeReactFactory.call(this, shouldRenderPage, siteData),
            createWixAdsRenderer.call(this),
            shouldRenderPage ? createWixBg.call(this) : null,
            shouldRenderPage ? createSiteRoot.call(this) : null,
            shouldRenderCustomSignup || shouldRenderPage ? createWixPopupRootConstructor.call(this, siteData) : null,
            createSiteAspectsDomContainer.call(this),
            createSelectionSharer.call(this, siteData),
            createExtraSiteHeight.call(this),
            createBlockingLayer.call(this)
        ];

        return _.compact(siteChildren);
    }

    function handleQueryParams(siteData, pageInfo, ignorePageUriSeo) {
        const dismissQueryParams = [];

        // clean appSectionParams on page change
        // todo: Hey future coder - when the logic of removing queryParams on navigation exists, please remove this code 🙏
        if (!experiment.isOpen('removeQueryParams', siteData) && !pageInfo.hasAppSectionParams) {
            dismissQueryParams.push('appSectionParams');
        }

        return utils.wixUrlParser.getUrl(siteData, pageInfo, false, false, undefined, undefined, dismissQueryParams, ignorePageUriSeo);
    }

    return createReactClass({
        displayName: 'WixSite',
        mixins: [siteAspectsMixin, utils.renderDoneMixin, siteClickHandler, siteTraversalMixin],

        propTypes: {
            getDynamicPageRealPage: PropTypes.func,
            navigateMethod: PropTypes.func,
            updateHeadMethod: PropTypes.func,
            updateUrlIfNeededMethod: PropTypes.func,
            extraEventsHandlers: PropTypes.object,
            className: PropTypes.string,
            onAfterLayout: PropTypes.func,
            getSiteContainer: PropTypes.func,
            viewerPrivateServices: PropTypes.shape({
                pointers: PropTypes.object.isRequired,
                displayedDAL: PropTypes.object.isRequired,
                siteDataAPI: PropTypes.object.isRequired
            }).isRequired,
            siteData: PropTypes.object.isRequired,
            siteRootPosition: PropTypes.oneOf(['static', 'relative']),
            scopedRoot: PropTypes.string,
            rootId: PropTypes.string,
            wixCodeAppApi: PropTypes.shape({
                init: PropTypes.func.isRequired,
                sendMessage: PropTypes.func.isRequired,
                registerMessageHandler: PropTypes.func.isRequired,
                registerMessageModifier: PropTypes.func.isRequired
            })
        },

        getDefaultProps() {
            return {
                onAfterLayout: _.noop,
                siteRootPosition: 'static',
                scopedRoot: null,
                rootId: 'masterPage',
                wixCodeAppApi: {
                    init: _.noop,
                    sendMessage: _.noop,
                    registerMessageHandler: _.noop,
                    registerMessageModifier: _.noop
                }
            };
        },

        getPrimaryPage() {
            let page = this.getMasterPage();
            if (this.props.rootId === 'masterPage' && page) {
                const primaryPageId = this.props.siteData.getPrimaryPageId();
                page = this.getPageComponent(page, primaryPageId);
            }
            return page;
        },

        getCurrentPopup() {
            const popupId = this.props.siteData.getCurrentPopupId();

            if (popupId) {
                return utils.reactComponentUtils.getRef(this, 'popupRoot', popupId);
            }

            return null;
        },

        getMasterPage() {
            const page = this.refs[this.props.rootId];
            return page;
        },

        getPageById(pageId) {
            const masterPage = this.getMasterPage();
            if (pageId === this.props.rootId) {
                return masterPage;
            }
            if (masterPage && this.getPageComponent(masterPage, pageId)) {
                return this.getPageComponent(masterPage, pageId);
            }
            return utils.reactComponentUtils.getRef(this, 'popupRoot', pageId);
        },

        getPageComponent(page, pageId) {
            return _.get(this.getPageRefs(page), pageId);
        },

        getPageOfComp(compId) {
            const rootOfCompId = _.find(this.siteAPI.getAllRenderedRootIds(), rootId => {
                const pageComponents = this.getComponentsByPageId(rootId);
                return !!pageComponents && !!pageComponents[compId];
            });

            return this.getPageById(rootOfCompId);
        },

        /**
         * @param {Object} scroll animation hooks
         * @param callbacks
         * @param {Object} scrollContainer window or dom container to scroll, defaults to siteContainer
         * */
        scrollTo(scroll, callbacks, scrollContainer) {
            scrollContainer = scrollContainer || this.props.getSiteContainer();
            const isMobileView = this.props.siteData.isMobileView();
            const duration = coreUtils.scrollUtils.calcScrollDuration(getCurrentScroll(scrollContainer).y, scroll.y, isMobileView);
            const easingName = isMobileView ? 'Quint.easeOut' : 'Sine.easeInOut';
            this.props.siteData.animations.animate('BaseScroll', scrollContainer, duration, 0, {
                y: scroll.y,
                ease: easingName,
                callbacks
            });
        },

        scrollToAnchor(scroll, progressCB) {
            this.scrollTo(scroll, {onUpdate: progressCB});
        },

        scrollToTop() {
            this.scrollTo({y: 0});
        },

        getInitialState() {
            this.onAfterLayout = _.once(this.props.onAfterLayout);
            this.reLayoutPending = false;
            this.requestRelayout = () => this.reLayout();
            this.pendingReLayoutCompsMap = {};
            /** @type core.SiteAPI */
            this.siteAPI = new SiteAPI(this);
            this.isBusy = true;
            this.isChangingUrlPage = false;
            this.siteIsReady = false;
            this.siteIsFullyRendered = false;
            this.siteAPI.reportBeatEvent(BEAT.START_WSR_RENDER, this.props.siteData.getPrimaryPageId());
            this.compRefs = {};
            this._requestRelayout = this.requestRelayout;

            return {};
        },

        addComponentRef(componentValue, componentId) {
            if (componentValue) {
                this.compRefs[componentId] = componentValue;
            } else {
                delete this.compRefs[componentId];
            }
        },

        componentWillMount() {
            const siteData = this.props.siteData;
            const urlPageId = siteData.getCurrentUrlPageId();
            const urlRootInfo = siteData.getExistingRootNavigationInfo(urlPageId);
            this.mobxObserverWrapperProps = getMobxObserverWrapperProps.call(this, this.props.viewerPrivateServices, this.siteAPI, this.addComponentRef, this.props.scopedRoot, this.requestRelayout);

            initComponentsRelayoutHandling.call(this, this.reLayoutIfPending);

            if (!siteData.isFirstRenderAfterSSR()) {
                this.updateSiteHeadTag();
            }

            //TODO: Alissa this is here for animations, remove after the animations don't use this anymore
            const currentUrlPageId = this.siteAPI.isPageAllowed(urlRootInfo) ? urlPageId : null;
            this.siteAPI.setComponentRenderStart(`WixSiteReact_${this.props.rootId}`);
            this.setState({
                currentUrlPageId,
                currentPopupId: null
            });
        },

        componentWillUpdate() {
            coreUtils.loggingUtils.performance.start(coreUtils.loggingUtils.performanceMetrics.RE_RENDER);
            this.isBusy = true;

            this.siteAPI.setComponentRenderStart(`WixSiteReact_${this.props.rootId}`);
        },

        getSiteChildren(shouldRenderPage, shouldRenderCustomSignup) {
            const {siteData} = this.props;

            const siteChildren = isDesktopMode(this.siteAPI) ?
                getDesktopModeSiteChildren.call(this, shouldRenderPage, shouldRenderCustomSignup, siteData) :
                getMobileModeSiteChildren.call(this, shouldRenderPage, shouldRenderCustomSignup, siteData);

            // report mesh eligibility according to layoutSettings in masterPage data item
            if (this.props.siteData.getMasterPageLayoutSettings().mechanism === utils.constants.LAYOUT_MECHANISMS.MESH) {
                const meshEligibilityBeatScriptString = beatsGenerator.createMeshEligibilityBeatScriptString(siteData);
                // This would only run the script in ssr flow because dangerouslySetInnerHTML won't run the script on client render
                siteChildren.unshift(santaComponents.utils.createReactElement('script', {id: 'meshEligibilityBeat', key: 'meshEligibilityBeat', dangerouslySetInnerHTML: {__html: meshEligibilityBeatScriptString}}));
            }

            // report mesh success according to computed layoutMechanism that excludes some sites
            if (this.siteAPI.getLayoutMechanism() === utils.constants.LAYOUT_MECHANISMS.MESH) {
                const partiallyVisibleBeatScriptString = beatsGenerator.createPartiallyVisibleBeatScriptString(siteData);
                // This would only run the script in ssr flow because dangerouslySetInnerHTML won't run the script on client render
                siteChildren.push(santaComponents.utils.createReactElement('script', {id: 'partiallyVisibleBeat', key: 'partiallyVisibleBeat', dangerouslySetInnerHTML: {__html: partiallyVisibleBeatScriptString}}));
            }

            return siteChildren;
        },

        render() {
            const beforeRender = _.now();

            const siteData = this.props.siteData;
            const urlPageNavigationInfo = siteData.getExistingRootNavigationInfo(siteData.getCurrentUrlPageId());
            const shouldRenderPage = this.siteAPI.isPageAllowed(urlPageNavigationInfo);
            const shouldRenderCustomSignup = this.siteAPI.getSiteAspect('siteMembers').shouldRenderCustomSignupPage(urlPageNavigationInfo);

            if (!shouldRenderPage) {
                this.siteAPI.getSiteAspect('siteMembers').showDialogOnNextRender(urlPageNavigationInfo);
            }

            if (siteData.isInSSR()) {
                if (!shouldRenderPage) {
                    siteData.ssr.shouldRenderPage = false;
                } else {
                    siteData.ssr.aspectsComponentStructures = _.transform(this.getAspectsComponentStructures(), (result, structure) => {
                        result[structure.id] = {structure};
                    }, {});
                }
            }

            let classNames = this.props.className;
            if (siteData.isVisualFocusEnabled()) {
                classNames += ' visual-focus-on';
            }

            const result = santaComponents.utils.createReactElement('div', _.assign({
                style: {position: 'relative'},
                className: classNames,
                id: this.props.scopedRoot ? this.props.scopedRoot : null,
                onClick: this.clickHandler,
                children: this.getSiteChildren(shouldRenderPage, shouldRenderCustomSignup)
            }, this.props.extraEventsHandlers));

            coreUtils.trackingReporter.reportDuration({
                appName: 'santa',
                actionName: 'WixSiteReact',
                actionDurationMs: _.now() - beforeRender,
                pageUrl: siteData.currentUrl.full,
                pageId: siteData.getCurrentUrlPageId()
            });

            return result;
        },

        getRootStyle() {
            const style = {};
            style.width = '100%';
            style.top = this.props.siteData.getPageTopMargin();
            style.paddingBottom = this.props.siteData.getPageBottomMargin();
            if (this.state.siteRootHidden) {
                style.height = 0;
                style.overflowY = 'hidden';
            }
            return style;
        },

        onPopState() {
            // this is called as a result of the browser navigation buttons (back, forward)
            // therefore, the way to get the popped state is to use the location.href
            // property. Please note that the siteData.currentUrl is only set the new url
            // as a result of this call
            const url = window.location.href;
            const urlData = utils.wixUrlParser.parseUrl(this.props.siteData, url);

            if (_.get(urlData, 'queryParams.language')) {
                coreUtils.multilingual.setCurrentLanguage(urlData.queryParams.language);
            }

            if (urlData) {
                this.tryToNavigate(urlData, true);
            }
        },

        onHashChange() {
            // This is called whenever the hash is changed. Like onPopState, the has not reliably
            // been changed yet in siteData
            const urlData = utils.wixUrlParser.parseUrl(this.props.siteData, window.location.href);
            if (urlData && this.props.siteData.isUsingUrlFormat('slash')) {
                this.tryToNavigate(urlData);
            }
        },

        isPageExists(pageId) {
            return _.includes(this.props.siteData.getAllPageIds(), pageId) || utils.errorPages.isErrorPage(pageId);
        },

        updateUrlIfNeeded(navigationInfo) {
            const siteData = this.siteAPI.getSiteData();
            this.props.updateUrlIfNeededMethod(siteData, navigationInfo);
        },

        navigateIfPossible(pageInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory, ignorePageUriSeo) {
            const siteDataAPI = _.get(this.props, 'viewerPrivateServices.siteDataAPI');
            const siteData = this.siteAPI.getSiteData();

            if (this.isPageExists(pageInfo.pageId)) {
                if (this.siteAPI.isPageAllowed(pageInfo)) {
                    const pageData = siteData.getDataByQuery(pageInfo.pageId);
                    if (!pageData) {
                        return;
                    }
                    if (!pageData.isPopup) {
                        this.siteAPI.getSiteAspect('siteMembers').forceCloseDialog();
                    }
                    const pageInfoToPass = _.clone(pageInfo);
                    pageInfoToPass.title = pageInfo.title || pageData.pageUriSEO;

                    const url = handleQueryParams(siteData, pageInfoToPass, ignorePageUriSeo);
                    const shouldSkipHistory = siteData.isPopupPage(pageInfo.pageId) || skipHistory;

                    this.siteAPI.startingPageTransition(pageInfoToPass);
                    this.props.navigateMethod(this, siteDataAPI, url, pageInfoToPass, !shouldSkipHistory, leaveOtherRootsAsIs, shouldReplaceHistory);
                } else {
                    this.siteAPI.getSiteAspect('siteMembers').showDialogOnNextRender(pageInfo);
                    this.forceUpdate();
                }
            }
        },

        tryToNavigate(pageInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory, ignorePageUriSeo) {
            const siteData = this.siteAPI.getSiteData();
            const actionsAspect = this.siteAPI.getSiteAspect('actionsAspect');
            this.currNav = pageInfo;
            clearComponentIsAliveFromTPA(this.siteAPI, siteData.getRootNavigationInfo());
            if (pageInfo.routerDefinition && (!pageInfo.pageId || pageInfo.isRefetchingPageAfterLogin)) {
                this.siteAPI.startingPageTransition(pageInfo);
                this.props.getDynamicPageRealPage(siteData, pageInfo, additionalNavInfo => {
                    if (utils.errorPages.isErrorPage(additionalNavInfo.pageId)) {
                        utils.errorPages.setIsFixingDisplayedMasterPage();
                        utils.errorPages.updateErrorPageMasterData('masterPage', siteData.pagesData.masterPage);
                        // first load error page to master
                        this.props.viewerPrivateServices.siteDataAPI.loadPage(additionalNavInfo, () => {
                            this.dynamicPageTryToNavigate(pageInfo, actionsAspect, additionalNavInfo, siteData, this.siteAPI, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory);
                        });
                    } else {
                        this.dynamicPageTryToNavigate(pageInfo, actionsAspect, additionalNavInfo, siteData, this.siteAPI, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory);
                    }
                });
                return;
            }

            loadPlatformApps(this.siteAPI, pageInfo);
            this.navigateIfPossible(pageInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory, ignorePageUriSeo);
        },

        dynamicPageTryToNavigate(pageInfo, actionsAspect, additionalNavInfo, siteData, siteAPI, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory) { // eslint-disable-line complexity
            if (additionalNavInfo.errorInfo) {
                const errorPagesPopUp = siteAPI.getRenderRealtimeConfigItem('errorPagesPopUp');
                if (errorPagesPopUp) {
                    errorPagesPopUp(additionalNavInfo.errorInfo);
                }
            }
            if (!_.isEqual(this.currNav, pageInfo)) {
                actionsAspect.handlePagePageNavigationCanceled();
                return;
            }
            if (additionalNavInfo.isStaticPageRedirect) {
                pageInfo = additionalNavInfo;
            } else {
                _.assign(pageInfo, additionalNavInfo);
            }

            loadPlatformApps(this.siteAPI, pageInfo);

            if (navigatingToTheSameDynamicPage(siteData, pageInfo)) { //if navigation is to the same page need to reload worker event if navigation doesn't really occur
                const innerRouteChanged = pageInfo.innerRoute !== siteData.getRootNavigationInfo().innerRoute;
                this.handleSamePageAnchorNavigation(pageInfo);
                if (!innerRouteChanged && pageInfo.imageZoom === siteData.getRootNavigationInfo().imageZoom) {
                    actionsAspect.handlePagePageNavigationCanceled();
                    return;
                }
            }

            delete pageInfo.isRefetchingPageAfterLogin;
            this.navigateIfPossible(pageInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory);
        },
        handleSamePageAnchorNavigation(navInfo) {
            const siteAPI = this.siteAPI;
            const siteData = this.props.siteData;
            if (navInfo.anchorData) {
                const scrollProgressCallback = function (tweenMax) {
                    const scrollEnded = tweenMax.ratio === 1;
                    if (scrollEnded) {
                        siteAPI.getSiteAspect('anchorChangeEvent').setSelectedAnchorAsync(siteData, navInfo.anchorData, navInfo.pageId, utils.constants.ACTIVE_ANCHOR.DELAY_TO_END_SCROLL);
                    }
                };
                siteAPI.scrollToAnchor(navInfo.anchorData, scrollProgressCallback);
            } else if (!siteData.isImageZoom(navInfo) && !navInfo.routerDefinition) {
                this.scrollToTop();
            }
        },
        handleNavigation(navInfo, linkUrl, changeUrl, keepRoots) { // eslint-disable-line complexity
            const siteData = this.props.siteData;
            if (navInfo.pageId === siteData.getPrimaryPageId()) {
                if (linkUrl !== '#') { //used for zoom
                    const siteDataAPI = _.get(this.props, 'viewerPrivateServices.siteDataAPI');
                    this.props.navigateMethod(this, siteDataAPI, linkUrl, navInfo, changeUrl, keepRoots);
                }
                this.handleSamePageAnchorNavigation(navInfo);
            } else {
                this.tryToNavigate(navInfo, !changeUrl);
            }
        },

        enforceAndPatchLayout: mobx.action('enforceAndPatchLayout', function () {
            const structuresDesc = getPagesAndBackgroundStructuresDescription.call(this);
            const layoutAPI = warmupUtils.layoutAPI(this.siteAPI);

            const enforcedCompsMap = layout.enforceAndPatch(structuresDesc, layoutAPI);

            _.assign(this.props.siteData.reLayoutedCompsMap, enforcedCompsMap);

            return !_.isEmpty(enforcedCompsMap);
        }),

        reLayout: mobx.action('reLayout', function () { // eslint-disable-line complexity
            const allContextsReadyOrLifecycleFailed = experiment.isOpen('sv_handleFailingWixCodeSdk', this.props.siteData) ? this.siteAPI.getSiteAspect('WidgetAspect').allContextsReadyOrLifecycleFailed() : this.siteAPI.getSiteAspect('WidgetAspect').allContextsReady();
            if (allContextsReadyOrLifecycleFailed) {
                const siteData = this.props.siteData;
                const currentUrlPageId = siteData.getCurrentUrlPageId();
                const urlPageNavigationInfo = siteData.getExistingRootNavigationInfo(currentUrlPageId);
                const primaryPageWixPageReact = this.getPrimaryPage();
                const isPrimaryPageRendered = !!(primaryPageWixPageReact && reactPageFind(primaryPageWixPageReact, currentUrlPageId));
                const shouldReLayoutPages = isPrimaryPageRendered && this.siteAPI.isPageAllowedAndLoaded(urlPageNavigationInfo);
                const layoutAPI = warmupUtils.layoutAPI(this.siteAPI);

                if (!siteData.isAddPanelView) {
                    layout.updateBodyNodeStyle(layoutAPI);
                }

                this.props.siteData.updateScreenSize();

                const structuresDesc = getStructuresDescription.call(this, shouldReLayoutPages);

                const compsToReLayoutMap = this.forceFullReLayout ? null : getCompsToReLayout(siteData, structuresDesc, this.compsToRelayoutMap, this.pendingReLayoutCompsMap);
                this.forceFullReLayout = false;

                const reLayoutResult = mobx.untracked(() => {
                    const result = layout.reLayout(structuresDesc, this.siteAPI.getNoEnforceAnchors(), this.lockedCompMap, compsToReLayoutMap, layoutAPI);
                    return result;
                });

                siteData.measureMap = reLayoutResult.measureMap;
                _.assign(siteData.reLayoutedCompsMap, reLayoutResult.reLayoutedCompsMap);

                delete this.tempMeasureMap;

                registerCompBehavioursToAspects(this.siteAPI);
                if (shouldReLayoutPages) {
                    this.pendingReLayoutCompsMap = {};
                    this.reLayoutPending = false;
                    this.isBusy = false;
                    const masterPage = this.getMasterPage();
                    const hasDoneLayout = [masterPage, primaryPageWixPageReact, this.refs.siteAspectsContainer];
                    const currentPopup = this.getCurrentPopup();
                    if (currentPopup) {
                        hasDoneLayout.push(currentPopup);
                    }
                    const siteBackground = this._aspectsSiteAPI.getComponentById('SITE_BACKGROUND');
                    hasDoneLayout.push(siteBackground);
                    notifyComponentDidLayout(hasDoneLayout, reLayoutResult.reLayoutedCompsMap);
                }
                this.isBusy = false; //this needs to always be set to false, even if this.state.shouldRenderPage is false
                this.notifyAspects(this.supportedEvents.didLayout, []);

                if (!this.siteIsReady) {
                    this.siteIsReady = true;
                    if (this.siteAPI && this.siteAPI.setBiMarker) {
                        this.siteAPI.setBiMarker('lastTimeStamp');
                    }
                    this.notifyAspects(this.supportedEvents.siteReady);
                }
            }
        }),

        registerReLayoutPending(compId) {
            this.pendingReLayoutCompsMap[compId] = true;
            this.reLayoutPending = true;
        },

        cancelReLayoutPending(compId) {
            delete this.pendingReLayoutCompsMap[compId];
            if (_.isEmpty(this.pendingReLayoutCompsMap)) {
                this.reLayoutPending = false;
            }
            if (this.props.siteData.renderingCompsMap.get(compId) === false) {
                this.props.siteData.renderingCompsMap.delete(compId);
            }
        },

        reLayoutIfPending: mobx.action(function reLayoutIfPending() {
            if (this.reLayoutPending && this.pendingRenderCounter.get() === 0) {
                this.reLayoutPending = false;
                this._requestRelayout();
            }
        }),

        componentDidMount() {
            const {siteData} = this.props;
            siteData._isFirstRenderAfterSSR = false;

            if (typeof window !== 'undefined' && experiment.isOpen('debouncePendingRelayout', siteData)) {
                this._requestRelayout = _.debounce(this._requestRelayout, RELAYOUT_DEBOUNCE.WAIT, {maxWait: RELAYOUT_DEBOUNCE.MAX_WAIT});
            }

            const {siteAPI} = this;
            this.onAfterLayout();
            if (siteData.isUsingUrlFormat(utils.siteConstants.URL_FORMATS.SLASH)) {
                siteAPI.reportCurrentPageEvent();
            } else {
                siteAPI.reportPageEvent(siteData.currentUrl.full, siteData.getCurrentUrlPageId());
            }
            siteAPI.initFacebookRemarketing();
            siteAPI.initGoogleRemarketing();
            siteAPI.initYandexMetrika();
            siteAPI.setBiMarker('renderEnd');

            utils.dataCapsule.init(this._aspectsSiteAPI);

            mobx.reaction(function whenSiteNeedsAnUpdate() {
                const focusedRootId = siteData.getFocusedRootId();
                const primaryPageInfo = siteData.getExistingRootNavigationInfo(focusedRootId);
                return {
                    mobileView: siteData.isMobileView(),
                    siteWidth: siteData.getSiteWidth(),
                    screenWidth: siteData.getScreenWidth(),
                    pageAllowedAndLoaded: siteAPI.isPageAllowedAndLoaded(primaryPageInfo),
                    pageMargins: siteData.getPageMargins(),
                    viewMode: siteAPI.getRenderFlag('componentViewMode')
                };
            }, function () {
                this.forceUpdate();
            }, {context: this, compareStructural: true});

            mobx.reaction(function whenViewModeChanges() {
                return siteData.isMobileView();
            }, function forceFullReLayout() {
                this.forceFullReLayout = true;
            }, {context: this});

            mobx.reaction(function whenScreenWidthChanges() {
                return siteData.getScreenWidth();
            }, function partialFullToDisplayed() {
                this.props.viewerPrivateServices.siteDataAPI.updateDisplayedNodesLayoutAndUpdateAnchors();
            }, {context: this, name: 'screenWidthChangeAction'});

            const currentRootsInfo = mobx.computed(function () {
                const primaryRootId = siteData.getPrimaryPageId();
                const primaryRootNavigationInfo = siteData.getExistingRootNavigationInfo(primaryRootId);
                const popupId = siteData.getCurrentPopupId();

                const result = {
                    primaryRootId,
                    popupId,
                    routersRendererIndex: primaryRootNavigationInfo.routersRendererIndex,
                    language: _.get(primaryRootNavigationInfo, 'query.lang')
                };

                if (experiment.isOpen('sv_addPageAdditionalDataToMobxCompute', siteData)) {
                    result.pageAdditionalData = primaryRootNavigationInfo.pageAdditionalData;
                }

                return result;
            }, {context: this, compareStructural: true});

            mobx.observe(currentRootsInfo, change => {
                const displayedDal = siteAPI.getDisplayedDAL();
                displayedDal.removeByPath(['pageStubComponents', change.newValue.primaryRootId]);
                const primaryIdChanged = change.oldValue.primaryRootId !== change.newValue.primaryRootId;
                const routersRendererIndexChanged = change.oldValue.routersRendererIndex !== change.newValue.routersRendererIndex;
                const languageChanged = change.oldValue.language !== change.newValue.language;
                if (primaryIdChanged || routersRendererIndexChanged || languageChanged) {
                    this.isChangingUrlPage = true;
                }
                this.forceUpdate();
            });

            this.registerAspectToEvent(this.supportedEvents.siteReady, () => {
                siteAPI.reportBeatFinish(siteData.getCurrentUrlPageId());
                //unfortunately, this hack is needed:
                removeServerGeneratedClassesForHTMLembeds(siteData);
                reactVersionNotifier.call(this);
                activeExperimentsNotifier.call(this);
            });

            this.registerAspectToEvent(this.supportedEvents.renderedRootsChanged, () => {
                warmupUtils.maxScrollHandler.unregister();
            });

            this.registerAspectToEvent(this.supportedEvents.onRendered, () => {
                compUtils.progressiveReveal.onRendered(siteAPI);
            });
            this.registerAspectToEvent(this.supportedEvents.fullyRendered, () => {
                if (!this.siteIsFullyRendered) {
                    this.siteIsFullyRendered = true;
                    siteAPI.reportBeatEvent(BEAT.REVEAL, siteData.getPrimaryPageId());
                }
            });

            this.registerAspectToEvent(this.supportedEvents.resize, getResizeHandler.call(this));
            siteAPI.reportBeatEvent(BEAT.FINISH_WSR_RENDER, siteData.getPrimaryPageId());

            siteAPI.setComponentRenderEnd(`WixSiteReact_${this.props.rootId}`);
            reportComponents(siteAPI);
        },

        componentDidUpdate() {
            const {siteData} = this.props;
            if (this.tempMeasureMap && !siteData.measureMap) {
                siteData.measureMap = this.tempMeasureMap;
                delete this.deletedMeasureMap;
                delete this.tempMeasureMap;
            }

            const {siteAPI} = this;
            if (this.isChangingUrlPage) {
                siteAPI.setBiMarker('renderEnd');
                const currentPageId = siteData.getCurrentUrlPageId();
                siteAPI.reportBeatFinish(currentPageId);
                if (!siteData.isUsingUrlFormat(utils.siteConstants.URL_FORMATS.SLASH)) {
                    siteAPI.reportPageEvent(siteData.currentUrl.full, currentPageId);
                }
                siteAPI.fireGoogleRemarketing();
                siteAPI.reportYandexPageHit(siteData.currentUrl.full);

                this.isChangingUrlPage = false;
                this.notifyAspects(this.supportedEvents.urlPageChange);

                reportComponents(siteAPI);
            }

            siteAPI.setComponentRenderEnd(`WixSiteReact_${this.props.rootId}`);

            coreUtils.loggingUtils.performance.finish(coreUtils.loggingUtils.performanceMetrics.RE_RENDER);
        },

        updateSiteHeadTag(tagTypes) {
            if (this.props.updateHeadMethod) {
                this.props.updateHeadMethod(this.props.siteData, this.props.rootId, tagTypes);
            }
        },

        componentWillUnmount() {
            this.dead = true;
            this.siteAPI.onSiteUnmount();
        }
    });
});
