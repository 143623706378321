define(['lodash'], function (_) {
    'use strict';

    const prefetchPages = function (behavior, siteAPI) {
        const siteData = siteAPI.getSiteData();
        const pagesData = siteData.getPagesDataItems();
        const filters = behavior.params.prefetchFilters;

        const pageIdsToFetch = _(pagesData)
            .filter(function (pageData) {
                return _.every(filters, function (ids, key) {
                    return _.includes(ids, pageData[key]);
                });
            })
            .map('id')
            .value();

        if (_.isEmpty(pageIdsToFetch)) { return; }

        const siteDataAPI = siteAPI.getSiteDataAPI();
        let loadedPages = 0;

        _.forEach(pageIdsToFetch, function (pageId) {
            const pageInfo = {
                pageId
            };
            siteDataAPI.loadPage(pageInfo, function () {
                ++loadedPages;
                if (loadedPages === pageIdsToFetch.length) {
                    siteAPI.addPrefetchPages(pageIdsToFetch);
                }
            });
        });
    };

    const isEnabled = function () {
        return true;
    };

    return {
        prefetchPages,
        isEnabled
    };
});
