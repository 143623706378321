define([
    'lodash',
    'componentsCore',
    'core/components/siteAspects/videoFileAvailability',
    'core/components/util/mediaFeatureDetections'
], function (_,
             componentsCore,
             VideoFileAvailability,
             mediaFeatureDetections) {
    'use strict';

    const ASPECT_NAME = 'mediaAspect';
    const FEATURE_DETECTION = 'featureDetections';

    const DESKTOP_CONTEXT_LIMIT = 14;
    const MOBILE_CONTEXT_LIMIT = 6;

    class WebGLContextAspect {
        constructor({isMobile}) {
            this.limit = isMobile ? MOBILE_CONTEXT_LIMIT : DESKTOP_CONTEXT_LIMIT;
            this.counter = 0;
            this.listeners = [];

            this.add = () => {
                if (this.counter < this.limit) {
                    this.counter += 1;

                    return true;
                }

                return false;
            };

            this.remove = () => {
                this.counter = Math.max(0, this.counter - 1);
            };
        }
    }

    /**
     * @constructor
     * @param {AspectsSiteAPI} aspectsSiteAPI
     *
     */
    function MediaAspect(aspectsSiteAPI) {
        if (typeof window !== 'undefined' && !window.Hls) {
            requirejs(['hls-light'], () => this.updateQualityState('adaptiveVideo', {'hlsLoaded': true}));
        }
        this.aspectSiteAPI = aspectsSiteAPI;
        this.siteData = aspectsSiteAPI.getSiteData();
        this.isEnabled = true;
        this.playerStateChangeListeners = {};

        const fileAvailabilityConfig = {
            videoQualityCheckInterval: 5000,
            headRequestStaticUrl: this.siteData.getStaticVideoHeadRequestUrl()
        };
        this.fileAvailabilityUtil = new VideoFileAvailability(fileAvailabilityConfig);
        this.aspectSiteAPI.setAspectGlobalData(ASPECT_NAME, {});

        const isMobile = this.siteData.isMobileView() || this.siteData.isMobileDevice();
        this.webglContexts = new WebGLContextAspect({isMobile});
    }

    MediaAspect.prototype = {
        /**
         * Get existing playback data for a video, return undefined otherwise
         * @param {string} playerId
         * @returns {object|undefined}
         */
        getData(playerId) {
            return this.aspectSiteAPI.getAspectComponentData(ASPECT_NAME, playerId) || {};
        },

        /**
         * Get existing quality data for a video, return undefined otherwise
         * @param {string} videoId
         * @returns {object|undefined}
         */
        getQualityData(videoId) {
            return _.get(this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME), videoId);
        },

        /**
         * Update a player state and notify all state listeners
         * @param playerId
         * @param stateUpdate
         */
        updatePlayerState(playerId, stateUpdate) {
            this.aspectSiteAPI.updateAspectComponentData(ASPECT_NAME, playerId, stateUpdate);

            //todo: should be removed when we are observable by mobx
            this.notifyStateChange(playerId);
        },

        /**
         * Update a video quality state
         * @param videoId
         * @param stateUpdate
         */
        updateQualityState(videoId, stateUpdate) {
            const state = _.assign({}, this.getQualityData(videoId), stateUpdate);
            this.aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {[videoId]: state});
        },

        /**
         * Remove state registration of a player and notify empty state to all listeners
         * @param playerId
         */
        removePlayerState(playerId) {
            if (!_.isEmpty(this.getData(playerId))) {
                this.aspectSiteAPI.setAspectComponentData(ASPECT_NAME, playerId, {});
                //todo: remove notification calls
                this.notifyStateChange(playerId);
            }
        },

        /**
         * @typedef {object} MediaAspectServices
         *
         * Viewport:
         * @property {function} viewport.callback
         * @property {array<string>} viewport.eventTypes any of 'in', 'out', 'while_in'
         *
         * Visibility:
         * @property {function} visibility callback
         *
         * Quality (File Availability):
         * @property {function} fileAvailability.callback
         * @property {string} fileAvailability.mediaQuality
         * @property {array} fileAvailability.videoPartTypes video|storyboard|clip
         *
         */

        /**
         * Register a player to state and services
         * @param {string} playerId
         * @param options
         * @param {string} [options.playerType]
         * @param {object} [options.mediaData] WixVideo data item
         * @param {MediaAspectServices} [options.services]
         */
        registerPlayer(playerId, options) {
            options = options || {};
            const mediaData = _.get(options, 'mediaData');
            const videoId = _.get(mediaData, 'videoId');

            this.updatePlayerState(playerId, {
                playerType: options.playerType || 'none',
                videoId,
                duration: _.get(mediaData, 'duration', 0)
            });
            this.registerPlayerServices(playerId, options.mediaData, options.services);
        },

        /**
         * Remove player registration
         * @param {string} playerId
         */
        unregisterPlayer(playerId) {
            this.removePlayerState(playerId);
            this.unregisterPlayerServices(playerId);
        },

        /**
         * Register player to services
         * @param {string} playerId
         * @param {object} data
         * @param {MediaAspectServices} services
         */
        registerPlayerServices(playerId, data, services) {
            services = services || {};
            if (services.viewport) {
                this.aspectSiteAPI.getSiteAspect('viewportChange').register(playerId, services.viewport.callback, services.viewport.eventTypes);
            }
            if (services.visibility) {
                this.aspectSiteAPI.getSiteAspect('siteVisibilityChange').register(playerId, services.visibility.callback);
            }
            if (services.fileAvailability) {
                _.forEach(services.fileAvailability.videoParts, function (videoPart) {
                    this.fileAvailabilityUtil.register(playerId, data, videoPart.quality, videoPart.name, services.fileAvailability.callback);
                }.bind(this));
            }
        },


        /**
         * Remove player registration to services
         * @param {string} playerId
         */
        unregisterPlayerServices(playerId) {
            this.aspectSiteAPI.getSiteAspect('siteVisibilityChange').unregister(playerId);
            this.aspectSiteAPI.getSiteAspect('viewportChange').unregister(playerId);
            this.fileAvailabilityUtil.unregister(playerId);
        },

        /**
         * Register a listener callback to a player state change,
         * will immediately invoke the callback if this player state exists
         * @param {string} listenerId
         * @param {string} playerId
         * @param {function} callback
         */
        registerStateChange(listenerId, playerId, callback) {
            _.set(this.playerStateChangeListeners, [playerId, listenerId], callback);
            const state = this.getData(playerId);
            if (!_.isEmpty(state)) {
                callback(state);
            }
        },

        /**
         * Remove a listener to a player state
         * @param {string} listenerId
         * @param {string} playerId
         */
        unregisterStateChange(listenerId, playerId) {
            this.playerStateChangeListeners[playerId] = _.omit(this.playerStateChangeListeners[playerId], listenerId);
            if (_.isEmpty(this.playerStateChangeListeners[playerId])) {
                this.playerStateChangeListeners = _.omit(this.playerStateChangeListeners, playerId);
            }
        },

        /**
         * Notify state to all registered listeners of a player
         * @param {string} playerId
         * @param {object} state
         */
        notifyStateChange(playerId) {
            _.forEach(this.playerStateChangeListeners[playerId], _.bind(function (callback) {
                callback(this.getData(playerId));
            }, this));
        },

        /**
         * since controls are not aware to the video data , they hold only the player component id
         * this method gets the relevant WixVideo data for the controls display ,
         * @param playerId
         * @param compDesign
         * @param compProp
         * @returns {{hasAudio: boolean, storyboardUrl: string, videoAspectRatio: number}}
         */
        updateControlsData(playerId, compDesign, compProp) {
            const mediaData = _.get(compDesign, ['background', 'mediaRef']);
            const qualities = _.get(mediaData, 'qualities');
            const storyboard = _.find(qualities, {quality: 'storyboard'});
            const controlsData = {
                disableAudio: _.get(compProp, 'disableAudio', false),
                hasAudio: _.get(mediaData, 'hasAudio', true),
                canPause: _.get(compProp, ['playerInteraction', 'click']) === 'toggle'
            };
            if (storyboard) {
                _.assign(controlsData, {
                    storyboardUrl: _.get(storyboard, 'url'),
                    videoAspectRatio: storyboard && storyboard.width / storyboard.height
                });
            }
            this.aspectSiteAPI.updateAspectComponentData(ASPECT_NAME, playerId, {controlsData});
        },
        shouldPlay(playerId) {
            const viewportState = this.aspectSiteAPI.getSiteAspect('viewportChange').get(playerId);
            if (!viewportState || !viewportState.in) {
                return false;
            }
            const siteVisibilityState = this.aspectSiteAPI.getSiteAspect('siteVisibilityChange').get();
            return !siteVisibilityState.hidden;
        },
        initFeatureDetections() {
            if (!this.siteData.isViewerMode() || !this.siteData.isInSSR() && !_.get(this.aspectSiteAPI.getAspectGlobalData(ASPECT_NAME), FEATURE_DETECTION)) { // eslint-disable-line no-mixed-operators
                this.aspectSiteAPI.updateAspectGlobalData(ASPECT_NAME, {[FEATURE_DETECTION]: mediaFeatureDetections()});
            }
        },
        //santa placeholder returns noop , bolt return pageBackground
        getPrevPageBackground: _.noop
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect(ASPECT_NAME, MediaAspect);
    return MediaAspect;
});
