define(['lodash', 'coreUtils', 'core/bi/events.json', 'utils'], function (_, coreUtils, biEvents, utils) {
    'use strict';

    function cancelEvent(event) {
        event.stopPropagation();
        event.preventDefault();
        return false;
    }

    function moveToMobileIfNeeded(link, siteAPI) {
        if (link.getAttribute('data-mobile')) {
            const isMobile = coreUtils.stringUtils.isTrue(link.getAttribute('data-mobile'));
            siteAPI.setMobileView(isMobile);
            coreUtils.mobileViewportFixer.fixViewportTag(siteAPI.getSiteData());
        }
    }

    function handleExternalNavigationSomeCases(event, link, linkUrl) { // eslint-disable-line complexity
        const siteData = this.props.siteData;

        const isExternalLink = coreUtils.linkRenderer.isExternalLink(siteData, linkUrl);
        const isEmailLink = coreUtils.linkRenderer.isEmailLink(linkUrl);
        const isOnCurrentTab = link.getAttribute('target') === '_self';
        const previewTooltipCallback = this.siteAPI.getRenderRealtimeConfigItem('previewTooltipCallback');

        const shouldBlockLinkNavigation = !this.siteAPI.getRenderFlag('isExternalNavigationAllowed') && previewTooltipCallback &&
            (isExternalLink && isOnCurrentTab || isEmailLink); // eslint-disable-line no-mixed-operators
        if (shouldBlockLinkNavigation) {
            const targetClientRect = event.target.getBoundingClientRect();
            previewTooltipCallback(targetClientRect, 'text_editor_inactive_link_on_preview');
            return true;
        }
        const customClickCount = this.siteAPI.getCustomClickOccurred();
        if (customClickCount && isExternalLink && isOnCurrentTab) {
            return true;
        }

        return handlePremiumNavigation.call(this, linkUrl);
    }

    function getNavInfo(link, linkUrl, siteData) {
        const noUrlChangeAttr = link.getAttribute('data-no-physical-url');
        const url = noUrlChangeAttr || linkUrl;
        return coreUtils.wixUrlParser.parseUrl(siteData, url);
    }

    function isChangingUrl(link) {
        return !link.getAttribute('data-no-physical-url');
    }

    function isKeepingNavigationRoots(link) {
        return !!link.getAttribute('data-keep-roots');
    }

    function assignAdditionalDataToNavInfo(navInfo, link) {
        _.assign(navInfo, {
            pageItemAdditionalData: link.getAttribute('data-page-item-context'),
            anchorData: link.getAttribute('data-anchor')
        });
    }

    function handleSiteInternalNavigation(link, linkUrl) {
        const siteData = this.props.siteData;
        const navInfo = getNavInfo(link, linkUrl, siteData);

        if (!navInfo || link.getAttribute('target') === '_blank') {
            return false;
        }

        assignAdditionalDataToNavInfo(navInfo, link);

        const changeUrl = isChangingUrl(link);
        const keepRoots = isKeepingNavigationRoots(link);
        const currentPageId = this.siteAPI.getSiteData().getPrimaryPageId();
        const customClickCount = this.siteAPI.getCustomClickOccurred();
        if (customClickCount && navInfo && navInfo.pageId && navInfo.pageId !== currentPageId) {
            return true;
        }
        this.handleNavigation(navInfo, linkUrl, changeUrl, keepRoots);

        return true;
    }

    function logLinkBI(siteAPI, link) {
        if (!link.getAttribute('data-type')) {
            return;
        }

        siteAPI.reportBI(biEvents.CLICK_TO_ACTION, {
            'field_type': link.getAttribute('data-type'),
            'data': utils.logger.sanitizePIIForBi(link.getAttribute('data-content')),
            'input_type': link.getAttribute('data-auto-recognition') ? 'auto_recognized' : 'user_input'
        });
    }

    function isPremiumLink(linkUrl) {
        const serviceTopology = this.props.siteData.serviceTopology;
        const premiumServerHostname = utils.urlUtils.parseUrl(serviceTopology.premiumServerUrl).hostname;
        const linkUrlHostname = utils.urlUtils.parseUrl(linkUrl).hostname;

        return linkUrlHostname === premiumServerHostname;
    }

    function handlePremiumNavigation(linkUrl) {
        const premiumNavigationHandler = this.siteAPI.getRenderRealtimeConfigItem('premiumNavigationHandler');
        if (premiumNavigationHandler && isPremiumLink.call(this, linkUrl)) {
            return premiumNavigationHandler();
        }
        return false;
    }

    function getClosestAncestorByTagName(element, parentTagName) {
        if (!element || !element.tagName) {
            return null;
        }
        if (element.tagName.toLowerCase() === parentTagName) {
            return element;
        }
        return getClosestAncestorByTagName(element.parentNode, parentTagName);
    }

    function getAnchor(event) {
        return getClosestAncestorByTagName(event.target, 'a');
    }

    function handleAnchorClick(event, $link) {
        const linkUrl = $link.getAttribute('href');

        logLinkBI(this.siteAPI, $link);
        moveToMobileIfNeeded($link, this.siteAPI);

        return handleExternalNavigationSomeCases.call(this, event, $link, linkUrl) ||
            handleSiteInternalNavigation.call(this, $link, linkUrl);
    }

    function clickHandler(event) {
        const $link = getAnchor(event);
        const isEventCancelled = !!$link && handleAnchorClick.call(this, event, $link);

        if (this.siteAPI.isSelectionSharerVisible()) {
            this.siteAPI.hideSelectionSharer();
        }

        this.siteAPI.resetCustomClickOccurred();
        return isEventCancelled ? cancelEvent(event) : true;
    }

    return {
        clickHandler
    };
});
