define([
    'lodash',
    'componentsCore',
    'coreUtils',
    'core/bi/errors'
], function (
    _,
    componentsCore,
    coreUtils,
    errors
) {
    'use strict';

    const HEAD = 'head';
    const BODY_START = 'bodyStart';
    const BODY_END = 'bodyEnd';

    const NODE_LOCATION_STATUS = {
        VALID: 0,
        MISSING: 1,
        CORRUPTED: 2
    };

    const locations = [HEAD, BODY_START, BODY_END];

    function getContextByLocation(location) {
        switch (location) {
            case HEAD: {
                return window.document.head;
            }
            case BODY_START:
            case BODY_END: {
                return window.document.body;
            }
            default: return null;
        }
    }

    const locationToCommentBegin = {
        [HEAD]: 'head html embeds start',
        [BODY_START]: 'body start html embeds start',
        [BODY_END]: 'body end html embeds start'
    };

    const locationToCommentEnd = {
        [HEAD]: 'head html embeds end',
        [BODY_START]: 'body start html embeds end',
        [BODY_END]: 'body end html embeds end'
    };


    function getScriptsForPage(siteData, location, pageId) {
        const embeds = siteData.getCodeEmbeds();
        return _(embeds)
            .filter({'position': location})
            .filter(embed => embed.embedType === 'custom' || embed.embedType === 'verificationCode')
            .filter(embed => !embed.pages || _.some(embed.pages, page => page === pageId) || isLoadOnce(embed))
            .value();
    }

    function _copyChildren(fromElem, toElem) {
        _.forEach(fromElem.childNodes, function (orig) {
            let copy = null;
            if (orig.nodeType === window.Node.ELEMENT_NODE) {
                copy = window.document.createElement(orig.tagName);
                _.forEach(orig.attributes, function (attr) {
                    copy.setAttribute(attr.name, attr.value);
                });
                _copyChildren(orig, copy);
            } else if (orig.nodeType === window.Node.TEXT_NODE) {
                copy = window.document.createTextNode(orig.textContent);
            } else if (orig.nodeType === window.Node.COMMENT_NODE) {
                copy = window.document.createComment(orig.textContent);
            }
            if (copy) {
                toElem.appendChild(copy);
            }
        });
    }

    function addNewScripts(siteData, location, commentNodeBegin, remainingScriptsIdsInDom, nodesInDom) {
        let nodeToAddAfter = commentNodeBegin;
        const context = getContextByLocation(location);

        let nextAvailableScriptIndexInDom = 0;
        _.forEach(getScriptsForPage(siteData, location, siteData.getCurrentUrlPageId()), script => {
            const tempDiv = window.document.createElement('div');

            if (remainingScriptsIdsInDom.length > 0 && remainingScriptsIdsInDom[nextAvailableScriptIndexInDom] === script.id) {
                nodeToAddAfter = nodesInDom[nextAvailableScriptIndexInDom];
                nextAvailableScriptIndexInDom++;
            } else {
                const element = window.document.createElement('div');
                element.innerHTML = script.content.html;
                _copyChildren(element, tempDiv);

                while (tempDiv.children.length) {
                    context.insertBefore(tempDiv.children[0], nodeToAddAfter.nextSibling);
                }
                nodeToAddAfter = nodeToAddAfter.nextSibling;
            }
        });
    }

    function validateNodeLocations(locationObj) {
        if (!locationObj) {
            return NODE_LOCATION_STATUS.MISSING;
        }

        const indexStart = _.get(locationObj, ['index', 'start']);
        const indexEnd = _.get(locationObj, ['index', 'end']);
        const nodesFound = _.get(locationObj, ['nodes', 'start']) && _.get(locationObj, ['nodes', 'end']);
        if (indexStart && indexEnd && indexStart < indexEnd && nodesFound) {
            return NODE_LOCATION_STATUS.VALID;
        }
        return NODE_LOCATION_STATUS.CORRUPTED;
    }

    function getCommentNodesIndex(location) {
        const commentNodeBegin = locationToCommentBegin[location];
        const commentNodeEnd = locationToCommentEnd[location];
        const context = getContextByLocation(location);
        let commentStartIndex = null;

        let locationObj = null;

        for (let i = 0; i < context.childNodes.length; i++) {
            const node = context.childNodes[i];
            if (node.nodeType === window.Node.COMMENT_NODE) {
                if (node.textContent === commentNodeBegin) {
                    commentStartIndex = i;
                    locationObj = {location};
                    _.set(locationObj, ['index', 'start'], commentStartIndex);
                    _.set(locationObj, ['nodes', 'start'], node);
                } else if (node.textContent === commentNodeEnd) {
                    locationObj = _.merge(locationObj, {'index': {'end': i}, 'nodes': {'end': node}});
                    break;
                }
            }
        }

        return locationObj;
    }

    function getNodesBetween(nodesCollection, commentStartIndex, commentEndNode) {
        return _(nodesCollection)
            .slice(commentStartIndex + 1, commentEndNode)
            .filter({'nodeType': window.Node.ELEMENT_NODE})
            .value();
    }

    function removeNodes(context, nodeListToDelete) {
        for (let i = 0; i < nodeListToDelete.length; ++i) {
            context.removeChild(nodeListToDelete[i]);
        }
    }

    const isLoadOnce = script => script.loadOnce && !script.pages;
    const shouldRemoveScriptFromDom = script => !isLoadOnce(script);

    const addBodyCommentNodes = () => {
        const siteContainer = window.document.getElementById('SITE_CONTAINER');
        window.document.body.insertBefore(window.document.createComment(locationToCommentBegin[BODY_START]), siteContainer);
        window.document.body.insertBefore(window.document.createComment(locationToCommentEnd[BODY_START]), siteContainer);
        window.document.body.appendChild(window.document.createComment(locationToCommentBegin[BODY_END]));
        window.document.body.appendChild(window.document.createComment(locationToCommentEnd[BODY_END]));
    };

    const loadScriptsToBody = siteData => {
        const commentNodeLocations = _.map([BODY_START, BODY_END], location => getCommentNodesIndex(location));
        _.forEach(commentNodeLocations, nodeLocation => {
            addNewScripts(siteData, nodeLocation.location, nodeLocation.nodes.start, [], []);
        });
    };

    /**
     * CodeEmbedsAspect constructor
     * - register triggers
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @constructor
     */
    function CodeEmbedsAspect(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this._siteData = aspectSiteAPI.getSiteData();
        this._aspectSiteAPI.registerToUrlPageChange(this.onUrlPageChanged.bind(this));
        this._aspectSiteAPI.registerToSiteReady(this.onSiteReady.bind(this));
        this._aspectSiteAPI.registerToComponentDidMount(() => {this.previousPageId = this._siteData.getCurrentUrlPageId();});
    }


    CodeEmbedsAspect.prototype.onSiteReady = function () {
        if (!this._siteData.getCodeEmbeds() || _.isEmpty(this._siteData.getCodeEmbeds())) {
            return;
        }

        const commentNodeStatus = _.map(locations, location => validateNodeLocations(getCommentNodesIndex(location)));

        if (_.includes(commentNodeStatus, NODE_LOCATION_STATUS.CORRUPTED)) {
            this._commentNodeStatus = NODE_LOCATION_STATUS.CORRUPTED;
            coreUtils.loggingUtils.logger.reportBI(this._siteData, errors.INVALID_CODE_EMBED_SCRIPT, {});
        } else if (_.includes(commentNodeStatus, NODE_LOCATION_STATUS.MISSING)) {
            this._commentNodeStatus = NODE_LOCATION_STATUS.VALID;
            addBodyCommentNodes();
            loadScriptsToBody(this._siteData);
        } else {
            this._commentNodeStatus = NODE_LOCATION_STATUS.VALID;
        }
    };

    CodeEmbedsAspect.prototype.onUrlPageChanged = function () {
        if (this._commentNodeStatus !== NODE_LOCATION_STATUS.VALID) {
            return;
        }

        const previousPageId = this.previousPageId;
        this.previousPageId = this._siteData.getCurrentUrlPageId();

        const commentNodeLocations = _.map(locations, location => getCommentNodesIndex(location));

        //Collect nodes to remove
        const nodesToDelete = {};
        const remainingScriptsIdsInDom = {};
        const remainingNodesInDom = {};
        _.forEach(commentNodeLocations, commentNodeLocation => {
            const location = commentNodeLocation.location;
            const context = getContextByLocation(location);
            const nodesInDom = getNodesBetween(context.childNodes, commentNodeLocation.index.start, commentNodeLocation.index.end);
            const scriptsInServer = getScriptsForPage(this._siteData, location, previousPageId);
            nodesToDelete[location] = [];
            remainingScriptsIdsInDom[location] = [];
            remainingNodesInDom[location] = [];
            for (let i = 0; i < scriptsInServer.length; ++i) {
                if (shouldRemoveScriptFromDom(scriptsInServer[i])) {
                    nodesToDelete[location].push(nodesInDom[i]);
                } else {
                    remainingScriptsIdsInDom[location].push(scriptsInServer[i].id);
                    remainingNodesInDom[location].push(nodesInDom[i]);
                }
            }
        });

        //Remove nodes
        for (let i = 0; i < locations.length; ++i) {
            const location = locations[i];
            const context = getContextByLocation(location);
            removeNodes(context, nodesToDelete[location]);
        }

        //Add new nodes
        _.forEach(commentNodeLocations, nodeLocation => {
            addNewScripts(this._siteData, nodeLocation.location, nodeLocation.nodes.start, remainingScriptsIdsInDom[nodeLocation.location], remainingNodesInDom[nodeLocation.location]);
        });
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('codeEmbedsAspect', CodeEmbedsAspect);
});
