define([
    'lodash',
    'coreUtils', //eslint-disable-line
    'wix-ui-santa/viewerComponentService.bundle', //eslint-disable-line santa/enforce-package-access
    'core/core/externalComponentsServices',
    'componentsCore'
], function (
    _,
    coreUtils,
    viewerComponentService,
    externalComponentsServices,
    componentsCore
) {
    'use strict';

    externalComponentsServices.register(viewerComponentService);

    const {getPackagesForComponent, allPackages, packagesToCallbacks} = coreUtils;

    const _comps = {};
    const STATUS = {
        PENDING: 'PENDING',
        LOADED: 'LOADED',
        FAILURE: 'FAILURE'
    };

    const storeData = {
        markComponentAsPending: componentType => {
            if (!_comps[componentType]) {
                _comps[componentType] = {status: STATUS.PENDING};
            }
        },

        isComponentLoaded: componentType =>
            _comps[componentType] && _comps[componentType].status === STATUS.LOADED,

        markComponentAsLoaded: componentType => {
            _comps[componentType].status = STATUS.LOADED;
        }
    };

    //const isBaseUIInSsr = componentType => typeof window === 'undefined' && componentType.indexOf('wixui.baseui.') === 0;

    const viewerApiRegistrar = {
        register({santaComponent, componentType, component}, skinRegistrar) {
            const componentToRegister = santaComponent || component;
            if (componentToRegister) {
                componentsCore.compRegistrar.register(componentType, componentToRegister);
            }
            if (componentToRegister && componentToRegister.getComponentSkins) {
                skinRegistrar.addBatch(componentToRegister.getComponentSkins());
            }
        }
    };

    const loadAndRegisterExternalComponent = (componentType, skinRegistrar, wixUiSantaBaseUrl) =>
        externalComponentsServices.load(componentType, wixUiSantaBaseUrl)
            .then(viewerBundles => viewerBundles.forEach(viewerBundle =>
                viewerApiRegistrar.register(viewerBundle.default, skinRegistrar))
            );

    const loadAndRegisterAllExternalComponents = (skinRegistrar, wixUiSantaBaseUrl) =>
        externalComponentsServices.loadAll(wixUiSantaBaseUrl)
            .then(components =>
                components.forEach(component =>
                    component.forEach(viewerBundle =>
                        viewerApiRegistrar.register(viewerBundle.default, skinRegistrar))
                )
            );

    const loadAndRegisterAllInternalPackages = skinRegistrar => {
        const packageNames = allPackages();
        const packagesNamesWithCallbacks = packagesToCallbacks(packageNames);
        return new Promise(resolve => requirejs(packageNames, (...pkgs) => {
            packageNames.forEach((packageName, index) =>
                packagesNamesWithCallbacks[packageName](pkgs[index], componentsCore.compRegistrar.register, componentsCore.siteAspectsRegistry.registerHostLibsAspect, skinRegistrar.addBatch));
            resolve();
        }));
    };

    const loadAndRegisterInternalPackages = (packageNames, skinRegistrar) => {
        const packagesNamesWithCallbacks = packagesToCallbacks(packageNames);
        return new Promise(resolve => requirejs(packageNames, (...pkgs) => {
            packageNames.forEach((packageName, index) => packagesNamesWithCallbacks[packageName](pkgs[index], componentsCore.compRegistrar.register, componentsCore.siteAspectsRegistry.registerHostLibsAspect, skinRegistrar.addBatch));
            resolve();
        }));
    };

    const getbaseUrl = () => `${requirejs.toUrl('wix-ui-santa')}/`;

    return {
        isComponentLoaded: componentType => storeData.isComponentLoaded(componentType),

        exists: (componentType, siteData) =>
            getPackagesForComponent(componentType, siteData).length > 0 || externalComponentsServices.exists(componentType),

        prefetchComponents: (componentTypes, siteData) => {
            const externalComponents = componentTypes.filter(externalComponentsServices.exists);
            const internalComponents = componentTypes.filter(componentType => !_.includes(externalComponents, componentType));

            externalComponents.forEach(componentType => externalComponentsServices.load(componentType, getbaseUrl()));

            const internalPackagesToPrefetch = _(internalComponents).flatMap(compType => getPackagesForComponent(compType, siteData)).uniq().value();
            if (internalPackagesToPrefetch.length > 0) {
                requirejs(internalPackagesToPrefetch, _.noop);
            }
        },

        loadAndRegisterAll: () =>
            new Promise(res => requirejs(['skins'], res))
                .then(skins => Promise.all([
                    loadAndRegisterAllExternalComponents(skins.skinsMap, getbaseUrl()),
                    loadAndRegisterAllInternalPackages(skins.skinsMap)
                ])),

        loadAndRegister: (componentType, siteData) => {
            storeData.markComponentAsPending(componentType);
            return new Promise(res => requirejs(['skins'], res))
                .then(skins => {
                    if (externalComponentsServices.exists(componentType)) {
                        return loadAndRegisterExternalComponent(componentType, skins.skinsMap, getbaseUrl());
                    }
                    const packageNames = getPackagesForComponent(componentType, siteData);
                    return loadAndRegisterInternalPackages(packageNames, skins.skinsMap);
                })
                .then(() => {
                    storeData.markComponentAsLoaded(componentType);
                });
        }
    };
});
