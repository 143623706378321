/**
 * Created by avim on 1/5/2015.
 */
define(['lodash',
    'utils',
    'coreUtils',
    'dataFixer',
    'experiment',
    'core/core/ghostStructureBuilder',
    'core/bi/errors',
    'core/bi/events',
    'core/util/santaVersions.json',
    'core/core/constants'
], function (_, utils, coreUtils, dataFixer, experiment, ghostStructureBuilder, errors, events, santaVersions, constants) {
    'use strict';

    const {logger} = coreUtils.loggingUtils;

    const MASTER_PAGE_ID = 'masterPage';
    const GHOST_STRUCTURE_DESTINATION_BASE_PATH = 'ghostStructureData';

    function didPageRequestFail(siteData, destination) {
        return _.some(siteData.failedRequests, function (reqDesc) {
            return _.isEqual(reqDesc.destination, destination);
        });
    }

    function reportPageRetrievalFailed(siteData, pageId) {
        logger.reportBI(siteData, errors.ALL_PAGE_RETRIEVAL_ATTEMPTS_FAILED, {
            pageId
        });
    }

    function getMasterPageRequest(siteData, pageList) {
        const urls = coreUtils.pagesUrlUtils.getMasterPageURLs(pageList);
        return buildPageRequest(siteData, urls, MASTER_PAGE_ID, pageList);
    }

    const convertToPageIdsArray = pageList => _.map(pageList.pages, 'pageId');

    function getTransformFunc(siteData, pageIdsArray = []) {
        return function (pageData, currentPageData, requestUrl) {
            const requestModel = coreUtils.siteDataUtils.getRequestModel(siteData);
            const rendererModel = coreUtils.siteDataUtils.getRendererModel(siteData);
            if (isFixedJsonRequestUrl(requestUrl)) {
                return dataFixer.clientOnlyDataFixers(pageData, pageIdsArray, requestModel, siteData.currentUrl, siteData.urlFormatModel, siteData.isViewerMode(), rendererModel);
            }

            const pageFileNames = coreUtils.pageUtils.getPageFileNames(siteData.rendererModel.pageList);
            return dataFixer.fix(pageData, pageIdsArray, requestModel, siteData.currentUrl, siteData.urlFormatModel, siteData.isViewerMode(), rendererModel, pageFileNames);
        };
    }

    function getPageRequest(siteData, pageId) {
        const pageList = siteData.getPageList();
        if (siteData.currentUrl && siteData.currentUrl.query.fakePage) {
            const pageName = siteData.currentUrl.query.fakePage;
            return {
                urls: [`${siteData.santaBase}/static/fakePages/${pageName}.json`],
                destination: ['pagesData', pageId],
                transformFunc: getTransformFunc(siteData, convertToPageIdsArray(pageList))
            };
        }

        let urls = coreUtils.pagesUrlUtils.getPageURLs(pageList, pageId);
        if (!urls) {
            pageId = pageList.mainPageId;
            urls = coreUtils.pagesUrlUtils.getPageURLs(pageList, pageList.mainPageId);
        }

        return buildPageRequest(siteData, urls, pageId, pageList);
    }

    const STATIC_DOMAIN_HTTP_REGEX = /http:\/\/[a-z]+\.[a-z]+\.com\//;
    const BASE_DOMAIN_FOR_PAGES_IN_HTTPS = '//static.wixstatic.com/';

    const buildSimplePageRequest = (urls, pageId, onError = _.noop, onUrlRequestFailure = _.noop) => ({
        name: 'page request',
        urls,
        destination: ['pagesData', pageId],
        isValidResponse: responseData => _.isObject(responseData),
        error: onError,
        onUrlRequestFailure
    });

    function buildPageRequest(siteData, urls, pageId, pageList) {
        const isHttps = typeof document !== 'undefined' && window.document.location.protocol === 'https:';
        if (isHttps) {
            urls[0] = urls[0].replace(STATIC_DOMAIN_HTTP_REGEX, BASE_DOMAIN_FOR_PAGES_IN_HTTPS);
        }

        const onError = (error, status) => {
            if (siteData.isInSSR() && siteData.onPageRequestFailed) {
                siteData.onPageRequestFailed(urls[0], status, error);
            }

            siteData.failedRequests.push(this);
        };
        const onUrlRequestFailure = (failedUrl, responseStatusCode) => {
            if (siteData.onPageRequestFailed) {
                siteData.onPageRequestFailed(failedUrl, responseStatusCode);
            }

            const parsedUrl = coreUtils.urlUtils.parseUrl(failedUrl);
            logger.reportBI(siteData, errors.SINGLE_PAGE_RETRIEVAL_ATTEMPT_FAILED, {
                pageId,
                hostname: parsedUrl.hostname,
                url: failedUrl,
                responseStatusCode
            });
        };

        const pageRequest = buildSimplePageRequest(urls, pageId, onError, onUrlRequestFailure);
        pageRequest.transformFunc = getTransformFunc(siteData, convertToPageIdsArray(pageList));

        return pageRequest;
    }

    function getRequestForGhostStructure(siteData, jsonFileName, applicationId, widgetId) {
        if (didPageRequestFail(siteData, [GHOST_STRUCTURE_DESTINATION_BASE_PATH, applicationId, widgetId])) {
            return;
        }

        const pagesTopology = _.get(siteData.getRendererModel(), ['pageList', 'topology']);
        const fallbackUrls = coreUtils.pagesUrlUtils.getJsonUrlFromJsonFileName(pagesTopology, jsonFileName);
        return buildDataFixedGhostStructureRequest(siteData, widgetId, applicationId, jsonFileName, fallbackUrls, pagesTopology);
    }

    function buildDataFixedGhostStructureRequest(siteData, widgetId, applicationId, jsonFileName, fallbackUrls = [], pagesTopology) {
        const url = buildDataFixedPageUrl(siteData, null, jsonFileName, pagesTopology);

        const dataFixerFunc = getTransformFunc(siteData);
        const transformFunc = (...args) => {
            const fixedPage = dataFixerFunc(...args);

            return ghostStructureBuilder.buildGhostStructure(fixedPage);
        };

        const requestDescriptor = {
            name: 'Ghost structure request',
            urls: [url, ...fallbackUrls],
            destination: [GHOST_STRUCTURE_DESTINATION_BASE_PATH, applicationId, widgetId],
            isValidResponse(responseData) {
                return _.isObject(responseData) && _.get(responseData, ['structure', 'id']) === widgetId;
            },
            onUrlRequestFailure(failedUrl, responseStatusCode, error) {
                siteData.failedRequests.push(requestDescriptor);

                if (experiment.isOpen('sv_loadGhostStructureReportErrors', siteData)) {
                    utils.integrations.ravenUtils.captureError('Request for ghost structure data failed', {
                        tags: {
                            'load-ghost-structure': true
                        },
                        extra: {
                            info: _.assign({
                                failRequestUrl: failedUrl,
                                applicationId,
                                widgetId
                            }, _.omit(error, 'name'))
                        }
                    });
                }
            },
            transformFunc
        };

        return requestDescriptor;
    }

    function getRequestsForPages(siteData, fullPagesData, urlDataForPages) {
        const pageList = siteData.getPageList();
        if (!pageList) {
            return [];
        }
        const requests = [];
        const shouldRequestPageFixedData = !siteData.isPreviewMode();

        if (didPageRequestFail(siteData, ['pagesData', MASTER_PAGE_ID])) {
            reportPageRetrievalFailed(siteData, MASTER_PAGE_ID);
        } else if (!fullPagesData.pagesData[MASTER_PAGE_ID]) {
            const masterPageRequest = getMasterPageRequest(siteData, pageList);
            requests.push(shouldRequestPageFixedData ? buildDataFixedPageRequest(siteData, MASTER_PAGE_ID, null, masterPageRequest.urls) : masterPageRequest);
        }

        // Disabling complexity until experiment is merged (less ifs)
        _.forEach(_.castArray(urlDataForPages), function (urlData) { // eslint-disable-line complexity
            if (!urlData.pageId) {
                return;
            }
            const pageHasNotLoadedYet = !fullPagesData.pagesData[urlData.pageId];

            if (coreUtils.errorPages.isErrorPage(urlData.pageId)) {
                requests.push(buildPageRequest(siteData, coreUtils.errorPages.getJSONS(siteData, urlData.pageId), urlData.pageId, pageList));
            } else if (didPageRequestFail(siteData, ['pagesData', urlData.pageId])) {
                reportPageRetrievalFailed(siteData, urlData.pageId);
            } else if (isAuthenticatedUser(siteData, urlData)) {
                const pageRequest = buildPageRequest(siteData, urlData.jsonUrls, urlData.pageId, pageList);
                requests.push(shouldRequestPageFixedData ? buildDataFixedPageRequest(siteData, urlData.pageId, urlData.jsonUrls[0], pageRequest.urls) : pageRequest);
            } else if (pageHasNotLoadedYet && (!isPasswordProtectedPage(siteData, urlData) || canFetchProtectedPage(siteData, urlData))) {
                const pageRequest = getPageRequest(siteData, urlData.pageId);
                requests.push(shouldRequestPageFixedData ? buildDataFixedPageRequest(siteData, urlData.pageId, null, pageRequest.urls) : pageRequest);
            }
        });

        return requests;
    }

    function getRequestDuration(requestUrl) {
        if (typeof window !== 'undefined' && window.performance && window.performance.getEntriesByName) {
            const perf = window.performance.getEntriesByName(requestUrl)[0];
            if (perf) {
                return Math.round(perf.duration);
            }
        }
        return 0;
    }

    function buildDataFixedPageRequest(siteData, pageId, jsonUrlOverride, fallbackUrls = []) {
        const {reportBI, shouldSendReport} = logger;
        const isMasterPage = pageId === MASTER_PAGE_ID;
        const pageNumber = siteData.biData.getPageNumber();
        const pageList = siteData.getPageList();
        const url = buildDataFixedPageUrl(siteData, pageId, jsonUrlOverride, pageList.topology);
        siteData.addFixedPageUrl(pageId, url);

        let requestStartTime;

        const requestDescriptor = {
            name: 'data fixed page request',
            urls: [url, ...fallbackUrls],
            destination: ['pagesData', pageId],
            isValidResponse(responseData) {
                return _.isObject(responseData);
            },
            transformFunc: getTransformFunc(siteData, convertToPageIdsArray(pageList)),
            onBeforeFetch: _.once(() => {
                requestStartTime = _.now();
                reportBI(siteData, events.DATA_FIXED_PAGE_REQUEST, {
                    stage: '1',
                    pageNumber,
                    masterPage: isMasterPage,
                    duration: null
                });
            }),
            callback: (transformedResponse, response, requestUrl, headers) => {
                if (isFixedJsonRequestUrl(requestUrl) && shouldSendReport(siteData, events.DATA_FIXED_PAGE_REQUEST)) {
                    const duration = getRequestDuration(requestUrl) || _.now() - requestStartTime;
                    reportBI(siteData, events.DATA_FIXED_PAGE_REQUEST, {
                        stage: '2',
                        pageNumber,
                        masterPage: isMasterPage,
                        duration,
                        cdn: _.get(headers, 'via', null),
                        cdnHit: isCdnHit(_.get(headers, 'age'))
                    });
                }
            },
            onUrlRequestFailure(failedUrl, responseStatusCode, error) {
                if (isFixedJsonRequestUrl(failedUrl)) {
                    const errorName = `Fixed ${pageId === MASTER_PAGE_ID ? 'Master ' : ''}Page Request Failed`;
                    utils.integrations.ravenUtils.captureError(errorName, {
                        tags: {
                            'data-fixer-server': true
                        },
                        extra: {
                            info: _.assign({failRequestUrl: url}, _.omit(error, 'name'))
                        }
                    });
                    reportBI(siteData, errors.FIXED_PAGE_REQUEST_FAILED, {
                        errorName,
                        failRequestUrl: url,
                        status: _.get(error, 'status'),
                        responseText: _.get(error, 'responseText')
                    });
                } else {
                    siteData.failedRequests.push(requestDescriptor);
                    if (siteData.onPageRequestFailed) {
                        siteData.onPageRequestFailed(failedUrl, responseStatusCode);
                    }

                    const parsedUrl = coreUtils.urlUtils.parseUrl(failedUrl);
                    reportBI(siteData, errors.SINGLE_PAGE_RETRIEVAL_ATTEMPT_FAILED, {
                        pageId,
                        hostname: parsedUrl.hostname,
                        url: failedUrl,
                        responseStatusCode
                    });
                }
            }
        };

        return requestDescriptor;
    }

    function buildDataFixedPageUrl(siteData, pageId, jsonUrlOverride, topology) {
        const {fixedPagesUrls} = siteData;
        if (fixedPagesUrls) {
            const url = fixedPagesUrls[pageId];
            if (url) {
                return url;
            }
        }

        const rendererModel = siteData.getRendererModel();
        const publicModel = siteData.getPublicModel();
        const currentUrl = siteData.currentUrl;
        const isUrlMigrated = siteData.isUsingUrlFormat(coreUtils.siteConstants.URL_FORMATS.SLASH);
        const siteAssetsServerUrl = siteData.getServiceTopologyProperty('siteAssetsServerUrl');
        let pageJsonFileName;

        if (jsonUrlOverride) {
            pageJsonFileName = coreUtils.pagesUrlUtils.getPageJsonFileNameFromUrl(jsonUrlOverride);
        } else if (pageId === MASTER_PAGE_ID) {
            pageJsonFileName = coreUtils.pagesUrlUtils.getMasterPageJsonFileName(siteData.getPageList());
        } else {
            pageJsonFileName = coreUtils.pagesUrlUtils.getPageJsonFileName(siteData.getPageList(), pageId);
            if (!pageJsonFileName) {
                pageJsonFileName = coreUtils.pagesUrlUtils.getPageJsonFileName(siteData.getPageList(), siteData.getPageList().mainPageId);
            }
        }

        const queryParamsObj = _.omitBy({
            isUrlMigrated,
            quickActionsMenuEnabled: _.get(rendererModel, 'siteMetaData.quickActions.configuration.quickActionsMenuEnabled', false),
            metaSiteId: rendererModel.metaSiteId,
            siteId: rendererModel.siteInfo.siteId,
            pageId: pageJsonFileName.replace(/\.json/i, ''),
            version: santaVersions['santa-data-fixer'],
            v: _(topology).get('0.parts').split('?v=').pop(), // JSON version
            isHttps: _.startsWith(currentUrl.protocol, 'https'),
            experiments: getDataFixersExperimentsList(siteData).join(','),
            ck: _.get(publicModel, 'siteAssets.cacheVersions.dataFixer'),
            siteRevision: pageId === MASTER_PAGE_ID ? publicModel.siteRevision : undefined
        }, _.isUndefined);

        const queryParams = coreUtils.urlUtils.toQueryString(queryParamsObj);
        return `${siteAssetsServerUrl}${constants.SITE_ASSETS_SERVER.FIXED_PAGE_JASON_PATH}?${queryParams}`;
    }

    function getDataFixersExperimentsList(siteData) {
        const dataFixersSpecs = [
            'sv_contactFormTemplatesMigration',
            'sv_contactFormFinalMigration',
            'sv_contactFormFinalMigrationEditor',
            'sv_migrateTpaToSemiNative',
            'sv_tpaAddPublicAPI',
            'sv_usedFontsDataFixer'
        ];

        return _.filter(dataFixersSpecs, expName => experiment.isOpen(expName, siteData));
    }

    function isPasswordProtectedPage(siteData, urlData) {
        const protectedPages = _.get(siteData, 'rendererModel.passwordProtectedPages', []);
        return _.includes(protectedPages, urlData.pageId);
    }

    function canFetchProtectedPage(siteData, urlData) {
        const page = _.find(siteData.getPageList().pages, {pageId: urlData.pageId});
        return isPasswordProtectedPage(siteData, urlData) && _.get(page, 'pageJsonFileName');
    }

    function isAuthenticatedUser(siteData, urlData) {
        const pageData = _.find(siteData.getPageList().pages, {pageId: urlData.pageId});
        return !_.isEmpty(urlData.jsonUrls) && _.isEmpty(_.get(pageData, 'urls'));
    }

    function isFixedJsonRequestUrl(requestUrl) {
        return _.includes(requestUrl, `${constants.SITE_ASSETS_SERVER.FIXED_PAGE_JASON_PATH}?`);
    }

    function isCdnHit(ageHeader) {
        return _.toNumber(ageHeader) > 0;
    }

    /**
     * @exports core/core/pageRequests
     */
    return {
        isFixedJsonRequestUrl,
        buildSimplePageRequest,
        getRequestsForPages,
        getRequestForGhostStructure
    };
});
