define([
    'lodash',
    'utils',
    'platformInit',
    'compUtils',
    'coreUtils',
    'core/core/data/wixCode/wixCodeComponentsFetcher'
], function (_, utils, platformInit, compUtils, coreUtils, wixCodeComponentsFetcher) {
    'use strict';

    return function mapStateToProps(siteAPI) {
        const siteData = siteAPI.getSiteData();
        const WidgetAspect = siteAPI.getHostLibsAspectConstructor('WidgetAspect');
        const siteDataAPI = siteAPI.getSiteDataAPI();

        return {
            isMobileView: siteData.isMobileView(),
            currentUrlPageId: siteData.getCurrentUrlPageId(),
            isTypeController: (componentStructure, pageId) => platformInit.appsUtils.isTypeController(componentStructure, siteData, pageId),
            hasWixCode: () => platformInit.appsUtils.hasWixCode(siteData.getRendererModel()),
            isExperimentOpen: siteAPI.isExperimentOpen,
            initWixCode: !!siteAPI.getRenderFlag('initWixCode'),
            componentViewMode: siteAPI.getRenderFlag('componentViewMode'),
            isViewerMode: siteData.isViewerMode(),
            handleProcessedBehavior: (behavior, event) => siteAPI.getSiteAspect('behaviorsAspect').handleProcessedBehavior(behavior, event),
            refreshRenderedContextsData: siteDataAPI.refreshRenderedRootsData.bind(siteDataAPI),
            shouldUpdateRuntimeModels: () => siteData.isFirstRenderAfterSSR() && !!siteData.runtimeModels,
            componentsFetcher: (...args) => wixCodeComponentsFetcher(siteDataAPI, ...args),
            RMIDataAPI: WidgetAspect.modelBuilderDataHelper.getApi(siteData),
            getRuntimeModels: () => siteData.runtimeModels,
            resolveWidgetData: (dataToResolve, compProps) => WidgetAspect.widgetDataResolvers.resolve(dataToResolve, siteAPI, compProps),
            routers: siteData.getRouters(),
            setWarmupDataForController: (controllerId, data) => {
                siteData.userWarmup = siteData.userWarmup || {};
                siteData.userWarmup[controllerId] = data;
            },
            isFirstRenderAfterSSR: () => siteData.isFirstRenderAfterSSR(),
            getNavigationDataForRgi: () => siteData.getNavigationDataForRgi(),
            getSMbySiteExtensionInstanceForRgi: () => siteData.getSMbySiteExtensionInstanceForRgi(),
            getAppsDataForRgi: () => siteData.getAppsDataForRgi(),
            getDataByQuery: dataQuery => siteData.getDataByQuery(dataQuery),
            getAllRenderedContextIds: () => siteAPI.getAllRenderedRootIds(),
            getApplicationsForContexts: contextIds => platformInit.appsUtils.getApplications(siteData.getClientSpecMap(), contextIds, siteData),
            filterContextsToSync: contextIds => _.without(contextIds, coreUtils.siteConstants.MASTER_PAGE_ID),
            filterValidContextsForLoad: contextIds => _(contextIds)
                .without(coreUtils.siteConstants.MASTER_PAGE_ID)
                .filter(siteData.getPageTitle.bind(siteData))
                .value(),
            filterContextsToActivate: rootsToActivate => _.reject(rootsToActivate, rootId => !coreUtils.siteDataUtils.isPageLoaded(siteData, rootId)),
            getContextInitData: contextId => platformInit.appsUtils.getContextInitData(siteData, contextId, utils.styleUtils),
            getContextIdsWhichShouldBeRendered: () => _.without(siteAPI.getRootIdsWhichShouldBeRendered(), coreUtils.siteConstants.MASTER_PAGE_ID),
            getCompReactClass: compUtils.compFactory.getCompReactClass,
            performanceLogger: coreUtils.loggingUtils.performance,
            deferWixCodeMessagesRegistration: true,
            getAllPossiblyRenderedContexts: () => siteData.getAllPossiblyRenderedRoots(),
            getGhostStructure: () => siteData.getGhostStructureData()
        };
    };
});
