define(['zepto', 'lodash', 'componentsCore', 'mobx'],
    function ($, _, componentsCore, mobx) {
        'use strict';

        const focusRingClass = 'focus-ring';
        const {addFocusRingClass} = componentsCore.utils.accessibility;

        const KEY_CODES = {
            ESC: 27,
            SPACE: 32,
            TAB: 9,
            END: 35,
            HOME: 36,
            RIGHT_ARROW: 39,
            LEFT_ARROW: 37,
            UP_ARROW: 38,
            DOWN_ARROW: 40
        };

        function removeFocusRingClass(el) {
            $(el).removeClass(focusRingClass);
        }

        function VisualFocusAspect(aspectSiteAPI) {
            aspectSiteAPI.registerToComponentDidMount(function () {
                this.disposeReaction = mobx.reaction(aspectSiteAPI.getSiteData().isVisualFocusEnabled,
                    this.addOrRemoveListeners.bind(this), {fireImmediately: true});
            }.bind(this));

            aspectSiteAPI.registerToWillUnmount(function () {
                this.disposeReaction();
                this.unregisterListeners();
            }.bind(this));
        }

        VisualFocusAspect.prototype = {
            addOrRemoveListeners(isVisualFocusEnabled) {
                if (isVisualFocusEnabled) {
                    this.registerListeners();
                } else {
                    this.unregisterListeners();
                }
            },

            registerListeners() {
                this.unregisterListeners();
                this.keyUpEventListener = this.onKeyUp.bind(this);
                this.elementBlurEventListener = this.onElementBlur.bind(this);
                this.elementFocusEventListener = this.onElementFocus.bind(this);
                this.windowFocusEventListener = this.onWindowFocus.bind(this);
                this.windowBlurEventListener = this.onWindowBlur.bind(this);

                window.document.addEventListener('keyup', this.keyUpEventListener, true);
                window.document.addEventListener('blur', this.elementBlurEventListener, true);
                window.document.addEventListener('focus', this.elementFocusEventListener, true);
                window.addEventListener('focus', this.windowFocusEventListener, true);
                window.addEventListener('blur', this.windowBlurEventListener, true);
            },

            unregisterListeners() {
                window.document.removeEventListener('keyup', this.keyUpEventListener, true);
                window.document.removeEventListener('focus', this.elementFocusEventListener, true);
                window.document.removeEventListener('blur', this.elementBlurEventListener, true);
                window.removeEventListener('focus', this.windowFocusEventListener, true);
                window.removeEventListener('blur', this.windowBlurEventListener, true);
            },

            onWindowFocus() {
                if (window.document.activeElement === this.elementWithFocusRing) {
                    addFocusRingClass(this.elementWithFocusRing);
                }
            },

            onWindowBlur() {
                if ($(window.document.activeElement).hasClass(focusRingClass)) {
                    this.elementWithFocusRing = window.document.activeElement;
                }
            },

            onKeyUp(e) {
                if (e.altKey || e.ctrlKey || e.metaKey) {
                    return;
                }
                if (_.includes([
                    KEY_CODES.ESC,
                    KEY_CODES.SPACE,
                    KEY_CODES.TAB,
                    KEY_CODES.HOME,
                    KEY_CODES.END,
                    KEY_CODES.RIGHT_ARROW,
                    KEY_CODES.LEFT_ARROW,
                    KEY_CODES.UP_ARROW,
                    KEY_CODES.DOWN_ARROW
                ], e.keyCode)) {
                    addFocusRingClass(e.target);
                }
            },

            onElementBlur(e) {
                if ($(e.target).hasClass(focusRingClass)) {
                    this.elementWithFocusRing = e.target;
                    removeFocusRingClass(e.target);
                }
            },

            onElementFocus() {
                if (window.document.activeElement === this.elementWithFocusRing) {
                    addFocusRingClass(this.elementWithFocusRing);
                }
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('visualFocusAspect', VisualFocusAspect);
    });
