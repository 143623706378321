/**
 * Created by avim on 1/5/2015.
 */
define([
    'lodash',
    'coreUtils'
], function (_, coreUtils) {
    'use strict';

    const fontUtils = coreUtils.fontUtils;

    function getRequests(siteData) {
        const requests = [];
        const cssUrls = getExternalStylesInfo(siteData);

        _.forEach(cssUrls, function (url, id) {
            if (_.startsWith(url, '//')) {
                url = `https:${url}`; // Force https protocol (SSR ajax handler can't resolve it correctly)
            }

            requests.push({
                urls: [getBrowserSpecificUrl(siteData, url, id)],
                destination: ['externalStyles', id],
                dataType: 'text',
                onUrlRequestFailure: siteData.isInSSR() ? function (reqUrl) {
                    throw new Error(`Failed fetching external style: ${reqUrl}`);
                } : undefined,
                isValidResponse(responseData) {
                    return _.isString(responseData) && !_.isEmpty(responseData);
                }
            });
        });

        return requests;
    }

    function getExternalStylesInfo(siteData) {
        const documentType = siteData.rendererModel.siteInfo.documentType;
        return fontUtils.getCssUrls(documentType, siteData.serviceTopology);
    }

    function getStylesIds(siteData) {
        return _.keys(getExternalStylesInfo(siteData));
    }

    function getBrowserSpecificUrl(siteData, url, id) {
        const supportLevel = fontUtils.getBrowserFontSupport(siteData.browser, siteData.os);
        const specificFontFacesUrl = fontUtils.getStyleWithSpecificFontFaces(siteData.serviceTopology, id, supportLevel);
        return specificFontFacesUrl || url;
    }

    /**
     * @exports core/core/externalStyles
     */
    return {
        getRequests,
        getStylesIds,
        getBrowserSpecificUrl
    };
});
