/*
 not completely sure why is all the fuss..
 */
define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const COMP_DATA_ITEM_QUERY = coreUtils.constants.COMP_DATA_QUERY_KEYS_WITH_STYLE;

    function getPageId(pointer) {
        const pageOfComponent = this._pointers.full.components.getPageOfComponent(pointer) || this._pointers.components.getPageOfComponent(pointer);
        return pageOfComponent.id;
    }

    function getDisplayedOnlyCompsForFullComp(fullCompPointer) {
        const displayedCompPointers = this._pointers.components.getAllDisplayedOnlyComponents(fullCompPointer);
        const displayedOnlyCompPointers = _.filter(displayedCompPointers, function (displayedCompPointer) {
            return coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(displayedCompPointer.id);
        });
        return displayedOnlyCompPointers;
    }

    function getFullAndDisplayedOnlyPointers(fullPointers) {
        return _.reduce(fullPointers, function (result, fullCompPointer) {
            const displayedCompsPointers = getDisplayedOnlyCompsForFullComp.call(this, fullCompPointer);
            _.forEach(displayedCompsPointers, function (compPointer) {
                result.push(compPointer);
            });
            result.push(fullCompPointer);
            return result;
        }.bind(this), []);
    }

    function syncAllComponents(viewMode, possiblyRenderedRoots) {
        this._roots = possiblyRenderedRoots;
        this._viewMode = viewMode;
        this._compPointers = _(possiblyRenderedRoots)
            .flatMap(function (rootId) {
                const root = this._pointers.full.components.getPage(rootId, viewMode);
                if (!root) {
                    return [];
                }
                const fullPointers = this._pointers.full.components.getChildrenRecursively(root);
                const fullAndDisplayedPointers = getFullAndDisplayedOnlyPointers.call(this, fullPointers);
                return [root].concat(fullAndDisplayedPointers);
            }.bind(this))
            .keyBy('id')
            .value();
    }

    function isFullOnlyComponent(compPointer) {
        const isCompDisplayedOnly = coreUtils.displayedOnlyStructureUtil.isDisplayedOnlyComponent(compPointer.id);
        if (isCompDisplayedOnly) {
            return false;
        }

        const displayedCompPointers = this._pointers.components.getAllDisplayedOnlyComponents(compPointer);
        return !_.isEqual(displayedCompPointers, [compPointer]);
    }

    function PointersRuntimeCache(pointers, displayedDal, documentAPI) {
        this._compPointers = {};
        this._pointers = pointers;
        this._displayedDal = displayedDal;
        this._documentAPI = documentAPI;
        this._roots = [];
        this._viewMode = null;
    }

    PointersRuntimeCache.prototype = {
        reset() {
            this._compPointers = {};
        },

        isEmpty() {
            return _.isEmpty(this._compPointers);
        },

        getAllCompIds(viewMode, possiblyRenderedRoots) {
            if (!_.isEqual(this._roots, possiblyRenderedRoots) || this._viewMode !== viewMode) {
                syncAllComponents.call(this, viewMode, possiblyRenderedRoots);
            }
            return _.keys(this._compPointers);
        },

        getCompPointer(compId, viewMode, possiblyRenderedRoots) {
            if (!compId) {
                return null;
            }

            const compPointer = this._compPointers[compId];
            if (compPointer && (this._displayedDal.isExist(compPointer) || isFullOnlyComponent.call(this, compPointer))) {
                return compPointer;
            }

            syncAllComponents.call(this, viewMode, possiblyRenderedRoots);
            return this._compPointers[compId];
        },

        getCompDataId(compPointer, dataType) {
            if (!compPointer) {
                return null;
            }

            const queryPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[dataType]);
            return this._displayedDal.get(queryPointer);
        },

        getCompDataItemPointer(compPointer, dataType, shouldResolveFromFull) {
            if (!compPointer) {
                return null;
            }

            let queryId, queryPointer;

            if (shouldResolveFromFull) {
                queryId = this._documentAPI.getFullStructureProperty(compPointer, COMP_DATA_ITEM_QUERY[dataType]);
            } else {
                queryPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[dataType]);
                queryId = this._displayedDal.get(queryPointer);
            }

            let dataPointer = null;
            if (queryId) {
                dataPointer = this._pointers.data.getItem(dataType, queryId.replace('#', ''), getPageId.call(this, compPointer));
            }
            //we check if the pointer exists because there are cases when we have a query but no data item
            return dataPointer && this._displayedDal.isExist(dataPointer) ? dataPointer : null;
        }
    };

    return PointersRuntimeCache;
});
