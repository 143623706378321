define(['lodash', 'utils', 'santaProps', 'componentsCore', 'core/siteRender/SiteAPI'], function (_, utils, santaProps, componentsCore, SiteAPI) {
    'use strict';

    /**
     * @typedef {SiteAspectsSiteAPI} core.SiteAspectsSiteAPI
     */

    /**
     * @constructor
     * @extends {core.SiteAPI}
     * @param site
     */
    function SiteAspectsSiteAPI(site) {
        SiteAPI.call(this, site, 'Site Aspect');

        _.bindAll(this, ['getComponentById', 'getRootOfComponentId', 'getCurrentPage', 'getCurrentPopup', 'getAllRenderedRoots', 'getMasterPage',
            'getPageById', 'getPageComponents', 'registerToMessage', 'registerToSiteReady', 'registerToScroll', 'registerToResize', 'registerToOrientationChange',
            'registerToModeChange', 'registerToSlideChange', 'registerToComponentDidMount', 'registerToSiteWillMount', 'registerToSiteWillUpdate',
            'registerToDidLayout', 'unRegisterFromDidLayout', 'registerToWillUnmount', 'registerToUrlPageChange', 'registerToOnRendered', 'registerToSSSRSuccess', 'registerToRenderedRootsChange',
            'registerToAddedRenderedRootsDidLayout', 'registerToKeyDown', 'registerToWindowTouchEvent', 'registerToFocusEvents', 'registerToVisibilityChange',
            'registerToDocumentClick', 'registerToSvSessionChange', 'registerToSiteMetadataChange', 'getSiteAPI', 'getSiteContainer', 'scrollSiteBy',
            'scrollSiteTo', 'getWindowSize', 'getDocumentSize', 'getSiteScroll', 'getCurrentPopupScroll', 'getBrowserFlags', 'getVisibilityState',
            'setSiteRootHiddenState', 'notifyAspects', 'registerToFakeModeChange']);
    }

    //not sure if object create works on all browsers..
    SiteAspectsSiteAPI.prototype = _.create(SiteAPI.prototype, {
        'constructor': SiteAspectsSiteAPI
    });

    /**
     * @param {string} id
     * @returns {ReactCompositeComponent}
     * @param rootId
     */
    SiteAspectsSiteAPI.prototype.getComponentById = function (id, rootId) { // eslint-disable-line complexity
        let comp = null;
        const compRootId = rootId || this.getRootOfComponentId(id);

        if (compRootId) {
            comp = this._site.getComponentByPageAndCompId(compRootId, id);
        }

        if (!comp) {
            comp = this._site.getAspectsContainer().refs[id] || this._site.getAspectsContainer().refs.aspectPortal.refs[id];
        }

        //todo: chene: is is needed?
        if (!comp && (id === 'SITE_BACKGROUND' || id === 'WIX_ADS')) {
            comp = this._site.compRefs[id];
        }

        return comp || null;
    };

    /**
     *
     * @param {string} id
     * @returns {string|null} returns the id of the pagesData root the the component belongs to (masterPage/primaryPage/popup)
     * if the component isn't rendered ot doesn't exist will return null
     */
    SiteAspectsSiteAPI.prototype.getRootOfComponentId = function (id) {
        const {_site} = this;
        const root = _site.getPageOfComp(id);
        let rootId = root && root.props.rootId;

        if (rootId) {
            return rootId;
        }

        //not sure that we should check on other current non visible pages...
        const masterPage = _site.getMasterPage();
        _.forEach(_site.getPageRefs(masterPage), (page, refName) => {
            const component = _site.getComponentByPageAndCompId(refName, id);
            if (component) {
                rootId = refName;
                return false;
            }
        });

        return rootId || null;
    };

    SiteAspectsSiteAPI.prototype.getParentComponentType = function (id, rootId) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const componentsPointers = pointers.components;

        const siteData = this.getSiteData();
        const viewMode = siteData.getViewMode();
        const rootPointer = componentsPointers.getPage(rootId, viewMode);
        const compPointer = componentsPointers.getComponent(id, rootPointer);
        const parentPointer = componentsPointers.getParent(compPointer);
        const parentComponentTypePointer = pointers.getInnerPointer(parentPointer, 'componentType');

        return displayedDAL.get(parentComponentTypePointer);
    };

    /**
     * @deprecated
     * @returns {ReactCompositeComponent}
     */
    SiteAspectsSiteAPI.prototype.getCurrentPage = function () {
        return this._site.getPrimaryPage();
    };

    SiteAspectsSiteAPI.prototype.getCurrentPopup = function () {
        return this._site.getCurrentPopup();
    };

    SiteAspectsSiteAPI.prototype.getAllRenderedRoots = function () {
        return _.map(this.getAllRenderedRootIds, this.getPageById);
    };

    /**
     *
     * @returns {ReactCompositeComponent}
     */
    SiteAspectsSiteAPI.prototype.getMasterPage = function () {
        return this._site.getMasterPage();
    };

    SiteAspectsSiteAPI.prototype.getPageById = function (pageId) {
        return this._site.getPageById(pageId);
    };

    SiteAspectsSiteAPI.prototype.getPageComponents = function (pageId) {
        return this._site.getComponentsByPageId(pageId);
    };

    SiteAspectsSiteAPI.prototype.getComponentByPageAndCompId = function (pageId, compId) {
        return this._site.getComponentByPageAndCompId(pageId, compId);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToMessage = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.message, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToSiteReady = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.siteReady, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToScroll = function (callback) {
        const siteData = this.getSiteAPI().getSiteData();
        this._site.registerAspectToEvent(this._site.supportedEvents.scroll, function (e) {
            const isScrollable = window.document.body.scrollHeight > window.innerHeight;
            const scrollPosition = {
                x: window.pageXOffset || (siteData.isMobileView() ? window.document.scrollLeft : window.document.body.scrollLeft),
                y: window.pageYOffset || (siteData.isMobileView() ? window.document.scrollTop : window.document.body.scrollTop),
                progressY: isScrollable ? window.scrollY / (window.document.body.scrollHeight - window.innerHeight - 1) : 0
            };
            callback(scrollPosition, e);
        });
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToResize = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.resize, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToOrientationChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.orientationchange, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToModeChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.modeChange, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToSlideChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.slideChange, callback);
    };

    /**
     *
     * @param {function()} callback
     */
    SiteAspectsSiteAPI.prototype.registerToComponentDidMount = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.mount, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToSiteWillMount = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.willMount, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToSiteWillUpdate = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.willUpdate, callback);
    };

    /**
     *
     * @param {function()} callback
     */
    SiteAspectsSiteAPI.prototype.registerToDidLayout = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.didLayout, callback);
    };

    SiteAspectsSiteAPI.prototype.unRegisterFromDidLayout = function (callback) {
        this._site.unregisterAspectFromEvent(this._site.supportedEvents.didLayout, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToWillUnmount = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.unmount, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToUrlPageChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.urlPageChange, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToOnRendered = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.onRendered, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToOnFullyRendered = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.fullyRendered, callback);
    };
    SiteAspectsSiteAPI.prototype.registerToSSSRSuccess = function (callback) {
        this.registerToMessage(message => {
            if (_.get(message, 'data') === this._site.supportedEvents.sssrSuccess) {
                return callback();
            }
        });
    };

    /**
     *
     * @param {function(Array<string>, Array<string>)} callback, the callback will receive the added and removed roots
     */
    SiteAspectsSiteAPI.prototype.registerToRenderedRootsChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.renderedRootsChanged, callback);
    };

    /**
     *
     * @param {function(Array<string>)} callback, the callback will receive the added roots after they didLayout
     */
    SiteAspectsSiteAPI.prototype.registerToAddedRenderedRootsDidLayout = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.addedRenderedRootsDidLayout, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToKeyDown = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.keydown, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToKeyUp = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.keyup, callback);
    };

    /**
     * @param {string} type - event type
     * @param {function} callback - callback to call
     */
    SiteAspectsSiteAPI.prototype.registerToWindowTouchEvent = function (type, callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents[type.toLowerCase()], callback);
    };

    /**
     * @param {string} event - event type
     * @param {function} callback - callback to call
     */
    SiteAspectsSiteAPI.prototype.registerToFocusEvents = function (event, callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents[event], callback);
    };

    /**
     * @param {function} callback - callback to call
     */
    SiteAspectsSiteAPI.prototype.registerToVisibilityChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.visibilitychange, callback);
    };

    /**
     * @param {function} callback - callback to call
     */
    SiteAspectsSiteAPI.prototype.registerToDocumentClick = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.click, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToOrientationChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.orientationchange, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToFakeModeChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.fakeModeChange, callback);
    };


    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToSvSessionChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.svSessionChange, callback);
    };

    /**
     *
     * @param {function(Event)} callback
     */
    SiteAspectsSiteAPI.prototype.registerToSiteMetadataChange = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.siteMetadataChange, callback);
    };

    SiteAspectsSiteAPI.prototype.registerToAspectsReady = function (callback) {
        this._site.registerAspectToEvent(this._site.supportedEvents.aspectsReady, callback);
    };

    SiteAspectsSiteAPI.prototype.unregisterFromAspectsReady = function (callback) {
        this._site.unregisterAspectFromEvent(this._site.supportedEvents.aspectsReady, callback);
    };

    /**
     *
     * @returns {core.SiteAPI}
     */
    SiteAspectsSiteAPI.prototype.getSiteAPI = function () {
        return this._site.siteAPI;
    };

    /**
     * get the context object of the site - window by default.
     * @returns {window|HTMLElement}
     */
    SiteAspectsSiteAPI.prototype.getSiteContainer = function () {
        return this._site.props.getSiteContainer();
    };

    /**
     * Scroll the site by x,y pixels
     * @param {number} x
     * @param {number} y
     */
    SiteAspectsSiteAPI.prototype.scrollSiteBy = function (x, y, callback) {
        this.getSiteContainer().scrollBy(x, y);
        if (_.isFunction(callback)) {
            utils.animationFrame.request(callback);
        }
    };

    /**
     * Scroll the site to position x,y pixels
     * @param {number} x
     * @param {number} y
     */
    SiteAspectsSiteAPI.prototype.scrollSiteTo = function (x, y) {
        this.getSiteContainer().scrollTo(x, y);
    };

    /**
     * Get the window height / width measurements
     * @returns {{height: (number), width: (number)}}
     */
    SiteAspectsSiteAPI.prototype.getWindowSize = function () {
        const siteContainer = this.getSiteContainer();
        return {
            height: siteContainer.innerHeight,
            width: siteContainer.innerWidth
        };
    };

    /**
     * Get the document size
     * @returns {{height: (number), width: (number)}}
     */
    SiteAspectsSiteAPI.prototype.getDocumentSize = function () {
        return {
            height: window.document.documentElement.clientHeight || window.document.body.clientHeight,
            width: window.document.documentElement.clientWidth || window.document.body.clientWidth
        };
    };

    /**
     * Get the current scroll amount of the site
     * @returns {{x: (number), y: (number)}}
     */
    SiteAspectsSiteAPI.prototype.getSiteScroll = function () {
        const siteContainer = this.getSiteContainer() || {};
        return {
            x: siteContainer.pageXOffset || siteContainer.scrollX || 0,
            y: siteContainer.pageYOffset || siteContainer.scrollY || 0
        };
    };

    /**
     * Get the scroll amount of the current popup
     * @returns {{x: (number), y: (number)}}
     */
    SiteAspectsSiteAPI.prototype.getCurrentPopupScroll = function () {
        const popupRootDOMNode = window.document.getElementById('POPUPS_ROOT');

        return {
            x: _.get(popupRootDOMNode, 'scrollLeft', 0),
            y: _.get(popupRootDOMNode, 'scrollTop', 0)
        };
    };

    SiteAspectsSiteAPI.prototype.getBrowserFlags = function () {
        return this.getSiteAPI().getSiteData().browserFlags();
    };

    /**
     * Is current document visible (https://developer.mozilla.org/en-US/docs/Web/API/Page_Visibility_API)
     * @returns {{hidden: boolean}}
     */
    SiteAspectsSiteAPI.prototype.getVisibilityState = function () {
        const doc = typeof document !== 'undefined';
        return {hidden: doc && window.document.hidden};
    };

    SiteAspectsSiteAPI.prototype.setSiteRootHiddenState = function (isHidden, callback) {
        this._site.setState({
            siteRootHidden: isHidden
        }, callback);
    };

    SiteAspectsSiteAPI.prototype.notifyAspects = function (event, ...eventArgs) {
        this._site.notifyAspects(event, ...eventArgs);
    };

    SiteAspectsSiteAPI.prototype.updateAspectGlobalData = function (aspectName, updatedGlobalData) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const globalDataPointer = pointers.siteAspects.getAspectGlobalData(aspectName);

        const currGlobalData = displayedDAL.get(globalDataPointer) || {};
        displayedDAL.set(globalDataPointer, _.assign(currGlobalData, updatedGlobalData));
    };

    SiteAspectsSiteAPI.prototype.setAspectGlobalData = function (aspectName, updatedGlobalData) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const globalDataPointer = pointers.siteAspects.getAspectGlobalData(aspectName);

        displayedDAL.set(globalDataPointer, updatedGlobalData);
    };

    SiteAspectsSiteAPI.prototype.removeAspectGlobalData = function (aspectName) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const globalDataPointer = pointers.siteAspects.getAspectGlobalData(aspectName);

        if (displayedDAL.isExist(globalDataPointer)) {
            displayedDAL.remove(globalDataPointer);
        }
    };

    SiteAspectsSiteAPI.prototype.updateAspectComponentData = function (aspectName, compId, data) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const compDataPointer = pointers.siteAspects.getAspectComponentData(aspectName, compId);

        const currCompData = displayedDAL.get(compDataPointer) || {};
        const nextData = _.assign({}, currCompData, data);
        if (!_.isEqual(currCompData, nextData)) {
            displayedDAL.set(compDataPointer, nextData);
        }
    };

    SiteAspectsSiteAPI.prototype.setAspectComponentData = function (aspectName, compId, data) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const compDataPointer = pointers.siteAspects.getAspectComponentData(aspectName, compId);

        displayedDAL.set(compDataPointer, data);
    };

    SiteAspectsSiteAPI.prototype.initHostLibsAspect = function (aspectName, mapStateToProps, ctorArgs = []) {
        const AspectConstructor = componentsCore.siteAspectsRegistry.getHostLibsAspectConstructor(aspectName);
        const ConnectedAspect = santaProps.aspectPropsConnector.connect(AspectConstructor, mapStateToProps, this.getSiteData().isDebugMode());
        const aspect = new ConnectedAspect(this, ...ctorArgs);

        return aspect;
    };

    SiteAspectsSiteAPI.prototype.setSiteRootAriaHiddenState = function (isAriaHidden) {
        this._site.setState({
            isAriaHidden
        });
    };

    return SiteAspectsSiteAPI;
});
