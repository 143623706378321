define([
    'lodash',
    'zepto',
    'utils',
    'coreUtils',
    'santaProps',
    'thirdPartyAnalytics',
    'core/bi/errors',
    'componentsCore',
    'experiment'
], function (
    _,
    $,
    utils,
    coreUtils,
    santaProps,
    thirdPartyAnalytics,
    errors,
    componentsCore,
    experiment
) {
    'use strict';

    const {logger} = utils;
    const privatesMap = new WeakMap();

    const TPA_COMP_TYPES = [
        'wysiwyg.viewer.components.tpapps.TPAGluedWidget',
        'wysiwyg.viewer.components.tpapps.TPASection',
        'wysiwyg.viewer.components.tpapps.TPAMultiSection',
        'wysiwyg.viewer.components.tpapps.TPAWidget'
    ];
    /**
     * @typedef {SiteAPI} core.SiteAPI
     */

    /**
     *
     * @param site
     * @constructor
     */
    function SiteAPI(site, from) {
        if (!site && arguments.length && typeof window !== 'undefined') {
            from = from || 'unknown';
            if (window.wixBiSession) {
                window.wixBiSession.sendError(errors.NULL_SITE_ERROR, from);
            } else {
                //console.error('Null site', from);
            }
        }
        this._site = site;

        _.bindAll(this, _.functionsIn(this));

        const fetchers = santaProps.fetchers.get(this);
        privatesMap.set(this, {fetchers});

        const actionQueue = this.getActionQueue();
        if (actionQueue) {
            actionQueue.registerPreFlushOperation(this.setQueueFlushStart);
            actionQueue.registerPostFlushOperation(this.setQueueFlushEnd);
        }
    }

    /**
     *
     * @param {ps.pointers} pointers
     * @param compId
     * @param rootId
     * @param animatingCompsIds
     */
    function isAnimating(pointers, compPointer, compsInRoot) {
        while (compPointer) {
            if (_.get(compsInRoot, [compPointer.id, 'isCurrentlyAnimating'])) {
                return true;
            }
            compPointer = pointers.components.getParent(compPointer);
        }

        return false;
    }

    function getControllerStageData(appId, controllerType, controllerState) {
        const controllerStageDataPointer = this.getPointers().platform.getControllerStageDataPointer(appId, controllerType, controllerState);
        return this.getDisplayedDAL().get(controllerStageDataPointer);
    }

    function getViewMode(isMobile) {
        return isMobile ? utils.constants.VIEW_MODES.MOBILE : utils.constants.VIEW_MODES.DESKTOP;
    }

    function updatePageAnchorsIfNeeded(forceMobileStructure) {
        const rootIds = this.getRootIdsWhichShouldBeRendered();
        const siteDataAPI = this.getSiteDataAPI();
        const viewMode = getViewMode(forceMobileStructure);
        const anchorsMap = this.getSiteData().anchorsMap;
        _.forEach(rootIds, function (rootId) {
            if (!_.has(anchorsMap, [rootId, viewMode])) {
                siteDataAPI.anchors.createPageAnchors(rootId, {forceMobileStructure});
            }
        });
    }

    function isCompOnPageId(siteAPI, compId, pageId) {
        const pointers = siteAPI.getPointers();
        const rootPointer = pointers.components.getPage(pageId, siteAPI.getSiteData().getViewMode());
        const compPointer = pointers.components.getComponent(compId, rootPointer);
        return siteAPI.getDisplayedDAL().isExist(compPointer) && pointers.components.getPageOfComponent(compPointer).id === pageId;
    }

    function getRenderedCompPageId(siteAPI, compId) {
        const renderedRootIds = siteAPI.getAllRenderedRootIds();
        if (_.includes(renderedRootIds, compId)) {
            return compId;
        }
        return _.find(renderedRootIds, rootId => isCompOnPageId(siteAPI, compId, rootId));
    }

    function isCompInFixPosition(measureMap, compId) {
        return _.get(measureMap, ['shownInFixed', compId], false);
    }

    function getAPIGlobalDataPath(apiName) {
        return ['siteApi', apiName, 'globalData'];
    }

    function updateCurrentPageDataItem(partialDataToUpdate) {
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const siteData = this.getSiteData();
        const currentUrlPageId = siteData.getCurrentUrlPageId();
        const pageDataItemPointer = pointers.data.getDataItem(currentUrlPageId, siteData.MASTER_PAGE_ID);

        const partialCurrentData = _.pick(displayedDAL.get(pageDataItemPointer), _.keys(partialDataToUpdate));

        if (!_.isEqual(partialCurrentData, partialDataToUpdate)) {
            displayedDAL.merge(pageDataItemPointer, partialDataToUpdate);
        }
    }

    function removeKeysFromCurrentPageDataItem(keysToRemove) {
        if (_.isEmpty(keysToRemove)) {
            return;
        }
        const displayedDAL = this.getDisplayedDAL();
        const pointers = this.getPointers();
        const siteData = this.getSiteData();
        const currentUrlPageId = siteData.getCurrentUrlPageId();
        const pageDataItemPointer = pointers.data.getDataItem(currentUrlPageId, siteData.MASTER_PAGE_ID);

        const pageData = displayedDAL.get(pageDataItemPointer);
        const dataWithItemsRemoved = displayedDAL.get(pageDataItemPointer);
        _.forEach(keysToRemove, key => _.unset(dataWithItemsRemoved, key));

        if (!_.isEqual(pageData, dataWithItemsRemoved)) {
            displayedDAL.set(pageDataItemPointer, dataWithItemsRemoved);
        }
    }

    function shouldBlockLinkNavigation(renderedLink) {
        const isExternalLink = utils.linkRenderer.isExternalLink(this.getSiteData(), renderedLink.href);
        const isEmailLink = renderedLink['data-type'] === 'mail';
        const isOnCurrentTab = renderedLink.target === '_self';
        return !this.getRenderFlag('isExternalNavigationAllowed') &&
            (isExternalLink && isOnCurrentTab || isEmailLink); // eslint-disable-line no-mixed-operators
    }

    function navigateToLink(renderedLink) { // eslint-disable-line complexity
        const isExternalLink = utils.linkRenderer.isExternalLink(this.getSiteData(), renderedLink.href);
        renderedLink['data-type'] = isExternalLink ? 'external' : renderedLink['data-type'];
        renderedLink.target = renderedLink.target || '_self';

        switch (renderedLink['data-type']) {
            case 'external':
                window.open(renderedLink.href, renderedLink.target);
                break;
            case 'mail':
                window.open(renderedLink.href, '_self');
                break;
            case 'phone':
                window.open(renderedLink.href, '_self');
                break;
            case 'document':
                renderedLink.docId = renderedLink.href;
                const docLink = coreUtils.linkUtils.getDocumentLink(renderedLink, this.getSiteData());
                window.open(docLink, '_blank');
                break;
            default:
                const url = renderedLink['data-no-physical-url'] ? renderedLink['data-no-physical-url'] : renderedLink.href;
                const navInfo = utils.wixUrlParser.parseUrl(this.getSiteData(), url);
                if (navInfo) {
                    _.assign(navInfo, {
                        pageItemAdditionalData: renderedLink['data-page-item-context'],
                        anchorData: renderedLink['data-anchor']
                    });
                    this.handleNavigation(navInfo, renderedLink, true);
                }
        }
    }

    SiteAPI.prototype = {
        setSite(site) {
            this._site = site;
        },

        reLayout() {
            //TODO: probably should control this
            this._site.reLayout();
        },

        registerReLayoutPending(compId) {
            this._site.registerReLayoutPending(compId);
        },

        cancelReLayoutPending(compId) {
            this._site.cancelReLayoutPending(compId);
        },

        reLayoutIfPending() {
            this._site.reLayoutIfPending();
        },
        /**
         * @param  siteAspectName
         * @return {*}
         */
        getSiteAspect(siteAspectName) {
            const {_site} = this;
            return _site &&
                ((_site.siteAspects && _site.siteAspects[siteAspectName]) ||
                 (_site.props.aspects && _site.props.aspects[siteAspectName]));
        },
        /**
         *
         * @return {core.SiteData}
         */
        getSiteData() {
            return this._site.props.siteData;
        },

        /**
         *
         * @return {core.SiteData}
         */
        getLoadedStyles() {
            return this._site.loadedStyles;
        },

        /**
         *
         * @param {string} key
         * @param {*} value
         */
        setBiParam(key, value) {
            const siteData = this.getSiteData();
            if (siteData && siteData.wixBiSession && siteData.wixBiSession[key] === undefined) {
                siteData.wixBiSession[key] = value;
            }
        },

        addPrefetchPages(pageIds) {
            const displayedDAL = this.getDisplayedDAL();
            const prefetchPages = displayedDAL.getByPath(['prefetchPages']);
            displayedDAL.setByPath(['prefetchPages'], _.union(prefetchPages, pageIds));
        },

        markVisitedPage(pageId) {
            const displayedDAL = this.getDisplayedDAL();
            const prefetchPages = displayedDAL.getByPath(['visitedPages']);
            displayedDAL.setByPath(['visitedPages'], _.union(prefetchPages, [pageId]));
        },

        setNoEnforceAnchors(noEnforceAnchors) {
            const displayedDAL = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const noEnforceAnchorsPointer = pointers.general.getNoEnforceAnchorsPointer();

            displayedDAL.set(noEnforceAnchorsPointer, noEnforceAnchors);
        },

        getNoEnforceAnchors() {
            const displayedDAL = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const noEnforceAnchorsPointer = pointers.general.getNoEnforceAnchorsPointer();

            return displayedDAL.get(noEnforceAnchorsPointer);
        },

        requestEnforceAnchors() {
            this.getSiteData().enforceAndPatchRequested = true;
        },

        /**
         *
         * @param {string} name
         */
        setBiMarker(name) {
            this.setBiParam(name, _.now());
        },

        onSiteUnmount() {
            setTimeout(function () {
                delete this._site;
            }.bind(this));
        },
        /**
         *
         * @return {boolean}
         */
        isZoomOpened() {
            const siteData = this._site.props.siteData;
            return !!(siteData.getExistingRootNavigationInfo(siteData.getFocusedRootId()).pageItemId || siteData.getNonPageItemZoomData());
        },
        /**
         *
         * @param {string} url
         * @param {string} target - one of: _blank, _top, _parent, _self
         * @param {string=} params
         */
        openPopup(url, target, params) {
            window.open(url, target, params);
        },

        /**
         *
         * @param url
         */
        href(url) {
            window.location.href = url;
        },
        /**
         * This navigates to the properties rendered by linkRenderer, without needing an actual dom node and click event
         * @param renderedLink
         */
        navigateToRenderedLink(renderedLink) {
            if (shouldBlockLinkNavigation.call(this, renderedLink)) {
                return false;
            }

            navigateToLink.call(this, renderedLink);
            return true;
        },

        /**
         * returns whether the page is a landing page
         * @param pageId
         * @returns {boolean}
         */
        isPageLandingPage(pageId) {
            return this.getSiteData().isPageLandingPage(pageId);
        },

        getMasterPageLayoutSettings() {
            return this.getSiteData().getMasterPageLayoutSettings();
        },

        isPageAllowedAndLoaded(rootNavInfo) {
            return this.isPageAllowed(rootNavInfo) && coreUtils.siteDataUtils.isPageLoaded(this.getSiteData(), rootNavInfo.pageId);
        },

        isPageAllowed(navigationInfo) {
            return this.getSiteAspect('siteMembers').isPageAllowed(navigationInfo);
        },

        /**
         * @return {string}
         */
        scrollToAnchor(anchorData, progressCallback) {
            const scroll = utils.scrollAnchors.calcAnchorScrollToPosition(anchorData, this);
            return this._site.scrollToAnchor(scroll, progressCallback);
        },
        /**
         *
         * @param compId
         * @param {{
         *      onStart: function,
         *      onUpdate: function,
         *      onComplete: function
         * }} callbacks
         */
        scrollToComponent(compId, callbacks) {
            const siteData = this.getSiteData();
            const measureMap = siteData.measureMap || {};
            const compPageId = getRenderedCompPageId(this, compId);
            if (compPageId) {
                if (!isCompInFixPosition(measureMap, compId)) {
                    const compTop = _.get(measureMap, ['absoluteTop', compId]) || _.get(measureMap, ['top', compId]);
                    const scrollContainer = siteData.isPopupPage(compPageId) ? siteData.getPopupsContainer() : this._site.props.getSiteContainer();
                    this._site.scrollTo({y: compTop}, callbacks, scrollContainer);
                } else if (callbacks) { //no scrolling is needed since the component is already fixed on screen
                    _.forEach(callbacks, function (callback) {
                        if (_.isFunction(callback)) {
                            callback();
                        }
                    });
                }
            }
        },

        getRenderRealtimeConfigItem(name) {
            const displayedDal = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const renderRealtimeConfigItem = pointers.general.getRenderRealtimeConfigItem(name);

            return displayedDal.get(renderRealtimeConfigItem);
        },

        /**
         *
         * @returns {Array<string>} will return the rendered ids
         */
        getAllRenderedRootIds() {
            return this._site.getAllRenderedRootIds();
        },

        /**
         * Will return the ids which are supposed to be rendered according to the site data.. but may not have actually rendered yet. Useful for componentWillUpdate, componentWillMount etc. type of lifecycle events
         * @returns {Array<string>}
         */
        getRootIdsWhichShouldBeRendered() {
            return this._site.getRootIdsWhichShouldBeRendered();
        },

        navigateToPage(navigationInfo, skipHistory, shouldReplaceHistory, ignorePageUriSeo) {
            const leaveOtherRootsAsIs = false;
            this._site.tryToNavigate(navigationInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory, ignorePageUriSeo);
        },
        updateUrlIfNeeded(navigationInfo) {
            this._site.updateUrlIfNeeded(navigationInfo);
        },
        handleNavigation(navInfo, linkUrl, changeUrl) {
            this._site.handleNavigation(navInfo, linkUrl, changeUrl);
        },
        getDynamicPageRealPageAndReloadLoadApps(navInfo) {
            const siteData = this.getSiteData();
            this._site.props.getDynamicPageRealPage(siteData, navInfo, function () {
                this.getSiteAspect('WidgetAspect').restartApps();
            }.bind(this));
        },
        updatePageNavInfo(navigationInfo, skipHistory, shouldReplaceHistory, ignorePageUriSeo) {
            const siteData = this.getSiteData();
            const leaveOtherRootsAsIs = true;
            if (navigationInfo.pageId !== siteData.getPrimaryPageId() && navigationInfo.pageId !== siteData.getCurrentPopupId()) {
                throw new Error('you need to navigate to page if it\'s a different page');
            }
            this._site.tryToNavigate(navigationInfo, skipHistory, leaveOtherRootsAsIs, shouldReplaceHistory, ignorePageUriSeo);
        },

        /**
         * call this method if you need to render the site on event handler (add components to it)
         */
        forceUpdate(callback) {
            this._site.forceUpdate(callback);
        },

        getComponentsByPageId(rootId) {
            return this._site.getComponentsByPageId(rootId);
        },

        getComponentsOnFocusedPage() {
            return this._site.getComponentsByPageId(this.getSiteData().getFocusedRootId());
        },

        /**
         * @param {bool} forceAddHash - force add to the url the string after the #
         * @returns {string}
         */
        getPageUrl(forceAddHash) {
            const routerId = _.get(this.getSiteData().getRootNavigationInfo(), 'routerDefinition.routerId');
            return this.getPageUrlFor(this.getSiteData().getCurrentUrlPageId(), forceAddHash, routerId, 'CURRENT_INNER_ROUTE');
        },

        getPageData() {
            return this.getSiteData().getDataByQuery(this.getSiteData().getCurrentUrlPageId());
        },

        setPageTitle(title, descriptionSEO, pageTitleSEO) {
            updateCurrentPageDataItem.call(this, {
                title,
                descriptionSEO,
                pageTitleSEO
            });
        },

        setRunTimePageTitle(title, descriptionSEO, pageTitleSEO) {
            this.setPageTitle(title, descriptionSEO, pageTitleSEO);
            this._site.updateSiteHeadTag();
            this._site.notifyAspects(this._site.supportedEvents.siteMetadataChange, {
                title: this.getSiteData().getCurrentUrlPageTitle(),
                description: descriptionSEO
            });
        },

        setRunTimeLinkTags(linkTags) {
            updateCurrentPageDataItem.call(this, linkTags);
            this._site.updateSiteHeadTag();
        },

        removePageSEORuntimeData(keysToRemove) {
            removeKeysFromCurrentPageDataItem.call(this, keysToRemove);
            this._site.updateSiteHeadTag();
        },

        setPageMetaKeywords(keywords) {
            updateCurrentPageDataItem.call(this, {
                metaKeywordsSEO: keywords
            });
        },

        setPageMetaOgTags(ogTags) {
            const siteData = this.getSiteData();
            siteData.setPageOgTags(siteData.getCurrentUrlPageId(), ogTags);
            this._site.updateSiteHeadTag();
        },

        setPageJsonld(jsonld) {
            const siteData = this.getSiteData();
            siteData.setJsonLd(siteData.getCurrentUrlPageId(), jsonld);
        },

        setPageJsonldImmediate(jsonld) {
            const siteData = this.getSiteData();
            siteData.setJsonLd(siteData.getCurrentUrlPageId(), jsonld);
            this._site.updateSiteHeadTag(['ld+json']);
        },

        setPageLinkTag(linkTag) {
            const siteData = this.getSiteData();
            siteData.setPageLinkTag(siteData.getCurrentUrlPageId(), linkTag);
        },

        setPageTwitterMetaTags(twitterTags) {
            const siteData = this.getSiteData();
            siteData.setPageTwitterTags(siteData.getCurrentUrlPageId(), twitterTags);
        },

        getPageUrlFor(pageId, forceAddHash, routerId, innerRoute) {
            const pageData = this.getSiteData().getDataByQuery(pageId);
            if (!pageData) {
                return '';
            }

            const pageInfo = {
                pageId,
                title: pageData.pageUriSEO
            };
            if (routerId) {
                pageInfo.routerId = routerId;
                pageInfo.innerRoute = innerRoute;
            }
            return utils.wixUrlParser.getUrl(this.getSiteData(), pageInfo, forceAddHash);
        },

        initFacebookRemarketing(pixelId) {
            if (pixelId) {
                thirdPartyAnalytics.initFacebookRemarketingPixelId(this.getSiteData(), pixelId);
            } else {
                thirdPartyAnalytics.initFacebookRemarketingUserPixel(this.getSiteData());
            }
        },

        fireFacebookRemarketingPixel(eventName, eventData) {
            thirdPartyAnalytics.fireFacebookRemarketingPixel(this.getSiteData(), eventName, eventData);
        },

        initGoogleRemarketing() {
            thirdPartyAnalytics.initGoogleRemarketingPixel(this.getSiteData());
        },

        fireGoogleRemarketing() {
            thirdPartyAnalytics.fireGoogleRemarketingPixel();
        },

        reportGoogleAnalytics() {
            thirdPartyAnalytics.reportGoogleAnalytics(this.getSiteData(), ...arguments);
        },

        reportGoogleTagManager(events) {
            thirdPartyAnalytics.reportGoogleTagManager(this.getSiteData(), events);
        },

        reportBiAnalytics(params) {
            thirdPartyAnalytics.reportBiAnalytics(this.getSiteData(), params);
        },

        fireFacebookCustomEvent(eventType, eventName, data) {
            thirdPartyAnalytics.fireFacebookCustomEvent(this.getSiteData(), eventType, eventName, data);
        },

        initYandexMetrika() {
            thirdPartyAnalytics.initYandexMetrika(this.getSiteData());
        },

        reportYandexPageHit(url) {
            thirdPartyAnalytics.reportYandexPageHit(url);
        },

        /**
         * Reports to BI
         * @param reportDef - either event/error/beat object
         * @param params
         */
        reportBI(reportDef, params) {
            logger.reportBI(this._site.props.siteData, reportDef, params);
        },

        /**
         * Returns the visitor_id GUID as used in site BI events reporting
         * @returns {string}
         */
        getBiVisitorId() {
            return _.get(this.getSiteData(), 'wixBiSession.visitorId');
        },

        /**
         * Sets a new visitorId
         * @param visitorId - new generated visitor_id
         */
        setBiVisitorId(visitorId) {
            _.set(this.getSiteData(), ['wixBiSession', 'visitorId'], visitorId);
        },

        /**
         * Returns the siteMemberId GUID as used in site BI events reporting
         * @returns {string}
         */
        getBiSiteMemberId() {
            return _.get(this.getSiteData(), 'wixBiSession.siteMemberId');
        },

        /**
         * Sets a new siteMemberId
         * @param siteMemberId - new generated siteMemberId
         */
        setBiSiteMemberId(siteMemberId) {
            _.set(this.getSiteData(), ['wixBiSession', 'siteMemberId'], siteMemberId);
        },

        /**
         * Reports current page event
         */
        reportCurrentPageEvent(currentUrl, pageId) {
            const siteData = this._site.props.siteData;
            if (!currentUrl) {
                currentUrl = _.isString(siteData.currentUrl) ? siteData.currentUrl : siteData.currentUrl.full;
            }
            if (!pageId) {
                pageId = siteData.getCurrentUrlPageId();
            }
            const baseUrl = siteData.getExternalBaseUrl().replace(/\/$/, '');
            const path = currentUrl.replace(baseUrl, '');
            this.reportPageEvent(path, pageId);
        },

        /**
         * Reports page event
         * @param {string} pagePath
         */
        reportPageEvent(pagePath, pageId) {
            const siteData = this._site.props.siteData;
            thirdPartyAnalytics.reportPageEvent(siteData, pagePath);
            utils.integrations.promoteAnalytics.reportPageView(this, {
                pagePath,
                pageTitle: siteData.getPageTitle(pageId),
                pageId,
                pageNumber: siteData.biData.getPageNumber()
            });
        },

        /**
         * Reports Beat event
         * @param evid (number greater than 3, or 'start', 'reset', 'finish'
         * @param {string} pageId
         */
        reportBeatEvent(evid, pageId) {
            logger.reportBeatEvent(this._site.props.siteData, evid, pageId);
        },

        /**
         *
         * @param {string} pageId
         */
        reportBeatStart(pageId) {
            this.getSiteData().biData.switchPage(pageId);
            this.reportBeatEvent('start', pageId);
        },

        /**
         *
         * @param {string} pageId
         */
        reportBeatFinish(pageId) {
            this.reportBeatEvent('finish', pageId);

            const siteData = this.getSiteData();
            utils.integrations.promoteAnalytics.trackEvent(this, 'WAPageView', {
                page_number: siteData.biData.getPageNumber(),
                page_id: pageId
            });
        },

        reportPageBI() {
            logger.reportBI(this._site.props.siteData);
        },

        getUserSession() {
            return this._site.props.siteData.getSvSession();
        },

        setUserSession(userSessionToken) {
            if (userSessionToken !== this.getUserSession()) {
                const siteData = this.getSiteData();
                siteData.pubSvSession(userSessionToken);
                utils.cookieUtils.deleteCookie('svSession', siteData.currentUrl.hostname, siteData.getMainPagePath());
                utils.cookieUtils.setCookie('svSession', userSessionToken, null, siteData.currentUrl.hostname, siteData.getMainPagePath());

                this._site.notifyAspects(this._site.supportedEvents.svSessionChange, userSessionToken);
            }
        },

        triggerFakeModeChange(pageId, compId) {
            this._site.notifyAspects(this._site.supportedEvents.fakeModeChange, pageId, compId);
        },

        isSiteBusy() {
            return this._site.isBusy;
        },

        isSiteBusyIncludingTransition() {
            return this.isSiteBusy() || this._site.isDuringTransition;
        },

        startingPageTransition(pageInfo) {
            const currNavigationInfo = this.getSiteData().getRootNavigationInfo();
            const isDynamicPageChange = !_.isEqual(currNavigationInfo.routerDefinition, pageInfo.routerDefinition) || currNavigationInfo.innerRoute !== pageInfo.innerRoute;
            const isLanguageChange = pageInfo.isLanguageChange;
            if (pageInfo.pageId && (this.getSiteData().getFocusedRootId() !== pageInfo.pageId) || isDynamicPageChange || isLanguageChange) { // eslint-disable-line no-mixed-operators
                this._site.isDuringTransition = true;
            }
        },
        endingPageTransition() {
            this._site.isDuringTransition = false;
        },

        setSiteScrollingEnabled(enabled) {
            if (enabled) {
                $('html').removeClass('disableSiteScroll');
            } else {
                $('html').addClass('disableSiteScroll');
            }
        },

        enterFullScreenMode(options) {
            const siteData = this.getSiteData();

            let addClass;
            if (siteData.isInSSR()) {
                const bodyClasses = siteData.ssr.bodyClasses = siteData.ssr.bodyClasses || [];
                addClass = bodyClasses.push.bind(bodyClasses);
            } else {
                const body = $('body');
                addClass = body.addClass.bind(body);
            }

            addClass('fullScreenMode');
            if (!options || options.scrollable) {
                addClass('fullScreenMode-scrollable');
            }
        },

        exitFullScreenMode() {
            if (typeof window === 'undefined') {
                utils.log.warn('SiteAPI.exitFullScreenMode should only be called from client specific code!');
                return;
            }
            $('body').removeClass('fullScreenMode');
            $('body').removeClass('fullScreenMode-scrollable');
        },

        enterOverflowHiddenMode() {
            const siteData = this.getSiteData();

            let addClass;
            if (siteData.isInSSR()) {
                const bodyClasses = siteData.ssr.bodyClasses = siteData.ssr.bodyClasses || [];
                addClass = bodyClasses.push.bind(bodyClasses);
            } else {
                const body = $('body');
                addClass = body.addClass.bind(body);
            }

            addClass('overflowHidden');
        },

        exitOverflowHiddenMode() {
            if (typeof window === 'undefined') {
                utils.log.warn('SiteAPI.exitOverflowHiddenMode should only be called from client specific code!');
                return;
            }
            $('body').removeClass('overflowHidden');
        },

        enterMediaZoomMode() {
            const siteData = this.getSiteData();

            let addClass;
            if (siteData.isInSSR()) {
                const htmlClasses = siteData.ssr.htmlClasses = siteData.ssr.htmlClasses || [];
                addClass = htmlClasses.push.bind(htmlClasses);
            } else {
                const html = $('html');
                addClass = html.addClass.bind(html);
            }

            addClass('media-zoom-mode');
        },

        exitMediaZoomMode() {
            if (typeof window === 'undefined') {
                utils.log.warn('SiteAPI.exitMediaZoomMode should only be called from client specific code!');
                return;
            }
            $('html').removeClass('media-zoom-mode');
        },

        forceBackground(background) {
            window.document.body.style.background = background;
        },

        disableForcedBackground() {
            window.document.body.style.background = '';
        },

        /**
         * this is needed in the cases when you cannot let the click just go the all the dom tree until it reaches the site (like in mediaZoom)
         * @param event the click event
         */
        passClickEvent(event) {
            this._site.clickHandler(event);
        },

        getRuntimeDal() {
            return this.getSiteDataAPI().runtime;
        },

        hasPendingFonts() {
            return this.getSiteAspect('fontsLoaderAspect').hasPendingFonts();
        },
        getCurrentPopupId() {
            return this.getSiteData().getCurrentPopupId();
        },

        openPopupPage(popupId) {
            this.getActionQueue().runImmediately(function () {
                this.navigateToPage({pageId: popupId}, true);
            }.bind(this));
        },

        closePopupPage() {
            this.getActionQueue().runImmediately(function () {
                this.navigateToPage(this.getSiteData().getRootNavigationInfo(), true);
            }.bind(this));
        },

        isPopupOpened() {
            return this.getSiteData().isPopupOpened();
        },

        getSiteDataAPI() {
            return _.get(this, '_site.props.viewerPrivateServices.siteDataAPI');
        },

        getPointers() {
            return this._site.props.viewerPrivateServices.pointers;
        },

        getDisplayedDAL() {
            return this._site.props.viewerPrivateServices.displayedDAL;
        },

        getActiveModes() {
            return this.getSiteDataAPI().modes.getActiveModes();
        },

        updateActiveSOSPModes(pageId) {
            return this.getSiteDataAPI().updateActiveSOSPModes(pageId);
        },

        getPrevRenderActiveModes() {
            return this._site.prevActiveModes;
        },

        activateModeById(compId, rootId, modeId) {
            const pointers = this.getPointers();
            const rootPointer = pointers.components.getPage(rootId, this.getSiteData().getViewMode());
            const compPointer = pointers.components.getComponent(compId, rootPointer);
            this.activateMode(compPointer, modeId);
        },

        activateMode(compPointer, modeId) {
            const pointers = this.getPointers();
            const pagePointer = pointers.components.getPageOfComponent(compPointer);
            if (isAnimating(pointers, compPointer, this._site.getComponentsByPageId(pagePointer.id))) {
                return;
            }

            this.getSiteDataAPI().modes.activateMode(compPointer, modeId);
        },

        deactivateModeById(compId, rootId, modeId) {
            this.getSiteDataAPI().modes.deactivateModeById(compId, rootId, modeId);
        },

        deactivateMode(pointer, modeId) {
            this.getSiteDataAPI().modes.deactivateMode(pointer, modeId);
        },

        resetRuntimeOverrides(rootId, samePageNavigation) {
            this.getSiteDataAPI().resetRuntimeOverrides(rootId, samePageNavigation);
        },

        switchModesByIds(compId, rootId, modeIdToDeactivate, modeIdToActivate) {
            this.getSiteDataAPI().modes.switchModesByIds(compId, rootId, modeIdToDeactivate, modeIdToActivate);
        },

        resetAllActiveModes() {
            this.getSiteDataAPI().modes.resetAllActiveModes();
        },

        setMobileView(isMobile) {
            const siteData = this.getSiteData();
            if (siteData.isMobileView() !== isMobile) {
                updatePageAnchorsIfNeeded.call(this, isMobile);
                siteData.setMobileView(isMobile);
                if (!isMobile) {
                    siteData.updateScreenSize();
                }
                const currNavigationInfo = siteData.getRootNavigationInfo();
                if (currNavigationInfo.routerDefinition) {
                    this.getDynamicPageRealPageAndReloadLoadApps(currNavigationInfo);
                } else {
                    this.getSiteAspect('WidgetAspect').restartApps();
                }
            }
        },

        showSelectionSharer(position, shareInfo) {
            this._site.refs.selectionSharer.show(position, shareInfo);
        },

        hideSelectionSharer() {
            if (this._site.refs.selectionSharer.isVisible()) {
                this._site.refs.selectionSharer.hide();
            }
        },

        isSelectionSharerVisible() {
            return _.get(this._site, 'refs.selectionSharer') && this._site.refs.selectionSharer.isVisible();
        },

        getWixCodeAppApi() {
            return this._site.props.wixCodeAppApi;
        },

        getControllerStageData(appId, controllerType, controllerState) {
            const stateStageData = getControllerStageData.call(this, appId, controllerType, controllerState);
            if (controllerState === 'default') {
                return stateStageData;
            }
            const defaultStageData = getControllerStageData.call(this, appId, controllerType, 'default');
            return _.defaults({}, stateStageData, defaultStageData);
        },
        getControllerState(controllerId) {
            const controllerStatePointer = this.getPointers().platform.getControllerStatePointer(controllerId);
            return this.getDisplayedDAL().get(controllerStatePointer) || 'default';
        },

        getAppManifest(appId) {
            const appManifestPointer = this.getPointers().platform.getAppManifestPointer(appId);
            return this.getDisplayedDAL().get(appManifestPointer);
        },

        getComponentByPageAndCompId(rootId, compId) {
            return this._site.getComponentByPageAndCompId(rootId, compId);
        },

        getAspectGlobalData(aspectName) {
            const displayDal = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const globalDataPointer = pointers.siteAspects.getAspectGlobalData(aspectName);

            return displayDal.get(globalDataPointer);
        },

        getAspectComponentData(aspectName, compId) {
            const displayDal = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const dataPointer = compId ?
                pointers.siteAspects.getAspectComponentData(aspectName, compId) :
                pointers.siteAspects.getAspectAllComponentsData(aspectName);

            return displayDal.get(dataPointer);
        },

        getRenderFlag(name) {
            const displayedDal = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const flagPointer = pointers.general.getRenderFlag(name);

            return displayedDal.get(flagPointer);
        },

        isLoggedInToWix() {
            const displayedDAL = this.getDisplayedDAL();
            const requestModelCookie = displayedDAL.getByPath(['requestModel', 'cookie']);
            return utils.wixUserApi.isSessionValid(requestModelCookie);
        },

        logoutFromWix() {
            const siteData = this.getSiteData();
            utils.wixUserApi.logout(siteData);
            this.forceUpdate();
        },

        registerClientSpecMapUpdateCallback(callback) {
            if (!_.isFunction(callback)) {
                return;
            }
            const displayedDAL = this.getDisplayedDAL();
            const clientSpecMapCallbacksPath = getAPIGlobalDataPath('ClientSpecMapUpdateCallbacks');

            if (displayedDAL.isPathExist(clientSpecMapCallbacksPath)) {
                const currentCallbacks = displayedDAL.getByPath(clientSpecMapCallbacksPath);
                displayedDAL.pushByPath(clientSpecMapCallbacksPath, callback, currentCallbacks.length);
            } else {
                displayedDAL.setByPath(clientSpecMapCallbacksPath, [callback]);
            }
        },

        getSiteRootPosition() {
            return this._site.props.siteRootPosition;
        },

        setClientSpecMap(clientSpecMap) {
            const displayedDAL = this.getDisplayedDAL();
            const clientSpecMapPointer = this.getPointers().general.getClientSpecMap();
            displayedDAL.set(clientSpecMapPointer, clientSpecMap);
            this.triggerClientSpecMapUpdatedCallbacks(clientSpecMap);
        },

        triggerClientSpecMapUpdatedCallbacks(clientSpecMap) {
            const displayedDAL = this.getDisplayedDAL();
            if (this._site.props.eventsManager) {
                this._site.props.eventsManager.emit('clientSpecMapUpdated', clientSpecMap);
            }
            const clientSpecMapCallbacksPath = getAPIGlobalDataPath('ClientSpecMapUpdateCallbacks');
            if (displayedDAL.isPathExist(clientSpecMapCallbacksPath)) {
                const clientSpecMapUpdateCallbacks = displayedDAL.getByPath(clientSpecMapCallbacksPath);
                if (clientSpecMapUpdateCallbacks && _.isArray(clientSpecMapUpdateCallbacks)) {
                    _.forEach(clientSpecMapUpdateCallbacks, function (callback) {
                        callback(clientSpecMap);
                    });
                }
            }
        },

        getActionQueue() {
            const siteDataAPI = this.getSiteDataAPI();
            return siteDataAPI && siteDataAPI.getActionQueue();
        },

        setQueueFlushStart() {
            const siteData = this.getSiteData();
            siteData.relayoutBlockedByQueue = true;
        },

        setQueueFlushEnd() {
            const siteData = this.getSiteData();
            siteData.relayoutBlockedByQueue = false;
        },
        setComponentRenderStart(compId) {
            const siteData = this.getSiteData();
            siteData.renderingCompsMap.set(compId, false);
        },

        setComponentRenderEnd(compId) {
            const siteData = this.getSiteData();
            siteData.renderingCompsMap.set(compId, true);
        },

        setPopoversLayer(popoversLayerElement) {
            const siteData = this.getSiteData();
            siteData.popoversLayer = popoversLayerElement;
        },

        getPopoversLayer() {
            const siteData = this.getSiteData();
            return siteData.popoversLayer;
        },

        getSantaFetcher(santaTypeDefinition) {
            const fetchers = privatesMap.get(this).fetchers;
            const fetchersRegistry = componentsCore.santaTypesFetchersRegistry.getRegisteredFetchers();
            const fetcher = _.get(fetchers, santaTypeDefinition.id) || _.get(fetchersRegistry, santaTypeDefinition.id);
            return fetcher.fetch || fetcher; //fetcher.fetch is the legacy santatypes which have not been converted
        },

        registerCompBehaviours(pageId, compId, compBehaviours) {
            const displayedDAL = this.getDisplayedDAL();
            const pointers = this.getPointers();

            const compBehavioursMapPointer = pointers.compBehaviours.getCompBehavioursMapPointer();
            const pageBehavioursPointer = pointers.getInnerPointer(compBehavioursMapPointer, [pageId]);
            const compBehavioursPointer = pointers.compBehaviours.getCompBehaviourPointer(pageId, compId);

            if (!displayedDAL.isExist(pageBehavioursPointer)) {
                displayedDAL.set(pageBehavioursPointer, {});
            }

            displayedDAL.set(compBehavioursPointer, compBehaviours);
            const actionAspect = this.getSiteAspect('actionsAspect');
            const behaviorsAspect = this.getSiteAspect('behaviorsAspect');

            actionAspect.registerBehaviors(compId, pageId, compBehaviours);
            behaviorsAspect.setBehaviorsForActions(behaviorsAspect.convertBehaviors(compBehaviours, compId), pageId, compId);
        },

        unregisterCompBehaviours(pageId, compId) {
            const displayedDAL = this.getDisplayedDAL();
            const pointers = this.getPointers();

            const compBehavioursPointer = pointers.compBehaviours.getCompBehaviourPointer(pageId, compId);

            if (displayedDAL.isExist(compBehavioursPointer)) {
                displayedDAL.remove(compBehavioursPointer);
            }

            const actionAspect = this.getSiteAspect('actionsAspect');
            const behaviorsAspect = this.getSiteAspect('behaviorsAspect');

            actionAspect.registerBehaviors(compId, pageId, []);
            behaviorsAspect.setBehaviorsForActions([], pageId, compId);
        },

        getCompBehaviours() {
            const displayedDAL = this.getDisplayedDAL();
            const pointers = this.getPointers();
            const compBehavioursMapPointer = pointers.compBehaviours.getCompBehavioursMapPointer();

            return displayedDAL.get(compBehavioursMapPointer);
        },

        resetCompBehavioursByPageAndCompId(pageId, compId) {
            const runtime = this.getRuntimeDal();
            return runtime.resetActionsAndBehaviors(compId);
        },

        isDuringPostUpdatesOperations(compId) {
            const siteData = this.getSiteData();
            return !!siteData.postUpdateOperationsRenders[compId];
        },

        removePostUpdateOperationsRender(compId) {
            const siteData = this.getSiteData();
            delete siteData.postUpdateOperationsRenders[compId];
        },

        getLayoutMechanism() {
            return coreUtils.layoutUtils.getLayoutMechanism(this.getSiteData());
        },

        didReact() {
            return this.getSiteData().reacted;
        },

        resetSiteReacted() {
            this.getSiteData().reacted = false;
        },

        isExperimentOpen(name) {
            return experiment.isOpen(name, this.getSiteData());
        },
        getExperimentValue(name) {
            return experiment.getValue(name, this.getSiteData());
        },
        resetCustomClickOccurred() {
            this.getSiteData().customClickOccurred = false;
        },
        getCustomClickOccurred() {
            return this.getSiteData().customClickOccurred;
        },
        setCustomClickOccurred() {
            this.getSiteData().customClickOccurred = true;
        },
        getTPAComponentsOnPage(pageId) {
            const pointers = this.getPointers();
            const pagePointer = pointers.components.getPage(pageId, this.getSiteData().getViewMode());
            if (!pagePointer) {
                return;
            }
            const allPageComponents = pointers.components.getChildrenRecursively(pagePointer);
            return _.reduce(allPageComponents, function (res, childPointer) {
                const componentType = this.getDisplayedDAL().get(pointers.getInnerPointer(childPointer, ['componentType']));
                if (_.includes(TPA_COMP_TYPES, componentType)) {
                    res.push(childPointer.id);
                }
                return res;
            }.bind(this), []);
        },
        reloadPage() {
            window.location.reload();
        },
        getCompsDataOnPage(pageId) {
            const pointers = this.getPointers();
            const displayedDAL = this.getDisplayedDAL();
            const pagePointer = pointers.components.getPage(pageId, this.getSiteData().getViewMode());
            if (!pagePointer) {
                return;
            }
            const allPageComponents = pointers.components.getChildrenRecursively(pagePointer);
            return _.map(allPageComponents, compPointer => {
                const dataQueryPointer = pointers.getInnerPointer(compPointer, 'dataQuery');
                const dataQuery = displayedDAL.get(dataQueryPointer);
                const componentType = displayedDAL.get(pointers.getInnerPointer(compPointer, ['componentType']));
                const data = this.getSiteData().getDataByQuery(dataQuery, pageId, this.getSiteData().dataTypes.DATA);
                return _.assign(data, {
                    componentType,
                    compId: compPointer.id
                });
            });
        },

        getCompProps: santaProps.componentPropsBuilder.getCompProps,

        getHostLibsAspectConstructor(aspectName) {
            return componentsCore.siteAspectsRegistry.getHostLibsAspectConstructor(aspectName);
        },

        getComponentType(compId) {
            const currentPageId = this.getPageData().id;
            const pointers = this.getPointers();
            const displayedDAL = this.getDisplayedDAL();
            const pagePointer = pointers.components.getPage(currentPageId, this.getSiteData().getViewMode());
            const compPointer = pointers.components.getComponent(compId, pagePointer);
            return displayedDAL.get(pointers.getInnerPointer(compPointer, ['componentType']));
        },
        getSvgStringToStoreData(svgString) {
            return coreUtils.svg.svgStringToStoreData(svgString);
        },
        notifyOnRendered() {
            this._site.notifyAspects(this._site.supportedEvents.onRendered);
        },
        notifyFullyRendered() {
            this._site.notifyAspects(this._site.supportedEvents.fullyRendered);
        },

        getClientSpecMap() {
            const siteData = this.getSiteData();
            return siteData.getClientSpecMap();
        },

        getPublicBaseUrl() {
            const siteData = this.getSiteData();
            return siteData.getPublicBaseUrl();
        },
        registerToSiteReady(callback) {
            this._site.registerAspectToEvent(this._site.supportedEvents.siteReady, callback);
        },
        getInitialClientSpecMap() {
            const siteData = this.getSiteData();
            return siteData.initialClientSpecMap;
        }

    };

    _.forEach({
        registerStateChange: 'mediaAspect',
        unregisterStateChange: 'mediaAspect',
        executeAction: 'actionsAspect',
        previewAnimation: 'actionsAspect',
        previewTransition: 'actionsAspect',
        stopPreviewAnimation: 'actionsAspect',
        reloadPageAnimations: 'actionsAspect',
        stopAndClearAllAnimations: 'animationsAspect',
        handleBehavior: 'behaviorsAspect',
        scroll: 'scrollableAspect',
        scrollBy: 'scrollableAspect',
        getTotalScroll: 'scrollableAspect',
        refreshAppsInCurrentPage: 'WidgetAspect',
        registerToNotifyApplicationRequestFromViewerWorker: 'WidgetAspect',
        isSiteScrollingBlocked: 'siteScrollingBlocker',
        removeAllPopups: 'tpaPopupAspect',
        removeModal: 'tpaModalAspect',
        updatePreventRefreshState: 'tpaCompStateAspect',
        getComponentsToRender: 'tpaWorkerAspect',
        getMemberDetailsInPreview: 'siteMembers',
        destroyAppsContainer: 'wixCodeWidgetAspect',
        reloadAppsContainer: 'wixCodeWidgetAspect',
        initApp: 'wixCodeWidgetAspect',
        registerNavigationComplete: 'actionsAspect',
        registerToPageNavigationCanceled: 'actionsAspect',
        updateSectionUrlState: 'tpaCompStateAspect',
        updateSectionUrlParams: 'tpaCompStateAspect',
        updatePushState: 'tpaCompStateAspect',
        reportStateChanged: 'tpaCompStateAspect',
        loadAllContextsUserCode: 'WidgetAspect',
        triggerAppStudioWidgetOnPropsChanged: 'WidgetAspect',
        getStylableEditorInstance: 'stylableRenderingAspect',
        setQuickChanges: 'stylableRenderingAspect',
        revertQuickChanges: 'stylableRenderingAspect',
        forceState: 'stylableRenderingAspect',
        revertForceState: 'stylableRenderingAspect'
    }, (aspectName, methodName) => _.set(SiteAPI.prototype, methodName, function () {
        const aspect = this.getSiteAspect(aspectName);
        return aspect[methodName].apply(aspect, arguments);
    }));

    return SiteAPI;
});
