define(['santa-components'], function (santaComponents) {
    'use strict';

    function getIframeReactComponent(src) {
        const iframe = santaComponents.utils.createReactElement('iframe', {
            src,
            id: 'checkoutPageIFrame',
            style: {
                width: '900px',
                height: '600px',
                top: '50%',
                left: '50%',
                marginLeft: '-450px',
                marginTop: '-300px',
                position: 'absolute'
            }
        });

        return santaComponents.utils.createReactElement('div', {
            style: {
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                backgroundColor: 'rgba(0, 0, 0, 0.5)'
            }
        }, iframe);
    }
    return getIframeReactComponent;
});
