/**
 * Created by lirank on 03/04/16.
 */

define(['santa-components', 'lodash', 'prop-types', 'create-react-class', 'coreUtils'], function (santaComponents, _, PropTypes, createReactClass, coreUtils) {
    'use strict';

    const wixappsClassicsLogger = coreUtils.wixappsClassicsLogger;
    const socialShareHandler = coreUtils.socialShareHandler;

    return createReactClass({

        displayName: 'selectionSharer',

        propTypes: {
            siteAPI: PropTypes.shape({
                getSiteData: PropTypes.func.isRequired,
                openPopup: PropTypes.func.isRequired
            }).isRequired
        },

        getInitialState() {
            return {
                visible: false,
                position: null,
                shareInfo: null
            };
        },

        sharePostWrapper(url) {
            const newShareInfo = this.state.shareInfo;
            newShareInfo.url = url;
            socialShareHandler.handleShareRequest(this.props.siteAPI, {
                url,
                service: newShareInfo.service,
                title: newShareInfo.title,
                storeId: newShareInfo.storeId,
                postId: newShareInfo.postId,
                hashTags: newShareInfo.hashTags || '',
                imageUrl: newShareInfo.imgSrc,
                addDeepLinkParam: false,
                description: newShareInfo.description
            });
        },

        show(position, shareInfo) {
            wixappsClassicsLogger.reportEvent(this.props.siteAPI.getSiteData(), wixappsClassicsLogger.events.SELECTION_SHARER_OPENED, {
                post_id: shareInfo.postId,
                site_id: shareInfo.siteId
            });
            this.setState({
                visible: true,
                position,
                shareInfo
            });
        },

        hide() {
            this.setState({
                visible: false,
                position: null,
                shareInfo: null
            });
        },

        isVisible() {
            return this.state.visible;
        },

        twitterClickHandler() {
            wixappsClassicsLogger.reportEvent(this.props.siteAPI.getSiteData(), wixappsClassicsLogger.events.SELECTION_SHARER_CLICKED, {
                type: 'twitter',
                post_id: this.state.shareInfo.postId,
                site_id: this.state.shareInfo.siteId
            });
            this.hide();
            const shareInfo = this.state.shareInfo;
            shareInfo.service = 'twitter';
            shareInfo.title = shareInfo.description;
            socialShareHandler.shortenURL(shareInfo.url, 2000, this.sharePostWrapper, this.sharePostWrapper.bind(null, shareInfo.url));
        },

        facebookClickHandler() {
            wixappsClassicsLogger.reportEvent(this.props.siteAPI.getSiteData(), wixappsClassicsLogger.events.SELECTION_SHARER_CLICKED, {
                type: 'facebook',
                post_id: this.state.shareInfo.postId,
                site_id: this.state.shareInfo.siteId
            });
            this.hide();
            const shareInfo = this.state.shareInfo;
            shareInfo.service = 'facebook';
            socialShareHandler.shortenURL(shareInfo.url, 2000, this.sharePostWrapper, this.sharePostWrapper.bind(null, shareInfo.url));
        },

        mailClickHandler() {
            wixappsClassicsLogger.reportEvent(this.props.siteAPI.getSiteData(), wixappsClassicsLogger.events.SELECTION_SHARER_CLICKED, {
                type: 'mail',
                post_id: this.state.shareInfo.postId,
                site_id: this.state.shareInfo.siteId
            });
            this.hide();
            const shareInfo = this.state.shareInfo;
            shareInfo.service = 'email';
            shareInfo.description = `${shareInfo.description}\r\n( ${shareInfo.url} )`;
            socialShareHandler.handleShareRequest(this.props.siteAPI, shareInfo);
        },

        render() {
            if (this.state.visible) {
                const containerStyle = {
                    position: 'absolute',
                    left: `${this.state.position.x}px`,
                    top: `${this.state.position.y}px`
                };

                const facebookImagePath = coreUtils.media.getMediaUrl(this.props.siteAPI.getSiteData().serviceTopology, 'selectionSharerSocialIcons/facebook.svg');
                const twitterImagePath = coreUtils.media.getMediaUrl(this.props.siteAPI.getSiteData().serviceTopology, 'selectionSharerSocialIcons/twitter.svg');
                const mailImagePath = coreUtils.media.getMediaUrl(this.props.siteAPI.getSiteData().serviceTopology, 'selectionSharerSocialIcons/mail.svg');

                return santaComponents.utils.createReactElement('div', {className: 'selectionSharerContainer', style: containerStyle},
                    [
                        santaComponents.utils.createReactElement('img', {key: 'selection-sharer-facebook-img', className: 'selectionSharerOption', src: facebookImagePath, onClick: this.facebookClickHandler}),
                        santaComponents.utils.createReactElement('div', {key: 'selection-sharer-first-separator', className: 'selectionSharerVerticalSeparator'}),
                        santaComponents.utils.createReactElement('img', {key: 'selection-sharer-twitter-img', className: 'selectionSharerOption', src: twitterImagePath, onClick: this.twitterClickHandler}),
                        santaComponents.utils.createReactElement('div', {key: 'selection-sharer-second-separator', className: 'selectionSharerVerticalSeparator'}),
                        santaComponents.utils.createReactElement('img', {key: 'selection-sharer-mail-img', className: 'selectionSharerOption', src: mailImagePath, onClick: this.mailClickHandler})
                    ]
                );
            }
            return null;
        }
    });
});

