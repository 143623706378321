define(['lodash'], function (_) {
    'use strict';

    // TODO this should be refactored somewhen to a common beats file that doesn't become a noop on ssr flow (like have a relevant to window in beat.js and not relevant to window in coreUtils/beatsCommons etc.)
    const BEAT_SITE_TYPES = ['No Site Type', 'WixSite', 'UGC', 'Template'];
    const PARTIALLY_VISIBLE_BEAT = 12;
    const MESH_ELIGIBILITY_BEAT = 13;

    function getSiteType(siteData) {
        const documentType = siteData.rendererModel.siteInfo.documentType;
        const siteType = _.indexOf(BEAT_SITE_TYPES, documentType);
        return siteType !== -1 ? siteType : documentType;
    }

    const createBeatScriptString = (siteData, et) => {
        const biUrl = siteData.getServiceTopologyProperty('biServerUrl') || 'https://frog.wix.com/';
        const src = 29;
        const evid = 3;
        const v = siteData.baseVersion || 'unknown';
        const msid = siteData.getMetaSiteId();
        const isp = siteData.isPremiumDomain() ? 1 : 0;
        const st = getSiteType(siteData);
        const beatSentFlag = et === PARTIALLY_VISIBLE_BEAT ? 'sentPartiallyVisibleBeat' : 'sentMeshEligibilityBeat';
        const mark = et === PARTIALLY_VISIBLE_BEAT ? 'Partially visible' : 'Meshable';

        return `
            if (typeof wixBiSession !== 'undefined' && !wixBiSession.${beatSentFlag}) {
                wixBiSession.${beatSentFlag} = true;
                
                if (wixBiSession.sendBeat) {
                    wixBiSession.sendBeat(${et}, '${mark}');
                } else {
                    var tts = '';
                    if (performance) {
                        if (performance.now) {
                            tts = '&tts=' + Math.round(performance.now());
                        }
                        if (performance.mark) {
                            performance.mark('${mark} (beat ${et})');
                        }
                    }
                    (new Image()).src = '${biUrl}bt?src=${src}&evid=${evid}&pn=1&et=${et}&v=${v}&msid=${msid}&vsi=' + wixBiSession.viewerSessionId +
                        '&is_rollout=' + (wixBiSession.is_rollout ? 1 : 0) +
                        '&url=' + encodeURIComponent(location.href.replace(/^http(s)?:\\/\\/(www\\.)?/, '')) +
                        '&isp=${isp}&st=${st}&ts=' + (Date.now() - new Date(wixBiSession.initialTimestamp)) + tts + '&iss=1&c=' + Date.now();                
                }
            }
        `;
    };

    return {
        createPartiallyVisibleBeatScriptString: siteData => createBeatScriptString(siteData, PARTIALLY_VISIBLE_BEAT),
        createMeshEligibilityBeatScriptString: siteData => createBeatScriptString(siteData, MESH_ELIGIBILITY_BEAT)
    };
});
