define(['lodash', 'componentsCore'],
    function (_, componentsCore) {
        'use strict';

        /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function AddComponentAspect(aspectSiteAPI) {
            this._aspectSiteAPI = aspectSiteAPI;
            _.bindAll(this, _.functionsIn(this));
        }

        AddComponentAspect.prototype = {

            getComponentsToRender(siteAspectsSiteAPI) {
                const aspectData = siteAspectsSiteAPI.getAspectGlobalData('addComponent');
                return _.values(aspectData);
            },
            addComponent(key, structure, props) {
                const newCompDescription = {};
                newCompDescription[key] = {
                    structure,
                    props
                };

                this._aspectSiteAPI.updateAspectGlobalData('addComponent', newCompDescription);
                this._aspectSiteAPI.forceUpdate();
            },

            deleteComponent(key) {
                const aspectData = this._aspectSiteAPI.getAspectGlobalData('addComponent');
                delete aspectData[key];
                this._aspectSiteAPI.setAspectGlobalData('addComponent', aspectData);

                this._aspectSiteAPI.forceUpdate();
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('addComponent', AddComponentAspect);

        return AddComponentAspect;
    });
