define([
    'lodash',
    'componentsCore'
], function (
    _,
    componentsCore
) {
    'use strict';

    /**
     *
     * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
     * @implements {core.SiteAspectInterface}
     * @constructor
     */
    class WelcomeScreenAspect {
        constructor(aspectSiteAPI) {
            aspectSiteAPI.registerToSiteReady(() => {
                _.invoke(window, 'requestCloseWelcomeScreen');
            });
        }
    }

    componentsCore.siteAspectsRegistry.registerSiteAspect('welcomeScreenAspect', WelcomeScreenAspect);
});
