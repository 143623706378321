/**
 * Created with IntelliJ IDEA.
 * User: avim
 * Date: 6/9/14
 * Time: 3:48 PM
 * To change this template use File | Settings | File Templates.
 */
define([
    'santa-components',
    'lodash',
    'mobx-react',
    'prop-types'
], function (
    santaComponents,
    _,
    mobxReact,
    PropTypes
) {
    'use strict';
    const getExternalFontsStylesCss = externalFontsStyles => _.map(externalFontsStyles,
        (content, id) => santaComponents.utils.styleNodeUtils.generateStyleNode(id, content));

    const getExternalFontsLinksCss = externalFontsUrls => _.map(externalFontsUrls,
        (cssUrl, key) => santaComponents.utils.createReactElement('link', {rel: 'stylesheet', type: 'text/css', href: cssUrl, key}));


    const externalStylesRenderer = props => _.compact(_.concat(
        getExternalFontsStylesCss(props.externalFontsStyles),
        getExternalFontsLinksCss(props.externalFontsUrls)
    ));

    externalStylesRenderer.propTypes = {
        externalFontsStyles: PropTypes.object,
        externalFontsUrls: PropTypes.object
    };

    return mobxReact.observer(externalStylesRenderer);
});
