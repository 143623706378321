define([
    'lodash',
    'utils',
    'core/components/actionsAspectActions/baseAction'
], function (
    _,
    utils,
    baseAction
) {
    'use strict';

    const triggerTypes = utils.triggerTypesConsts;

    function ScrollScrubAction() {
        baseAction.apply(this, arguments);
        this._windowScrollAspect = this._aspectSiteAPI.getSiteAspect('windowScrollEvent');
        this._currentScroll = this.getCurrentScroll();
        this._previousScroll = undefined;
        this._accumulatedScroll = 0;
    }

    ScrollScrubAction.prototype = _.create(baseAction.prototype, {
        constructor: ScrollScrubAction,
        ACTION_TRIGGERS: [
            triggerTypes.SCROLL,
            triggerTypes.PAGE_CHANGED,
            triggerTypes.RESIZE,
            triggerTypes.ACTIONS_ADDED_LAYOUTED,
            triggerTypes.ACTIONS_REMOVED,
            triggerTypes.DID_LAYOUT
        ],
        ACTION_NAME: 'scrollScrub',
        ACTIONS_SUPPORTED: ['scrollScrub'],

        runAction(event) {
            this._previousScroll = this._currentScroll;
            this._currentScroll = this.getCurrentScroll();
            this._accumulatedScroll = this.getAccumulatedScroll();

            event = _.assign(event, {
                scroll: {
                    previous: this._previousScroll,
                    current: this._currentScroll,
                    accumulatedScroll: this._accumulatedScroll
                }
            });

            this.triggerActions(_.keys(this._currentActions), event);
        },

        getAccumulatedScroll() {
            const scrollDirectionChanged = this._currentScroll.direction !== this._previousScroll.direction;
            const scrollYDelta = Math.abs(this._currentScroll.position.y - this._previousScroll.position.y);
            return scrollDirectionChanged ? 0 : this._accumulatedScroll + scrollYDelta;
        },

        getCurrentScroll() {
            if (!this._windowScrollAspect) {
                return {};
            }
            const position = this._windowScrollAspect.getScrollPosition();
            const direction = this._windowScrollAspect.getScrollDirection(position);

            return {
                direction,
                position: _.defaults(position, {x: 0, y: 0})
            };
        },

        handleActionTrigger(triggerType) {
            switch (triggerType) {
                case triggerTypes.ACTIONS_REMOVED:
                case triggerTypes.ACTIONS_ADDED_LAYOUTED:
                    break;

                case triggerTypes.RESIZE:
                case triggerTypes.DID_LAYOUT:
                case triggerTypes.PAGE_CHANGED:
                case triggerTypes.SCROLL:
                    this.executeOnNextTick(this.runAction);
                    break;
            }
        },

        shouldEnable() {
            const isBrowser = typeof window !== 'undefined';
            return isBrowser;
        },

        resetActionState: _.noop
    });

    /**
     * @exports ScrollScrubAction
     */
    return ScrollScrubAction;
});
