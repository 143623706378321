define(['coreUtils', 'lodash'], function (coreUtils, _) {
    'use strict';

    const constants = coreUtils.constants;
    const SYSTEM_MODES = {};
    SYSTEM_MODES[coreUtils.siteConstants.COMP_MODES_TYPES.SHOW_ON_SOME_PAGES] = true;
    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state

    function getPointerByCompId(sitePrivates, compId, rootId) {
        const mode = getSiteViewMode(sitePrivates);
        const pagePointer = sitePrivates.pointers.components.getPage(rootId, mode);
        return sitePrivates.pointers.components.getComponent(compId, pagePointer);
    }

    function getSiteViewMode(sitePrivates) {
        return sitePrivates.siteData.isMobileView() ? constants.VIEW_MODES.MOBILE : constants.VIEW_MODES.DESKTOP;
    }

    function ModesApplicationAPI(siteData, createDisplayedPage, createDisplayedNode, pointers, displayedDal, actionQueue) {
        this.privatesKey = siteData;

        privates.set(siteData, {
            siteData,
            createDisplayedPage,
            createDisplayedNode,
            pointers,
            displayedDal,
            actionQueue
        });

        _.bindAll(this, _.functionsIn(this));
    }

    function updateCompModesAndEnsureActiveModesPerModeType(sitePrivates, rootId, compPointer, modeIdToDeactivate, modeIdToActivate) {
        const pageActiveModes = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
        if (!sitePrivates.displayedDal.isExist(pageActiveModes)) {
            sitePrivates.displayedDal.set(pageActiveModes, {});
        }

        const pageActiveModesPointer = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
        const definitionsPointer = sitePrivates.pointers.componentStructure.getModesDefinitions(compPointer);
        const modesDefinitions = sitePrivates.displayedDal.get(definitionsPointer);
        const modeToActivate = modeIdToActivate && _.find(modesDefinitions, {modeId: modeIdToActivate});
        const modeToDeactivate = modeIdToDeactivate && _.find(modesDefinitions, {modeId: modeIdToDeactivate});
        const activeModes = sitePrivates.displayedDal.get(pageActiveModesPointer);

        const newActiveModes = coreUtils.modesUtils.getActiveModesForActivationChange(modesDefinitions, activeModes, modeToActivate, modeToDeactivate);

        sitePrivates.displayedDal.set(pageActiveModesPointer, newActiveModes);
    }

    function deactivateModesInPage(sitePrivates, rootId, predicate) {
        const pageActiveModesPointer = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
        const currentRootActiveModes = sitePrivates.displayedDal.get(pageActiveModesPointer);
        if (!_.isEmpty(currentRootActiveModes)) {
            const newActiveModes = predicate ? _.omitBy(currentRootActiveModes, predicate) : {};

            sitePrivates.displayedDal.set(pageActiveModesPointer, newActiveModes);
            return sitePrivates.createDisplayedPage(rootId);
        }
        return false;
    }

    function isSystemMode(activeMode) {
        return Boolean(SYSTEM_MODES[activeMode.modeType]);
    }

    function isResetableMode(modeData) {
        return !isSystemMode(modeData);
    }


    ModesApplicationAPI.prototype = {
        resetAllActiveModes() {
            const sitePrivates = privates.get(this.privatesKey);
            const allActiveModesPointer = sitePrivates.pointers.activeModes.getAllActiveModes();
            const rootIdsToRemove = sitePrivates.displayedDal.getKeys(allActiveModesPointer);
            _.forEach(rootIdsToRemove, function (rootId) {
                deactivateModesInPage(sitePrivates, rootId, isResetableMode);
            });
        },

        deactivateModesInPage(rootId) {
            const sitePrivates = privates.get(this.privatesKey);
            return deactivateModesInPage(sitePrivates, rootId);
        },

        getActiveModes() {
            const sitePrivates = privates.get(this.privatesKey);
            const allActiveModesPointer = sitePrivates.pointers.activeModes.getAllActiveModes();
            const rootIds = sitePrivates.displayedDal.getKeys(allActiveModesPointer);
            return _.reduce(rootIds, function (activeModes, rootId) {
                const pageModes = this.getPageActiveModes(rootId);

                if (!_.isEmpty(pageModes)) {
                    activeModes[rootId] = pageModes;
                }
                return activeModes;
            }.bind(this), {});
        },

        getPageActiveModes(rootId) {
            const sitePrivates = privates.get(this.privatesKey);
            const pageActiveModesPointer = sitePrivates.pointers.activeModes.getPageActiveModes(rootId);
            const currentRootModes = sitePrivates.displayedDal.get(pageActiveModesPointer);
            return _.pickBy(currentRootModes);
        },

        activateModeById(compId, rootId, modeId) {
            const sitePrivates = privates.get(this.privatesKey);
            const pointer = getPointerByCompId(sitePrivates, compId, rootId);
            return this.activateMode(pointer, modeId);
        },

        activateMode(compPointer, modeId) {
            const sitePrivates = privates.get(this.privatesKey);
            const rootPointer = sitePrivates.pointers.full.components.getPageOfComponent(compPointer);
            if (rootPointer && rootPointer.id) {
                const currentRootModes = this.getActiveModes()[rootPointer.id];
                if (currentRootModes && currentRootModes[modeId]) {
                    return false;
                }
                sitePrivates.actionQueue.runImmediately(function () {
                    updateCompModesAndEnsureActiveModesPerModeType(sitePrivates, rootPointer.id, compPointer, null, modeId);
                    sitePrivates.createDisplayedNode(compPointer);
                });
                return true;
            }
            return false;
        },

        deactivateModeById(compId, rootId, modeId) {
            const sitePrivates = privates.get(this.privatesKey);
            const pointer = getPointerByCompId(sitePrivates, compId, rootId);
            return this.deactivateMode(pointer, modeId);
        },

        deactivateMode(compPointer, modeId) {
            const sitePrivates = privates.get(this.privatesKey);
            const rootPointer = sitePrivates.pointers.full.components.getPageOfComponent(compPointer);
            if (rootPointer && rootPointer.id) {
                const currentRootModes = this.getActiveModes()[rootPointer.id];
                if (!currentRootModes || !currentRootModes[modeId]) {
                    return false;
                }
                sitePrivates.actionQueue.runImmediately(function () {
                    updateCompModesAndEnsureActiveModesPerModeType(sitePrivates, rootPointer.id, compPointer, modeId);
                    sitePrivates.createDisplayedNode(compPointer);
                });
                return true;
            }
            return false;
        },

        switchModesByIds(compId, rootId, modeIdToDeactivate, modeIdToActivate) {
            const sitePrivates = privates.get(this.privatesKey);
            const compPointer = getPointerByCompId(sitePrivates, compId, rootId);
            return this.switchModes(compPointer, modeIdToDeactivate, modeIdToActivate);
        },

        switchModes(compPointer, modeIdToDeactivate, modeIdToActivate) {
            const sitePrivates = privates.get(this.privatesKey);
            if (modeIdToDeactivate === modeIdToActivate) {
                return false;
            }
            const rootPointer = sitePrivates.pointers.full.components.getPageOfComponent(compPointer);
            const currentRootModes = this.getActiveModes()[rootPointer.id] || {};
            const modesChanged = !currentRootModes[modeIdToActivate] || currentRootModes[modeIdToDeactivate];

            if (modesChanged) {
                sitePrivates.actionQueue.runImmediately(function () {
                    updateCompModesAndEnsureActiveModesPerModeType(sitePrivates, rootPointer.id, compPointer, modeIdToDeactivate, modeIdToActivate);
                    sitePrivates.createDisplayedNode(compPointer);
                });
            }
            return modesChanged;
        }
    };

    return ModesApplicationAPI;
});
