define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const privates = new coreUtils.SiteDataPrivates(); //eslint-disable-line santa/no-module-state



    /**
     * OriginalValuesAPI
     * @param displayedJson
     * @constructor
     */
    function OriginalValuesAPI(displayedJson) {
        this.privatesKey = displayedJson;
        privates.set(displayedJson, {siteData: displayedJson});
    }

    OriginalValuesAPI.prototype = {
        createPageOriginalValuesMap(rootId, forceMobileStructure) {
            const sitePrivates = privates.get(this.privatesKey);
            const siteData = sitePrivates.siteData;
            const pageStructure = siteData.getPageData(rootId, ['structure']);

            const masterPageTheme = siteData.getAllStylesFromPage('masterPage');
            const theme = rootId === coreUtils.siteConstants.MASTER_PAGE_ID ? masterPageTheme :
                Object.assign({}, masterPageTheme, siteData.getAllStylesFromPage(rootId));
            const viewMode = forceMobileStructure ? coreUtils.constants.VIEW_MODES.MOBILE : siteData.getViewMode();
            const isMobileView = viewMode === coreUtils.constants.VIEW_MODES.MOBILE;

            const originalValuesMap = coreUtils.originalValuesMapGenerator.createOriginalValuesMap(pageStructure, theme, isMobileView);
            _.set(siteData, ['originalValuesMap', pageStructure.id, viewMode], originalValuesMap);
        },

        removePageOriginalValues(rootId, forceMobileStructure) {
            const sitePrivates = privates.get(this.privatesKey);
            const siteData = sitePrivates.siteData;
            const pageStructure = siteData.getPageData(rootId, ['structure']);
            const viewMode = forceMobileStructure ? coreUtils.constants.VIEW_MODES.MOBILE : siteData.getViewMode();

            _.set(siteData, ['originalValuesMap', pageStructure.id, viewMode], {});
        }
    };

    return OriginalValuesAPI;
});
