define(['santa-components', 'prop-types', 'create-react-class', 'mobx-react'], function (santaComponents, PropTypes, createReactClass, mobxReact) {
    'use strict';

    return mobxReact.observer(createReactClass({
        displayName: 'extraSiteHeight',
        propTypes: {
            siteAPI: PropTypes.object.isRequired
        },
        render() {
            if (this.props.siteAPI.getRenderFlag('extraSiteHeight') === 0) {
                return null;
            }
            return santaComponents.utils.createReactElement('div', {
                style: {
                    height: this.props.siteAPI.getRenderFlag('extraSiteHeight'),
                    position: 'relative'
                }
            });
        }
    }));
});
