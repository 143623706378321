define([], function () {
    'use strict';
    function playsInline() {
        return /(iPhone|iPod)/g.test(navigator.userAgent) ? 'playsInline' in window.document.createElement('video') : true;
    }

    return () => ({
        playsInline: playsInline()
    });
});
