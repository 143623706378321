define(['lodash', 'componentsCore'],
    function (_, componentsCore) {
        'use strict';

        const supportedKeys = {
            39: 'ArrowRight',
            37: 'ArrowLeft',
            27: 'Escape',
            13: 'Enter',
            32: 'Space'
        };

        /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function WindowKeyboardEventAspect(aspectSiteAPI) {
            /** @type {core.SiteAspectsSiteAPI} */
            this._aspectSiteAPI = aspectSiteAPI;
            this._aspectSiteAPI.registerToKeyDown(this.propagateKeyDownSpecificKeyEvent.bind(this));
            this._aspectSiteAPI.registerToKeyUp(this.propagateKeyboardEvent.bind(this, 'KeyUp'));
            this._registeredCompIds = {
                specificKeysKeyDown: {
                    Escape: [],
                    ArrowRight: [],
                    ArrowLeft: []
                },
                KeyDown: [],
                KeyUp: []
            };
        }

        /**
         * do not propagate any key for security reasons, since we don't want passwords to leak out
         * @param event
         */
        function isKeySupported(event) {
            return Boolean(supportedKeys[event.keyCode || event.which]);
        }

        WindowKeyboardEventAspect.prototype = {

            registerToKeyDownWithFocus(comp, element) {
                element.addEventListener('focus', function () {
                    this.registerToKeyDown(comp);
                }.bind(this));

                element.addEventListener('blur', function () {
                    this.unRegisterKeyDown(comp);
                }.bind(this));
            },

            registerToEscapeKey(comp) {
                this._registeredCompIds.specificKeysKeyDown.Escape.push(comp.props.id);
            },

            registerToArrowRightKey(comp) {
                this._registeredCompIds.specificKeysKeyDown.ArrowRight.push(comp.props.id);
            },

            registerToArrowLeftKey(comp) {
                this._registeredCompIds.specificKeysKeyDown.ArrowLeft.push(comp.props.id);
            },

            unRegisterKeys(comp) {
                _.forEach(this._registeredCompIds.specificKeysKeyDown, function (value, key) {
                    this._registeredCompIds.specificKeysKeyDown[key] = _.without(this._registeredCompIds.specificKeysKeyDown[key], comp.props.id);
                }.bind(this));
            },

            registerToKeyDown(comp) {
                this._registeredCompIds.KeyDown.push(comp.props.id);
            },

            unRegisterKeyDown(comp) {
                this._registeredCompIds.KeyDown = _.without(this._registeredCompIds.KeyDown, comp.props.id);
            },

            registerToKeyUp(comp) {
                this._registeredCompIds.KeyUp.push(comp.props.id);
            },

            unRegisterKeyUp(comp) {
                this._registeredCompIds.KeyUp = _.without(this._registeredCompIds.KeyUp, comp.props.id);
            },

            unRegisterAll(comp) {
                this.unRegisterKeys(comp);
                this.unRegisterKeyDown(comp);
                this.unRegisterKeyUp(comp);
            },

            propagateKeyDownSpecificKeyEvent(event) {
                this.propagateKeyboardEvent('KeyDown', event);
                const key = supportedKeys[event.keyCode || event.which];
                if (_.isEmpty(this._registeredCompIds.specificKeysKeyDown[key])) {
                    return;
                }

                const compId = _.last(this._registeredCompIds.specificKeysKeyDown[key]);
                const listener = this._aspectSiteAPI.getComponentById(compId);
                const listenerFn = `on${key}Key`;
                if (!listener) {
                    this._registeredCompIds.specificKeysKeyDown[key] = _.without(this._registeredCompIds.specificKeysKeyDown[key], compId);
                } else if (listener[listenerFn]) {
                    event.preventDefault();
                    listener[listenerFn]();
                }
            },

            propagateKeyboardEvent(eventType, event) {
                if (_.isEmpty(this._registeredCompIds[eventType]) || !isKeySupported(event)) {
                    return;
                }

                _.forEach(this._registeredCompIds[eventType], function (compId) {
                    const listener = this._aspectSiteAPI.getComponentById(compId);
                    const listenerFn = `on${eventType}`;
                    if (!listener) {
                        this._registeredCompIds[eventType] = _.without(this._registeredCompIds[eventType], compId);
                    } else if (listener[listenerFn]) {
                        event.preventDefault();
                        listener[listenerFn](event);
                    }
                }.bind(this));
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('windowKeyboardEvent', WindowKeyboardEventAspect);
    });
