define([], function () {
    'use strict';

    return function (pointers, dalGetters, rootId) {
        return function getStructureInfo(compStructure) {
            const structureInfo = {};
            if (compStructure.dataQuery) {
                const dataPointer = pointers.data.getDataItem(compStructure.dataQuery.replace('#', ''), rootId);
                structureInfo.dataItem = dalGetters.get(dataPointer);
            }

            if (compStructure.propertyQuery) {
                const propsPointer = pointers.data.getPropertyItem(compStructure.propertyQuery.replace('#', ''), rootId);
                structureInfo.propertiesItem = dalGetters.get(propsPointer);
            }

            return structureInfo;
        };
    };
});
