define(['lodash', 'zepto', 'coreUtils', 'utils', 'reactDOM'], function (_, $, coreUtils, utils, ReactDOM) {
    'use strict';

    const triggerTypes = utils.triggerTypesConsts;

    const Animations = {
        ENTER: coreUtils.siteConstants.Animations.Modes.AnimationType.ENTER,
        LEAVE: coreUtils.siteConstants.Animations.Modes.AnimationType.LEAVE,
        TRANSITION: coreUtils.siteConstants.Animations.Modes.AnimationType.TRANSITION
    };

    const AnimationReverse = {};
    AnimationReverse[Animations.ENTER] = Animations.LEAVE;
    AnimationReverse[Animations.LEAVE] = Animations.ENTER;

    Object.freeze(AnimationReverse);

    const AnimationTypeToAction = {};
    AnimationTypeToAction[Animations.ENTER] = 'modeIn';
    AnimationTypeToAction[Animations.LEAVE] = 'modeOut';
    AnimationTypeToAction[Animations.TRANSITION] = 'modeChange';
    Object.freeze(AnimationTypeToAction);

    const ComponentToTransitionType = {
        'wysiwyg.viewer.components.SiteButton': coreUtils.siteConstants.Animations.TransitionType.NO_SCALE,
        'wysiwyg.viewer.components.WRichText': coreUtils.siteConstants.Animations.TransitionType.NO_DIMESIONS
    };

    function shouldBehaviorBePlayed(compIdToAnimationType, modeChanges, behavior) {
        const behaviorModeIds = _.get(behavior, 'params.modeIds');
        switch (behavior.action) {
            case 'modeIn':
                return compIdToAnimationType[behavior.targetId] === 'enter' && _.every(behaviorModeIds, function (modeId) {
                    return modeChanges[modeId] === true;
                });
            case 'modeOut':
                return compIdToAnimationType[behavior.targetId] === 'leave' && _.every(behaviorModeIds, function (modeId) {
                    return modeChanges[modeId] === false;
                });
            case 'modeChange':
                return compIdToAnimationType[behavior.targetId] === 'transition' && _.every(behaviorModeIds, function (modeId) {
                    return !_.isUndefined(modeChanges[modeId]);
                });
            default:
                return false;
        }
    }

    function filterAndFormatBehaviorsToPlay(behaviors, compIdToAnimationType, modeChanges) {
        return _(behaviors)
            .filter(_.partial(shouldBehaviorBePlayed, compIdToAnimationType, modeChanges))
            .map(function (behavior) {
                return _.assign({animationType: compIdToAnimationType[behavior.targetId]}, behavior);
            })
            .keyBy('targetId')
            .value();
    }

    /**
     * Constructor for ScreenIn action, starts disabled.
     * @param aspectSiteAPI
     * @constructor
     */
    function ModeChangeAction(aspectSiteAPI) {
        this._aspectSiteAPI = aspectSiteAPI;
        this._siteData = aspectSiteAPI.getSiteData();
        this.animations = this._siteData.animations;
        this._behaviors = [];
        this._isEnabled = false;
        this._animateOnNextTick = false;
        this._behaviorsById = {};
        this.playingSequences = {};
        this.componentAnimationsInfo = {};
    }

    ModeChangeAction.prototype = _.create(Object.prototype, {
        constructor: ModeChangeAction,
        ACTION_TRIGGERS: [triggerTypes.MODE_CHANGED_INIT, triggerTypes.MODE_CHANGED_EXECUTE],
        ACTION_NAME: 'modeChange',
        ACTIONS_SUPPORTED: ['modeChange', 'modeIn', 'modeOut'],

        /**
         * If this returns false, we shouldn't enable this action.
         * @returns {boolean}
         */
        shouldEnable() {
            const isBrowser = typeof window !== 'undefined';
            const isTablet = this._siteData.isTabletDevice();
            const isMobile = this._siteData.isMobileDevice();

            return isBrowser && !isTablet && !isMobile;
        },

        /**
         * Enable ScreenIn Action
         * - Register current page behaviors
         * - hide all elements with 'hideOnStart' on their animation
         * - Start querying if animation should run on each tick
         */
        enableAction() {
            if (this._isEnabled) {
                return;
            }

            this._tickerCallback = this._executeActionOnTick.bind(this);
            this.animations.addTickerEvent(this._tickerCallback);

            this._isEnabled = true;
        },
        //
        /**
         * Disable modeChange Action
         * - Stop the ticker listener
         * - Stop and clear all running sequences
         * - un-hide all hidden elements  with 'hideOnStart' on their animation
         * - reset playedOnce list and lastVisitedPage state
         */
        disableAction() {
            if (!this._isEnabled) {
                return;
            }

            this._animateOnNextTick = false;

            this.animations.removeTickerEvent(this._tickerCallback);
            this._tickerCallback = null;

            this.stopAndClearAnimations();
            this.revertHideElementsByAnimationType(this._behaviorsById);

            this._behaviorsById = {};
            this._isEnabled = false;
        },

        isEnabled() {
            return this._isEnabled;
        },

        needToRunPreConditions(/* action */) {
            return false;
        },

        /**
         * Execute the action only if it is scheduled to run on next tick
         * @private
         */
        _executeActionOnTick() {
            if (!this._animateOnNextTick) {
                return;
            }

            this._executeAction();
            this._animateOnNextTick = false;
        },

        /**
         * Stop and clear all sequences previously created by this action
         */
        stopAndClearAnimations() {
            this._behaviorsById = {};
            _.forEach(this.playingSequences, function (entry, compId) {
                const parent = this.getComponentPage(entry.parentId);
                parent.stopSequence(entry.sequence.getId(), 1);
                this.handleSequenceEnded(compId, entry.parentId, entry.type);
            }.bind(this));

            _.forEach(this.componentAnimationsInfo, function (info, compId) {
                info.hasPendingAnimation = false; // eslint-disable-line santa/no-side-effects
                this.handleSequenceEnded(compId, info.pageId, info.type);
            }.bind(this));
        },

        /**
         * Execute the action
         */
        _executeAction() {
            if (_.isEmpty(this._behaviorsById)) {
                return;
            }
            _.assign(this.playingSequences, this.playJsAnimations());
        },

        /**
         * external API to allow triggering of a mode change event
         */
        executeAction() {
            this.handleTrigger.apply(this, arguments);
        },

        clearPlayedBehaviors(playedTargets) {
            this._behaviorsById = _.omitBy(this._behaviorsById, function (behavior, sourceId) {
                return playedTargets[sourceId];
            });
        },

        convertBehaviorTimingFunctionToCSS(timingFunction) {
            return coreUtils.siteConstants.Animations.TimingFunctions[timingFunction];
        },

        clearCssTransitionFromNode($compNode) {
            $compNode.css({
                'transition-property': '',
                'transition-duration': '',
                'transition-delay': '',
                'transition-timing-function': '',
                '-webkit-transition-timing-function': '',
                '-moz-transition-timing-function': '',
                '-o-transition-timing-function': ''
            });
        },

        addCssTransitionToNode(behavior, $compNode) {
            const timingFunctionCss = this.convertBehaviorTimingFunctionToCSS(behavior.params.timingFunction);
            $compNode.css({
                'transition-property': 'background-color, color !important',
                'transition-duration': `${behavior.duration}s !important`,
                'transition-delay': `${behavior.delay}s !important`,
                'transition-timing-function': `${timingFunctionCss} !important`,
                '-webkit-transition-timing-function': `${timingFunctionCss} !important`,
                '-moz-transition-timing-function': `${timingFunctionCss} !important`,
                '-o-transition-timing-function': `${timingFunctionCss} !important`
            });
        },

        initCssTransitions() {
            const transitionBehaviors = _.filter(this._behaviorsById, {animationType: 'transition'});

            if (!transitionBehaviors.length) {
                return;
            }

            _.forEach(transitionBehaviors, function (behavior) {
                const compId = behavior.targetId;
                const $compNode = $(this.getComponentNode(behavior.pageId, compId));

                if (!this.componentAnimationsInfo[compId].didCssTransitionStartExecuting) {
                    this.componentAnimationsInfo[compId].didCssTransitionStartExecuting = true;
                    this.addCssTransitionToNode(behavior, $compNode);
                }
            }.bind(this));
        },

        handleSequenceEnded(compId, pageId, animationType) {
            if (animationType === Animations.TRANSITION) {
                this.clearCssTransitionFromNode($(this.getComponentNode(pageId, compId)));
            }
            delete this.playingSequences[compId];
            this.notifyAnimationEnded(compId);
        },

        playJsAnimations() {
            const playedTargets = {};
            const sequences = {};

            _.forEach(this._behaviorsById, function (behavior) {
                const parent = this.getComponentPage(behavior.pageId);
                const targetId = behavior.targetId;
                const playingSequence = this.playingSequences[targetId];
                const isSeqReverseOfCurrentlyPlaying = playingSequence && AnimationReverse[playingSequence.type] === behavior.animationType;
                const isSameAnimationType = playingSequence && playingSequence.type === behavior.animationType;
                playedTargets[targetId] = true;

                if (isSameAnimationType) {
                    return;
                }

                if (isSeqReverseOfCurrentlyPlaying) {
                    this.reverseAnimation(behavior);
                    return;
                }

                if (playingSequence) {
                    parent.stopSequence(playingSequence.sequence.id);
                }

                sequences[targetId] = {
                    sequence: parent.sequence(),
                    parentId: behavior.pageId,
                    type: behavior.animationType
                };
                this.addAnimationToSequence(behavior, this.componentAnimationsInfo[behavior.targetId], sequences[behavior.targetId].sequence);
            }.bind(this));

            _.forEach(sequences, this.executeSequenceIfNeeded.bind(this));

            this.clearPlayedBehaviors(playedTargets);

            return sequences;
        },

        reverseAnimation(behavior) {
            const targetId = behavior.targetId;
            const parent = this.getComponentPage(behavior.pageId);
            const playingSequence = this.playingSequences[targetId];
            playingSequence.type = behavior.animationType;
            this.componentAnimationsInfo[targetId].hasPendingAnimation = false;
            parent.reverseSequence(playingSequence.sequence.getId());
        },

        executeSequenceIfNeeded(sequenceObj, compId) {
            const sequence = sequenceObj.sequence;
            if (sequence.hasAnimations()) {
                const progress = this.componentAnimationsInfo[compId].progress;
                const handleSequenceEndedBound = this.handleSequenceEnded.bind(this, compId, sequenceObj.parentId, sequenceObj.type);

                this.componentAnimationsInfo[compId].hasPendingAnimation = false;
                sequence.onCompleteAll(handleSequenceEndedBound);
                sequence.onReverseAll(handleSequenceEndedBound);
                if (progress) {
                    sequence.onInit(function (liveSequence) {
                        liveSequence.progress(1 - progress);
                    });
                }

                sequence.execute();
            }
        },

        /**
         *
         * @param {SiteAspectsSiteAPI} siteAPI
         * @param {String} parentId
         * @returns {ReactCompositeComponent}
         */
        getComponentPage(parentId) {
            return this._aspectSiteAPI.getPageById(parentId);
        },

        getPageComponents(parentId) {
            return this._aspectSiteAPI.getPageComponents(parentId);
        },

        getComponentNode(pageId, compId) {
            const compRef = this.getPageComponents(pageId)[compId];

            if (compRef) {
                return ReactDOM.findDOMNode(compRef);
            }

            return null;
        },

        /**
         * Add an animation to the passed sequence if the element to animate position is inside the calculated bounds of the screen
         * @param {ParsedBehavior} behavior
         * @param {object} compAnimationInfo
         * @param {Sequence} sequence
         * @returns {String}
         */
        addAnimationToSequence(behavior, compAnimationInfo, sequence) {
            const parentComponents = this.getPageComponents(behavior.pageId);
            const compId = behavior.targetId;
            const propsToClear = behavior.action === 'modeOut' ? 'clip,clipPath,webkitClipPath,transform,transformOrigin' : 'clip,clipPath,webkitClipPath,opacity,transform,transformOrigin';
            if (!parentComponents[compId]) {
                return null;
            }

            const structure = parentComponents[compId].props.structure;

            const duration = behavior.duration;
            const delay = behavior.delay;

            if (behavior.animationType === Animations.TRANSITION) {
                const fromLayout = compAnimationInfo.prevLayout;
                const animationName = behavior.name + (ComponentToTransitionType[structure.componentType] || coreUtils.siteConstants.Animations.TransitionType.SCALE);
                sequence.add(compId, animationName, duration, delay, {from: fromLayout});
            } else {
                sequence.add(compId, behavior.name, duration, delay, behavior.params, 0);
            }
            sequence.add(compId, 'BaseClear', 0, 0, {props: propsToClear, immediateRender: false});

            return compId;
        },

        /**
         * Hide all elements with animations that has the hideOnStart flag enabled
         * @param {SiteAspectsSiteAPI} siteAPI
         * @param {object} pageBehaviorsByTargets
         */
        hideElementsByAnimationType(pageBehaviorsByTargets) {
            _.forEach(pageBehaviorsByTargets, function (behavior, targetId) {
                if (this.animations.getProperties(behavior.name).hideOnStart) {
                    const element = this.getComponentNode(behavior.pageId, targetId);
                    const playingSequence = this.playingSequences[targetId];
                    const isSeqReverseOfCurrentlyPlaying = playingSequence && AnimationReverse[playingSequence.type] === behavior.animationType;
                    if (element && !isSeqReverseOfCurrentlyPlaying) {
                        element.style.opacity = 0;
                    }
                }
            }.bind(this));
        },

        /**
         * Un-hide all elements with animations that has the hideOnStart flag enabled
         * @param {SiteAspectsSiteAPI} siteAPI
         * @param {object} allPagesBehaviorsByTargets
         */
        revertHideElementsByAnimationType(allPagesBehaviorsByTargets) {
            _.forEach(allPagesBehaviorsByTargets, function (pageBehaviors) {
                _.forEach(pageBehaviors, function (behavior, targetId) {
                    if (this.animations.getProperties(behavior.name).hideOnStart) {
                        const element = this.getComponentNode(behavior.pageId, targetId);
                        if (element) {
                            element.style.opacity = '';
                        }
                    }
                }.bind(this));
            }.bind(this));
        },

        initiateBehaviors(pageId, behaviorsToPlayByTargetId, compIdToAnimationsType, transitioningComponentsPrevLayout, onComplete) {
            this._behaviorsById = _.assign(this._behaviorsById, behaviorsToPlayByTargetId);
            _.forEach(compIdToAnimationsType, function (animationType, compId) {
                const compAnimationInfo = this.componentAnimationsInfo[compId] = this.componentAnimationsInfo[compId] || {};
                compAnimationInfo.pageId = pageId;
                compAnimationInfo.type = animationType;
                compAnimationInfo.hasPendingAnimation = true;
                compAnimationInfo.onComplete = onComplete;
                if (animationType === Animations.TRANSITION) {
                    compAnimationInfo.prevLayout = transitioningComponentsPrevLayout[compId];
                }
            }.bind(this));
        },

        handleBehaviorsUpdate(behaviors) {
            this._behaviors = behaviors;
        },

        runJsAnimationsOnNextTick() {
            this._animateOnNextTick = true;
        },

        runJsAnimationsImmediately() {
            this._executeAction();
        },

        notifyAnimationEnded(compId) {
            if (!this.componentAnimationsInfo[compId] || this.componentAnimationsInfo[compId].hasPendingAnimation) {
                return;
            }

            const compToAnimationType = {};
            compToAnimationType[compId] = this.componentAnimationsInfo[compId].type;
            const callback = this.componentAnimationsInfo[compId].onComplete;
            delete this.componentAnimationsInfo[compId];
            callback(compToAnimationType);
        },

        handleDidLayout() {
            this._aspectSiteAPI.unRegisterFromDidLayout();
            const browser = this._siteData.getBrowser();
            if (browser && (browser.ie || browser.edge || browser.safari)) { // Ugly hack to avoid extra-animation-frame flicker
                this.runJsAnimationsImmediately();
            } else {
                this.runJsAnimationsOnNextTick();
            }
        },

        handleReverseTransitionAnimations(componentAnimations) {
            const willTransitionCompIds = _.transform(componentAnimations, function (result, animationType, compId) {
                if (animationType === Animations.TRANSITION) {
                    result.push(compId);
                }
            }, []);
            const duringTransitionCompIds = _.transform(this.playingSequences, function (result, sequenceInfo, compId) {
                if (sequenceInfo.type === Animations.TRANSITION) {
                    result.push(compId);
                }
            }, []);
            const compIdsToReverse = _.intersection(willTransitionCompIds, duringTransitionCompIds);

            _.forEach(compIdsToReverse, function (compId) {
                const compAnimationInfo = this.componentAnimationsInfo[compId];
                const parent = this.getComponentPage(compAnimationInfo.pageId);
                const sequenceId = this.playingSequences[compId].sequence.getId();
                const sequence = parent.getSequence(sequenceId);
                const compNode = this.getComponentNode(compAnimationInfo.pageId, compId);

                compAnimationInfo.progress = sequence.progress();
                sequence.set(compNode, {clearProps: 'clip,opacity'});
                parent.stopSequence(sequenceId, 1);
                delete this.playingSequences[compId];
            }.bind(this));
        },

        handleTrigger(triggerType, triggerArgs) {
            if (!this._isEnabled) {
                if (triggerArgs && triggerArgs.onComplete) {
                    triggerArgs.onComplete(triggerArgs.componentAnimations);
                }

                return;
            }

            switch (triggerType) {
                case triggerTypes.MODE_CHANGED_INIT:
                    const behaviorsToPlayByTargetId = filterAndFormatBehaviorsToPlay(this._behaviors, triggerArgs.componentAnimations, triggerArgs.modeChanges);
                    const registeredComponentAnimations = _.pick(triggerArgs.componentAnimations, _.keys(behaviorsToPlayByTargetId));
                    const unregisteredComponentAnimations = _.omit(triggerArgs.componentAnimations, _.keys(behaviorsToPlayByTargetId));

                    this.initiateBehaviors(triggerArgs.pageId, behaviorsToPlayByTargetId, registeredComponentAnimations, triggerArgs.transitioningComponentsPrevLayout, triggerArgs.onComplete);
                    this.handleReverseTransitionAnimations(triggerArgs.componentAnimations);
                    this.initCssTransitions();
                    triggerArgs.onComplete(unregisteredComponentAnimations);
                    break;
                case triggerTypes.MODE_CHANGED_EXECUTE:
                    this.hideElementsByAnimationType(this._behaviorsById);
                    this._aspectSiteAPI.registerToDidLayout(this.handleDidLayout.bind(this));
                    break;
            }
        }
    });

    /**
     * @exports ModeChangeAction
     */
    return ModeChangeAction;
});
