define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    function isPageStub(siteAPI, pageId) {
        if (!pageId) {
            return;
        }

        const rootIdsWhichShouldBeRendered = siteAPI.getRootIdsWhichShouldBeRendered();
        return !coreUtils.componentUtils.shouldBeRendered(rootIdsWhichShouldBeRendered, pageId);
    }

    function isPageCurrentlyRendered(siteAPI, pageId) {
        return !isPageStub(siteAPI, pageId);
    }

    function getRenderedPages(siteAPI, pageRefs) {
        return _(pageRefs)
            .omit('')
            .keys()
            .pickBy(pageId => !isPageStub(siteAPI, pageId))
            .value();
    }

    function getAllRenderedRootIds() {
        const masterPage = this.getMasterPage();
        //popups are rendered above the master page, so if there is no master page there are no roots
        if (!masterPage) {
            return [];
        }

        const siteData = this.props.siteData;
        const {siteAPI} = this;
        const pageRefs = getPageRefs(masterPage); //no need for fallback, since we always have pageRefs if we have masterpage
        if (!pageRefs) {
            return [];
        }
        const popupRefs = getPopupRefs(this);

        const currentPageId = siteData.getPrimaryPageId();
        const currentPopupId = siteData.getCurrentPopupId();

        // the below if is an optimization so we don't loop over all refs if not necessary
        // however, what if a popup is currently rendered, but 'shouldnt be'? i.e. siteData.getCurrentPopupId() => undefined, but it's still rendered (site didnt update yet) ?
        // this optimization wont be correct in this case... after discussing with Alissa, this probably doesn't matter as you don't really want to do anything with a popup which is closing
        // if it's a problem, we can remove this optimization and think of another way to optimize this if we need to
        if (isPageCurrentlyRendered(siteAPI, currentPageId) &&
            (!currentPopupId || isPageCurrentlyRendered(siteAPI, currentPopupId))) {
            return _.compact(['masterPage', currentPageId, currentPopupId]);
        }

        return _.flatten(['masterPage', getRenderedPages(siteAPI, pageRefs), getRenderedPages(siteAPI, popupRefs)]);
    }

    function getRootIdsWhichShouldBeRendered() {
        const siteData = this.props.siteData;
        //popups are rendered above the master page, so if there is no master page there are no roots
        if (!this.getMasterPage()) {
            const navInfo = siteData.getExistingRootNavigationInfo(siteData.getCurrentUrlPageId());
            const isAllowed = this.siteAPI.getSiteAspect('siteMembers').isPageAllowed(navInfo);
            const customSignupPageId = this.siteAPI.getSiteAspect('siteMembers').getCustomSignupPageId();

            if (!isAllowed) {
                return customSignupPageId ? [customSignupPageId] : [];
            }
        }

        return _.compact([
            'masterPage',
            siteData.getPrimaryPageId(),
            siteData.getCurrentPopupId()
        ]);
    }

    function getComponentsByPageId(rootId) {
        const page = this.getPageById(rootId);
        return page && page.compRefs;
    }

    function getComponentByPageAndCompId(rootId, compId) {
        const pageComponents = this.getComponentsByPageId(rootId);
        return pageComponents && pageComponents[compId];
    }

    function getPageRefs(page) {
        return _.get(page, ['compRefs', 'SITE_PAGES', 'refs']);
    }

    function getPopupRefs(site) {
        return _.get(site, ['refs', 'popupRoot', 'refs'], {});
    }

    return {
        getAllRenderedRootIds,
        getRootIdsWhichShouldBeRendered,
        getComponentsByPageId,
        getComponentByPageAndCompId,
        getPageRefs
    };
});
