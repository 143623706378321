define(['lodash', 'coreUtils'], function (_, coreUtils) {
    'use strict';

    const DATA_TYPES = coreUtils.constants.DATA_TYPES;
    const CONSTANTS_TYPES_TO_REFS_TYPES = coreUtils.constants.PAGE_DATA_DATA_TYPES;

    const COMP_DATA_ITEM_QUERY = coreUtils.constants.COMP_DATA_QUERY_KEYS_WITH_STYLE;

    const COMP_OVERRIDE_TYPES = {
        props: 'props',
        data: 'data',
        design: 'design',
        layout: 'layout',
        style: 'style',
        actionsBehaviors: 'actionsBehaviors',
        resetActionsBehaviors: 'resetActionsBehaviors',
        dynamicBehaviorsCounter: 'dynamicBehaviorsCounter'
    };

    const MASTER_PAGE = 'masterPage';
    const dataResolver = coreUtils.dataResolver;
    const runtimeDataPrefix = '_runtime_';

    function getPageId(pointer) {
        return this._pointers.full.components.getPageOfComponent(pointer).id;
    }

    function addEmptyDataItemToComp(compPointer, dataType, defaultValues, shouldNotAddId, dataId) {
        dataId = dataId || _.uniqueId(runtimeDataPrefix);
        //TODO: maybe we should set this after creating the data item
        const queryPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[dataType]);
        this._displayedDal.set(queryPointer, `#${dataId}`);

        const dataPointer = this._pointers.data.getItem(dataType, dataId, getPageId.call(this, compPointer));
        if (shouldNotAddId) {
            this._displayedDal.set(dataPointer, defaultValues);
        } else {
            this._displayedDal.set(dataPointer, _.assign({id: dataId}, defaultValues));
        }

        return dataPointer;
    }

    function cloneCompStyle(compPointer) {
        const queryPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[DATA_TYPES.theme]);
        const styleId = this._displayedDal.get(queryPointer);
        const clonedStyleId = styleId + _.uniqueId(runtimeDataPrefix);
        const stylePointer = this._pointers.data.getItem(DATA_TYPES.theme, styleId);
        const compStyle = this._displayedDal.get(stylePointer);
        const newCompStyle = _.defaults({id: clonedStyleId}, compStyle);
        const newStylePointer = this._pointers.data.getItem(DATA_TYPES.theme, clonedStyleId, MASTER_PAGE);
        this._displayedDal.set(queryPointer, clonedStyleId);
        this._displayedDal.set(newStylePointer, newCompStyle);
    }

    function shouldCloneInnerRefs(currentJsonValue, refType) {
        const dataRefsForType = dataResolver.getDataRefsForDataItems(refType, currentJsonValue.type);
        if (dataRefsForType) {
            const refs = _.keys(dataRefsForType);
            const refsVal = _.pick(currentJsonValue, refs);
            const notOverrideRefs = _.filter(refsVal, function (val) {
                return val && !_.includes(val.id, runtimeDataPrefix);
            });
            return !_.isEmpty(notOverrideRefs);
        }
        return false;
    }


    function takeIdsFromJson(runtimeValue, jsonVal, key) {
        //TODO: I don't thunk we should gues on types for nested items, it's dangerous - they don't have to be of same type
        if (key === 'type' && !runtimeValue) {
            if (jsonVal) {
                return true;
            }
            throw new Error('data items have to be passed with a type');
        }
        return key === 'id' && !_.includes(runtimeValue, runtimeDataPrefix);
    }

    function addTypeToRootDataItem(dataType, runtimeValue, jsonValue) {
        if (runtimeValue.type) {
            return runtimeValue;
        }
        let returnValue = null;
        if (jsonValue && jsonValue.type) {
            returnValue = _.assign({}, runtimeValue, {type: jsonValue.type});
        } else if (dataType === DATA_TYPES.prop) {
            returnValue = _.assign({}, runtimeValue, {type: 'DefaultProperties'});
        } else {
            throw new Error('data items have to be passed with a type');
        }
        return returnValue;
    }

    function getPageOfDataItem(pointers, dal, componentRootId, parentDataRootId, dataItemId, dataType) {
        return _.find([componentRootId, parentDataRootId], function (rootId) {
            const dataItemPointer = pointers.data.getItem(dataType, dataItemId, rootId);
            return dal.isExist(dataItemPointer);
        }) || parentDataRootId;
    }

    function setDataItemToDal(newRuntimeValue, dataType, rootDataPointer, compPointer) {
        const pageId = this._pointers.data.getPageIdOfData(rootDataPointer);
        const componentRootId = this._pointers.components.getPageOfComponent(compPointer) && this._pointers.components.getPageOfComponent(compPointer).id;
        const refType = CONSTANTS_TYPES_TO_REFS_TYPES[dataType];
        let currentJsonValue = this._siteData.getDataByQuery(rootDataPointer.id, pageId, refType);
        if (coreUtils.objectUtils.isSubset(currentJsonValue, newRuntimeValue)) { // we only want to update if the the dataItem properties have changed
            return rootDataPointer;
        }
        if (shouldCloneInnerRefs.call(this, currentJsonValue, refType)) {
            currentJsonValue = dataResolver.cloneDataItemWithNewRefIdsRecursively(currentJsonValue, refType, runtimeDataPrefix, this._siteData.getClonedDataItemsIdsMap(compPointer.id));
        }
        const runtimeWithType = addTypeToRootDataItem(dataType, newRuntimeValue, currentJsonValue);
        const dataToSet = coreUtils.objectUtils.cloneAndConditionalMergeOfFields(runtimeWithType, currentJsonValue, takeIdsFromJson);

        let flatMap = dataResolver.getFlatMapOfNestedItem(dataToSet, refType);
        flatMap = _.mapValues(flatMap, function (v) {
            return coreUtils.objectUtils.cloneDeep(v);
        });

        this._displayedDal.merge(rootDataPointer, flatMap[rootDataPointer.id]); //this is shallow merge
        delete flatMap[rootDataPointer.id];

        _.forOwn(flatMap, function (dataItem, dataItemId) {
            const dataItemPageId = getPageOfDataItem(this._pointers, this._displayedDal, componentRootId, pageId, dataItemId, dataType);
            const pointer = this._pointers.data.getItem(dataType, dataItemId, dataItemPageId);
            this._pointers.data.validatePath(pointer);
            this._displayedDal.set(pointer, dataItem);
        }.bind(this));

        return rootDataPointer;
    }

    function updateDataItem(dataType, runtimeValue, compPointer, shouldUpdateToFull) {
        let dataPointer = this._compPointersCache.getCompDataItemPointer(compPointer, dataType, shouldUpdateToFull);
        if (!dataPointer) {
            dataPointer = addEmptyDataItemToComp.call(this, compPointer, dataType);
        }

        if (runtimeValue[dataPointer.id] && dataType === DATA_TYPES.theme) {
            const styleIdPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[DATA_TYPES.theme]);

            const styleId = this._displayedDal.get(styleIdPointer);
            if (!styleId) {
                return null;
            }
            if (styleId && !_.includes(styleId, runtimeDataPrefix)) {
                cloneCompStyle.call(this, compPointer);
            }
            const currentStyle = this._displayedDal.get(dataPointer);
            runtimeValue = runtimeValue[dataPointer.id];
            _.forOwn(runtimeValue, function (val, key) {
                _.defaultsDeep(runtimeValue[key], currentStyle[key]);
            });
            return overrideTypeToMethod[COMP_OVERRIDE_TYPES.style].call(this, runtimeValue, compPointer);
        }

        return setDataItemToDal.call(this, runtimeValue, dataType, dataPointer, compPointer);
    }

    function updateProps(newRuntimeValue, compPointer, shouldUpdateToFull) { // eslint-disable-line complexity
        const dataType = DATA_TYPES.prop;
        let rootDataPointer = this._compPointersCache.getCompDataItemPointer(compPointer, dataType, shouldUpdateToFull);

        const isRepeatedComp = coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id);
        const isRepeatedCompData = rootDataPointer && coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(rootDataPointer.id);
        const shouldAddDisplayedData = isRepeatedComp && !isRepeatedCompData;

        if (!rootDataPointer || shouldAddDisplayedData) {
            const itemId = coreUtils.displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id);
            const originalId = coreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id);
            const baseId = rootDataPointer ? rootDataPointer.id : _.uniqueId(`${originalId}_props`);
            const dataId = shouldAddDisplayedData && coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(baseId, itemId);

            rootDataPointer = shouldAddDisplayedData ? addEmptyDataItemToComp.call(this, compPointer, dataType, {}, false, dataId) : addEmptyDataItemToComp.call(this, compPointer, dataType, {}, true);
        }

        const fullCompPointer = _.defaults({id: coreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)}, compPointer);
        const fullDataPointer = this._compPointersCache.getCompDataItemPointer(fullCompPointer, dataType, true);
        const dataInFull = fullDataPointer && this._documentAPI.getFullDataItem(fullDataPointer);
        const runtimeWithType = addTypeToRootDataItem(dataType, newRuntimeValue, dataInFull);
        const newValue = _.defaults({}, runtimeWithType, dataInFull);
        if (!coreUtils.objectUtils.isSubset(this._displayedDal.get(rootDataPointer), newValue)) {
            this._displayedDal.merge(rootDataPointer, newValue); //this is shallow merge
        }

        return rootDataPointer;
    }

    function updateActionsAndBehaviors(runtimeValue, compPointer) { // eslint-disable-line complexity
        const dataType = DATA_TYPES.behaviors;
        let dataPointer = this._compPointersCache.getCompDataItemPointer(compPointer, dataType);

        const isRepeatedComp = coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(compPointer.id);
        const isRepeatedCompData = dataPointer && coreUtils.displayedOnlyStructureUtil.isRepeatedComponent(dataPointer.id);
        const shouldAddDisplayedData = isRepeatedComp && !isRepeatedCompData;

        if (!dataPointer || shouldAddDisplayedData) {
            const itemId = coreUtils.displayedOnlyStructureUtil.getRepeaterItemId(compPointer.id);
            const originalId = coreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id);
            const baseId = dataPointer ? dataPointer.id : _.uniqueId(`${originalId}_behavior`);
            const dataId = shouldAddDisplayedData && coreUtils.displayedOnlyStructureUtil.getUniqueDisplayedId(baseId, itemId);

            dataPointer = addEmptyDataItemToComp.call(this, compPointer, dataType, {type: 'ObsoleteBehaviorsList'}, false, dataId);
        }

        //TODO: for now behaviors can't be overriden by modes but we should run conversion here..
        const fullCompPointer = _.defaults({id: coreUtils.displayedOnlyStructureUtil.getRepeaterTemplateId(compPointer.id)}, compPointer);
        const fullDataPointer = this._compPointersCache.getCompDataItemPointer(fullCompPointer, dataType, true);
        const dataInFull = fullDataPointer && this._documentAPI.getFullDataItem(fullDataPointer);
        const fullItems = dataInFull && dataInFull.items || []; // eslint-disable-line no-mixed-operators
        const parsedFullItems = _.isString(fullItems) ? JSON.parse(fullItems) : fullItems;
        const newItems = parsedFullItems.concat(runtimeValue); //we would use resolve if it wasn't caching the value
        const itemsPointer = this._pointers.getInnerPointer(dataPointer, 'items');
        const newValue = JSON.stringify(newItems);
        if (!_.isEqual(this._displayedDal.get(itemsPointer), newValue)) {
            this._displayedDal.set(itemsPointer, newValue);
        }

        return itemsPointer;
    }

    function resetActionsAndBehaviors(compPointer) {
        const dataType = DATA_TYPES.behaviors;
        const dataPointer = this._compPointersCache.getCompDataItemPointer(compPointer, dataType);

        const itemsPointer = this._pointers.getInnerPointer(dataPointer, 'items');
        this._displayedDal.set(itemsPointer, JSON.stringify({}));

        return itemsPointer;
    }

    const overrideTypeToMethod = {};
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.dynamicBehaviorsCounter] = _.noop;
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.data] = _.partial(updateDataItem, DATA_TYPES.data);
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.style] = _.partial(updateDataItem, DATA_TYPES.theme);
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.design] = _.partial(updateDataItem, DATA_TYPES.design);
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.props] = updateProps;
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.actionsBehaviors] = updateActionsAndBehaviors;
    overrideTypeToMethod[COMP_OVERRIDE_TYPES.resetActionsBehaviors] = resetActionsAndBehaviors;

    overrideTypeToMethod[COMP_OVERRIDE_TYPES.layout] = function (runtimeValue, compPointer) {
        const layoutPointer = this._pointers.getInnerPointer(compPointer, 'layout');
        this._displayedDal.merge(layoutPointer, runtimeValue);
        return layoutPointer;
    };

    Object.freeze(overrideTypeToMethod);

    function RuntimeToDisplayed(siteData, displayedDal, pointers, pointersCache, documentAPI) {
        this._pointers = pointers;
        this._displayedDal = displayedDal;
        this._siteData = siteData;
        this._compPointersCache = pointersCache;
        this._documentAPI = documentAPI;
    }

    function getCompPointer(pointerOrId, shouldResolveFromFull) {
        let pointer = null;
        if (_.isPlainObject(pointerOrId)) {
            pointer = pointerOrId;
        } else {
            pointer = this._compPointersCache.getCompPointer(pointerOrId, this._siteData.getViewMode(), this._siteData.getAllPossiblyRenderedRoots());
        }

        if (shouldResolveFromFull) {
            return pointer;
        }
        return pointer && this._displayedDal.isExist(pointer) ? pointer : null;
    }

    RuntimeToDisplayed.prototype = {
        updateDataItem(compIdOrPointer, dataType, runtimeValue, shouldUpdateToFull) {
            const compPointer = getCompPointer.call(this, compIdOrPointer, shouldUpdateToFull);
            if (!compPointer) {
                return null;
            }
            return overrideTypeToMethod[dataType].call(this, runtimeValue, compPointer, shouldUpdateToFull);
        },

        updateActionsAndBehaviorsItems(compIdOrPointer, runtimeAddedItems) {
            const compPointer = getCompPointer.call(this, compIdOrPointer);
            if (!compPointer) {
                return null;
            }
            return overrideTypeToMethod[COMP_OVERRIDE_TYPES.actionsBehaviors].call(this, runtimeAddedItems, compPointer);
        },

        resetActionsAndBehaviorsItems(compIdOrPointer) {
            const compPointer = getCompPointer.call(this, compIdOrPointer);
            if (!compPointer) {
                return null;
            }
            return overrideTypeToMethod[COMP_OVERRIDE_TYPES.resetActionsBehaviors].call(this, compPointer);
        },

        updateCompLayout(compIdOrPointer, runtimeValue) {
            const compPointer = getCompPointer.call(this, compIdOrPointer);
            if (!compPointer) {
                return null;
            }
            return overrideTypeToMethod[COMP_OVERRIDE_TYPES.layout].call(this, runtimeValue, compPointer);
        },

        updateCompStyle(compIdOrPointer, runtimeValue) {
            const compPointer = getCompPointer.call(this, compIdOrPointer);
            if (!compPointer) {
                return null;
            }
            const styleIdPointer = this._pointers.getInnerPointer(compPointer, COMP_DATA_ITEM_QUERY[DATA_TYPES.theme]);
            const styleId = this._displayedDal.get(styleIdPointer);
            if (!styleId) {
                return null;
            }
            if (styleId && !_.includes(styleId, runtimeDataPrefix)) {
                cloneCompStyle.call(this, compPointer);
            }
            const stylePointer = this._pointers.data.getItem(DATA_TYPES.theme, styleId);
            const currentStyle = this._displayedDal.get(stylePointer);
            _.forOwn(runtimeValue, function (val, key) {
                _.defaultsDeep(runtimeValue[key], currentStyle[key]);
            });
            return overrideTypeToMethod[COMP_OVERRIDE_TYPES.style].call(this, runtimeValue, compPointer);
        },

        updateAll(allRuntimeComps) {
            const self = this;
            // var allRuntimeComps = runtimeData.components;
            const compPointers = _.map(allRuntimeComps, function (compRuntimeData, compId) {
                const compOverrides = compRuntimeData.overrides;
                const compPointer = getCompPointer.call(self, compId);
                if (!compPointer) {
                    return null;
                }

                _.forOwn(compOverrides, function (overrideValue, overrideType) {
                    overrideTypeToMethod[overrideType].call(self, overrideValue, compPointer);
                });

                return compPointer;
            });

            return _.compact(compPointers);
        },

        OVERRIDE_TYPES: COMP_OVERRIDE_TYPES
    };

    return RuntimeToDisplayed;
});
