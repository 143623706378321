define(['lodash', 'zepto', 'componentsCore'],
    function (_, $, componentsCore) {
        'use strict';

        /**
         *
         * @param {core.SiteAspectsSiteAPI} aspectSiteAPI
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function WindowResizeEventAspect(aspectSiteAPI) {
            /** @type {core.SiteAspectsSiteAPI} */
            this._aspectSiteAPI = aspectSiteAPI;
            this._registeredCompIds = {};
            this._registeredCompIdsOrientation = {};
            this._aspectSiteAPI.registerToResize(this.propagateResizeEvent.bind(this));
            this._aspectSiteAPI.registerToOrientationChange(this.propagateOrientationEvent.bind(this));
        }

        WindowResizeEventAspect.prototype = {
            registerToResize(comp) {
                this._registeredCompIds[comp.props.id] = comp.props.id;
            },

            unregisterToResize(comp) {
                delete this._registeredCompIds[comp.props.id];
            },
            registerToOrientationChange(comp) {
                this._registeredCompIdsOrientation[comp.props.id] = comp.props.id;
            },

            unregisterToOrientationChange(comp) {
                delete this._registeredCompIdsOrientation[comp.props.id];
            },

            propagateResizeEvent() {
                let windowSize = null;
                _.forEach(this._registeredCompIds, function (compId) {
                    const listener = this._aspectSiteAPI.getComponentById(compId);
                    if (!listener) {
                        delete this._registeredCompIds[compId];
                    } else if (listener.onResize) {
                        if (!windowSize) {
                            const body = $(window.document.body);
                            windowSize = {
                                'height': body.height(), 'width': body.width()
                            };
                        }
                        listener.onResize(windowSize);
                    }
                }.bind(this));
            },

            propagateOrientationEvent() {
                _.forEach(this._registeredCompIdsOrientation, function (compId) {
                    const listener = this._aspectSiteAPI.getComponentById(compId);
                    if (!listener) {
                        delete this._registeredCompIdsOrientation[compId];
                    } else if (listener.onOrientationChange) {
                        listener.onOrientationChange();
                    }
                }.bind(this));
            }
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('windowResizeEvent', WindowResizeEventAspect);
    });
