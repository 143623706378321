define([
    'santa-components',
    'lodash',
    'prop-types',
    'create-react-class',
    'mobx-react',
    'components'
], function (santaComponents, _, PropTypes, createReactClass, mobxReact, components) {
    'use strict';

    const scriptClass = components.scriptClass;

    return mobxReact.observer(
        createReactClass({
            displayName: 'externalScriptContainer',
            propTypes: {
                siteAPI: PropTypes.shape({
                    getAspectGlobalData: PropTypes.func.isRequired
                }).isRequired
            },

            getScriptComponents() {
                const scripts = this.props.siteAPI.getAspectGlobalData('externalScriptLoader');
                return _.map(scripts, function getNewScript(scriptData, scriptName) { //eslint-disable-line react/display-name
                    return santaComponents.utils.createReactElement(scriptClass, {
                        key: scriptName,
                        ref: scriptName,
                        scriptData
                    });
                });
            },

            render() {
                return santaComponents.utils.createReactElement('div', null, this.getScriptComponents());
            }
        }));
});
