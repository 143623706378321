define(['lodash', 'componentsCore'], function (_, componentsCore) {
    'use strict';

    function PopupPageAspect(aspectSiteApi) {
        this._aspectSiteAPI = aspectSiteApi;
        this._registeredCloseHandlers = {};

        _.bindAll(this, ['registerCloseHandler', 'unregisterCloseHandler', 'closePopupPage', 'openPopupPage']);
    }

    PopupPageAspect.prototype = {
        registerCloseHandler(popupId, callback) {
            this._registeredCloseHandlers[popupId] = callback;
        },

        unregisterCloseHandler(popupId) {
            this._registeredCloseHandlers = _.omit(this._registeredCloseHandlers, popupId);
        },

        closePopupPage() {
            const currentPopupId = this._aspectSiteAPI.getCurrentPopupId();
            if (!currentPopupId) {
                return;
            }

            this._aspectSiteAPI.closePopupPage();

            if (_.isFunction(this._registeredCloseHandlers[currentPopupId])) {
                this._registeredCloseHandlers[currentPopupId].apply(this, arguments);
                this.unregisterCloseHandler(currentPopupId);
            }
        },

        openPopupPage(popupId) {
            this._aspectSiteAPI.openPopupPage(popupId);
        }
    };

    componentsCore.siteAspectsRegistry.registerSiteAspect('PopupPageAspect', PopupPageAspect);

    return PopupPageAspect;
});
