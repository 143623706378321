define(['lodash', 'core/core/pageItemRegistry', 'componentsCore'],
    function (_, /** core.pageItemRegistry */ pageItemRegistry, componentsCore) {
        'use strict';

        function tryToParsePageItemAdditionalData(rawAdditionalData) {
            const statementsSeparator = ' ';
            const keyValuesSeparator = ':';
            const parsedPageItemAdditionalData = {};
            if (!rawAdditionalData) {
                return;
            }

            const statements = rawAdditionalData.split(statementsSeparator);
            _.forEach(statements, function (statement) {
                const keyValueArr = statement.split(keyValuesSeparator);
                if (keyValueArr[0] && keyValueArr[1]) {
                    parsedPageItemAdditionalData[keyValueArr[0]] = keyValueArr[1];
                }
            });

            return _.isEmpty(parsedPageItemAdditionalData) ? rawAdditionalData : parsedPageItemAdditionalData;
        }

        function getPageItemAdditionalData(siteData) {
            const result = {};
            const pageId = siteData.getFocusedRootId();
            const pageItemInfo = siteData.getExistingRootNavigationInfo(pageId);
            const parsedPageItemAdditionalData = tryToParsePageItemAdditionalData(pageItemInfo.pageItemAdditionalData);

            if (_.isPlainObject(parsedPageItemAdditionalData)) {
                const galleryData = siteData.getDataByQuery(parsedPageItemAdditionalData.galleryId) || parsedPageItemAdditionalData.galleryId;

                if (galleryData) {
                    result.pageItemAdditionalData = galleryData;
                }

                if (parsedPageItemAdditionalData.galleryCompId) {
                    result.galleryCompId = parsedPageItemAdditionalData.galleryCompId;
                }
            } else {
                result.pageItemAdditionalData = parsedPageItemAdditionalData;
            }
            return result;
        }

        function getPageItemStructure(siteData) {
            const pageItemInfo = siteData.getExistingRootNavigationInfo(siteData.getFocusedRootId());
            const dataItem = siteData.getDataByQuery(pageItemInfo.pageItemId);
            if (!dataItem) {
                return null;
            }

            const parsedPageItemAdditionalData = tryToParsePageItemAdditionalData(pageItemInfo.pageItemAdditionalData);
            const propertyQuery = _.get(parsedPageItemAdditionalData, 'propertyQuery');
            const structure = pageItemRegistry.getCompStructure(dataItem.type, dataItem, propertyQuery, siteData);
            if (!structure) {
                return null;
            }
            return structure;
        }

        function getComponentsToRender(aspectsSiteAPI) {
            const siteData = aspectsSiteAPI.getSiteData();
            const structure = getPageItemStructure(siteData);
            if (!structure) {
                return null;
            }
            return [{
                structure,
                pageId: siteData.getFocusedRootId(),
                props: getPageItemAdditionalData(siteData)
            }];
        }

        /**
         *
         * @implements {core.SiteAspectInterface}
         * @constructor
         */
        function PageItemAspect() {
        }

        PageItemAspect.prototype = {
            getComponentsToRender
        };

        componentsCore.siteAspectsRegistry.registerSiteAspect('pageItem', PageItemAspect);
    });
